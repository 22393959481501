import { updateBasketList } from '../../../../conteiners/Basket/basketSlice'
import { selectUser } from '../../../../conteiners/Login/loginSlice'
import { initialExp, finalExp } from '../../../../utils/validation'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'

const Form = ({ back, list }) => {
  const user = useSelector(selectUser)
  const [form, setForm] = useState({
    email: user.email || '',
    phone: user.phone || ''
  })

  const { email, phone } = form
  const dispatch = useDispatch()

  const isFormValid = (() => {
    return phone.match(finalExp.phone) && email.match(finalExp.mail)
  })()

  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    if ((name === 'phone' ? value.match(initialExp.phone) : value.match(initialExp.mail))) setForm({
      ...form,
      [name]: value
    })
  }

  const notify = () => {
    dispatch(updateBasketList(list.reduce((r, { identifier }) => {
      r = {
        ...r,
        [identifier]: { notify: { phone, email } }
      }
      return r
    }, {})))
    back()
  }

  return (
    <div className='popup--newPlace popup__reservationFailed'>
      <p>
        <span>Powiadomienia</span>
        <span>Uzupełnij aby otrzymywać inf. o magazynie</span>
      </p>

      <div className='popup__input'>
        {phone && <div className='input__label'>Numer telefonu</div>}
        <input
          value={phone}
          name='phone'
          type='tel'
          placeholder='Numer telefonu'
          onChange={changeHandler} />
      </div>

      <div className='popup__input'>
        {email && <div className='input__label'>Adres e-mail</div>}
        <input
          value={email}
          name='email'
          placeholder='Adres e-mail'
          onChange={changeHandler} />
      </div>

      <div className='popup__buttons center'>
        <div
          className='button__cancel --termina'
          onClick={back}>Zamknij</div>
        <div
          className='button__submit --termina'
          style={isFormValid ? undefined: { opacity: 0.67 }}
          onClick={() => isFormValid && notify()}>Zgłoś</div>
      </div>
    </div>
  )
}

export default Form