import useTranslation from '../../../hooks/useTranslation'
import { useHistory, useLocation } from 'react-router-dom'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'

const ErrorPopup = () => {

  const { t } = useTranslation('basic')

  const { goBack } = useHistory()
  const { state: { message } = {} } = useLocation()

  return (
    <Popup>
      <div
        className='popup__verification'
        style={{ paddingTop: 0 }}>

        <div className='popup__title'>
          <div>{t('caution')}</div>
        </div>
        <p>{message}</p>

        <Submit
          text='OK'
          action={goBack} />
      </div>
    </Popup>
  )
}

export default ErrorPopup