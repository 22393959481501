import { ReactComponent as Backspace } from '../../assets/backspace.svg'
import useTranslation from '../../hooks/useTranslation'
import './style.scss'

const Keyboard = ({ showBackspace = true, click }) => {
  const { t } = useTranslation('basic')
  const keys = [[1, 2, 3], [4, 5, 6], [7, 8, 9]]

  return (
    <div className='keyboard--numeric'>
      {keys.map((l, i) => <div key={i} className='keyboard__row'>
        {l.map(e => <div
          key={e}
          onClick={() => click(e)}
          className='keyboard__key'>
          <span>{e}</span>
        </div>)}
      </div>)}

      <div className='keyboard__row'>
        <div className='keyboard__key --backspace' onClick={() => click(-1)}>
          {showBackspace ? <Backspace /> : <span>{t('back')}</span>}
        </div>

        <div className='keyboard__key' onClick={() => click(0)}>
          <span>0</span>
        </div>

        <div className='keyboard__key --ok' onClick={() => click('ok')}>
          <span>OK</span>
        </div>
      </div>
    </div>
  )
}

export default Keyboard