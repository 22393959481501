import { ReactComponent as Cost } from '../../../assets/cost.svg'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import BackBtn from '../../../components/buttons/BackBtn'
import Popup from '../../../components/popups/Popup'
import { useHistory } from 'react-router-dom'
import Item from './Item'

const OutsourcePhotoPopup = () => {
  const { goBack } = useHistory()

  return (
    <Popup>
      <div className='title'>
        <div>Zleć to nam</div>
        <div>Nie możesz osobiście zrobić zdjęcia?<br />Możesz zlecić to nam</div>
      </div>

      <div className='popup--photo'>
        <div className='resignation__list'>
          <div className='list__label'>Po co zdjęcia?</div>

          <Item
            icon={<Cost />}
            title='Płacisz 20zł'
            desc='My wysyłamy pracownika'
          />

          <Item
            title='Pracownik ogląda MAGa'
            desc='i sprawdza czy wszystko jest w porzadku'
          />
        </div>


        <div className='resignation__buttons'>
          <ArrowBtn
            enlarged
            text='Dalej'
            action={goBack}
          />

          <BackBtn
            text='Wróć'
            action={goBack}
          />
        </div>
      </div>
    </Popup>
  )
}

export default OutsourcePhotoPopup