import { errorMessage, finalExp, CARD_TYPE, isValidPesel } from '../../../utils/validation'
import { selectForm, updateValue } from '../formSlice'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../../components/inputs/Select'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import InputWithOptions from './InputWithOptions'
import { useState } from 'react'

const Form = ({ block, submit }) => {
  const { city = '', postalCode = '', street = '', houseNumber = '', localNumber = '', pesel = '', streetPrefix, cityDistricted, cardType = CARD_TYPE.idCard, identityCard = '' } = useSelector(selectForm)

  const [warning, setWarning] = useState({
    city: false,
    postalCode: false,
    street: false,
    houseNumber: false,
    pesel: false,
    identityCard: false,
  })

  const dispatch = useDispatch()

  const refs = []

  const changeHandler = e => {
    if (!e) return

    const { name, value } = e.target
    dispatch(updateValue({ name, value }))

    if (name === 'cardType' && identityCard) dispatch(updateValue({ name: 'identityCard', value: '' }))
    if (value && warning[name] && ((name === 'houseNumber') ||
      ((name === 'city' || name === 'street') && value.length > 1) ||
      (name === 'postalCode' && value.length === 6) ||
      (name === 'pesel' && isValidPesel(value)) ||
      (name === 'identityCard' && value.match(finalExp[cardType]))))
      setWarning({
        ...warning,
        [name]: false,
      })
  }

  const blurHandler = e => {
    if (!e) return
    const { name, value } = e.target
    let warn = false

    switch (name) {
      case 'houseNumber':
        warn = !value ? 'niewłaściwy numer' : false
        break
      case 'postalCode':
        warn = !value.match(finalExp.postalCode) ? '**-***' : false
        break
      case 'pesel':
        warn = value.length < 11 ? errorMessage(11 - value.length) : !isValidPesel(value) ? 'niewłaściwy PESEL' : false
        break
      case 'identityCard':
        if ((cardType === CARD_TYPE.idCard || cardType === CARD_TYPE.passport) && value.length < 9) warn = errorMessage(9 - value.length)
        else if (cardType === CARD_TYPE.drivingLicense && value.length < 12) warn = errorMessage(12 - value.length)
        else if (!value.match(finalExp[cardType])) warn = 'niewłaściwy numer'
        break
      default: warn = value.length < 2 ? errorMessage(2 - value.length) : false
    }

    if (warn || (warning[name] && !warn)) setWarning({
      ...warning,
      [name]: warn,
    })
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const validation = () => {
    const peselOK = isValidPesel(pesel)
    const codeOK = !!postalCode.match(finalExp.postalCode)
    const idOK = !!identityCard.match(finalExp[cardType])

    if (city.length > 1 && codeOK && street.length > 1 && houseNumber && peselOK && idOK) {
      const body = { city, zip_code: postalCode, street, building_nr: houseNumber, apartment_nr: localNumber, pesel, identity_card_type: cardType, identity_card: identityCard }
      submit(body)

    } else setWarning({
      city: city.length < 2 ? errorMessage(2 - city.length) : false,
      postalCode: !codeOK ? '**-***' : false,
      street: street.length < 2 ? errorMessage(2 - street.length) : false,
      houseNumber: !houseNumber ? 'niewłaściwy numer' : false,
      pesel: pesel.length < 11 ? errorMessage(11 - pesel.length) : !peselOK ? 'niewłaściwy PESEL' : false,
      identityCard: !idOK ? 'niewłaściwy numer' : false,
    })
  }

  return (
    <div className='contract__form form'>
      <InputWithOptions
        required
        ref={r => refs.push(r)}
        value={city}
        name='city'
        label='Miejscowość'
        minLength='2'
        maxLength='200'
        disabled={block}
        warning={warning.city}
        placeholder='Wpisz miejscowość'
        enterClicked={enterClicked}
        changeHandler={changeHandler}
        blurHandler={blurHandler} />

      <InputWithOptions
        required
        ref={r => refs.push(r)}
        value={street}
        name='street'
        label='Ulica'
        minLength='2'
        maxLength='200'
        disabled={block}
        additionalValues={{ city }}
        warning={warning.street}
        placeholder='Wpisz ulicę'
        enterClicked={enterClicked}
        changeHandler={changeHandler}
        blurHandler={blurHandler} />

      <InputWithOptions
        required
        ref={r => refs.push(r)}
        value={houseNumber}
        name='houseNumber'
        label='Numer domu'
        maxLength='10'
        disabled={block}
        additionalValues={city.length > 1 && street.length > 1 ? { city, cityDistricted: cityDistricted || city, street, houseNumber } : false}
        warning={warning.houseNumber}
        placeholder='Wpisz numer domu'
        enterClicked={enterClicked}
        changeHandler={changeHandler}
        blurHandler={blurHandler} />

      <Input
        ref={r => refs.push(r)}
        value={localNumber}
        name='localNumber'
        type='tel'
        label='Numer mieszkania'
        maxLength='10'
        disabled={block}
        placeholder='Wpisz numer domu'
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <InputWithOptions
        required
        ref={r => refs.push(r)}
        value={postalCode}
        name='postalCode'
        label='Kod pocztowy'
        maxLength='6'
        disabled={block}
        additionalValues={city.length > 1 && street.length > 1 ? { city, street, houseNumber, streetPrefix } : false}
        warning={warning.postalCode}
        placeholder='Wpisz kod pocztowy'
        enterClicked={enterClicked}
        changeHandler={e => {
          e.target.value = e.target.value.replace(/^(\d{2})(\d+)$/, '$1-$2')
          changeHandler(e)
        }}
        blurHandler={blurHandler} />

      <div className='form__info'>
        <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="18.5" viewBox="0 0 18.5 18.5">
          <g id="help-circle" transform="translate(0.75 0.75)">
            <circle id="Ellipse_385" data-name="Ellipse 385" cx="8.5" cy="8.5" r="8.5" fill="none" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Path_728" data-name="Path 728" d="M9.09,8.691a2.538,2.538,0,0,1,4.931.846c0,1.692-2.538,2.538-2.538,2.538" transform="translate(-3.093 -2.77)" fill="none" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <line id="Line_78" data-name="Line 78" transform="translate(8 13)" fill="none" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </g>
        </svg>

        <span>Dlaczego potrzebujemy Twoje dane?</span>
      </div>

      <Input
        required
        ref={r => refs.push(r)}
        value={pesel}
        name='pesel'
        type='password'
        label='PESEL'
        minLength='11'
        maxLength='11'
        disabled={block}
        warning={warning.pesel}
        placeholder='Wpisz PESEL'
        enterClicked={enterClicked}
        changeHandler={changeHandler}
        blurHandler={blurHandler} />

      <Select
        label='Typ dokumentu'
        value={cardType}
        name='cardType'
        changeHandler={changeHandler}
        options={Object.entries(CARD_TYPE).map(([_, v], i) => <option key={i} value={v}>
          {`Numer ${v === CARD_TYPE.idCard ? 'dowodu' : v === CARD_TYPE.passport ? 'paszportu' : 'prawa jazdy'}`}
        </option>)} />

      <Input
        required
        ref={r => refs.push(r)}
        value={identityCard}
        name='identityCard'
        type='password'
        label={`Nr ${cardType === CARD_TYPE.idCard ? 'dowodu' : cardType === CARD_TYPE.passport ? 'paszportu' : 'prawa jazdy'}`}
        disabled={block}
        warning={warning.identityCard}
        placeholder={`Wpisz nr ${cardType === CARD_TYPE.idCard ? 'dowodu' : cardType === CARD_TYPE.passport ? 'paszportu' : 'prawa jazdy'}`}
        enterClicked={enterClicked}
        changeHandler={changeHandler}
        blurHandler={blurHandler} />

      <Submit
        text='Zapisz'
        action={() => !block && validation()} />
    </div>
  )
}

export default Form