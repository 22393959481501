const LocationSection = ({ data }) => {
  const { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude } = data || {}

  return (
    <div>
      <div className='list__header element__title'>Lokalizacja</div>
      <div className='list__element --big'>
        <div className='group'>
          <div className='element__desc'>
            <div>{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}</div>
            <div>pow. {province}</div>
            <div>{city}</div>
          </div>
          <div
            className='element__desc'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${gpslatitude},${gpslongitude}`)}>Pokaż</div>
        </div>
      </div>
    </div>
  )
}

export default LocationSection