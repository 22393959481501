import { ReactComponent as Terminate } from '../../../assets/terminateRent.svg'
import { ReactComponent as Insurance } from '../../../assets/insurance.svg'
import { ReactComponent as Payments } from '../../../assets/payments.svg'
import { ReactComponent as Mag } from '../../../assets/magDetails.svg'
import { ReactComponent as Share } from '../../../assets/share.svg'
import { LOCATION_STATES } from '../../../utils/constants'
import useTranslation from '../../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import { ACCOUNT_TYPE } from '../accountSlice'

const ActionsButton = ({ action, text, Icon }) => <div
  className='menu__button center'
  onClick={action}>
  <div className='center'>
    <Icon />
  </div>
  <span>{text}</span>
</div>

const Actions = () => {

  const { t } = useTranslation('account')
  const { push } = useHistory()

  const goTo = route => push(`/account/${route}`)

  return (
    <div className='account__buttons'>
      <div className='buttons__row center'>
        <ActionsButton
          Icon={Mag}
          text={t('basic')}
          action={() => goTo(ACCOUNT_TYPE.basic)} />

        <hr />

        <ActionsButton
          Icon={Terminate}
          text={t('companies')}
          action={() => goTo(ACCOUNT_TYPE.company)} />

        <hr />

        <ActionsButton
          Icon={Share}
          text={t('invoices')}
          action={() => goTo(ACCOUNT_TYPE.invoices)} />
      </div>

      <hr />

      <div className='buttons__row center'>
        <ActionsButton
          Icon={Payments}
          text={t('payments')}
          action={() => goTo(ACCOUNT_TYPE.payments)} />

        <hr />

        <ActionsButton
          Icon={Payments}
          text={`${t('payments')} - Legacy`}
          action={() => push(window.location.pathname, LOCATION_STATES.popup(ACCOUNT_TYPE.payments))} />

        <hr />

        <ActionsButton
          Icon={Insurance}
          text={t('settings')}
          action={() => goTo(ACCOUNT_TYPE.settings)} />
      </div>
    </div>
  )
}

export default Actions