const KEY_NAME = 'redux_state'

export const loadState = (session = true, name = KEY_NAME, defaultVal = {}) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const state = storage.getItem(name)
    return state ? JSON.parse(state) : defaultVal

  } catch (err) {
    console.log('loadState', err)
    return defaultVal
  }
}

export const saveState = (state, session = true, name = KEY_NAME) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const stringState = JSON.stringify(state)
    storage.setItem(name, stringState)

  } catch (err) {
    console.log('saveState', err)
  }
}