import { selectBasket } from '../conteiners/Basket/basketSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useMagPrice = () => {

  const { toPay } = useSelector(selectBasket)
  const [final, setFinal] = useState({
    amount: 0,
    price: 0
  })

  useEffect(() => {
    const values = Object.values(toPay)
    if (values.length > 0) {
      const price = values.reduce((r, { priceSum }) => r += Number(priceSum), 0)
      setFinal({
        amount: values.length,
        price: price.toFixed(2)
      })
    }
  }, [toPay])

  return final
}

export default useMagPrice