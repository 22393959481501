import Popup from '../../../components/popups/Popup'
import Sygnet from '../Map/Sygnet'

const legend = [
  { color: 'red', desc: 'MAG dostępny bez klucza można otworzyć zdalnie z telefonu' },
  { color: 'black', desc: 'Zamknięty na kłódkę z szyfrem który otrzymasz po wynajęciu MAGa' },
  { color: 'yellow', desc: 'W tym miejscu budujemy nowy plac z Magami.' },
]

const LegendPopup = () => {
  return (
    <Popup>
      <div className='popup__legend'>
        <div className='popup__title'>
          <div>Legenda mapy</div>
          <div>Zobacz opisy znaczników</div>
        </div>

        <div className='legend__list'>
          {legend.map(({ color, desc }, i) => <div
            key={i}
            className='list__item center'>
            <Sygnet color={color} />
            <div>{desc}</div>
          </div>)}
        </div>
      </div>
    </Popup>
  )
}

export default LegendPopup