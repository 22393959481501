import { loadWholeState, saveState } from './storage'
import { useEffect, useState } from 'react'
import './style.scss'

const AppLogin = ({ login }) => {
  const [checkbox, setCheckbox] = useState(false)
  const [passwd, setPasswd] = useState('')

  useEffect(() => {
    const key = loadWholeState()
    if (key === process.env.REACT_APP_PASSWORD) login(true)
  }, [login])

  const change = e => {
    const { value } = e.target
    if (value === '\n') return
    if (value === process.env.REACT_APP_PASSWORD) {
      saveState(value, !checkbox)
      login(true)

    } else setPasswd(e.target.value)
  }

  return (
    <div className='app__login'>
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="44" viewBox="0 0 46 44">
        <g id="logo" transform="translate(-504.88 -150)">
          <path id="Frame" d="M506.349,185a1.469,1.469,0,0,0-1.469,1.469V201.04a1.469,1.469,0,0,0,1.469,1.469h.711v-1.2H506.3a.268.268,0,0,1-.267-.267V186.47a.268.268,0,0,1,.267-.267h1.971v-.44a4.072,4.072,0,0,1,.075-.762Zm20.885,0h-1.983a3.177,3.177,0,0,1,.044.5v.7h1.987a.267.267,0,0,1,.267.267v14.568a.267.267,0,0,1-.267.267h-.777v1.2h.728a1.469,1.469,0,0,0,1.47-1.469V186.469A1.469,1.469,0,0,0,527.234,185Z" transform="translate(0 -32.661)" fill="#181818" />
          <path id="M" d="M590.883,171.826a.152.152,0,0,1-.043,0h-2.978a.156.156,0,0,1-.176-.132.152.152,0,0,1,0-.043V153.542h-2.1l0,14.9v3.281c0,.068-.058.1-.175.1H582.43c-.117,0-.176-.034-.176-.1V153.542h-2.1v18.109a.155.155,0,0,1-.133.175.152.152,0,0,1-.043,0H577a.156.156,0,0,1-.176-.132.179.179,0,0,1,0-.043V153.236a3.493,3.493,0,0,1,.123-.9A2.942,2.942,0,0,1,579.863,150h7.926a2.957,2.957,0,0,1,3.137,2.339,2.988,2.988,0,0,1,.089.677v18.635A.155.155,0,0,1,590.883,171.826Z" transform="translate(-67.138)" fill="#181818" />
        </g>
      </svg>

      <div className='login__main'>
        <div className='login__title'>Wpisz tajny kod:</div>
        <div className='login__form'>
          <input
            type='text'
            value={passwd}
            onChange={change} />

          <div className='login__checkbox'>
            <label htmlFor='login_checkbox'>Zapamiętaj mnie</label>
            <input
              id='login_checkbox'
              type='checkbox'
              checked={checkbox}
              onChange={() => setCheckbox(!checkbox)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppLogin