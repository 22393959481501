import { useHistory, useParams } from 'react-router-dom'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import { MAG_STATUS } from '../../utils/constants'
import { saveFirstLaunch } from './storage'
import { useEffect } from 'react'

const SplashButton = ({ mags, setMags, close }) => {

  const { number } = useParams()
  const { push } = useHistory()

  const numValue = Number(number || 0)
  const lastIndex = mags.length - 1
  const isLast = lastIndex === numValue
  const status = mags[lastIndex].status

  const updateStatus = s => {
    if (status !== MAG_STATUS.closed) return
    setMags(m => {
      const copy = [...m]
      copy[lastIndex].status = s
      return copy
    })
  }

  useEffect(() => {
    if (isLast && status === MAG_STATUS.opening) {
      const timeout = setTimeout(() => {
        saveFirstLaunch()
        close()
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [close, isLast, status])

  return (
    <div className='splash__button'>
      <ArrowBtn
        enlarged
        text={isLast ? 'Zaczynamy' : 'Dalej'}
        action={() => isLast ? updateStatus(MAG_STATUS.opening) : push(`/splash/${number ? numValue + 1 : '1'}`)} />
    </div>
  )
}

export default SplashButton