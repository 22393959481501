import { ReactComponent as Logo } from '../../../assets/logo.svg'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { useSelector } from 'react-redux'
import { selectForm } from '../formSlice'
import { useCallback } from 'react'

const ConfirmationPopup = ({ status, submit, close }) => {
  const { phone = '' } = useSelector(selectForm)

  const phoneWithSpaces = (useCallback(() =>
    phone.replace(/^([\d]{3})([\d]{3})([\d]{3})$/, '$1 $2 $3'), [phone])
  )()

  return (
    <Popup close={() => status === STATUS.idle && close()}>
      <div className='popup__verification'>
        <Logo />

        <h2>Autoryzacja SMS</h2>

        <div className='verification__text'>
          <div>Wyślemy sms z kodem</div>
          <div>weryfikacyjnym na numer</div>
        </div>

        <div className='verefication__phone'>
          {status === STATUS.pennding ? <Loading /> : <>
            <h2>{phoneWithSpaces}</h2>

            <div
              className='verefication__button'
              onClick={close}>
              Zmień
            </div>
          </>}
        </div>

        <Submit
          text='Wyślij kod'
          block={status === STATUS.pennding}
          action={submit} />
      </div>
    </Popup>
  )
}

export default ConfirmationPopup