import useMagPrice from '../../hooks/useMagPrice'

const PriceSection = () => {
  const { amount, price } = useMagPrice()

  return (
    <div>
      <div className='list__header element__title'>Podsumowanie</div>
      <div className='list__element --big'>
        <div className='element__desc'>
          <div>Do zapłaty: <span className='--marked'>{price} zł</span></div>
        </div>

        <div className='element__desc'>
          <div>Ilość MAGów: <span className='--marked'>{amount}</span></div>
        </div>
      </div>
    </div>
  )
}

export default PriceSection