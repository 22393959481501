import BasketConteiner from '../../conteiners/Basket/BasketConteiner'
import Registration from '../../conteiners/forms/Registration'
import SubWarehouse from '../../conteiners/Warehouse/SubMenu'
import EntranceLogin from '../../conteiners/Entrance/Login'
import { Switch, Redirect, Route } from 'react-router-dom'
import NewWarehouse from '../../conteiners/NewWarehouse'
import FirstOpening from '../../conteiners/FirstOpening'
import Contract from '../../conteiners/forms/Contract'
import Billing from '../../conteiners/forms/Billing'
import MapDetails from '../../conteiners/MapDetails'
import Categories from '../../conteiners/Categories'
import Additional from '../../conteiners/Additional'
import Warehouses from '../../conteiners/Warehouses'
import Warehouse from '../../conteiners/Warehouse'
import MapSearch from '../../conteiners/MapSearch'
import Entrance from '../../conteiners/Entrance'
import Calendar from '../../conteiners/Calendar'
import Reset from '../../conteiners/forms/Reset'
import Summary from '../../conteiners/Summary'
import Payment from '../../conteiners/Payment'
import Account from '../../conteiners/Account'
import Login from '../../conteiners/Login'
import Chats from '../../conteiners/Chats'
import Home from '../../conteiners/Home'
import Maps from '../../conteiners/Maps'
import Help from '../../conteiners/Help'
import Test from '../../conteiners/Test'
import MRoute from './MRoute'

const Router = () => {
  return (
    <Switch>
      <MRoute exact path={['/home', '/']}>
        <Home />
      </MRoute>

      <MRoute exact path={['/account/:key/:action', '/account/:key', '/account']}>
        <Account />
      </MRoute>

      <MRoute exact path='/additional/:type' animation={false}>
        <BasketConteiner children={<Additional />} />
      </MRoute>

      <MRoute exact path='/billing'>
        <BasketConteiner extendedAppearance={false} children={<Billing />} />
      </MRoute>

      <MRoute exact path='/calendar/:placeId/:hash' animation={false}>
        <BasketConteiner children={<Calendar />} />
      </MRoute>

      <MRoute exact path='/categories' darkHeader animation={false}>
        <Categories />
      </MRoute>

      <MRoute exact path={['/chat/:id', '/chat']}>
        <Chats />
      </MRoute>

      <MRoute exact path='/contract'>
        <Contract />
      </MRoute>

      <MRoute exact path='/entrance' animation={false}>
        <Entrance />
      </MRoute>

      <MRoute exact path='/entrance/login' animation={false}>
        <EntranceLogin />
      </MRoute>

      <Route exact path={['/first/:number/:key', '/first/:number']}>
        <FirstOpening />
      </Route>

      <MRoute exact path='/help'>
        <Help />
      </MRoute>

      <MRoute exact path='/login'>
        <Login />
      </MRoute>

      <MRoute exact path='/login/reset'>
        <Reset />
      </MRoute>

      <MRoute exact path={['/map/:placeId', '/map']} flyingHeader>
        <Maps />
      </MRoute>

      <Route exact path={['/mapsearch/:provinceIdx/:cityIdx','/mapsearch/:provinceIdx', '/mapsearch']}>
        <MapSearch />
      </Route>

      <MRoute exact path='/mapdetails/:placeId'>
        <MapDetails />
      </MRoute>

      <Route exact path={['/newwarehouse/:key/:id', '/newwarehouse/:key', '/newwarehouse']}>
        <NewWarehouse />
      </Route>

      <MRoute exact path='/payment'>
        <Payment />
      </MRoute>

      <MRoute exact path='/registration'>
        <Registration />
      </MRoute>

      <Route exact path='/test'>
        <Test />
      </Route>

      <MRoute exact path='/summary'>
        <Summary />
      </MRoute>

      <MRoute exact path='/warehouses' animation={false}>
        <Warehouses />
      </MRoute>

      <MRoute exact path='/warehouses/:number' animation={false}>
        <Warehouse />
      </MRoute>

      <MRoute exact path={['/warehouses/:number/:type/:action', '/warehouses/:number/:type']} animation={false}>
        <SubWarehouse />
      </MRoute>

      <Redirect to='/' />
    </Switch>
  )
}

export default Router