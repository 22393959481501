import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CARD_TYPE, initialExp } from '../../utils/validation'
import { companiesFields } from '../../rest/urls'
import { STATUS } from '../../utils/constants'
import { GET } from '../../rest/request'

export const getCompaniesFields = createAsyncThunk('form/getCompaniesFields', async () => {
  const { fields = {} } = await GET(companiesFields)
  return fields
})

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    data: {},
    fields: [],
    fieldsStatus: STATUS.idle,
  },
  reducers: {
    updateValue: (state, action) => {
      const { name, value } = action.payload
      const { cardType = CARD_TYPE.idCard } = state.data

      if (name) {
        if ((name === 'phone' || name === 'mail' || name === 'postalCode' || name === 'pesel') && value && !value.match(initialExp[name])) return
        else if (name === 'identityCard' && value && !value.match(initialExp[cardType])) return

        state.data[name] = value
      }
    },
    updateValues: (state, action) => {
      Object.entries(action.payload).map(([k, v]) => state.data[k] = v)
    },
    resetForm: (state, _) => {
      state.data = {}
    },
  },
  extraReducers: {
    [getCompaniesFields.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (Array.isArray(data)) {
        const fields = data
          .filter(({ isRequired, inputName }) => isRequired && inputName !== 'nip' && inputName !== 'companyUserId')
          .map((e) => {
            const { inputName, inputLabel } = e
            if (!inputLabel) {
              const { label, placeholder } = ((name) => {
                switch (name) {
                  case 'name': return { label: 'Nazwa', placeholder: 'Wpisz nazwę' }
                  case 'streetName': return { label: 'Nazwa ulicy', placeholder: 'Wpisz nazwę ulicy' }
                  case 'postalCode': return { label: 'Kod pocztowy', placeholder: 'Wpisz kod pocztowy' }
                  case 'cityName': return { label: 'Miasto', placeholder: 'Wpisz miasto' }
                  case 'voivodshipName': return { label: 'Województwo', placeholder: 'Wpisz województwo' }
                  case 'country': return { label: 'Kraj', placeholder: 'Wpisz kraj' }
                  case 'companyFormId': return { label: 'Rodzaj działalności', placeholder: 'Wpisz rodzaj działalności' }
                  case 'companyUserPosition': return { label: 'Stanowisko', placeholder: 'Wpisz stanowisko' }
                  default: return { label: name, placeholder: `Wpisz ${name}` }
                }
              })(inputName)
              e.inputLabel = label
              e.placeholder = placeholder
            }
            return e
          })
        state.fieldsStatus = STATUS.succeed
        state.fields = fields
      }
    }
  }
})

export const { updateValue, updateValues, resetForm } = formSlice.actions

export const selectFieldsStatus = state => state.form.fieldsStatus
export const selectFields = state => state.form.fields
export const selectForm = state => state.form.data

export default formSlice.reducer
