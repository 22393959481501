import InaccessiblePopup from '../../components/popups/InaccessiblePopup'
import { useState, useContext, useRef, useEffect } from 'react'
import SystemContext from '../socket/SocketContext'
import { STATUS } from '../../utils/constants'
import CallContext from './CallContext'
import Peer from 'simple-peer'

const CallProvider = ({ children }) => {

  const [status, setStatus] = useState(STATUS.idle)
  const [isBlocked, setBlock] = useState(false)
  const [error, setError] = useState('')

  const { messageData, sendMessage } = useContext(SystemContext)

  const statusRef = useRef(STATUS.idle)
  const connectionRef = useRef(null)
  const serviceVideo = useRef(null)
  const serviceIdRef = useRef('')

  const updateStatus = s => {
    setStatus(s)
    statusRef.current = s
  }

  useEffect(() => {
    const { event, payload } = messageData

    switch (event) {
      case 'exception':
        updateStatus(STATUS.failed)
        setError('Usługa niedostępna')
        connectionRef.current?.destroy()
        break

      case 'callAccepted':
        const { signal, serviceID } = payload
        updateStatus(STATUS.succeed)
        serviceIdRef.current = serviceID
        connectionRef.current.signal(signal)
        break

      case 'callUser':
        if (statusRef.current === STATUS.idle) {
          const { from, signal } = payload
          setBlock(true)
          makeCall(false, from, signal)
        }
        break

      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData])

  const makeCall = (initiator = true, to, signal) => {
    setError('')
    updateStatus(initiator ? STATUS.pennding : STATUS.succeed)

    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then(stream => {
        const close = (err = '') => {
          if (!initiator) setBlock(false)
          if (err) console.log(`error, ${err}`)
          stream.getTracks().forEach(track => track.stop())
          leaveCall()
        }

        const peer = new Peer({
          config: {
            iceServers: [{
              urls: 'stun:stun.l.google.com:19302'
            }, {
              urls: 'turn:ser.mmapp.pl:3478',
              username: process.env.REACT_APP_TURN_USER,
              credential: process.env.REACT_APP_TURN_PASSWORD
            }]
          },
          initiator,
          trickle: false,
          stream
        })

        peer.on('signal', data => {
          sendMessage(initiator ? {
            command: 'callService',
            signal: data,
            name: 'KLIENT'
          } : {
            command: 'answerCallFromService',
            signal: data,
            to: to,
          })
        })

        peer.on('stream', stream => {
          serviceVideo.current.srcObject = stream
        })

        peer.on('close', close)
        peer.on('error', err => close(err))

        if (signal) peer.signal(signal)
        connectionRef.current = peer

      }).catch(err => {
        console.warn(`Error: ${err}`)
        updateStatus(STATUS.failed)
        setError('Musisz wyrazić zgody na użycie mikrofonu')
      })
  }

  const leaveCall = () => {
    updateStatus(STATUS.idle)

    if (connectionRef.current) {
      connectionRef.current.removeAllListeners('signal')
      connectionRef.current.destroy()
    }
  }

  const cancelCall = () => {
    sendMessage({ command: 'cancelCall' })
    leaveCall()
  }

  const endCall = () => {
    sendMessage({ command: 'endCall', id: serviceIdRef.current })
    leaveCall()
  }

  return (
    <CallContext.Provider value={{
      status,
      isBlocked,
      endCall,
      makeCall,
      cancelCall,
    }}>
      {children}

      {status === STATUS.succeed && <video
        playsInline
        autoPlay
        ref={serviceVideo}
        style={{ display: 'none' }} />}

      {error && <InaccessiblePopup
        desc={error}
        close={() => setError('')} />}

    </CallContext.Provider>
  )
}

export default CallProvider