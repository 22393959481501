import { ReactComponent as Calendar } from '../../../assets/calendar.svg'
import { ReactComponent as Camera } from '../../../assets/camera.svg'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import { STATUS } from '../../../utils/constants'
import Option from '../../../components/Option'
import { useHistory } from 'react-router-dom'
import { RESIGNATION_TYPE } from '.'

const Default = ({ amount, date, photo, paid, setStatus }) => {
  const { push, location: { pathname } } = useHistory()

  const changeSection = s => {
    const lastCharInPath = pathname[pathname.length - 1]
    push(`${pathname}${lastCharInPath === '/' ? '' : '/'}${s}`)
  }

  const countSteps = (() => 3 - (date ? 1 : 0) - (photo ? 1 : 0) - (paid ? 1 : 0))()

  return <>
    <div className='title'>
      <div>Już kończymy?</div>
      <div>Musisz jeszcze {countSteps ? `wykonać ${countSteps} kroki` : 'potwierdzić rezygnację'}</div>
    </div>

    <div className='resignation__main'>
      <div className='resignation__steps'>
        <Option
          selected
          secondary={!!date}
          label={date ? 'Wybrano' : 'Wybierz datę'}
          title='Kiedy chcesz zakończyć wynajem?'
          desc={date || 'Nie wybrano'}
          buttonText='Wybierz'
          icon={<Calendar />}
          action={() => changeSection(RESIGNATION_TYPE.date)}
        />

        <Option
          selected
          secondary={!!photo}
          label={photo ? 'Dodano' : 'Zrób zdjęcie'}
          title='Zrób zdjęcie MAGa'
          desc={photo ? photo?.name : 'Pokaż stan swojego MAGa'}
          buttonText='Dodaj'
          icon={<Camera />}
          action={() => changeSection(RESIGNATION_TYPE.photo)}
        />

        <Option
          selected
          secondary={!!paid}
          label={paid ? 'Opłacono' : 'Opłać fakturę'}
          title='Zapłać ostatnią fakturę'
          buttonText='Zapłać'
          desc={date ? `${paid ? 'Zapłacono' : 'Do zapłaty'} ${amount}zł` : 'Wybierz najpierw datę'}
          action={() => changeSection(date ? RESIGNATION_TYPE.payment : RESIGNATION_TYPE.date)}
        />
      </div>

      <ArrowBtn
        enlarged
        text='Zrezygnuj'
        disabled={!date && !photo && !paid}
        action={() => setStatus(STATUS.succeed)}
      />
    </div>
  </>
}

export default Default