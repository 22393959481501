import { ReactComponent as Arrow } from '../../assets/arrowRight.svg'
import { ReactComponent as Mag } from '../../assets/magDetails.svg'
import { ReactComponent as New } from '../../assets/newMag.svg'
import useTranslation from '../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'

const HiddenMenu = () => {
  const { t } = useTranslation('menu')
  const { push } = useHistory()

  return (
    <div className='hiddenMenu'>
      <div>
        <div className='menu__title'>{t('takeShortcuts')}</div>
        <div className='menu__buttons'>
          <div
            className='menu__button'
            onClick={() => push('/warehouses')}>
            <Mag />
            <div className='group'>
              <div className='button__desc --termina'>{t('takeShortcuts')}</div>
              <div className='menu__arrow center'>
                <Arrow />
              </div>
            </div>
          </div>

          <div className='menu__button'
            onClick={() => push('/categories')}>
            <New />
            <div className='group'>
              <div className='button__desc --termina'>{t('newWarehouse')}</div>
              <div className='menu__arrow center'>
                <Arrow />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HiddenMenu