const InvoiceSection = ({ data }) => {
  const { nip, name, street, estateNumber, localNumber, city, province } = data || {}

  return (
    <div>
      <div className='list__header element__title'>Najemca</div>
      <div className='list__element --big'>
        <div className='group'>
          <div>
            <div className='element__title' style={{ marginBottom: '0.5em' }}>{name}</div>
            <div className='element__desc'>
              {nip && <div>NIP: {nip},</div>}
              <div>{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}</div>
              <div>pow. {province}</div>
              <div>{city}</div>
            </div>
          </div>
          <div className='element__desc'>Zmień</div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceSection