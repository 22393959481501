import { addItemToBasket, selectBasketItem } from './basketSlice'
import { ReactComponent as Arrow } from '../../assets/arrowRight.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../../components/popups/Popup'
import { getDateString } from '../../utils/date'
import { useHistory } from 'react-router-dom'

const AnotherMagPopup = () => {

  const { hash, address, minDate, size, name, placeId, start, end, dailyPrice, monthlyPrice, termiateDays, rangeDate } = useSelector(selectBasketItem)
  const { goBack, replace, location: { pathname } } = useHistory()
  const { t } = useTranslation('basket')

  const dispatch = useDispatch()

  const addNewItem = withTheSameDate => {
    const item = { address, minDate, size, name, placeId, dailyPrice, monthlyPrice, termiateDays }
    if (withTheSameDate) {
      item.rangeDate = rangeDate
      item.hash = hash
      item.start = start
      item.end = end
    }
    dispatch(addItemToBasket(item))
    withTheSameDate || pathname.includes('calendar') ? goBack() : replace(`/calendar/${placeId}/${hash}`)
  }

  return (
    <Popup>
      <div className='popup__newMag'>
        <h2>{t('anotherMAG')}</h2>

        <p>{t('duration')}: <span className='--sb'>{getDateString(start)} → {end ? getDateString(end) : '∞'}</span></p>

        <div className='popup__menu'>
          <div
            className='menu__item group'
            onClick={() => addNewItem(true)}>
            <div className='item__desc --termina'>{t('keepTheSameLeaseTerm')}</div>

            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>

          <div
            className='menu__item group'
            onClick={() => addNewItem(false)}>
            <div className='item__desc --termina'>{t('selectAgain')}</div>

            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default AnotherMagPopup