import { addFilters, selectFilters, selectFiltersStatus, selectFiltersTypes, selectLocation } from '../menu/filtersSlice'
import { fetchCategories, selectCategories, selectCategoriesStatus } from '../../Categories/categoriesSlice'
import { selectMapOptions, updateMapOption } from '../Map/mapSlice'
import { useSelector, useDispatch } from 'react-redux'
import Popup from '../../../components/popups/Popup'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { DISTANCE_LIST } from '../Map/mapUtils'
import Error from '../../../components/Error'
import Range from '../../../components/Range'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

const FilterPopup = () => {
  const location = useSelector(selectLocation)
  const categories = useSelector(selectCategories)
  const { distance } = useSelector(selectMapOptions)
  const selectedfilters = useSelector(selectFilters)
  const filtersTypes = useSelector(selectFiltersTypes)
  const filtersStatus = useSelector(selectFiltersStatus)
  const categoriesStatus = useSelector(selectCategoriesStatus)

  const [filters, setFilters] = useState(selectedfilters)
  const dispatch = useDispatch()

  const { push, goBack } = useHistory()

  const updateFilter = (name, where, value = '', additionalName = '', f = filters) => {
    const newVal = additionalName ? { ...f[where][name], [additionalName]: value } : value

    if (value !== '') setFilters({ ...f, [where]: { ...f[where], [name]: newVal } })
    else {
      const copy = { ...f[where] }

      if (additionalName) {
        delete copy[name][additionalName]
        if (Object.keys(copy[name]).length === 0) delete copy[name]

      } else delete copy[name]
      setFilters({ ...f, [where]: copy })
    }
  }

  const saveFilters = value => {
    dispatch(addFilters(value))
    goBack()
  }

  useEffect(() => {
    if (categoriesStatus !== STATUS.succeed) dispatch(fetchCategories())
  }, [categoriesStatus, dispatch])

  return (
    <Popup isLine={false}>

      <div className='popup--filter'>
        <h2>Wybierz filtry</h2>

        {filtersStatus === STATUS.failed || categoriesStatus === STATUS.failed ? <Error staticPos />
          : filtersStatus === STATUS.succeed && categoriesStatus === STATUS.succeed ? <div className='popup__main'>
            <div className='popup__list'>
              {Object.entries(filtersTypes).map(([, { name, where, field, type, options }], idx) => {
                let filter = false

                if (field === 'categoryId') {
                  filter = <>
                    <select
                      value={filters[where][field] || ''}
                      onChange={e => {
                        if (filters[where]['warehouseTypeId']) {
                          const copy = { ...filters[where] }

                          delete copy['warehouseTypeId']
                          updateFilter(field, where, e.target.value, '', { ...filters, [where]: copy })
                        } else updateFilter(field, where, e.target.value)
                      }}>
                      <option value=''>Wszystko</option>
                      {categories.map(({ disabled, name, id }) => disabled ? false : <option key={id} value={id}>{name}</option>)}
                    </select>

                    {filters[where][field] && <div className='element'>
                      <div className='element__title'>Rozmiar</div>
                      <select
                        value={filters[where]['warehouseTypeId'] || ''}
                        onChange={e => updateFilter('warehouseTypeId', where, e.target.value)}>
                        <option value=''>Wszystko</option>
                        {categories.find(({ id }) => id === filters[where][field])?.warehouseTypes?.map(({ typeNameForClient: name, id }) => <option key={id} value={id}>{name}</option>)}
                      </select>
                    </div>}
                  </>

                } else if (field === 'area') {
                  filter = <Range
                    unit={<>m<sup>2</sup></>}
                    value={[filters[where][field]?.min, filters[where][field]?.max]}
                    options={[0, 1, 5, 10, 14, 30, 56]}
                    updateValue={d => {

                      const copy = { ...filters[where][field] }
                      if (filters[where][field]?.min !== d[0]) copy.min = d[0]
                      if (filters[where][field]?.max !== d[1]) copy.max = d[1]

                      setFilters(f => { return { ...f, [where]: { ...f[where], [field]: copy } } })
                    }}
                  />

                } else if (type === 'range') {
                  filter = <div className='group'>
                    <input
                      placeholder='od'
                      type='number'
                      value={filters[where][field]?.min || ''}
                      onChange={e => updateFilter(field, where, e.target.valueAsNumber || '', 'min')} />

                    <input
                      placeholder='do'
                      type='number'
                      value={filters[where][field]?.max || ''}
                      onChange={e => updateFilter(field, where, e.target.valueAsNumber || '', 'max')} />
                  </div>

                } else if (type === 'location') {
                  filter = <input
                    type='text'
                    placeholder={name}
                    defaultValue={location}
                    onClick={() => push('/mapsearch')} />

                } else if (type === 'distance') {
                  filter = <select
                    value={distance || DISTANCE_LIST[0]}
                    onChange={e => dispatch(updateMapOption({ distance: e.target.value }))}>
                    {DISTANCE_LIST.map(v => <option key={v} value={v}>{v} km</option>)}
                  </select>

                } else if (type === 'select') {
                  filter = <select
                    value={filters[where][field] || ''}
                    onChange={e => {
                      const { value } = e.target
                      const newVal = value === 'true' ? true : value
                      updateFilter(field, where, newVal)
                    }}>
                    {options.map(({ name, value }, idx) => <option key={idx} value={value}>{name}</option>)}
                  </select>

                } else if (type === 'section') {
                  filter = <div className='element__section'>
                    {options.map(({ type, field, where, value, name }, idx) => <div
                      key={idx}
                      className='element__checkbox'>
                      <input
                        id={name}
                        type='checkbox'
                        checked={type === 'array_checkbox' ? filters[where][field]?.includes(value) || false : filters[where][field] || false}
                        onChange={e => {
                          const { checked } = e.target

                          if (type === 'array_checkbox') {
                            let copy = Array.isArray(filters[where][field]) ? [...filters[where][field]] : []
                            if (checked) copy.push(value)
                            else copy = copy.filter(v => v !== value)
                            updateFilter(field, where, copy.length === 0 ? '' : copy)

                          } else updateFilter(field, where, checked || '')
                        }} />
                      <label htmlFor={name}>{name}</label>
                    </div>)}
                  </div>

                } else if (type === 'checkbox') {
                  filter = <div className='element__checkbox'>
                    <input
                      id={field}
                      type='checkbox'
                      checked={filters[where][field] || false}
                      onChange={e => updateFilter(field, where, e.target.checked || '')} />
                    <label htmlFor={field}>{name}</label>
                  </div>
                }

                return <div
                  key={idx}
                  className='element'
                  style={type === 'location' ? { width: '65%', display: 'inline-block' }
                    : type === 'distance' ? { width: '30%', marginLeft: '5%', display: 'inline-block' } : {}}>
                  {type !== 'checkbox' && <div className='element__title'>{name}</div>}
                  {filter}
                </div>
              })}
            </div>

            <div className='popup__buttons center'>
              <div
                className='button__cancel'
                onClick={() => saveFilters({})}>Usuń filtry</div>
              <div
                className='button__add'
                onClick={() => saveFilters(filters)}>Pokaż</div>
            </div>
          </div> : <Loading />}
      </div>
    </Popup>
  )
}

export default FilterPopup