import FilterButton from '../../../components/Filters/FilterButton'
import { selectUserData, ACCOUNT_TYPE } from '../accountSlice'
import useTranslation from '../../../hooks/useTranslation'
import Filters from '../../../components/Filters'
import Error from '../../../components/Error'
import { useHistory } from 'react-router-dom'
import { MONTHS } from '../../../utils/date'
import { useEffect, useState } from 'react'
import * as filtersFields from './filters'
import { useSelector } from 'react-redux'
import Item from '../Item'

const type = ACCOUNT_TYPE.payments

const PaymentHistory = ({ full = false, mag }) => {
  const { history } = useSelector(state => selectUserData(state, type))

  const [filters, setFilters] = useState(mag ? { mag } : {})
  const [filteredList, filterList] = useState(history)

  const { t, lang } = useTranslation()
  const { push } = useHistory()


  useEffect(() => {
    const filtersList = Object.entries(filters)
    const filterDate = filters.date ? {} : false
    if (filterDate) {
      if (filters.date.start) filterDate.start = new Date(`${filters.date.start}, 00:00`)
      if (filters.date.end) filterDate.end = new Date(`${filters.date.end}, 00:00`)
    }
    const filtered = history.filter(({ date, mag }) => {
      return filtersList.reduce((r, [k, v]) => {
        if (k === 'date') {
          if (!date) return false
          const dateObj = new Date(date.split('.')?.reverse())
          const { start, end } = filterDate

          if (start) r = r && dateObj >= start
          if (end) r = r && dateObj <= end

        } else if (k === 'mag') {
          if (mag && Array.isArray(mag)) r = r && mag.includes(v)
        }
        return r
      }, true)
    })
    filterList(filtered)
  }, [filters, history])

  return (
    <>
      <div className='group'>
        <p>{t('account.paymentHistory')}</p>
        {full && <FilterButton />}
      </div>

      {full && <Filters
        filters={filters}
        setFilters={setFilters}
        fields={filtersFields[lang]}
      />}

      <div className='account__list'>
        {filteredList.length > 0 ? filteredList
          .filter((_, i) => i < (full ? filteredList.length : 3))
          .map(({ date, title, desc }, i) => <Item
            key={i}
            showArrow
            label={(() => {
              const [day, month] = date.split('.')
              return `${day} ${month ? MONTHS.short[Number(month) - 1] : ''}`
            })()}
            title={title}
            desc={desc}
          />) : <Error text={t('filters.noItems')} />}

        {!full && <div
          className='list__more'
          onClick={() => push(`${window.location.pathname}/history`)}>{t('account.more')}</div>}
      </div>
    </>
  )
}

export default PaymentHistory