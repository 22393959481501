
const randomStringFromServer = 'qwertyuiop'
const hostname = window.location.hostname

export const register = async (name = 'test@test.test', displayName = 'Test') => {
  const publicKeyCredentialCreationOptions = {
    challenge: Uint8Array.from(randomStringFromServer, c => c.charCodeAt(0)),
    rp: {
      name: "MMAPP",
      id: hostname,
    },
    user: {
      id: Uint8Array.from(
        "UZSL85T9AFC", c => c.charCodeAt(0)),
      name,
      displayName,
    },
    pubKeyCredParams: [
      { alg: -257, type: "public-key" },
      { alg: -7, type: "public-key" }
    ],
    authenticatorSelection: {
      authenticatorAttachment: "platform",
    },
    timeout: 60000,
    attestation: "none",
  };

  try {
    const credential = await navigator.credentials.create({
      publicKey: publicKeyCredentialCreationOptions
    })

    if (credential) {
      console.log(credential)
      return credential.rawId
    } else new Error()
  }
  catch (err) {
    console.error(err)
    return false
  }
}


export const login = async rawId => {
  if (!rawId) {
    console.error('first register app!')
    return
  }
  const publicKeyCredentialRequestOptions = {
    challenge: Uint8Array.from(randomStringFromServer, c => c.charCodeAt(0)),
    rpId: hostname,
    allowCredentials: [{
      id: rawId,
      type: 'public-key',
      transports: ['internal'],
    }],
    timeout: 60000,
    userVerification: "preferred",
  }

  try {
    const assertion = await navigator.credentials.get({
      publicKey: publicKeyCredentialRequestOptions
    })

    if (assertion) {
      console.log(assertion)
      return true
    } else new Error()
  } catch (err) {
    console.error(err)
    return false
  }
}