const Item = ({ icon, title, desc, extra }) => {
  return (
    <div className='list__item'>
      <div className='item__icon center'>{icon}</div>

      <div className='item__desc'>
        <div>{title}</div>
        <div>{desc}</div>
      </div>

      {extra && <div className='item__extra center'>{extra}</div>}
    </div>
  )
}

export default Item