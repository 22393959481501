import useTranslation from '../../hooks/useTranslation'
import Popup from './Popup'

const InaccessiblePopup = ({ close, desc = 'Usługa jest niedostępna' }) => {
  const { t } = useTranslation('basic')

  return (
    <Popup close={close}>
      <div className='popup__title'>
        <div>{t('caution')}</div>
        <div>{desc}</div>
      </div>
    </Popup>
  )
}

export default InaccessiblePopup