import useTranslation from '../../hooks/useTranslation'
import BackBtn from '../../components/buttons/BackBtn'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import { MAG_STATUS } from '../../utils/constants'
import { useHistory } from 'react-router-dom'

const Introduction = ({ header, next }) => {

  const { t } = useTranslation('slides')
  const { push } = useHistory()

  const desc = t('openedMagDescArray')

  return <>
    {header}

    <div className='slide__title center'>
      <div className='--termina'>{t('weInviteYouInside')}</div>
      <div>{t('watchYourMag')}</div>
    </div>

    <div className='slide__warehouses --wider'>
      {[1, 2, 3].map(e => <div
        key={e}
        className={`warehouse${e === 1 ? ' --left' : e === 3 ? ' --right' : ''}`}>
        <Warehouse status={e === 2 ? MAG_STATUS.opened : MAG_STATUS.closed} />
      </div>
      )}
    </div>

    <div className='slide__desc'>
      {Array.isArray(desc) ? desc.map((d, i) => <div key={i}>{d}</div>)
        : desc}
    </div>

    <div className='slide__buttons'>
      <BackBtn
        text={t('reportProblem')}
        action={() => push('/chat/new')} />

      <Submit
        text={t('next')}
        action={next} />
    </div>

  </>
}

export default Introduction