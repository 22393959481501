import { ReactComponent as Lightning } from '../../assets/lightning.svg'
import { ReactComponent as Calendar } from '../../assets/calendar.svg'
import { ReactComponent as Key } from '../../assets/key.svg'
import WarehouseAttr from '../Maps/Result/WarehouseAttr'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import Skeleton from 'react-loading-skeleton'
import { useState } from 'react'

const DetailsElement = ({ data, action }) => {
  const { isAvailableForRentNow, free_now_to_rent_count: freeCount, count, availabilityDate, for_rent = [], for_reservation = [], data: { monthlyGrossPrice, rentalMinDays, hasOnlineAccess } = {} } = data || {}
  const [more, showMore] = useState(true)

  return (
    <div className={`list__element --big${availabilityDate ? ' --planned' : ''}`}>

      {availabilityDate && <div className='element__header'>Planowany</div>}

      <div
        className='element__main'
        onClick={() => data && showMore(!more)}>

        {data ? <>
          <div className='element__icons group'>
            <div className='element__icon --first'>
              {hasOnlineAccess ? <Lightning /> : <Key />}
              <div className='icon__desc'>
                <div>{hasOnlineAccess ? 'Elekt. zapięcie' : 'Zamek klasyczny'}</div>
              </div>
            </div>

            <div className='element__icon'>
              <Calendar />
              <div className='icon__desc'>
                <div>Wynajem od {rentalMinDays} {rentalMinDays < 2 ? 'dnia' : 'dni'}</div>
              </div>
            </div>
          </div>

          <WarehouseAttr
            more={more}
            left={freeCount} />
        </> : <Skeleton className='skeleton__icons' count={2} />}
      </div>

      <div className='element__buttons group'>
        {data ? <>
          <div className='element__detail --price center'>
            <div className='--big'>{Number(monthlyGrossPrice)} zł</div>
            <div>m-c</div>
          </div>

          <ArrowBtn
            enlarged
            disabled={!count}
            text={isAvailableForRentNow ? 'Wynajmij teraz' :
              for_rent?.length > 0 && for_rent[0] ? `Wynajmij od ${for_rent[0]}` :
                for_reservation?.length > 0 && for_reservation[0] ? `Zarezerwuj od ${for_reservation[0]}`
                  : 'Zarezerwuj'}
            action={() => {
              if (for_rent?.length > 0 && for_rent[0]) action(for_rent[0])
              else if (for_reservation?.length > 0 && for_reservation[0]) action(for_reservation[0])
              else if (count > 0) action()
            }} />
        </> : <>
          <Skeleton className='skeleton__detail' />
          <Skeleton containerClassName='skeleton__button' height='1.8em' />
        </>}
      </div>
    </div>
  )
}

export default DetailsElement