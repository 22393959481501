
import { selectBasket } from '../../../../conteiners/Basket/basketSlice'
import Popup from '../../../../components/popups/Popup'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Form from './Form'
import List from './List'
import './style.scss'

const ReservationFailedPopup = () => {
  const { errors } = useSelector(selectBasket)
  const [isForm, showForm] = useState(false)

  return (
    <Popup
      centered
      isLine={false}
      close={() => {}}>

      {isForm ? <Form
        list={errors}
        back={() => showForm(false)} />
        : <List
          list={errors}
          showForm={showForm} />}
    </Popup>
  )
}

export default ReservationFailedPopup