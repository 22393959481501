import { ReactComponent as Clock } from '../../assets/clock.svg'
import useTranslation from '../../hooks/useTranslation'

const Warning = ({ time }) => {
  const { t } = useTranslation('header.warning')
  const desc = t('desc')

  return (
    <div className='warning__popup'>
      <div className='warning__title'>
        <div className='--termina'>{t('title')}</div>
        <div className='warning__text'>{t('subTitle')}</div>
      </div>

      <div className='warning__circular'>
        <Clock />

        <div className='circular__inner'>
          <div className='warning__time --termina'>{time.toFixed()}</div>
        </div>

        <div className='circle__bar --left'>
          <div className='circle__progress' />
        </div>

        <div className='circle__bar --right'>
          <div className='circle__progress' />
        </div>
      </div>

      <div className='warning__desc'>
        {desc && desc.map((t, i) => <div
          key={i}
          className='warning__text'>{t}</div>)}
      </div>
    </div>
  )
}

export default Warning