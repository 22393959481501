const Checkbox = ({ selected }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <g id="Group_14072" data-name="Group 14072" transform="translate(-151.336 -236.336)">
        <circle id="Ellipse_397" data-name="Ellipse 397" cx="10.5" cy="10.5" r="10.5" transform="translate(151.336 236.336)" fill={selected ? "var(--red)" : "var(--theme-bg)"} />
        <path id="Path_1920" data-name="Path 1920" d="M-10473.7,1689.778l4,4,6.188-6.186" transform="translate(10630.44 -1443.849)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
      </g>
    </svg>
  )
}

export default Checkbox