import { useEffect, useState } from 'react'

const MESSAGES = [
  'Bezpieczny',
  'Niezawodny',
  'Wygodny',
]
const MESSAGE_LENGTH = MESSAGES.length

const SplashMessage = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => setIndex(i => (i + 1) % MESSAGE_LENGTH), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className='splash__message'>
      #{MESSAGES[index]}
    </div>
  )
}

export default SplashMessage