const Item = ({ icon = false, title, label = false, desc, right = false, showArrow, action }) => {
  return (
    <div
      className={`list__item${label ? ' --label' : ''}`}
      onClick={action}>

      {label && <div className='item__label item__text'>{label}</div>}

      <div className='group'>
        <div className='center'>
          <div className='item__icon'>
            {icon}
          </div>

          <div className='item__desc'>
            <div>{title}</div>
            <div>{desc}</div>
          </div>
        </div>

        {showArrow ? <div className='item__icon--right'>
          <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.70825 0.2955C1.31725 -0.0985 0.68425 -0.0985 0.29425 0.2955C-0.0967496 0.6905 -0.0967496 1.3295 0.29425 1.7245L6.48925 8.0095L0.29325 14.2945C-0.09775 14.6885 -0.09775 15.3285 0.29325 15.7235C0.68425 16.1175 1.31725 16.1175 1.70725 15.7235L8.60725 8.7245C8.98725 8.3395 8.98825 7.6805 8.60725 7.2955L1.70825 0.2955Z" fill="var(--theme-text)" />
          </svg>
        </div> : right}
      </div>
    </div>
  )
}

export default Item