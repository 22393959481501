const Message = ({ isNewMessage = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1202_199" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1202_199)">
        <path d="M17.9168 11.6545C17.9196 12.675 17.6812 13.6818 17.2212 14.5928C16.676 15.6847 15.8375 16.6032 14.7997 17.2454C13.7619 17.8876 12.5658 18.2281 11.3453 18.2287C10.3248 18.2315 9.31802 17.9932 8.40706 17.5331L4 18.9998L5.46879 14.5928C5.00873 13.6818 4.77038 12.675 4.77316 11.6545C4.77381 10.4341 5.11431 9.23793 5.75651 8.20012C6.3987 7.16231 7.31721 6.32384 8.40911 5.77866C9.31925 5.31902 10.325 5.08068 11.3446 5.08302H11.7309C13.3424 5.17194 14.8645 5.85214 16.0057 6.99339C17.147 8.13464 17.8272 9.65675 17.9161 11.2683L17.9168 11.6545Z" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" />
        {isNewMessage && <path d="M16.8034 10.1743C18.5084 10.1743 19.8906 8.79211 19.8906 7.08713C19.8906 5.38216 18.5084 4 16.8034 4C15.0985 4 13.7163 5.38216 13.7163 7.08713C13.7163 8.79211 15.0985 10.1743 16.8034 10.1743Z" fill="#EB003D" stroke="var(--theme-bg)" strokeWidth="1.5" />}
      </g>
    </svg>
  )
}

export default Message