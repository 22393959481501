export const data = [{
  time: '11:00',
  date: '25.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Bartek',
  desc: '',
}, {
  time: '14:00',
  date: '25.05.2022',
  title: 'Zamknnięcie rygla',
  action: 'opening',
  user: 'Bartek',
  desc: '',
}, {
  time: '15:00',
  date: '25.05.2022',
  title: 'Uruchomienie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '16:00',
  date: '25.05.2022',
  title: 'Wyłączenie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '11:00',
  date: '26.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Michał',
  desc: '',
}, {
  time: '14:00',
  date: '26.05.2022',
  title: 'Zamknnięcie rygla',
  action: 'closing',
  user: 'Michał',
  desc: '',
}, {
  time: '16:00',
  date: '25.05.2022',
  title: 'Wyłączenie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '11:00',
  date: '26.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Bartek',
  desc: '',
}, {
  time: '14:00',
  date: '26.05.2022',
  title: 'Zamknnięcie rygla',
  action: 'closing',
  user: 'Bartek',
  desc: '',
}, {
  time: '16:00',
  date: '25.05.2022',
  title: 'Wyłączenie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '11:00',
  date: '26.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Bartek',
  desc: '',
}, {
  time: '14:00',
  date: '26.05.2022',
  title: 'Zamknnięcie rygla',
  action: 'closing',
  user: 'Bartek',
  desc: '',
}, {
  time: '16:00',
  date: '26.05.2022',
  title: 'Wyłączenie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '11:00',
  date: '27.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Bartek',
  desc: '',
}, {
  time: '14:00',
  date: '27.05.2022',
  title: 'Zamknnięcie rygla',
  action: 'closing',
  user: 'Bartek',
  desc: '',
}, {
  time: '16:00',
  date: '27.05.2022',
  title: 'Wyłączenie alarmu',
  action: 'alarm',
  user: '',
  desc: 'Naruszenie czujmika ruchu',
}, {
  time: '11:00',
  date: '28.05.2022',
  title: 'Otwarcie rygla',
  action: 'opening',
  user: 'Paweł',
  desc: '',
}, {
  time: '14:00',
  date: '01.06.2022',
  title: 'Zamknnięcie rygla',
  action: 'closing',
  user: 'Paweł',
  desc: '',
}]