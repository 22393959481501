import SocketContext from '../../context/socket/SocketContext'
import useTranslation from '../../hooks/useTranslation'
import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { POST } from '../../rest/request'
import { addMessage } from './chatSlice'
import { api } from '../../rest/urls'
import Paperclip from './Paperclip'

const Input = ({ disabled }) => {
  const [files, setFiles] = useState(null)
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation('form')
  const { sendMessage } = useContext(SocketContext)

  const send = () => {
    if (isLoading) return
    if (files) sendFiles()
    if (message) sendTextMessage()
  }

  const sendTextMessage = async () => {
    dispatch(addMessage({ sender: 'USER', message }))
    sendMessage({ command: 'sendMessage', receiver: 'SERVICE', message })
    setMessage('')
  }

  const sendFiles = async () => {
    setLoading(true)
    const formData = new FormData()
    const filesList = [...files]
    filesList.forEach((file, idx) => formData.append(`file[${idx}]`, file))
    formData.append('receiverSocketId', 'SERVICE')
    formData.append('channel', 'chat')

    try {
      const response = await POST(`${api}callservice/upload`, { form: formData })
      setLoading(false)
      if (response) {
        const list = response.map(({ download_link, document_title, document_description, thumbnail }) => ({
          thumbnail,
          name: document_title,
          description: document_description,
          public_path: download_link.api,
          download_link: download_link.web,
          is_image: !!thumbnail
        }))
        dispatch(addMessage({ sender: 'USER', files: list }))
        setFiles(null)
      }

    } catch (error) {
      console.error('Sending files failed', error)
      setLoading(false)
    }
  }

  return (
    <div
      className='chat__input center'
      style={disabled ? { opacity: 0.5 } : undefined}>

      <Paperclip
        disabled={disabled}
        setFiles={setFiles}
        length={files?.length} />

      <div className='input__main'>
        <textarea
          rows='1'
          value={message}
          disabled={disabled}
          placeholder={t('writeMessage')}
          onKeyDown={e => e.key === 'Enter' && !e.shiftKey && send()}
          onChange={e => e.target.value !== '\n' && setMessage(e.target.value)}
        />

        <svg xmlns="http://www.w3.org/2000/svg" width="20.551" height="20.551" viewBox="0 0 20.551 20.551" onClick={send} style={isLoading ? { opacity: 0.5 } : undefined}>
          <g id="send" transform="translate(0.75 0.734)">
            <line id="Line_1" data-name="Line 1" x1="10" y2="10" transform="translate(8.525 0.542)" fill="none" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Path_3" data-name="Path 3" d="M21.051,2,14.383,21.051l-3.81-8.573L2,8.668Z" transform="translate(-2 -1.984)" fill="none" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </g>
        </svg>
      </div>

    </div>
  )
}

export default Input