import { companies, updateCompanies } from '../../../rest/urls'
import { DELETE, PATCH, POST } from '../../../rest/request'

export const submit = async (body, id = '', success = () => { }, error = () => { }) => {
  const { companyUserPosition, ...restBody } = body || {}

  try {
    const response = (id && !body) ? await DELETE(`${updateCompanies}/${id}`, { credentials: false })
      : (id && body) ? await PATCH(`${updateCompanies}/${id}`, { body: restBody })
        : await POST(companies, { body })

    if (response) {
      const { data } = response
      success(data)
    }

  } catch (err) {
    error(err)
  }
}