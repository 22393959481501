import useTranslation from '../../hooks/useTranslation'
// import { getRangeString } from '../../utils/date'
import useMagPrice from '../../hooks/useMagPrice'

const Summary = ({ price: additionalPrice }) => {
  const { amount, price } = useMagPrice()
  const { t } = useTranslation()

  return (
    <div className='payment__summary center'>
      <div>
        <div className='summary__item --primary'>
          <div>{t('account.toPay')}</div>
          <div>{price || additionalPrice} zł</div>
        </div>
      </div>

      <div>
        {/* <div className='summary__item'>
          <div>Czas trwania</div>
          <div>{getRangeString({ m, d })}</div>
        </div> */}

        {amount > 0 && <div className='summary__item'>
          <div>{t('basket.numberOfMags')}</div>
          <div>{amount}</div>
        </div>}
      </div>
    </div>
  )
}

export default Summary