import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import MagNumber from '../../components/MagNumber'
import { ReactComponent as Key } from './key.svg'
import Form from '../../components/PinForm'
import { OPENING_TYPE } from '.'
import { useState } from 'react'

const MagSlide = ({ data, next }) => {
  const { address, number, type } = data || {}
  const { street, estateNumber, localNumber, city } = address || {}

  const [pin, setPin] = useState(type === OPENING_TYPE.pin ? '1234' : null)
  const [warning, setWarning] = useState(false)

  const validation = () => pin.length === 4 ? next() : setWarning(true)

  const submit = <Submit
    text='Dalej'
    action={() => pin === null ? next() : validation()} />

  return <>
    <div>
      <MagNumber number={number} />
      <div className='slide__address'>ul. {street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, {city}</div>
    </div>

    <div className='slide__warehouses --wider'>
      {[1, 2, 3].map(e => <div
        key={e}
        className={`warehouse${e === 1 ? ' --left' : e === 3 ? ' --right' : ''}`}>
        <Warehouse />
      </div>
      )}
    </div>

    <div className='slide__title center'>
      <div className='--termina'>Jak otworzyć magazyn?</div>
      {type === OPENING_TYPE.app && <div>Skorzystaj z aplikacji</div>}
    </div>

    {type === OPENING_TYPE.pin ? <>
      <Form
        value={pin}
        clear={!pin}
        setValue={setPin}
        warning={warning}
        setWarning={setWarning} />

      <div className='slide__desc'>
        <div>Twój MAG jest otwierany</div>
        <div>przy pomocy Twojego kodu PIN</div>
      </div>

      <div className='slide__buttons'>
        <button className='button --back' onClick={() => setPin('')}>
          <span>Zmień PIN</span>
        </button>

        {submit}
      </div>
    </> : type === OPENING_TYPE.key ? <>
      <Key />

      <div className='slide__desc'>
        <div>Twój MAG jest otwierany</div>
        <div>przy pomocy kłódki na klucz</div>
      </div>

      <div className='slide__buttons'>
        <button className='button --back'>
          <span>Skąd wziąć klucz</span>
        </button>

        {submit}
      </div>
    </> : <>
      {pin !== null && <Form
        value={pin}
        clear={!pin}
        setValue={setPin}
        warning={warning}
        setWarning={setWarning} />}

      <div className='slide__desc'>
        <div>Twój MAG jest otwierany zdalnie</div>
        <div>z aplikacji lub z panelu przy wjeździe</div>
        <div>przy pomocy Twojego kodu PIN</div>
      </div>

      <div className='slide__buttons'>
        <button
          className='button --back'
          onClick={() => setPin(p => p === null ? '' : null)}>
          <span>{pin === null ? 'Ustaw inny PIN' : 'Anuluj'}</span>
        </button>

        {submit}
      </div>
    </>}

  </>
}

export default MagSlide