import { ReactComponent as User } from '../../../assets/account.svg'
import { LOCATION_STATES, STATUS } from '../../../utils/constants'
import { selectUserData, ACCOUNT_TYPE } from '../accountSlice'
import useTranslation from '../../../hooks/useTranslation'
import Skeleton from 'react-loading-skeleton'
import Error from '../../../components/Error'
import { useHistory } from 'react-router-dom'
import useUserFetch from '../useUserFetch'
import { useSelector } from 'react-redux'

const type = ACCOUNT_TYPE.basic

const BasicInfo = () => {
  const { data } = useSelector(state => selectUserData(state, type))
  const { push } = useHistory()
  const { t } = useTranslation()

  const status = useUserFetch(type)

  const showPopup = (p) => push(window.location.pathname, LOCATION_STATES.popup(p))

  const renderData = ({ name, surname, birth, zip_code, city, street, building_nr, apartment_nr, email, phone }) => <>
    <div>
      <div className='list__header element__title'>{t('form.personalData')}</div>
      <div className='list__element --big'>
        <div className='group'>
          <div>
            <div className='element__title'>{t('form.firstName')}</div>
            <div className='element__desc'>{name}</div>

            <div className='element__title'>{t('form.lastName')}</div>
            <div className='element__desc'>{surname}</div>

            <div className='element__title'>{t('form.dateOfBirth')}</div>
            <div className='element__desc'>{birth}</div>

            <div className='element__title'>{t('form.address')}</div>
            <div className='element__desc'>
              <div>{zip_code} {city}</div>
              <div>{street} {building_nr}{apartment_nr ? `/${apartment_nr}` : ''}</div>
            </div>
          </div>

          <div
            className='element__title'
            onClick={() => showPopup('editUser')}>{t('form.change')}</div>
        </div>
      </div>
    </div>

    <div>
      <div className='list__header element__title'>{t('form.contactInfo')}</div>
      <div className='list__element --big'>
        <div className='group'>
          <div>
            <div className='element__title'>{t('form.email')}</div>
            <div className='element__desc'>{email}</div>
          </div>
          <div
            className='element__title'
            onClick={() => showPopup('editMail')}>{t('form.change')}</div>
        </div>

        <div className='group'>
          <div>
            <div className='element__title'>{t('form.phoneNumber')}</div>
            <div className='element__desc'>{phone?.replace(/(\d{3})/g, '$1 ')}</div>
          </div>
          <div
            className='element__title'
            onClick={() => showPopup('editPhone')}>{t('form.change')}</div>
        </div>
      </div>
    </div>
  </>

  return (
    <div className='account__basic summary'>
      <div className='account__header'>
        <div className='account__icon'>
          <User />
        </div>

        <div className='title'>
          <div>{t('account.basic')}</div>
          <div>{t('account.subTitle')}</div>
        </div>
      </div>

      {status === STATUS.failed ? <Error />
        : <div className='list'>
          {data ? renderData(data) : <>
            <div>
              <div className='list__header'>
                <Skeleton className='skeleton__title' />
              </div>
              <div className='list__element --big --skeleton'>
                <div className='group'>
                  <div>
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' />
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' />
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' />
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' count={2} />
                  </div>
                  <Skeleton containerClassName='skeleton__button' />
                </div>
              </div>
            </div>

            <div>
              <div className='list__header'>
                <Skeleton className='skeleton__title' />
              </div>
              <div className='list__element --big --skeleton'>
                <div className='group'>
                  <div>
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' />
                    <Skeleton className='skeleton__title' />
                    <Skeleton className='skeleton__desc' />
                  </div>
                  <Skeleton containerClassName='skeleton__button' />
                </div>
              </div>
            </div>
          </>}
        </div>}
    </div>
  )
}

export default BasicInfo