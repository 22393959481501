const MagSection = ({ data }) => {
  const { area, internalHeight, internalLength, internalWidth, typeDescriptionForClient } = data || {}

  return (
    <div>
      <div className='list__header element__title'>{typeDescriptionForClient}</div>
      <div className='list__element --big'>
        <div className='element__desc'>
          <div>Powierzchnia: {Number(area)}m²</div>
          <div>Długość: {internalLength}m</div>
          <div>Szerokość: {internalWidth}m</div>
          <div>Wysokość: {internalHeight}m</div>
        </div>
      </div>
    </div>
  )
}

export default MagSection