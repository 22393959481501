import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../utils/constants'
import { categories } from '../../rest/urls'
import { GET } from '../../rest/request'

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const response = await GET(categories)
  return response.data
})

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    status: STATUS.idle,
  },
  reducers: {},
  extraReducers: {
    [fetchCategories.fulfilled]: (state, action) => {
      state.status = STATUS.succeed
      state.categories = action.payload
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const selectCategoriesStatus = state => state.categories.status
export const selectCategories = state => state.categories.categories

export default categoriesSlice.reducer
