import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { selectWarehouses } from './warehousesSlice'
import Warehouse from '../../components/Warehouse'
import ComercialSlide from './ComercialSlide'
import WarehouseInfo from './WarehouseInfo'
import { useSelector } from 'react-redux'
import { Autoplay } from 'swiper'
import { useRef } from 'react'

import 'swiper/modules/autoplay/autoplay.scss'
import 'swiper/swiper.scss'


const SlideShow = ({ startIndex = 0, autoplay = false, showInfo = false, actionInAllSlides = false, action }) => {

  const warehouses = useSelector(selectWarehouses)
  const index = useRef(startIndex)

  return (
    <Swiper
      loop
      grabCursor
      centeredSlides
      slideToClickedSlide
      touchMoveStopPropagation
      speed={1000}
      modules={[Autoplay]}
      preventClicks={true}
      slidesPerView='auto'
      initialSlide={startIndex}
      autoplay={autoplay ? { delay: 5000 } : false}
      onSlideChangeTransitionEnd={({ realIndex }) => index.current = realIndex}
      onSlideChange={({ realIndex }) => actionInAllSlides && warehouses[realIndex] && action(warehouses[realIndex]?.number)}>

      {warehouses.map(({ status, alerts, number, ...rest }, i) => <SwiperSlide key={i} className='warehouse__slide'>

        {({ isActive }) => <div
          className={`slide${isActive ? ' --selected' : ''}`}
          onClick={() => i === index.current && action(number, status)}>

          <Warehouse
            number={number}
            status={status}
            alerts={alerts} />

          {isActive && showInfo && <WarehouseInfo {...rest} />}
        </div>}

      </SwiperSlide>)}

      <SwiperSlide className='warehouse__slide comercial center'>
        {({ isActive }) => <div className={`slide${isActive ? ' --selected' : ''}`}>
          <ComercialSlide />
        </div>}
      </SwiperSlide>
    </Swiper>
  )
}

export default SlideShow