import { ReactComponent as Arrow } from '../../../assets/arrowDown.svg'

const PaymentsSection = ({ data }) => {
  return (
    <div>
      <div className='list__header element__title'>Płatności</div>
      <div className='list__element --big'>
        <div className='element__desc'>
          {data.map(({ number, date, amount }, i) => <div
            key={i}
            className='group'>
            <div className='element__invoice'>
              <div><b>Faktura {number}</b></div>
              <div>{date} - {amount}zł</div>
            </div>
            <Arrow />
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default PaymentsSection