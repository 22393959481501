import { ReactComponent as Mark } from '../../../assets/checked.svg'
import useTranslation from '../../../hooks/useTranslation'
import Option from '../../../components/Option'
import { useState } from 'react'

const random = () => ((Math.random() * 50) + 50).toFixed(2)

const Status = () => {
  const [amount, setAmaunt] = useState(random())
  const { t } = useTranslation('account')

  return (
    <div className='account__status'>
      {amount ? <Option
        selected
        buttonText={t('pay')}
        title={t('payTheInvoice')}
        desc={`${t('toPay')} ${amount}zł`}
        action={() => setAmaunt(0)}
      /> : <Option
        selected
        secondary
        title={t('everythingsOkay')}
        desc={t('paidInvoices')}
        icon={<Mark />}
      />}
    </div>
  )
}

export default Status