import { ReactComponent as Logo } from '../../assets/logo.svg'

const PlaceItem = ({ logo = true, arrow = false, cross = false, data, selectPosition, crossAction }) => {
  const { name, placeName, list, street, estateNumber, district, province, city } = data

  return <div
    className='list__item group'
    onClick={() => !cross && selectPosition(data)}>

    <div
      className='place__item'
      onClick={() => cross && selectPosition(data)}>

      <div className='item__logo'>
        {logo ? <Logo />
          : <svg xmlns="http://www.w3.org/2000/svg" width="20.24" height="26.987" viewBox="0 0 20.24 26.987">
            <path id="location-pin" d="M21.62,0A10.168,10.168,0,0,0,11.5,10.193c0,7.987,9.169,16.234,9.559,16.58a.845.845,0,0,0,1.122,0c.39-.347,9.559-8.594,9.559-16.581A10.168,10.168,0,0,0,21.62,0Zm0,15.742a5.622,5.622,0,1,1,5.622-5.622A5.629,5.629,0,0,1,21.62,15.742Z" transform="translate(-11.5)" fill="#fff" />
          </svg>}
      </div>

      <div className='item__desc'>
        <div>{name ? `woj. ${name}` : placeName || city}{list ? <span style={{ opacity: list.length > 0 ? 1 : 0.5 }}> ({list.length})</span> : ''}</div>
        {street ? <div>{street} {estateNumber}, {province}</div>
          : district ? <div>pow. {district}</div> : false}
      </div>
    </div>

    {arrow && <svg xmlns="http://www.w3.org/2000/svg" width="8.174" height="8.879" viewBox="0 0 8.174 8.879">
      <g id="arrow-right" transform="translate(0.6 0.849)">
        <line id="Line_77" data-name="Line 77" x2="6.765" transform="translate(0 3.591)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path id="Path_723" data-name="Path 723" d="M12,5l3.591,3.591L12,12.182" transform="translate(-8.617 -5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
      </g>
    </svg>}

    {cross && <div
      className='cross'
      onClick={crossAction} />}
  </div>
}

export default PlaceItem