import { addMessage } from '../../conteiners/Chats/chatSlice'
import useWssConnection from './useWssConnection'
import SocketContext from './SocketContext'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const SocketProvider = ({ children }) => {
  const { messageData, sendMessage } = useWssConnection()
  const dispatch = useDispatch()

  useEffect(() => {
    if (messageData.event === 'newMessage') {
      dispatch(addMessage(messageData.payload))
    }
  }, [dispatch, messageData])

  return (
    <SocketContext.Provider value={{
      messageData,
      sendMessage,
    }}>
      {children}
    </SocketContext.Provider>
  )
}

export default SocketProvider