import { useHistory } from 'react-router-dom'
import SlideShow from './SlideShow'
import './style.scss'

const Warehouses = () => {
  const { push, location: { pathname } } = useHistory()

  return (
    <div className='warehouses'>
      <div className='title'>
        <div>Moje magazyny</div>
        <div>Wybierz magazyn do zarządzania</div>
      </div>

      <div className='warehouses__slides center'>
        <div>
          <SlideShow
            autoplay
            showInfo
            action={number => push(`${pathname}/${number}`)} />
        </div>
      </div>
    </div>
  )
}

export default Warehouses