import { AVAILABLE_LANGUAGES, selectLanguage } from '../appSlice'
import { useSelector } from 'react-redux'
import en from '../lang/en.json'
import pl from '../lang/pl.json'

const useTranslation = (section = '') => {
  const languageName = useSelector(selectLanguage)

  const getKeys = (k = '') => k.split('.')

  const translate = (key = '') => {
    const keys = getKeys(`${section ? `${section}.` : ''}${key}`)
    const language = getLanguage(languageName)

    return (
      getNestedTranslation(language, keys) ??
      getNestedTranslation(en, keys) ??
      `{${key}}`)
  }

  return {
    lang: languageName,
    t: translate,
  }
}

const getLanguage = language => {
  switch (language) {
    case AVAILABLE_LANGUAGES.pl: return pl
    default: return en
  }
}

const getNestedTranslation = (language, keys) => {
  return language ? keys.reduce((obj, key) => {
    return obj?.[key]
  }, language) : undefined
}

export default useTranslation