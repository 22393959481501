import { selectBasketItem, updateBasketItem } from '../Basket/basketSlice'
import { selectCalendar } from '../Calendar/calendarSlice'
import { useHistory, useParams } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import Checkbox from '../../components/icons/Checkbox'
import { useSelector, useDispatch } from 'react-redux'
import { STATUS } from '../../utils/constants'
import { useLayoutEffect } from 'react'
import './style.scss'

const Additional = () => {

  const { status, availableAttributes } = useSelector(selectCalendar)
  const { attr = [] } = useSelector(selectBasketItem)

  const { t } = useTranslation('additional')
  const { goBack } = useHistory()
  const dispatch = useDispatch()
  const { type } = useParams()

  const isInsurance = type === 'insurance'

  useLayoutEffect(() => {
    if (status !== STATUS.succeed) goBack()
  }, [goBack, status])

  return (
    <div className='additional'>
      <div className='title'>
        <div>{t(isInsurance ? 'insuranceTitle': 'optionTitle')}</div>
        <div>{t('subTitle')}</div>
      </div>

      <div className='list'>
        {availableAttributes
          .filter(e => (isInsurance === e.isInsurance) && !!e.isVisible)
          .map(({ id, features, description, name, installationGrossPrice, monthlyGrossPrice }, idx) => {
            const selected = attr.includes(id)

            return <div
              key={idx}
              className={`list__element${description || features ? ' --big' : ''} ${selected ? '--selected' : ''}`}
              onClick={() => {
                const newAttr = selected ? attr.filter(e => e !== id) : [...attr, id]
                dispatch(updateBasketItem({ attr: newAttr }))
              }}>

              <div className='element__group group'>
                <div className='element__header'>{t(selected ? 'chosen' : 'option')}</div>
                <Checkbox selected={selected} />
              </div>

              <div className='element__title --termina'>{name}</div>
              <div className='element__desc'>
                <div>{description}</div>
                {installationGrossPrice && <div>{t('installationFee')}: <b>{installationGrossPrice} zł</b></div>}
                {monthlyGrossPrice && <div>{t('monthlyFee')}: <b>{monthlyGrossPrice} zł</b></div>}
              </div>

              {features && <div className='element__desc'>
                {features.map((e, i) => <div key={i}><span>+</span> {e}</div>)}
              </div>}
            </div>
          })}
      </div>
    </div>
  )
}

export default Additional