import useTranslation from '../../hooks/useTranslation'
import './style.scss'

const Error = ({ text, staticPos = false }) => {
  const { t } = useTranslation('basic')

  return (
    <div className={`error --${staticPos ? 'static' : 'centered'}`}>
      {text || t('errorMessage')}
    </div>
  )
}

export default Error