import Popup from '../../components/popups/Popup'

const SizePopup = ({ data, close }) => {
  const { internalWidth: width, internalLength: length, internalHeight: height } = data || {}
  const ratio = 0.75

  return (
    <Popup close={close}>
      <div className='popup__size'>
        <h2>Rozmiar MAGa</h2>

        <div className='scene center'>
          <div
            className='cuboid'
            style={{
              height: `calc(${height} * ${2 * ratio}em)`,
              width: `calc(${width} * ${2 * ratio}em)`
            }}>

            <div
              className='cuboid__side'
              style={{
                // background: '#0000003c',
                transform: `translate3d(-50%, -50%, calc(${length} * ${ratio}em))`
              }}>
              <div className='slide__label --bottom'>{width}m</div>
              <div className='slide__label --left'>{height}m</div>
            </div>

            <div
              className='cuboid__side'
              style={{
                // background: '#0000002d',
                transform: `translate3d(-50%, -50%, calc(${length} * -${ratio}em)) rotateY(180deg)`
              }}>
              <div className='slide__label --right'>{height}m</div>
            </div>

            <div
              className='cuboid__side'
              style={{
                // background: '#00000032',
                width: `calc(${length} * ${2 * ratio}em)`,
                transform: `translate(-50%, -50%) rotateY(90deg) translate3d(0, 0, calc(${width} * ${ratio}em))`
              }}>
              <div className='slide__label --bottom'>{length}m</div>
            </div>

            <div
              className='cuboid__side'
              style={{
                // background: '#00000046',
                width: `calc(${length} * ${2 * ratio}em)`,
                transform: `translate(-50%, -50%) rotateY(-90deg) translate3d(0, 0, calc(${width} * ${ratio}em))`
              }} />

            <div
              className='cuboid__side'
              style={{
                // background: '#00000050',
                height: `calc(${length} * ${2 * ratio}em)`,
                transform: `translate(-50%, -50%) rotateX(90deg) translate3d(0, 0, calc(${height} * ${ratio}em))`
              }} />

            <div
              className='cuboid__side'
              style={{
                // background: '#0000001e',
                height: `calc(${length} * ${2 * ratio}em)`,
                transform: `translate(-50%, -50%) rotateX(-90deg) translate3d(0, 0, calc(${height} * ${ratio}em))`
              }} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default SizePopup