import InaccessiblePopup from '../../../components/popups/InaccessiblePopup'
import { resetContract, selectContract } from '../../Login/loginSlice'
import useAuthorizedFetch from '../../../hooks/useAuthorizedFetch'
import { selectBasketItem } from '../../Basket/basketSlice'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, updateValues } from '../formSlice'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { PATCH } from '../../../rest/request'
import { useState, useEffect } from 'react'
import { user } from '../../../rest/urls'
import Form from './Form'
import '../style.scss'

const ERROR_MESSAGE = 'Nie udało się dodać danych'

const Contract = () => {

  const [status, setStatus] = useState(STATUS.idle)
  const [error, setError] = useState('')

  const { hash = '' } = useSelector(selectBasketItem)
  const contract = useSelector(selectContract)
  const { run } = useAuthorizedFetch()

  const dispatch = useDispatch()
  const { replace } = useHistory()

  useEffect(() => {
    const savedFields = typeof contract === 'object' && Object
      .entries(contract)
      .reduce((r, [k, v]) => {
        if (!v) {
          const key = k === 'apartment_nr' ? 'localNumber'
            : k === 'identity_card_type' ? 'cardType'
              : k === 'identity_card' ? 'identityCard'
                : k === 'building_nr' ? 'houseNumber'
                  : k === 'zip_code' ? 'postalCode' : k
          r = { ...r, [key]: v }
        }
        return r
      }, {})

    if (Object.keys(savedFields) > 0) {
      dispatch(updateValues(savedFields))
    }
  }, [dispatch, contract])

  const submit = async body => {
    setStatus(STATUS.pennding)

    const error = (err) => {
      setStatus(STATUS.failed)

      try {
        const paresedError = JSON.parse(err)
        const errors = paresedError.errors

        if (errors && typeof errors === 'object') {
          const errList = Object.entries(errors).reduce((r, [k, v], i) => {
            const value = Array.isArray(v) ? v.reduce((r, e) => r += e, '') : v

            return [
              ...r,
              <li key={i}>{k}: {value}</li>
            ]
          }, [])
          setError(errList.length > 0 ? <ul>{errList}</ul> : ERROR_MESSAGE)
        } else throw new Error()

      } catch (errr) {
        setError(ERROR_MESSAGE)
      }
    }

    run(
      PATCH(user, { body, credentials: true }),
      () => {
        setStatus(STATUS.succeed)
        dispatch(resetForm())
        dispatch(resetContract())
        replace(hash ? '/billing' : '/')
      },
      error
    )
  }


  return (
    <div className='contract'>
      <div className='contract__title title'>
        <div>Dane do umowy</div>
        <div>Uzupełnij dane</div>
      </div>

      {error && <InaccessiblePopup
        desc={error}
        close={() => setError('')}
      />}

      <div className='contract__main main center'>
        {status === STATUS.pennding && <Loading middleOfPage />}

        <Form
          block={status === STATUS.pennding}
          submit={submit} />
      </div>
    </div>
  )
}

export default Contract