import { selectBasketItem, selectBasket } from './basketSlice'
import SummaryAmount from './SummaryAmount'
import { useSelector } from 'react-redux'

const SummaryHeader = ({ index, block }) => {
  const { selected, list } = useSelector(selectBasket)
  const { name, address } = useSelector(state => selectBasketItem(state, index))

  const value = (index === undefined ? selected : index) + 1

  return (
    <div className='basket__header group'>
      <div className='header__desc'>
        <div>{list?.length > 1 ? `${value}. ` : ''}{name}</div>

        {address && (({ street, estateNumber, localNumber, province }) => <div>
          ul.{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, {province}
        </div>)(address)}
      </div>

      <SummaryAmount index={index} block={block} />
    </div>
  )
}

export default SummaryHeader