import { ReactComponent as Mastercard } from '../../../assets/mastercard.svg'
import { selectUserData, ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { ReactComponent as Visa } from '../../../assets/visa.svg'
import useTranslation from '../../../hooks/useTranslation'
import { removePaymentMethods } from '../../../rest/urls'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../../../utils/constants'
import Loading from '../../../components/Loading'
import { DELETE } from '../../../rest/request'
import Error from '../../../components/Error'
import useUserFetch from '../useUserFetch'
import Item from '../Item'

const type = ACCOUNT_TYPE.cards

const PaymentCard = () => {

  const { data } = useSelector(state => selectUserData(state, type))
  const { t } = useTranslation()

  const status = useUserFetch(type)

  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))
  const removeCard = async id => {
    if (!id) return
    try {
      await DELETE(`${removePaymentMethods}${id}`)
      const newList = [...data].filter(e => e.id !== id)
      updateVal('data', newList)

    } catch (error) { }
  }

  return (
    <>
      <p>{t('account.paymentMethods')}</p>
      <div className='account__list'>
        {status === STATUS.succeed ?
          data?.length > 0 ?
            data.map(({ id, cardIssuer, cardNumber, cardValidityDate }) => <Item
              key={id}
              icon={cardIssuer === 'VISA' ? <Visa /> : <Mastercard />}
              title={`${cardIssuer} ${cardNumber}`}
              desc={cardValidityDate}
              right={<div
                className='item__text'
                onClick={() => removeCard(id)}>{t('account.remove')}</div>
              }
            />) : <Error
              staticPos
              text={t('filters.noItems')} />
          : status === STATUS.failed ? <Error staticPos />
            : <Loading />}
        <div className='list__more'>+ {t('account.addACard')}</div>
      </div>
    </>
  )
}

export default PaymentCard