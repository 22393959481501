import { updateBasketItem, selectBasketItem } from './basketSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { checkAvailability } from '../../rest/urls'
import { useHistory } from 'react-router-dom'
import { POST } from '../../rest/request'
import { useState } from 'react'

const SummaryAmount = ({ index, block }) => {
  const { amount, hash, placeId } = useSelector(state => selectBasketItem(state, index))
  const [status, setStatus] = useState(STATUS.idle)

  const { t } = useTranslation('basic')

  const dispatch = useDispatch()
  const { push } = useHistory()

  const showPopup = p => push(window.location.pathname, LOCATION_STATES.popup(p))
  const updateAmount = amount => dispatch(updateBasketItem({ amount }))

  const verifyAvailability = async newAmount => {
    setStatus(STATUS.pennding)

    try {
      const response = await POST(checkAvailability(placeId), { body: { hash } })
      const { count } = response
      setStatus(STATUS.succeed)

      newAmount <= count ? showPopup('increaseMag')
        : showPopup('warning')
    } catch (err) {
      setStatus(STATUS.failed)
    }
  }

  const changeAmount = async (e, differnce) => {
    e?.stopPropagation()
    if (status === STATUS.pennding || block) return
    const newAmount = amount + differnce

    newAmount < 1 ? showPopup('confirmation')
      : amount > newAmount ? updateAmount(newAmount)
        : verifyAvailability(newAmount)
  }

  return (
    <div className='header__amount'>
      <div className='center'>
        <div onClick={e => changeAmount(e, -1)}>-</div>
        <span>{amount}</span>
        <div onClick={e => changeAmount(e, 1)}>+</div>
      </div>
      <div>{t('amount')}</div>
    </div>
  )
}

export default SummaryAmount