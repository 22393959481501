import { selectWarehouse } from '../../Warehouses/warehousesSlice'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import PaymentsSection from './PaymentsSection'
import LocationSection from './LocationSection'
import SettingsSection from './SettingsSection'
import DateTile from '../../Calendar/DateTile'
import InvoiceSection from './InvoiceSection'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MagSection from './MagSection'
import { forwardRef } from 'react'

const MagDetails = forwardRef((_, ref) => {

  const { number } = useParams()
  const { start, end, invoice, mag, address, payments } = useSelector(state => selectWarehouse(state, number))

  const getDateFromString = (s = '') => new Date(s.split('.').reverse().join('-'))

  return (
    <div className='menu__section summary'>
      <p>Szczegóły wynajmu</p>

      <div
        ref={ref}
        className='menu__details'>

        <div className='details__date calendar'>
          <div className='calendar__main'>
            <div className='calendar__inputs center'>
              <DateTile
                start
                date={getDateFromString(start)} />
              <DateTile date={getDateFromString(end)} />
            </div>
          </div>
        </div>

        <div className='list'>
          <MagSection data={mag} />
          <LocationSection data={address} />
          <InvoiceSection data={invoice} />
          <PaymentsSection data={payments} />
          <SettingsSection />
        </div>

        <div className='details__buttons'>
          <ArrowBtn
            enlarged
            text='Przedłuż wynajem' />
          <div className='button__cancel'>Anuluj wynajem</div>
        </div>
      </div>
    </div>
  )
})

export default MagDetails