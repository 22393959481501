import { ReactComponent as Arrow } from '../../assets/arrowRight.svg'
import { useState, useEffect } from 'react'
import './style.scss'

const ArrowBtn = ({ text, className = '', showArrow = true, enlarged = false, disabled = false, action }) => {
  const [animation, animate] = useState(false)

  useEffect(() => {
    if (animation) {
      const timeout = setTimeout(() => {
        animate(false)
        action()
      }, 200)

      return () => clearTimeout(timeout)
    }
  }, [action, animation])

  return (
    <button
      className={`button ${className} --arrow${enlarged ? ' --enlarged' : ''}${animation ? ' --animate' : disabled ? ' --disabled' : ''}`}
      onClick={() => !disabled && animate(true)}>
      <span className='--termina'>{text}</span>
      {showArrow && <Arrow />}
    </button>
  )
}

export default ArrowBtn