import { UNAUTHORIZED_TYPES } from '../../../utils/constants'
import usePopupState from '../../../hooks/usePopupState'
import VerificationPopup from './VerificationPopup'
import NewMagPopup from './NewMagPopup'
import ErrorPopup from './ErrorPopup'
import AuthPopup from './AuthPopup'
import PinPopup from './PinPopup'

const Popups = () => {
  const { popup } = usePopupState()

  switch (popup) {
    case UNAUTHORIZED_TYPES.mail:
    case UNAUTHORIZED_TYPES.phone:
    case UNAUTHORIZED_TYPES.device: return <VerificationPopup />
    case UNAUTHORIZED_TYPES.webAuth: return <AuthPopup />
    case UNAUTHORIZED_TYPES.pin: return <PinPopup />
    case 'formError': return <ErrorPopup />
    case 'newMag': return <NewMagPopup />
    default: return false
  }
}

export default Popups