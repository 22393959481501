import { ReactComponent as Fingerprint } from '../../assets/fingerprint.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Submit from '../../components/inputs/Submit'
import { register } from '../Entrance/credentials'
import Warehouse from '../../components/Warehouse'
import { useState } from 'react'
import phone from './phone.png'

const BiometricSlide = ({ next }) => {
  const [rawId, setRawId] = useState('')

  const registerApp = async () => {
    try {
      const id = await register()
      if (id) {
        setRawId(id)
        next()
      }
      console.log(rawId)
    } catch (error) { }
  }

  return (
    <>
      <Logo />

      <div className='slide__warehouses'>
        <div className='warehouse --left'>
          <Warehouse />
        </div>
        <div className='warehouse --right'>
          <Warehouse />
        </div>
        <img className='img--phone' alt='phones' src={phone} />
      </div>

      <div className='slide__title center'>
        <div className='--termina'>Czy chcesz logować się przy pomocy biometrii?</div>
      </div>

      <Fingerprint />

      <div className='slide__desc'>
        <div>Możesz również logować się</div>
        <div>przy pomocy biometrii</div>
      </div>

      <div className='slide__buttons'>
        <button className='button --back' onClick={next}>
          <span>Pomiń</span>
        </button>

        <Submit text='Tak' action={registerApp} />
      </div>
    </>
  )
}

export default BiometricSlide