import { updateBasketItem, selectBasketItem, resetTime, extendFormToMap } from '../Basket/basketSlice'
import { getStartDate, getEndDate, getRangeFromDates, DAY } from '../../utils/date'
import { useEffect, useLayoutEffect, useState, useCallback, useMemo } from 'react'
import { fetchCalendar, selectCalendar } from './calendarSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { ReactComponent as Help } from '../../assets/help.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import usePopupState from '../../hooks/usePopupState'
import { useParams, useHistory } from 'react-router'
import RentalPeriodPopup from './RentalPeriodPopup'
import Loading from '../../components/Loading'
import CalendarPopup from './CalendarPopup'
import Error from '../../components/Error'
import { Prompt } from 'react-router-dom'
import DateTile from './DateTile'
import Range from './Range'
import './style.scss'

const Calendar = () => {
  const { start, end, minDate, rangeDate: range = { m: 0, d: 0 }, hash: basketHash } = useSelector(selectBasketItem)
  const { status, priceList, promotions, hash: calendarHash, termiateDays } = useSelector(selectCalendar)

  const [selectedStart, selectStart] = useState(true)

  const { t } = useTranslation()

  const { hash: paramHash, placeId: placeID } = useParams()
  const { popup } = usePopupState()
  const { push } = useHistory()

  const dispatch = useDispatch()


  const updateBasket = useCallback(obj => dispatch(updateBasketItem(obj)), [dispatch])
  const updateEndDate = useCallback(date => updateBasket({ end: date }), [updateBasket])
  const updateStartDate = useCallback(date => updateBasket({ start: date }), [updateBasket])

  useLayoutEffect(() => { dispatch(extendFormToMap(false)) }, [dispatch])

  useEffect(() => {
    if (calendarHash !== paramHash) dispatch(fetchCalendar({ placeID, hash: paramHash }))
  }, [dispatch, calendarHash, paramHash, placeID])

  useEffect(() => {
    if (status === STATUS.succeed) dispatch(resetTime())
  }, [status, dispatch])

  useLayoutEffect(() => {
    // to restore from session
    if (start && typeof start !== 'object') updateStartDate(getStartDate(start))
    if (end && typeof end !== 'object') updateEndDate(getStartDate(end))
  }, [start, end, updateStartDate, updateEndDate])

  useEffect(() => {
    if (calendarHash === paramHash && calendarHash !== basketHash && !basketHash && priceList.length > 0) {
      const { m, d, gross_price: monthlyPrice, gross_price_d: dailyPrice } = priceList[priceList.length - 1]
      const date = getStartDate(minDate)

      updateStartDate(date)
      updateEndDate(getEndDate({ m, d }, date))
      updateBasket({ hash: calendarHash, termiateDays, rangeDate: { m, d }, dailyPrice, monthlyPrice, promotions })
    }
  }, [basketHash, calendarHash, minDate, paramHash, priceList, promotions, termiateDays, updateBasket, updateEndDate, updateStartDate])

  const calendarChange = d => {
    const date = getStartDate(d)

    if (selectedStart) {
      selectStart(false)
      updateStartDate(date)
      updateEndDate(getEndDate(range, date))

    } else if (date - start > DAY) {
      const rangeDate = getRangeFromDates(date, start)
      const priceIdx = priceList.findIndex(({ m, d }) => (m > rangeDate.m) || (rangeDate.m === 0 && d > rangeDate.d))
      const priceObj = priceIdx > 0 ? priceList[priceIdx - 1] : priceList[priceList.length - 1]
      const { gross_price: monthlyPrice, gross_price_d: dailyPrice } = priceObj

      updateEndDate(date)
      updateBasket({ rangeDate, dailyPrice, monthlyPrice })
    }
  }

  const rangeCom = useMemo(() => <Range
    range={range}
    ranges={priceList}
    updateRange={obj => {
      const { m, d, gross_price: monthlyPrice, gross_price_d: dailyPrice } = obj

      selectStart(false)
      updateEndDate(getEndDate({ m, d }, start))
      updateBasket({ rangeDate: { m, d }, dailyPrice, monthlyPrice })
    }}
  />, [priceList, range, start, updateBasket, updateEndDate])

  const startDateTile = useMemo(() => <DateTile
    start
    date={start}
    action={() => {
      push(window.location.pathname, LOCATION_STATES.popup('calendar'))
      selectStart(true)
    }}
  />, [push, start])

  const endDateTile = useMemo(() => <DateTile
    date={end}
    action={() => {
      push(window.location.pathname, LOCATION_STATES.popup('calendar'))
      selectStart(false)
    }}
  />, [end, push])

  return (
    <>
      <Prompt
        message={({ pathname }) => {
          return pathname.match(/(?=.*(map|calendar|additional))(?!.*mapdetails)/) ? true : t('calendar.areYouSure')
        }}
      />

      <div className='calendar'>
        {popup === 'info' && <RentalPeriodPopup />}
        {popup === 'calendar' && <CalendarPopup
          end={end}
          start={start}
          minDate={minDate}
          selected={selectedStart}
          minEndDate={priceList.length > 0 ? getEndDate(priceList[0], start) : getStartDate(start.getTime() + DAY)}
          rangeCom={rangeCom}
          select={selectStart}
          change={calendarChange} />}

        <div className='calendar__main'>
          <div className='title'>
            <div className='group'>
              <span>{t('calendar.leasePeriod')}</span>
              <Help onClick={() => push(window.location.pathname, LOCATION_STATES.popup('info'))} />
            </div>
          </div>

          {status === STATUS.failed ? <Error />
            : status === STATUS.succeed ? <>
              <div className='center'>
                <div className='center__main'>
                  <div className='calendar__inputs center'>
                    {startDateTile}
                    {endDateTile}
                  </div>

                  <div className='calendar__range'>
                    {rangeCom}
                  </div>
                </div>
              </div>

              <p>{t('basket.periodOfNotice')}: <b>{termiateDays} {t(`basket.${termiateDays === 1 ? 'day' : 'days'}`)}</b></p>

              <div className='calendar__info'>
                <div>{t('calendar.billingInformation')}:</div>
                <div>{t('calendar.billingInformationDesc')}</div>
              </div>
            </> : <Loading middleOfPage />}
        </div>
      </div>
    </>
  )
}

export default Calendar