import './style.scss'

const CircleBtn = ({ text, Icon, action }) => {
  return (
    <div
      className='button__circle'
      onClick={action}>
      <div className='button__icon'><Icon /></div>
      <div className='button__text'>{text}</div>
    </div>
  )
}

export default CircleBtn