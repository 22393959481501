
const register = (publicKey, callback) => {
  const publicKeyCredential = { ...publicKey };
  publicKeyCredential.user.id = bufferDecode(publicKey.user.id);
  publicKeyCredential.challenge = bufferDecode(base64Decode(publicKey.challenge));
  if (publicKey.excludeCredentials) {
    publicKeyCredential.excludeCredentials = credentialDecode(publicKey.excludeCredentials);
  }

  navigator.credentials.create({
    publicKey: publicKeyCredential
  }).then((data) => {
    registerCallback(data, callback);
  }, (error) => {
    console.error(`Error, ${error}`)
  });
}

const registerCallback = (publicKey, callback) => {
  let publicKeyCredential = {
    id: publicKey.id,
    type: publicKey.type,
    rawId: bufferEncode(publicKey.rawId),
    response: {
      clientDataJSON: bufferEncode(publicKey.response.clientDataJSON),
      attestationObject: bufferEncode(publicKey.response.attestationObject)
    }
  };

  callback(publicKeyCredential);
}

const sign = (publicKey, callback) => {
  const publicKeyCredential = { ...publicKey };
  publicKeyCredential.challenge = bufferDecode(base64Decode(publicKey.challenge));
  if (publicKey.allowCredentials) {
    publicKeyCredential.allowCredentials = credentialDecode(publicKey.allowCredentials);
  }

  navigator.credentials.get({
    publicKey: publicKeyCredential
  }).then((data) => {
    signCallback(data, callback);
  }, (error) => {
    console.error(`Error, ${error}`)
  }
  );
}

const signCallback = (publicKey, callback) => {
  let publicKeyCredential = {
    id: publicKey.id,
    type: publicKey.type,
    rawId: bufferEncode(publicKey.rawId),
    response: {
      authenticatorData: bufferEncode(publicKey.response.authenticatorData),
      clientDataJSON: bufferEncode(publicKey.response.clientDataJSON),
      signature: bufferEncode(publicKey.response.signature),
      userHandle: (publicKey.response.userHandle ? bufferEncode(publicKey.response.userHandle) : null),
    }
  };

  callback(publicKeyCredential);
}

// const bufferEncode = (value) => window.btoa(String.fromCharCode.apply(null, new Uint8Array(value)))

const bufferEncode = (buffer) => {
  const bytes = new Uint8Array(buffer);
  let str = '';

  for (const charCode of bytes) {
    str += String.fromCharCode(charCode);
  }

  const base64String = btoa(str);

  return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

const bufferDecode = value => {
  var t = window.atob(value)
  return Uint8Array.from(t, c => c.charCodeAt(0));
}

const base64Decode = (input) => {
  input = input.replace(/-/g, '+').replace(/_/g, '/');

  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    input += new Array(5 - pad).join('=');
  }

  return input;
}

const credentialDecode = (credentials) => {
  return credentials.map((data) => {
    return {
      id: bufferDecode(base64Decode(data.id)),
      type: data.type,
      transports: data.transports,
    };
  });
}


export { register, sign }