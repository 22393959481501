import useTranslation from '../../hooks/useTranslation'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ChatList from './ChatList'
import Chat from './Chat'
import './style.scss'

const chat = <Chat />

const Chats = () => {
  const [smallScreen, updateScreen] = useState(window.innerWidth < 700)

  const { id } = useParams()
  const { t } = useTranslation('basic')

  const list = <>
    <h4 className='--termina'>{t('messages')}</h4>
    <ChatList mobile={smallScreen} />
    <div className='list__footer center'>
      <hr />
      <span className='chat__text'>{t('thatIsAll')}</span>
    </div>
  </>

  useEffect(() => {
    const resize = () => updateScreen(window.innerWidth < 700)
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return (
    <div className='chat'>
      <div className='chat__main'>
        {smallScreen ? id ? chat : list : (
          <div className='chat__group'>
            <div className='chat__left'>{list}</div>
            <div className='chat__right'>{chat}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Chats