import InaccessiblePopup from '../../components/popups/InaccessiblePopup'
import useAuthorizedReduxFetch from '../../hooks/useAuthorizedReduxFetch'
import { ReactComponent as Account } from '../../assets/account.svg'
import { ReactComponent as Padlock } from '../../assets/padlock.svg'
import { fetchLogIn, removeError, selectError } from './loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../components/inputs/Submit'
import Input from '../../components/inputs/Input'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const Form = () => {
  const error = useSelector(selectError)
  const { run } = useAuthorizedReduxFetch()

  const [form, setForm] = useState({
    mail: 'demo@demo.com',
    password: 'demo',
  })
  const [warning, setWarning] = useState({
    mail: false,
    password: false,
  })

  const dispatch = useDispatch()
  const { push } = useHistory()

  const refs = []


  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
    if (value && warning[name]) setWarning({
      ...warning,
      [name]: false
    })
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const submit = () => {
    const { mail, password } = form

    if (mail && password) {
      const body = { email: mail, password }
      run(fetchLogIn(body))

    } else setWarning({
      mail: !mail,
      password: !password
    })
  }

  return (
    <div className='login__form'>
      {error && <InaccessiblePopup
        desc={error}
        close={() => dispatch(removeError())} />}

      <Input
        ref={r => refs.push(r)}
        value={form.mail}
        name='mail'
        label='Login'
        minLength='1'
        maxLength='200'
        icon={<Account />}
        warning={warning.mail}
        placeholder='Numer telefonu lub e-mail'
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        ref={r => refs.push(r)}
        value={form.password}
        name='password'
        type='password'
        label='Hasło'
        minLength='6'
        maxLength='90'
        placeholder='Hasło'
        icon={<Padlock />}
        warning={warning.password}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Submit
        text='Zaloguj'
        action={submit} />

      <p
        className='--secondary'
        onClick={() => push('/login/reset')}>Nie pamiętasz hasła?</p>
    </div>
  )
}

export default Form