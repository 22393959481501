import './style.scss'

const Checkbox = ({ required = false, name, label, disabled = false, checked = false, changeHandler }) => {
  return (
    <div className='input__checkbox'>
      <input
        id={name}
        name={name}
        type='checkbox'
        className='checkbox'
        autoComplete='none'
        disabled={disabled}
        checked={checked}
        required={required}
        onChange={changeHandler} />

      <label htmlFor={name}>
        {required && <span className='--warning'>* </span>}
        <span>{label}</span>
      </label>
    </div>
  )
}

export default Checkbox