const labels = [{
  month: 1,
  price: 300
}, {
  month: 3,
  price: 290
}, {
  month: 6,
  price: 280
}, {
  month: 12,
  price: 270
}]

const selectedMonth = 1

const MagPromotion = () => {

  return (
    <div className='menu__section'>
      <p>Dłużej magazynujesz, więcej zyskujesz</p>

      <div className='menu__promotion'>
        {labels.map(({ month, price }, i) => <div
          key={i}
          className={`promotion__line center${month <= selectedMonth ? ' --selected' : ''}`}>
          <div className={`promotion__label ${month === selectedMonth ? ' --selected' : ''}`}>
            <span>{price} zł</span>
            <span className='--bottom'>{month} m-c</span>
          </div>
        </div>)}
      </div>
    </div>
  )
}

export default MagPromotion