import phillars from '../../assets/gate/gatePhillars.png'
import { GATE_STATUS } from '../../utils/constants'
import gate from '../../assets/gate/gate.png'
import { useState } from 'react'
import { TIMES } from '.'

const Gate = ({ status = GATE_STATUS.closed }) => {
  const [loading, setLoading] = useState({
    gate: true,
    phillars: true
  })

  const animate = status === 'gate_opening' ? TIMES.gateOpening
    : status === 'gate_closing' ? TIMES.gateClosing : 0

  const updateLoading = (n = 'gate') => setLoading(l => { return { ...l, [n]: false } })

  return (
    <svg id='gate' xmlns='http://www.w3.org/2000/svg' width='90%' height='183.5' viewBox='0 0 369.356 183.5'>
      <defs>
        <pattern id='gate-pattern' preserveAspectRatio='none' width='100%' height='100%' viewBox='0 0 534 264'>
          <image
            width='534'
            height='264'
            href={gate}
            onLoad={() => updateLoading('gate')}
            onError={() => updateLoading('gate')} />
        </pattern>
        <pattern id='phillars-pattern' preserveAspectRatio='none' width='100%' height='100%' viewBox='0 0 732 346'>
          <image
            width='732'
            height='346'
            href={phillars}
            onLoad={() => updateLoading('phillars')}
            onError={() => updateLoading('phillars')} />
        </pattern>

        <filter id='lewy' x='62.102' y='0' width='25' height='171' filterUnits='userSpaceOnUse'>
          <feOffset dy='-7' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodColor='#eba500' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='prawy' x='283.102' y='0' width='25' height='171' filterUnits='userSpaceOnUse'>
          <feOffset dy='-7' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='3' result='blur-2' />
          <feFlood floodColor='#eba500' />
          <feComposite operator='in' in2='blur-2' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='cienpodfilter' filterUnits='userSpaceOnUse'>
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodColor='#000000' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>

      <g id='brama-full' transform='translate(-21.898 -356.5)'>
        {!loading.phillars && <>
          <path id='cien-noga-lewa' d='M-2578.97,1838.574h63.805l6.551-6.968h-66.475Z' transform='translate(2606.868 -1306.81)' />
          <path id='cien-noga-prawa' d='M-2508.614,1838.574h-63.805l-6.551-6.968h66.475Z' transform='translate(2893.868 -1306.81)' />
        </>}
        <g id='przesuwna' className={status === 'gate_opened' || status === 'gate_opening' ? '--opened' : ''} style={animate ? { transitionDuration: `${animate}s` } : {}}>
          {!loading.gate && <ellipse id='cienpod-brama' cx='126' cy='1.5' rx='126' ry='1.5' transform='translate(81 524)' fill='var(--gate-shadow)' filter='url(#cienpodfilter)' />}
          <rect id='brama' width='267' height='132' transform='translate(74 382)' fill={`url(#gate-pattern)`} />
        </g>
        {!loading.phillars && !loading.gate && <ellipse id='cien-piono' cx='13.5' cy='73' rx='13.5' ry='73' transform='translate(303 379)' opacity='0.42' />}
        <rect id='filary' width='366' height='173' transform='translate(24 362)' fill={`url(#phillars-pattern)`} />
        <g id='Ledy' opacity='0' style={animate ? { animationDuration: `${animate}s` } : {}}>
          <g transform='matrix(1, 0, 0, 1, 21.9, 356.5)' filter='url(#lewy)'>
            <line id='lewy-2' data-name='lewy' y2='147' transform='translate(74.6 19)' fill='none' stroke='#ebeb00' strokeWidth='1' strokeDasharray='49 4' />
          </g>
          <g transform='matrix(1, 0, 0, 1, 21.9, 356.5)' filter='url(#prawy)'>
            <line id='prawy-2' data-name='prawy' y2='147' transform='translate(295.6 19)' fill='none' stroke='#ebeb00' strokeWidth='1' strokeDasharray='49 4' />
          </g>
        </g>
      </g>
    </svg >
  )
}

export default Gate