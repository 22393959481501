import RefreshController from './conteiners/Login/RefreshController'
import SocketProvider from './context/socket/SocketProvider'
import { fetchToken } from './conteiners/Login/loginSlice'
import CallProvider from './context/call/CallProvider'
import { Provider } from 'react-redux'
import Desktop from './layouts/Desktop'
import ReactDOM from 'react-dom'
import Router from './Router'
import store from './store'
import React from 'react'
import App from './App'
import './index.css'

store.dispatch(fetchToken())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketProvider>
        <App>
          <Router>
            <CallProvider>
              <Desktop />
            </CallProvider>
            <RefreshController />
          </Router>
        </App>
      </SocketProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
