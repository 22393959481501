import { ReactComponent as Help } from '../../assets/helpBig.svg'
// import { ReactComponent as Phone } from '../../assets/ringing.svg'
import { ReactComponent as Alert } from '../../assets/alert.svg'
// import BigCircleBtn from '../../components/buttons/BigCircleBtn'
import CircleBtn from '../../components/buttons/CircleBtn'
import { useHistory } from 'react-router-dom'
import CallBtn from './CallBtn'

const Actions = ({ showFaq }) => {
  const { push } = useHistory()

  return (
    <div className='menu__buttons --top'>
      <CircleBtn
        text='Napisz'
        Icon={Alert}
        action={() => push('/chat/new')} />

      {/* <BigCircleBtn
        interior={<div className='center'>
          <Alert />
          <span>Zgłos awarię</span>
        </div>} /> */}
      <CallBtn />

      <CircleBtn
        text='FAQ'
        Icon={Help}
        action={showFaq} />
    </div>
  )
}

export default Actions