import { getDateString, getRangeString } from '../../utils/date'

const MagSection = ({ address, monthlyPrice, dailyPrice, amount, termiateDays, size, name, start, end, rangeDate }) => {
  const { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude } = address || {}
  const { area, internalHeight, internalLength, internalWidth } = size || {}
  const { m = 0, d = 0 } = rangeDate || {}

  return (
    <div>
      <div className='list__header element__title'>Magazyn</div>
      <div className='list__element --big'>
        <div className='element__title'>{name}</div>
        <div className='element__desc'>
          <div>Powierzchnia: {Number(area)}m²</div>
          <div>Długość: {internalLength}m</div>
          <div>Szerokość: {internalWidth}m</div>
          <div>Wysokość: {internalHeight}m</div>
        </div>

        <div className='element__title'>Lokalizacja</div>
        <div className='group'>
          <div className='element__desc'>
            <div>{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}</div>
            <div>pow. {province}</div>
            <div>{city}</div>
          </div>
          <div
            className='element__desc'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${gpslatitude},${gpslongitude}`)}>Pokaż</div>
        </div>

        <div className='element__title'>Czas wynajęcia</div>
        <div className='element__desc'>
          <div>{getRangeString(rangeDate)}</div>
          <div>{getDateString(start)} → {end ? getDateString(end) : '∞'}</div>
        </div>

        <div className='element__title'>Koszt</div>
        <div className='element__desc'>
          {m !== 0 && <div>
            <span>{m === 1 && d === 0 ? 'Wynajm' : 'Miesięcznie'}: </span>
            <span className='--marked'>{amount * Number(m === 0 ? dailyPrice * d : monthlyPrice)}zł</span>
          </div>}

          {d > 0 && <div>
            <span>{m === 0 ? 'Wynajm' : 'Pozostałe dni'}: </span>
            <span className='--marked'>{d} {d === 1 ? 'dzień' : 'dni'} x {dailyPrice}zł = {dailyPrice * d}zł</span>
          </div>}

          <div>Okres wypowiedzenia: {termiateDays} dni</div>
        </div>
      </div>
    </div>
  )
}

export default MagSection