import QRCode from 'react-qr-code'
import React from 'react'

const href = window.location.href

const ComercialPopup = ({ close }) => {
  return (
    <div className='popup__bg center'>
      <div className='popup--comercial'>
        <div className='popup__close' onClick={close}>x</div>
        <div className='popup__header'>
          <h1>Skorzystaj z wygodniejszej aplikacji</h1>
          <h1>na systemy mobilne</h1>
        </div>

        <div className='popup__main group'>
          <div className='popup__section'>
            <QRCode
              level='L'
              size={192}
              value={href}
            />
            <h2>iOS</h2>
          </div>

          <div className='popup__section'>
            <QRCode
              level='M'
              size={192}
              value={href}
            />
            <h2>Android</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComercialPopup