import { AVAILABLE_LANGUAGES, selectLightTheme, updateLanguage, updateTheme } from '../../../appSlice'
import { selectUserData, ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { ReactComponent as Language } from '../../../assets/language.svg'
import { ReactComponent as User } from '../../../assets/account.svg'
import { ReactComponent as Moon } from '../../../assets/moon.svg'
import { ReactComponent as Ring } from '../../../assets/ring.svg'
import { ReactComponent as Sun } from '../../../assets/sun.svg'
import useTranslation from '../../../hooks/useTranslation'
import { selectIsLoggedIn } from '../../Login/loginSlice'
import Switch from '../../../components/inputs/Switch'
import { useDispatch, useSelector } from 'react-redux'
import UserSettings from './UserSettings'
import Item from '../Item'

const type = ACCOUNT_TYPE.settings

const Settings = () => {

  const { notifications = false } = useSelector(state => selectUserData(state, type))
  const isLightTheme = useSelector(selectLightTheme)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { t, lang } = useTranslation('account')

  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  return (
    <div className='account__settings account__popup'>
      <div className='account__header'>
        <div className='account__icon'>
          <User />
        </div>
        <div className='title'>
          <div>{t('settings')}</div>
          <div>{t('subTitle')}</div>
        </div>
      </div>


      <p>{t('preferences')}</p>
      <div className='account__list'>
        <Item
          icon={isLightTheme ? <Sun /> : <Moon />}
          title={t('theme')}
          desc={t(isLightTheme ? 'bright' : 'dark')}
          right={<Switch
            name='theme'
            checked={isLightTheme}
            changeHandler={() => dispatch(updateTheme(!isLightTheme))}
          />}
        />

        <Item
          icon={<Ring />}
          title={t('notifications')}
          desc={t(notifications ? 'on' : 'off')}
          right={<Switch
            name='notifications'
            checked={notifications}
            changeHandler={() => updateVal('notifications', !notifications)}
          />}
        />

        <Item
          icon={<Language />}
          title={t('language')}
          desc={lang === AVAILABLE_LANGUAGES.pl ? 'polski' : 'english'}
          right={<Switch
            name='language'
            checked={lang === AVAILABLE_LANGUAGES.pl}
            changeHandler={() => {
              dispatch(updateLanguage(lang === AVAILABLE_LANGUAGES.pl ? AVAILABLE_LANGUAGES.en : AVAILABLE_LANGUAGES.pl))
            }}
          />}
        />
      </div>

      {isLoggedIn && <UserSettings />}
    </div>
  )
}

export default Settings