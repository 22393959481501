import { ACCOUNT_TYPE, selectUserData, updateValue } from '../accountSlice'
import { STATUS, UNAUTHORIZED_TYPES } from '../../../utils/constants'
import useAuthorizedFetch from '../../../hooks/useAuthorizedFetch'
import { finalExp, initialExp } from '../../../utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import Popup from '../../../components/popups/Popup'
import PinForm from '../../../components/PinForm'
import { useHistory } from 'react-router-dom'
import { PATCH } from '../../../rest/request'
import { user } from '../../../rest/urls'
import { useState } from 'react'

const type = ACCOUNT_TYPE.basic

const EditPhonePopup = () => {

  const { data = {} } = useSelector(state => selectUserData(state, type))
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState(false)
  const [form, showForm] = useState(true)
  const [phone, setPhone] = useState('')
  const [token, setToken] = useState('')

  const { run } = useAuthorizedFetch()
  const { goBack, push } = useHistory()
  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const sendCode = async () => {
    setStatus(STATUS.pennding)

    try {
      const body = { phone }
      await PATCH(`${user}/phone`, { body })

    } catch (err) {
      try {
        const error = JSON.parse(err)
        const { type, message } = error

        if (type === UNAUTHORIZED_TYPES.smsOtp) {
          showForm(false)
          setStatus(STATUS.idle)
        } else if (message) {
          push(window.location.pathname, { ...error, popup: 'formError' })
        } else throw new Error('Wrong response')

      } catch (errr) {
        console.error(errr)
        setStatus(STATUS.failed)
      }
    }
  }


  const submit = () => {
    const body = { phone, sms_token: token }
    setStatus(STATUS.pennding)

    run(
      PATCH(`${user}/phone`, { body }),
      res => {
        if (res) {
          updateVal('data', { ...data, phone })
          goBack()
        }
      },
      () => {
        setToken('')
        setWarning(true)
        setStatus(STATUS.failed)
      }
    )
  }

  const validatePhone = () => {
    if (phone.match(finalExp.phone)) sendCode()
    else setWarning(true)
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <div className='popup__title'>
          <div>Zmiana numeru telefonu</div>
        </div>

        <div className='popup__form'>
          {form ? <>
            <Input
              required
              name='phone'
              type='tel'
              minLength='9'
              maxLength='12'
              value={phone}
              warning={warning}
              label='Numer telefonu'
              placeholder='Wpisz numer telefonu'
              disabled={status === STATUS.pennding}
              blurHandler={() => setWarning(phone.match(finalExp.phone) ? false : 'niewłaściwy numer')}
              changeHandler={e => {
                const { value } = e.target
                if (!value || value.match(initialExp.phone)) setPhone(value)
              }} />

            <Submit
              text='Zmień'
              block={phone?.length < 9 || status === STATUS.pennding}
              isLoading={status === STATUS.pennding}
              action={validatePhone} />
          </> : <>
            <div className='verification__text'>
              <div>Przepisz kod weryfikacyjny</div>
              <div>wysłany na numer</div>
            </div>

            <h2>{phone.replace(/^([\d]{3})([\d]{3})([\d]{3})$/, '$1 $2 $3')}</h2>

            <PinForm
              isCode
              autoFocus
              value={token}
              warning={warning}
              block={status === STATUS.pennding}
              autoLoadCodeFromSMS={status === STATUS.idle}
              setWarning={setWarning}
              setValue={setToken}
              submit={submit}
            />

            <Submit
              text='Zapisz'
              block={status === STATUS.pennding}
              isLoading={status === STATUS.pennding}
              action={() => token?.length === 6 ? submit() : setWarning(true)} />
          </>}
        </div>
      </div>
    </Popup>
  )
}

export default EditPhonePopup