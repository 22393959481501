const initialExp = {
  phone: /^[1-9][0-9]{0,2}[-| ]?[0-9]{0,3}[-| ]?[0-9]{0,3}$/,
  mail: /^[a-zA-Z0-9_.-]*[@]{0,1}[a-zA-Z0-9-]*[.]{0,1}[a-zA-Z0-9-.]*$/,
  driving_license: /^[0-9]{0,5}[/]?[0-9]{0,2}[/]?[0-9]{0,5}$/,
  postalCode: /^[0-9]{0,2}[-]?[0-9]{0,3}$/,
  passport: /^[A-z]{0,2}[0-9]{0,7}$/,
  ID_card: /^[A-z]{0,3}[0-9]{0,6}$/,
  nip: /^[1-9][0-9]{0,9}$/,
  pesel: /^[0-9]{0,11}$/
}

const finalExp = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+-=[\]{};'\\:"|,./<>?]).{8,}$/,
  phone: /^[1-9]([0-9]{2})([-| ])?([0-9]{3})([-| ])?([0-9]{3})$/,
  mail: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  driving_license: /^[0-9]{5}[/]?[0-9]{2}[/]?[0-9]{5}$/,
  postalCode: /^[0-9]{2}[-]?[0-9]{3}$/,
  passport: /^[A-z]{2}[0-9]{7}$/,
  ID_Card: /^[A-z]{3}[0-9]{6}$/,
  nip: /^[1-9]([0-9]{9})$/,
  pesel: /^[0-9]{11}$/
}

const CARD_TYPE = {
  drivingLicense: 'driving_license',
  passport: 'passport',
  idCard: 'ID_card',
} 

const isValidPesel = (pesel = '') => {
  if (typeof pesel !== 'string' || pesel.length !== 11) return false

  const controlNumber = Number(pesel[10])
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
  const sum = weight.reduce((r, e, i) => r += (Number(pesel[i]) * e), 0)

  return (10 - (sum % 10)) % 10 === controlNumber
}

const errorMessage = (n = 1) => `brakuje ${n} znak${n === 1 ? 'u' : 'ów'}`

export { initialExp, finalExp, CARD_TYPE, isValidPesel, errorMessage }