import InaccessiblePopup from '../../../components/popups/InaccessiblePopup'
import VerificationPopup from './VerificationPopup'
import ConfirmationPopup from './ConfirmationPopup'
import { fetchLogIn } from '../../Login/loginSlice'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { register } from '../../../rest/urls'
import { POST } from '../../../rest/request'
import WarningPopup from './WarningPopup'
import { useDispatch } from 'react-redux'
import { resetForm } from '../formSlice'
import { useState } from 'react'
import Form from './Form'
import '../style.scss'
import './style.scss'

const ERROR_MESSAGE = 'Nie udało się zarejestrować'
const INIT_POPUP = {
  type: '',
  content: '',
}

const Registration = () => {
  const [popup, setPopup] = useState(INIT_POPUP)
  const [status, setStatus] = useState(STATUS.idle)

  const { goBack, replace } = useHistory()
  const dispatch = useDispatch()

  const hidePopup = () => setPopup(INIT_POPUP)
  const showPopup = (content, type = 'error') => setPopup({ type, content })

  const loginUser = user => {
    if (!user) return
    dispatch(resetForm())
    dispatch(fetchLogIn(user))
      .then(() => window.history ? goBack() : replace('/login'))
  }

  const submit = async body => {
    setStatus(STATUS.pennding)

    try {
      const response = await POST(register, { body, credentials: true })
      if (response) {
        const { email, password } = body
        const { need_phone_otp } = response
        const content = { email, password }

        setStatus(STATUS.succeed)
        if (need_phone_otp) showPopup(content, 'verification')
        else loginUser(content)
      }

    } catch (err) {
      setStatus(STATUS.failed)

      try {
        const paresedError = JSON.parse(err)
        const errors = paresedError.errors

        if (errors && typeof errors === 'object') {
          const errList = Object.entries(errors).reduce((r, [k, v], i) => {
            const value = Array.isArray(v) ? v.reduce((r, e) => r += e, '') : v

            return [
              ...r,
              <li key={i}>{k}: {value}</li>
            ]
          }, [])
          showPopup(errList.length > 0 ? <ul>{errList}</ul> : ERROR_MESSAGE)
        } else throw new Error()

      } catch (errr) {
        showPopup(ERROR_MESSAGE)
      }
    }
  }

  return (
    <div className='registration'>
      <div className='registration__title title'>
        <div>Zarejestruj się</div>
        <div>Dane do logowania</div>
      </div>

      {popup.type && <>
        {popup.type === 'confirmation' ? <ConfirmationPopup
          submit={() => submit(popup.content)}
          status={status}
          close={hidePopup}
        /> : popup.type === 'verification' ? <VerificationPopup
          loginUser={() => loginUser(popup.content)}
          close={hidePopup}
        /> : popup.type === 'warning' ? <WarningPopup
          mail={popup.content}
          close={hidePopup}
        /> : <InaccessiblePopup
          desc={popup.content}
          close={hidePopup}
        />}
      </>}

      <div className='registration__main main center'>
        {status === STATUS.pennding && <Loading middleOfPage />}

        <Form
          block={status === STATUS.pennding}
          showWarning={mail => showPopup(mail, 'warning')}
          submit={(otp, content) => {
            if (otp) showPopup(content, 'confirmation')
            else submit(content)
          }} />
      </div>
    </div>
  )
}

export default Registration