import { removeItemFromBasket, selectBasket, selectBasketItem } from './basketSlice'
import InaccessiblePopup from '../../components/popups/InaccessiblePopup'
import ConfirmationPopup from '../../components/popups/ConfirmationPopup'
import { ReactComponent as Arrow } from '../../assets/arrowTop.svg'
import { useHistory, useLocation } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import { LOCATION_STATES } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import usePopupState from '../../hooks/usePopupState'
import { getRangeString } from '../../utils/date'
import useMagPrice from '../../hooks/useMagPrice'
import AnotherMagPopup from './AnotherMagPopup'
import { forwardRef, useEffect } from 'react'
import BasketSummary from './BasketSummary'
import SummaryHeader from './SummaryHeader'
import BasketNextBtn from './BasketNextBtn'
import { useRef } from 'react'

const nextBtn = <BasketNextBtn />

const BasketDetails = forwardRef(({ extendedAppearance = true }, ref) => {
  const selectedBasketItem = useSelector(selectBasketItem)
  const { list, toPay } = useSelector(selectBasket)

  const { amount, price } = useMagPrice()
  const { t } = useTranslation('basket')
  const dispatch = useDispatch()

  const { push, goBack, replace } = useHistory()
  const { popup, pathname } = usePopupState()
  const { state } = useLocation()

  const mainRef = useRef(null)
  const expanded = popup === 'basket'

  useEffect(() => {
    if (!list || list.length === 0) replace('/map')
  }, [list, replace])

  const expand = e => e ? push(pathname, LOCATION_STATES.popup('basket')) : goBack()

  const onTouch = e => {
    const menu = ref.current
    if (!menu) return

    const initialY = e.touches[0].clientY

    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 20 && !expanded) || (currentY - initialY > 20 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  return <>
    {expanded && <div
      className={`basket__bg${state?.popup ? '' : ' --close'}`}
      onClick={() => expand(!expanded)} />}

    {popup === 'confirmation' && <ConfirmationPopup
      okAction={() => {
        goBack()
        dispatch(removeItemFromBasket({ removeSelected: true }))
      }}
    />}
    {popup === 'increaseMag' && <AnotherMagPopup />}
    {popup === 'warning' && <InaccessiblePopup
      desc={t('warning')} />}

    <div
      ref={ref}
      onTouchStart={onTouch}
      className='details--basket'>

      <p
        className='basket__title center'
        onClick={() => expand(!expanded)}>
        <Arrow className={`arrow --${expanded ? 'down' : 'up'}`} />
        <span>{t('basket')}{amount > 1 ? ` (${amount})` : ''}</span>
      </p>

      {expanded ? <div
        className={`summary__container${state?.popup ? '' : ' --close'}`}
        style={{ maxHeight: `${window.innerHeight - (mainRef.current?.clientHeight || 130) - 70}px` }}>
        <BasketSummary />
      </div>
        : extendedAppearance ? <div
          className='basket__summary--short'
          onClick={() => expand(true)}>
          <SummaryHeader />
        </div>
          : false}

      {extendedAppearance && <div
        ref={mainRef}
        className='basket__main'>
        <div className='basket__details'>
          <div className='details__list group'>
            <div className='list__col'>
              {expanded ? <>
                <div className='basket__decs'>
                  <div>{t('numberOfMags')}</div>
                  <div>{amount}</div>
                </div>

                <div className='basket__decs'>
                  <div>{t('totalPayable')}</div>
                  <div className='--big'>{price} zł</div>
                </div>
              </> : (
                ({ identifier, amount, dailyPrice, monthlyPrice, rangeDate: { m, d } = {} }) => <>
                  <div className='basket__decs'>
                    <div>{t('rentalPeriod')}:</div>
                    <div>{getRangeString({ m, d }, true)}{m === 0 ? ` x ${dailyPrice} zł` : ''}</div>
                  </div>

                  <div className='basket__decs'>
                    <div>{t(m === 0 || (m === 1 && d === 0) ? 'rentalPrice' : 'priceForMonth')}</div>
                    <div className='--big'>{toPay[identifier]?.priceSum || amount * Number(m === 0 ? dailyPrice * d : monthlyPrice)} zł</div>
                  </div>
                </>
              )(selectedBasketItem)}
            </div>

            <div className='details__col'>
              <button className='button --coupon'>
                <span>{t('getDiscount')}</span>
              </button>
              {nextBtn}
            </div>
          </div>
        </div>
      </div>}
    </div>
  </>
})

export default BasketDetails