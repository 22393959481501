const KEY_NAME = 'APP_KEY'

const loadState = (session = false) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const state = storage.getItem(KEY_NAME)
    return state ? JSON.parse(state) : ''

  } catch (err) {
    console.log('loadState', err)
    return ''
  }
}

export const loadWholeState = () => {
  const key = loadState(true)
  if (key) return key
  else return loadState()
}

export const saveState = (state, session = false) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const stringState = JSON.stringify(state)
    storage.setItem(KEY_NAME, stringState)

  } catch (err) {
    console.log('saveState', err)
  }
}