import { ReactComponent as Mag } from '../../assets/magLogo.svg'
import { useHistory } from 'react-router-dom'

const WarehouseComercial = () => {
  const { push } = useHistory()

  return (
    <div
      className='warehouse__comercial center'
      onClick={() => push('/categories')}>
      <div className='comercial__title --termina'>Wynajmij nowy</div>
      <Mag />
    </div>
  )
}

export default WarehouseComercial