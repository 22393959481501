import { ReactComponent as User } from '../../../assets/account.svg'
import useTranslation from '../../../hooks/useTranslation'
import PaymentHistory from './PaymentHistory'
import { useParams } from 'react-router-dom'
import PaymentCard from './PaymentCard'

const Payments = ({ popup = false }) => {

  const { t } = useTranslation('account')
  const { action } = useParams()

  const title = <div className='title'>
    <div>{t('payments')}</div>
    <div>{t('subTitle')}</div>
  </div>

  return (
    <div className='account__safety account__popup'>
      {popup ? title : <div className='account__header'>
        <div className='account__icon'>
          <User />
        </div>
        {title}
      </div>}

      {!action && <PaymentCard />}
      <PaymentHistory full={!!action} />
    </div>
  )
}

export default Payments