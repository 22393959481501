import { useState } from 'react'

const data = [{
  question: 'Co to znaczy MAG?',
  answer: 'magazyn',
}, {
  question: 'Do czego służy aplikacja?',
  answer: 'Do wynajmowania oraz zarządzania MAGami',
}, {
  question: 'Czy mogę zrezygnować z wynajmu przed upływem wyznaczonego terminu?',
  answer: 'Tak, w każdej chwili możesz zrezygnować z wynajmu',
}]

const FaqItem = ({ question, answer }) => {
  const [selected, select] = useState(false)

  return (
    <div
      className='list__item'
      onClick={() => select(!selected)}>

      <div className='group'>
        <p>{question}</p>
        <div className={`item__arrow --${selected ? 'down' : 'up'}`}></div>
      </div>

      {selected && <div><i>{answer}</i></div>}
    </div>
  )
}

const Faq = () => {
  return (
    <div className='help__faq'>
      <h4>FAQ</h4>
      <div className='faq__list'>
        {data.map((e, i) => <FaqItem {...e} key={i} />)}
      </div>
    </div>
  )
}

export default Faq