import { STATUS } from '../../utils/constants'
import './style.scss'

const BigCircleBtn = ({ interior, text = '', status = STATUS.idle, action }) => {
  return (
    <div
      className='button__circle --big --termina'
      onClick={action}>
      {status === STATUS.pennding && <div className='button__ring --rotated' />}
      {status === STATUS.succeed && <div className='button__ring' />}
      {interior ? interior : <span>{text}</span>}
    </div>
  )
}

export default BigCircleBtn