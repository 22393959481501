import useAuthorizedReduxFetch from '../../../hooks/useAuthorizedReduxFetch'
import { selectBasket, updateCompany } from '../../Basket/basketSlice'
import { getCompaniesFields, selectFieldsStatus } from '../formSlice'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useTranslation from '../../../hooks/useTranslation'
import { selectIsLoggedIn } from '../../Login/loginSlice'
import { companies, userInfo } from '../../../rest/urls'
import Checkbox from '../../../components/icons/Checkbox'
import Submit from '../../../components/inputs/Submit'
import { useSelector, useDispatch } from 'react-redux'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { GET } from '../../../rest/request'
import Companies from './Companies'
import NewMagBtn from './NewBtn'
import Receipt from './Receipt'
import '../style.scss'
import './style.scss'

const Billing = () => {
  const { list } = useSelector(selectBasket)
  const isLogin = useSelector(selectIsLoggedIn)
  const fieldsStatus = useSelector(selectFieldsStatus)

  const [invoice, setInvoice] = useState(null)
  const [data, setData] = useState({
    company: [],
    user: {},
  })

  const { push, replace } = useHistory()
  const { run } = useAuthorizedReduxFetch()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const menuRef = useRef(null)

  useLayoutEffect(() => {
    if (list?.length === 0) replace('/map')
  }, [list?.length, replace])

  useEffect(() => {
    if (isLogin && fieldsStatus !== STATUS.succeed) run(getCompaniesFields())
  }, [fieldsStatus, isLogin, run])

  useEffect(() => {
    if (isLogin && fieldsStatus === STATUS.succeed) {
      getData().then(() => getData(true))
    }
  }, [fieldsStatus, isLogin])

  const getData = async (user = false) => {
    try {
      const response = await GET(user ? userInfo : companies)
      if (response.data) {
        setData(d => {
          return {
            ...d,
            [user ? 'user' : 'company']: response.data
          }
        })
      }

    } catch (err) {
      console.warn('Fetch: failed')
    }
  }

  return (
    <div className='billing'>
      <div className='billing__title title'>
        <div>{t('form.dataCompletion')}</div>
        <div>{t('form.contractData')}</div>
      </div>

      <div
        ref={menuRef}
        className='billing__main main center'>
        <div className='billing__menu'>
          <div
            className={`menu__item group ${invoice ? '--selected' : ''}`}
            onClick={() => setInvoice(true)}>
            <div className='item__desc --termina'>
              <div>Faktura na</div>
              <div>firmę</div>
            </div>

            <Checkbox selected={invoice} />
          </div>

          <div
            className={`menu__item group ${invoice === false ? '--selected' : ''}`}
            onClick={() => setInvoice(false)}>
            <div className='item__desc --termina'>
              <div>Faktura na</div>
              <div>osobę prywatną</div>
            </div>

            <Checkbox selected={invoice === false} />
          </div>
        </div>

        {invoice === null ? <div className='billing__info'>
          <div className='info__title'>
            <div>{t('basic.justMoment')}</div>
            <div>{t('basic.doYouNeedMoreSpace')}</div>
          </div>
          <NewMagBtn />
        </div>
          : invoice ? <Companies
            menuRef={menuRef}
            data={data?.company}
            getData={getData} />
            : <>
              <Receipt data={data.user} />
              <div className='recipt__button'>
                <Submit
                  text={t('basic.choose')}
                  action={() => {
                    dispatch(updateCompany(false))
                    push('/summary')
                  }} />
              </div>
            </>}
      </div>
    </div>
  )
}

export default Billing