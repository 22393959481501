import { selectCalendar } from '../Calendar/calendarSlice'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import useTranslation from '../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const BasketNextBtn = () => {
  const { availableAttributes = [] } = useSelector(selectCalendar)
  const { t } = useTranslation('basket')
  const { push } = useHistory()

  const action = () => {
    const next = getNextPath(availableAttributes)
    if (next) push(next)
  }

  return (
    <ArrowBtn
      text={t('next')}
      action={action} />
  )
}

const getNextPath = (attributes = []) => {
  const attr = attributes.reduce((r, { isInsurance }) => {
    r[isInsurance ? 'withInsurance' : 'withoutInsurance'] += 1
    return r
  }, { withInsurance: 0, withoutInsurance: 0 })
  const { pathname } = window.location
  const paths = pathname.split('/')

  switch (paths[1] || '') {
    case 'calendar':
      return attr.withoutInsurance ? '/additional/option'
        : attr.withInsurance ? '/additional/insurance'
          : '/login'

    case 'additional':
      return pathname.includes('option') && attr.withInsurance ? '/additional/insurance' : '/login'
    default: return ''
  }
}

export default BasketNextBtn