import { ReactComponent as Logo } from '../../assets/logo.svg'
import useTranslation from '../../hooks/useTranslation'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import phone from '../NewWarehouse/phone.png'

const End = ({ next }) => {
  const { t } = useTranslation('slides')

  const desc = t('manageMagDescArray')

  return (
    <>
      <Logo />
      <div className='slide__title center'>
        <div className='--termina'>{t('ready')}</div>
        <div>{t('manageMagInApp')}</div>
      </div>

      <div className='slide__warehouses'>
        <div className='warehouse --left'>
          <Warehouse />
        </div>
        <div className='warehouse --right'>
          <Warehouse />
        </div>
        <img className='img--phone' alt='phones' src={phone} />
      </div>

      <div className='slide__desc'>
        {Array.isArray(desc) ? desc.map((d, i) => <div key={i}>{d}</div>)
          : desc}
      </div>

      <Submit text={t('goToPanel')} action={next} />
    </>
  )
}

export default End