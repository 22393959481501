import { fetchToken, selectExpirationDate, selectIsLoggedIn } from './loginSlice'
import { useDispatch, useSelector } from 'react-redux'
import { resetAccount } from '../Account/accountSlice'
import { useEffect } from 'react'

const RefreshController = () => {
  const isLogIn = useSelector(selectIsLoggedIn)
  const expirationDate = useSelector(selectExpirationDate)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLogIn && expirationDate > 0) {
      let interval = setInterval(() => dispatch(fetchToken()), expirationDate)
      return () => clearInterval(interval)
    }
  }, [dispatch, expirationDate, isLogIn])

  useEffect(() => {
    if (isLogIn) {
      return () => dispatch(resetAccount())
    }
  }, [dispatch, isLogIn])

  return null
}

export default RefreshController