import { ReactComponent as Unlock } from '../../assets/unlock.svg'
import { ReactComponent as Pin } from '../../assets/pin.svg'
import { useState } from 'react'

const PinInput = ({ value, warning = false }) => {
  const [secured, setSecure] = useState(true)

  const rightIcon = <Unlock onClick={() => setSecure(!secured)} />

  return <div
    className={`input --bigger${warning ? ' --warning' : ''}`}>

    <Pin />
    <div className={`input__main${!value ? ' --secondary' : ''}`}>
      <span className={value ? '--focus': ''}>{secured ? value.replace(/[0-9]/g, '•') : value}</span>
      {!value && 'pin: 1234'}
    </div>
    {rightIcon}
  </div>
}

export default PinInput