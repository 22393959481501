import './style.scss'

const Switch = ({ name, disabled = false, checked = false, changeHandler }) => {
  return (
    <div className='switch group'>
      <input
        id={`switch_${name}`}
        name={name}
        type='checkbox'
        checked={checked}
        disabled={disabled}
        onChange={changeHandler} />
      <label htmlFor={`switch_${name}`} />
    </div>
  )
}

export default Switch