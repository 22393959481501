const Submit = ({ text, block = false, isLoading = false, action }) => {
  return (
    <div className={`input__submit center ${isLoading ? ' --loading' : ''}${block ? ' --disabled' : ''}`}>
      {isLoading && <div className='input__spinner' />}
      <input
        type='submit'
        className='submit --termina'
        value={text}
        disabled={block}
        onClick={action} />
    </div>
  )
}

export default Submit