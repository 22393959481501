import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getLocalDateString } from '../../utils/date'
import { POST } from '../../rest/request'
import { cart } from '../../rest/urls'
import { v4 } from 'uuid'

const basketItem = {
  start: '',
  hash: '',
  end: '',
  name: '',
  amount: 1,
  placeId: '',
  address: '',
  dailyPrice: '',
  monthlyPrice: '',
  hasOnlineAccess: false,
  rangeDate: { m: 0, d: 0 },
  promotions: [],
  termiateDays: 0,
  attr: [],
  size: '',
}

const THREE_MINUTES = 1000 * 60 * 3

export const sendBasket = createAsyncThunk('basket/send', async (_, thunkAPI) => {
  const store = thunkAPI.getState()
  const { list, invoice, time } = store.basket
  const body = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    companyId: invoice?.id || '',
    time: time + THREE_MINUTES,
    list: list.map(({ identifier, notify, hash, name, placeId, start, end, address, dailyPrice, monthlyPrice, attr }) => {
      const item = {
        identifier,
        hash,
        name,
        placeId,
        attr: attr.map(id => ({ id })),
        parameters: {
          startDate: getLocalDateString(start),
          endDate: end ? getLocalDateString(end) : undefined,
          monthlyPrice,
          dailyPrice,
          address
        },
      }
      if (notify) item.notify = notify
      return item
    }),
  }
  return await POST(cart, { body })
})

export const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    invoice: false,
    unlock: false,
    backup: false,
    time: 0,
    selected: 0,
    lastSentTime: 0,
    errors: false,
    list: [],
    toPay: {},
    purchased: []
  },
  reducers: {
    addItemToBasket: (state, action) => {
      const { payload } = action
      const { list } = state

      if (typeof payload === 'object') {
        const newSelected = list.length
        state.list.push({ identifier: v4(), ...basketItem, ...payload })
        state.selected = newSelected
      }
    },
    removeItemFromBasket: (state, action) => {
      const { removeSelected, removeIdentifiers } = action.payload || {}
      const { selected, list, invoice } = state

      const newList = Array.isArray(removeIdentifiers) || removeSelected ? list
        .filter(({ identifier }, i) => removeIdentifiers ? !identifier.includes(removeIdentifiers) : i !== selected) : []
      state.backup = { list, invoice }
      if (newList.length === 0) {
        state.toPay = {}
        state.time = 0
        state.selected = 0
        state.lastSentTime = 0
        state.invoice = false
        state.errors = false

      } else state.selected = newList.length - 1
      state.list = newList
      state.unlock = false
    },
    changeSelectedItem: (state, action) => {
      const { list } = state
      const { payload } = action

      if (payload >= 0 && list.length > payload) state.selected = payload
    },
    updateBasketList: (state, action) => {
      const { payload } = action
      const { list } = state

      if (typeof payload === 'object') {
        state.list = list.map(e => payload[e.identifier] ? { ...e, ...payload[e.identifier] } : e)
      }
    },
    updateBasketItem: (state, action) => {
      const { selected, list } = state
      const { payload } = action

      if (payload && list[selected]) {
        state.list[selected] = { ...list[selected], ...payload }
      }
    },
    completePurchase: (state) => {
      const { list } = state

      state.time = 0
      state.selected = 0
      state.lastSentTime = 0
      state.invoice = false
      state.purchased = list.map(({ address, hasOnlineAccess }, i) => {
        const number = Math.ceil(Math.random() * 10) + (i * 10)
        return { address, hasOnlineAccess, number }
      })
      state.list = []
    },
    clearPurchase: (state) => { state.purchased = [] },
    extendFormToMap: (state, action) => { state.unlock = action.payload },
    updateCompany: (state, action) => { state.invoice = action.payload },
    resetTime: (state) => { state.time = Date.now() },
    restoreBackup: (state, action) => {
      const { payload } = action
      const { backup } = state

      if (payload && backup) {
        const { list = [], invoice } = backup || {}

        state.list = list
        state.time = Date.now()
        state.invoice = invoice
        state.selected = list.length - 1
      }
      state.backup = false
      state.lastSentTime = 0
    },
  },
  extraReducers: {
    [sendBasket.fulfilled]: (state, action) => {
      const { positions: positionsObj } = action.payload
      const { list } = state

      const positionsList = Object.entries(positionsObj)
      const newList = positionsList.reduce((r, [k, v], idx) => {
        if (v.result === 'ok') {
          r.toPay = {
            ...r.toPay,
            [k]: {
              list: v?.to_pay?.list || [],
              priceSum: v?.to_pay?.price_sum || 0
            }
          }

        } else {
          const item = list.find(({ identifier }) => identifier === k)
          if (item) r.errors.push({ ...item, result: v.result, errors: v.errors })
        }
        return r
      }, { toPay: {}, errors: [] })

      if (positionsList.length !== list.length) {
        state.list = list.filter(({ identifier }) => !!positionsObj[identifier])
      }

      state.toPay = newList.toPay
      state.errors = newList.errors.length > 0 ? newList.errors : false
      state.lastSentTime = state.time + THREE_MINUTES - 500
    },
  }
})

export const { addItemToBasket, removeItemFromBasket, changeSelectedItem, updateBasketList, updateBasketItem, clearPurchase, completePurchase, extendFormToMap, updateCompany, resetTime, restoreBackup } = basketSlice.actions

export const selectBasket = state => state.basket
export const selectBasketItem = (state, idx) => {
  const { selected, list } = state.basket
  const index = idx !== undefined ? idx : selected
  return list[index] || {}
}

export default basketSlice.reducer
