import { UNAUTHORIZED_TYPES } from '../utils/constants'
import { useHistory } from 'react-router-dom'

const useAuthorizedFetch = () => {

  const { push } = useHistory()

  const showPopup = (popup, error) => push(window.location.pathname, { ...error, popup })
  const run = async (request = new Promise(resolve => resolve()), successCallback = r => console.log(r), errorCallback = e => console.error(e)) => {
    try {
      const response = await request
      successCallback(response)

    } catch (err) {
      try {
        const error = JSON.parse(err)
        const { type, message } = error
        const { state } = window.history

        if (type && (type === UNAUTHORIZED_TYPES.pin
          || type === UNAUTHORIZED_TYPES.mail
          || type === UNAUTHORIZED_TYPES.phone
          || type === UNAUTHORIZED_TYPES.device
          || type === UNAUTHORIZED_TYPES.webAuth)) {
          if (state?.state?.type !== type) showPopup(type, error)

        } else if (message) showPopup('formError', error)
        else throw new Error(err)

      } catch (errr) {
        errorCallback(errr)
      }

    }
  }

  return { run }
}

export default useAuthorizedFetch