import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { useCallback, useEffect, useState, useRef } from 'react'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import { STATUS } from '../../../utils/constants'
import { verifyPhone } from '../../../rest/urls'
import { POST } from '../../../rest/request'
import { useSelector } from 'react-redux'
import { selectForm } from '../formSlice'

const INIT_CODE = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }
const CODE_LENGTH = 6

const VerificationPopup = ({ loginUser, close }) => {
  const { phone = '' } = useSelector(selectForm)

  const [code, setCode] = useState(INIT_CODE)
  const [warning, setWarning] = useState(false)
  const [status, setStatus] = useState(STATUS.idle)

  const refs = []
  const autoCodeLoaderRef = useRef(null)

  const codeToString = Object.entries(code).reduce((r, [, v]) => r += v, '')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refs[0] && refs[0]?.focus(), [])

  const validateCode = useCallback(async () => {
    setStatus(STATUS.pennding)
    if (autoCodeLoaderRef.current) {
      autoCodeLoaderRef.current?.abort()
      autoCodeLoaderRef.current = null
    }

    try {
      const body = { phone, token: codeToString }
      const response = await POST(verifyPhone, { body })

      if (response) {
        setStatus(STATUS.succeed)
        loginUser()
      }

    } catch (err) {
      setStatus(STATUS.failed)
    }
  }, [codeToString, loginUser, phone])

  useEffect(() => {
    if ('OTPCredential' in window) {
      const loadCodeFromSMS = () => {
        console.log('DOMContentLoaded event')
        const ac = new AbortController()

        autoCodeLoaderRef.current = ac
        navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: ac.signal
        }).then(otp => {
          alert(otp.code)
          if (otp.code) {
            const codeList = otp.code.split('')
            setCode({ ...codeList })
            validateCode()
          }
        }).catch(err => {
          console.warn(err)
        })
      }

      window.addEventListener('DOMContentLoaded', loadCodeFromSMS)
      return () => window.removeEventListener('DOMContentLoaded', loadCodeFromSMS)
    }
  }, [validateCode])

  const phoneWithSpaces = (useCallback(() =>
    phone.replace(/^([\d]{3})([\d]{3})([\d]{3})$/, '$1 $2 $3'), [phone])
  )()

  const getSubmitText = len => len === CODE_LENGTH ? 'Autoryzuj'
    : `Brakuje ${CODE_LENGTH - len} znak${len === CODE_LENGTH - 1 ? 'u' : 'ów'}`

  const moveFocus = (ref, forward = true) => {
    const step = forward ? 1 : -1
    const index = refs.findIndex(r => Object.is(r, ref))
    const key = index > -1 ? index + step : undefined

    if (key !== undefined && refs[key]) {
      const len = code[key].length
      refs[key].setSelectionRange(0, len)
      refs[key].focus()
    }
  }

  return (
    <Popup close={close}>
      <div className='popup__verification'>
        <Logo />

        <div className='verefication__phone'>
          <h2>{phoneWithSpaces}</h2>
        </div>

        <div className={`verification__code group${warning ? ' --warning' : ''}`}>
          {Object.entries(code).map(([k, v]) => <input
            required
            key={k}
            value={v}
            type='tel'
            ref={r => refs.push(r)}
            disabled={status === STATUS.pennding}
            onKeyUp={e => {
              if (e.key === 'Backspace' && !v) {
                moveFocus(e.target, false)
              }
            }}
            onChange={e => {
              const { value } = e.target
              const firstChar = value.charAt(0)

              if (value.length === CODE_LENGTH && value.match(/^[0-9]{6}$/)) {
                const list = value.split('')
                setCode({ ...list })
              }
              else if (!firstChar || firstChar.match(/^[0-9]{1}$/)) {
                setCode({
                  ...code,
                  [k]: firstChar
                })
                moveFocus(e.target, !!firstChar)
              }
            }}
          />)}
        </div>

        <div
          className='verefication__button'
          onClick={() => {
            if (status === STATUS.pennding) return
            setCode(INIT_CODE)
            setWarning(false)
          }}>
          Wyślij ponownie
        </div>

        <Submit
          text={getSubmitText(codeToString.length)}
          block={codeToString.length !== CODE_LENGTH || status === STATUS.pennding}
          action={validateCode}
        />
      </div>
    </Popup>
  )
}

export default VerificationPopup