import { selectIsLoggedIn } from '../../conteiners/Login/loginSlice'
import HeadersManager from '../../rest/HeadersManager'
import { useEffect, useState, useRef } from 'react'
import { getUuid } from '../../utils/uuid'
import { useSelector } from 'react-redux'
import { api } from '../../rest/urls'

const AUTH_URL = `${api}wss/auth`
const WSS_URL = process.env.REACT_APP_WSS || 'wss://api.mmapp.pl/wss'
const uuid = getUuid()

const useWssConnection = () => {
  const isLogIn = useSelector(selectIsLoggedIn)
  const [messageData, setMessageData] = useState({})
  const wssConnection = useRef(null)

  const sendMessage = obj => wssConnection.current && wssConnection.current.send(JSON.stringify(obj))

  const parseWssResponse = data => {
    try {
      const parsed = JSON.parse(data)
      const { event, payload } = parsed

      if (event === 'connectionID' && payload.connectionID) {
        HeadersManager.connectionId.set(payload.connectionID)
      }
      setMessageData(parsed)

    } catch (err) {
      console.error('Parser error')
    }
  }

  useEffect(() => {
    let timeout
    const connect = async () => {
      const db = process.env.REACT_APP_DB_HEADER
      const token = HeadersManager.jwt.get()
      const headers = new Headers({
        'Accept': 'application/json',
        'X-Client': 'webclient',
        'X-Client-Hash': uuid,
      })

      if (token) headers.set('Authorization', `Bearer ${token}`)
      if (db) headers.set('X-Database', db)

      const init = {
        method: 'POST',
        headers,
        credentials: 'include',
      }

      try {
        const request = await fetch(AUTH_URL, init)
        const response = request.ok ? await request.json() : {}
        const { result = '', token } = response || {}


        if (result === 'ok' && token) {
          const wss = new WebSocket(WSS_URL)
          wssConnection.current = wss

          wss.onopen = () => {
            HeadersManager.connectionId.destroy()
            sendMessage({ command: 'connectionID' })
            sendMessage({ command: 'token', token })
          }

          wss.onclose = () => {
            HeadersManager.connectionId.destroy()
            timeout = setTimeout(connect, 5000)
          }

          wss.onmessage = e => parseWssResponse(e.data)

        } else throw Error('wrong result')

      } catch (err) {
        console.error(`Error: ${err}`)
      }
    }

    connect()
    return () => timeout && clearTimeout(timeout)
  }, [isLogIn])

  return {
    messageData,
    sendMessage
  }
}

export default useWssConnection
