import { ReactComponent as Frame } from '../../assets/logoFrame.svg'
import { ReactComponent as Mag } from '../../assets/magLogo.svg'
import useTranslation from '../../hooks/useTranslation'
import './style.scss'

const MagNumber = ({ number }) => {
  const { t } = useTranslation('basic')

  return (
    <div className='mag__number'>
      <div className='mag__icon'>
        <Mag />
      </div>

      <div className='mag__desc'>
        <Frame />
        <div className='center'>
          <div>{t('numberShort')}</div>
          <div className='--termina'>{number}</div>
        </div>
      </div>
    </div>
  )
}

export default MagNumber