import { fetchMapLocations, resetMapStatus, selectMapStatus, selectPlaces, updateMapOption } from '../Maps/Map/mapSlice'
import { addToSearchList, removeFromSearchList, selectSearch, updateProvinces } from './mapSearchSlice'
import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { selectFilters, updateLocation } from '../Maps/menu/filtersSlice'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../components/Loading'
import { useHistory } from 'react-router-dom'
import { STATUS } from '../../utils/constants'
import PlaceItem from './PlaceItem'
import Provinces from './Provinces'
import Search from './Search'
import Places from './Places'
import './style.scss'

const MapSearch = () => {
  const mapStatus = useSelector(selectMapStatus)
  const lastSearch = useSelector(selectSearch)
  const filters = useSelector(selectFilters)
  const places = useSelector(selectPlaces)

  const [height, setHeight] = useState(window.innerHeight - 72)
  const [fullScreen, setFullScreen] = useState(false)
  const [search, setSearch] = useState('')

  const { go, replace, push, length } = useHistory()
  const dispatch = useDispatch()

  const searchRef = useRef(null)


  useEffect(() => {
    if (mapStatus === STATUS.idle) dispatch(fetchMapLocations({ body: { filters } }))
    else if (mapStatus === STATUS.succeed) dispatch(updateProvinces(places))
  }, [dispatch, filters, mapStatus, places])

  useEffect(() => {
    const resize = () => setHeight(window.innerHeight - searchRef?.current?.clientHeight || 72)

    window.addEventListener('resize', resize, false)
    return () => window.removeEventListener('resize', resize, false)
  }, [])

  const back = useCallback((back = -1, id) => {
    (length < 3 || id) ? replace(`/map`) : go(back)
    if (id) push(`/map/${id}`)
  }, [go, length, push, replace])

  const resetMap = useCallback(() => dispatch(resetMapStatus()), [dispatch])

  const selectPosition = (data, addToList = true) => {
    const { id = '', name, city, street, estateNumber, province, value, gpslatitude, gpslongitude } = data
    const { list, go = -1, ...restData } = data
    const isVoivodship = name && !street
    const mapOption = isVoivodship ? {
      mapPosition: [gpslatitude, gpslongitude],
      showMarker: false,
      voivodship: value
    } : {
      position: [gpslatitude, gpslongitude],
      showMarker: !street
    }

    if (addToList) dispatch(addToSearchList(restData))
    resetMap()
    dispatch(updateLocation(isVoivodship ? name : `${street ? `${street} ${estateNumber}` : city}, ${province}`))
    dispatch(updateMapOption(mapOption))
    back(go, id)
  }

  return (
    <div className='mapSearch'>
      <div
        className='search__main'
        style={{ height: `${height}px` }}>

        {search ? <Places
          search={search}
          selectPosition={selectPosition}
        /> : <>
          {mapStatus === STATUS.failed ? <section><h1>Nie udało się pobrać danych</h1></section>
            : mapStatus === STATUS.succeed ? <Provinces
              fullScreen={fullScreen}
              anotherSection={lastSearch.length > 0}
              setFullScreen={setFullScreen}
              selectPosition={selectPosition}
            /> : <Loading />}

          {lastSearch.length > 0 && !fullScreen && <section className='--auto'>
            <h1>Ostatnie</h1>
            <div className='search__list'>
              {lastSearch.map((e, idx) => {
                const crossAction = () => dispatch(removeFromSearchList(idx))

                return typeof e === 'string' ? <div
                  key={idx}
                  className='list__item group'>
                  <span>{e}</span>
                  <div
                    className='cross'
                    onClick={crossAction} />
                </div> : <PlaceItem
                  cross
                  key={idx}
                  data={e}
                  crossAction={crossAction}
                  selectPosition={d => selectPosition(d, false)} />
              })}
            </div>
          </section>}
        </>}
      </div>

      <div ref={searchRef}>
        {useMemo(() => <Search
          back={back}
          search={search}
          resetMap={resetMap}
          setSearch={setSearch}
        />, [back, resetMap, search])}
      </div>
    </div>
  )
}

export default MapSearch