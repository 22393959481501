import Keyboard from '../../components/keyboard/Numeric'
import useTranslation from '../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import PinInput from './PinInput'

const Login = () => {
  const [warning, setWarning] = useState(false)
  const [password, setPassword] = useState('')

  const { goBack, replace, location: { state } } = useHistory()
  const { t } = useTranslation('form')

  useEffect(() => {
    if (warning) {
      const timeout = setTimeout(() => setWarning(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [warning])

  const submit = () => {
    if (password === '1234') {
      goBack()
      replace('/entrance', state)

    } else setWarning(t(password.length < 4 ? 'notEnoughChar' : 'wrongPIN'))
  }

  return (
    <div className='entrance warehouse'>
      <div className='login__main center'>
        <div>
          <div className='title'>
            <div>{t('authorization')}</div>
          </div>
          <PinInput
            value={password}
            warning={warning} />
        </div>
      </div>

      <div className='entrance__menu menu__main'>
        <Keyboard
          showBackspace={!!password}
          click={v => {
            switch (v) {
              case 'ok':
                submit()
                break
              case -1:
                password ? setPassword(p => p.slice(0, password.length - 1)) : goBack()
                break
              default: setPassword(p => `${p}${v}`)
            }
          }} />
      </div>
    </div>
  )
}

export default Login