import BigCircleBtn from '../../components/buttons/BigCircleBtn'
import CallContext from '../../context/call/CallContext'
import { STATUS } from '../../utils/constants'
import React, { useContext } from 'react'

const CallBtn = () => {
  const { isBlocked, status, makeCall, cancelCall, endCall } = useContext(CallContext)

  const { action, interior } = ((s) => {
    if (isBlocked) return {
      action: undefined,
      interior: 'Zadzwoń'
    }

    switch (s) {
      case STATUS.succeed: return {
        action: endCall,
        interior: 'Zakończ'
      }
      case STATUS.pennding: return {
        action: cancelCall,
        interior: 'Anuluj'
      }
      default: return {
        action: makeCall,
        interior: 'Zadzwoń'
      }
    }
  })(status)

  return (
    <div style={isBlocked ? { opacity: '0.4' } : {}}>
      <BigCircleBtn
        status={status}
        action={action}
        interior={interior}
      />
    </div>
  )
}

export default CallBtn