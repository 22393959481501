export const DAY = 86400000
export const YEAR = 31536000000

export const MONTHS = {
  long: ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'],
  short: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paz', 'lis', 'gru'],
}

const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate()

const getDate = dateVal => {
  const date = typeof dateVal === 'object' ? dateVal : new Date(dateVal)

  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  }
}

export const getDateString = date => {
  const { day, month, year } = getDate(date)
  return `${day} ${MONTHS.long[month]} ${year}`
}

export const getLocalDateString = date => {
  const d = typeof date === 'object' ? date : new Date(date)
  return d.toLocaleDateString()
}

export const getRangeString = ({ m = 0, d = 0 }, short = false) => {
  if (m < 0) return 'nieokreślony'
  const getDays = () => `${d} ${d === 1 ? 'dzień' : 'dni'}`
  const getMonths = () => `${m} ${short ? 'm-c' : 'miesiąc'}${m > 1 && m < 5 ? 'e' : m > 4 ? 'y' : ''}`
  return !m ? getDays() : `${getMonths()}${d !== 0 ? ` i ${getDays()}` : ''}`
}

export const getStartDate = time => new Date(time || Date.now())

export const getEndDate = ({ m = 0, d = 0 }, startDate = new Date()) => {
  if (m < 0) return undefined

  const start = getDate(startDate)
  const daysInMonth = getDaysInMonth(start.year, start.month)

  const days = start.day + d
  const months = start.month + m + (days / daysInMonth | 0)

  const end = {
    day: days % daysInMonth,
    month: months % 12,
    year: start.year + (months / 12 | 0),
  }

  return new Date(end.year, end.month, end.day)
}

export const getRangeFromDates = (endDate = new Date(), startDate = new Date()) => {
  const start = getDate(startDate)
  const end = getDate(endDate)

  const monthDiff = (12 * (end.year - start.year)) + end.month - start.month
  const daysDiff = end.day - start.day

  if (daysDiff < 0) {
    const daysInLastMonth = !end.month ? 31 : getDaysInMonth(end.year, end.month - 1)
    const d = daysInLastMonth + daysDiff

    return { m: monthDiff - 1, d }

  } else return { m: monthDiff, d: daysDiff }
}