import { ReactComponent as Trash } from '../../../assets/trash.svg'
import { devices, removeDevice } from '../../../rest/urls'
import useTranslation from '../../../hooks/useTranslation'
import { useEffect, useRef, useState } from 'react'
import Popup from '../../../components/popups/Popup'
import { DELETE, GET } from '../../../rest/request'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import Error from '../../../components/Error'
import Item from '../Item'

const DevicePopup = () => {

  const [status, setStatus] = useState(STATUS.idle)
  const [data, setData] = useState(null)

  const { t } = useTranslation('account')
  const lockFetch = useRef(false)


  useEffect(() => {
    const getData = async () => {
      try {
        const response = await GET(devices)
        const { data } = response
        if (data) {
          setData(data)
          setStatus(STATUS.succeed)
        }

      } catch (error) {
        setStatus(STATUS.failed)
      }
    }

    if (status === STATUS.idle) getData()
  }, [status])

  const removeItem = async idx => {
    if (lockFetch.current) return

    const { id } = data[idx]
    lockFetch.current = true

    if (id) {
      try {
        await DELETE(`${removeDevice}${id}`)
        setData(d => d.filter((_, i) => i !== idx))
        lockFetch.current = false

      } catch (error) {
        lockFetch.current = false
      }
    }
  }

  return (
    <Popup>
      <div className='popup__device'>
        <div className='popup__title --sticky'>
          <div>{t('authorizedDevices')}</div>
        </div>

        <div className='device__main account__list'>
          {status === STATUS.succeed ? data.map(({ id, name, lastAccess }, idx) => <Item
            key={id}
            title={name}
            desc={`${t('lastAccess')}: ${lastAccess}`}
            right={<div
              className='item__icon--right'
              onClick={() => removeItem(idx)}>
              <Trash />
            </div>}
          />
          ) : status === STATUS.failed ? <Error staticPos />
            : <Loading />}
        </div>
      </div>
    </Popup>
  )
}

export default DevicePopup