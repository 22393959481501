import useTranslation from '../../hooks/useTranslation'

const DateInput = ({ updateFilter, start = false, ...data }) => {
  const { t } = useTranslation('filters')

  return (
    <div className='center'>
      <span>{t(start ? 'from' : 'to')}</span>
      <input
        {...data}
        type='date'
        onChange={e => updateFilter('date', e.target.value || '', start ? 'start' : 'end')} />
    </div>
  )
}

export default DateInput