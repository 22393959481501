import { ReactComponent as Terminate } from '../../assets/terminateRent.svg'
import { ReactComponent as Insurance } from '../../assets/insurance.svg'
import { ReactComponent as Payments } from '../../assets/payments.svg'
import { ReactComponent as Mag } from '../../assets/magDetails.svg'
import { ReactComponent as Alert } from '../../assets/alert.svg'
import { ReactComponent as Share } from '../../assets/share.svg'
import { selectWarehouse } from '../Warehouses/warehousesSlice'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SECTION_TYPES } from '.'

const ManageButton = ({ action, text, Icon }) => <div
  className='menu__button center'
  onClick={action}>
  <Icon />
  <span>{text}</span>
</div>

const MagManage = ({ goTo }) => {

  const { push } = useHistory()
  const { number } = useParams()

  const { mag: { hasOnlineAccess = true } } = useSelector(state => selectWarehouse(state, number))

  return (
    <div className='menu__section menu__manage'>
      <p>Zarządzaj magazynem</p>

      <div className='menu__buttons'>
        <div className='buttons__row center'>
          <ManageButton
            Icon={Mag}
            text='Szczegóły wynajmu'
            action={() => goTo({ path: SECTION_TYPES.details })} />

          <hr />

          <ManageButton
            Icon={Payments}
            text='Historia płatności'
            action={() => goTo({ path: SECTION_TYPES.payments })} />

          <hr />

          <ManageButton
            Icon={Insurance}
            text='Ubezpieczenie magazynu' />
        </div>

        <hr />

        <div className='buttons__row center'>
          <ManageButton
            Icon={Share}
            text='Udostępnij MAGa'
            action={goTo} />

          <hr />

          <ManageButton
            Icon={Terminate}
            text='Zrezygnuj z wynajmu'
            action={() => goTo({ path: SECTION_TYPES.resignation })} />

          {hasOnlineAccess && <>
            <hr />

            <ManageButton
              Icon={Alert}
              text='Zgłoś awarię'
              action={() => push('/help')} />
          </>}
        </div>
      </div>
    </div>
  )
}

export default MagManage