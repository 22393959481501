import { clearPurchase, selectBasket } from '../Basket/basketSlice'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BiometricSlide from './BiometricSlide'
import { useLayoutEffect, useState } from 'react'
import FirstSlide from './FirstSlide'
import GateSlide from './GateSlide'
import MagSlide from './MagSlide'
import PinSlide from './PinSlide'
import { v4 } from 'uuid'
import './style.scss'

export const OPENING_TYPE = {
  app: 'APP',
  key: 'KEY',
  pin: 'PIN'
}

const INIT_PURCHASED = [{
  address: { street: 'Graniczna', estateNumber: 11, localNumber: null, city: 'Pabinice' },
  hasOnlineAccess: true,
  number: 12,
}, {
  address: { street: '20 Stycznia', estateNumber: 111, localNumber: 33, city: 'Pabinice' },
  hasOnlineAccess: false,
  number: 22,
}]

const NewWarehouse = () => {

  const [purchased, setPurchased] = useState([])
  const { purchased: p = [] } = useSelector(selectBasket)

  const { replace, push } = useHistory()
  const { key, id } = useParams()

  const dispatch = useDispatch()

  const next = (path = '') => {
    if (!path) {
      dispatch(clearPurchase())
      push('/warehouses')

    } else push(`/newwarehouse/${path}`)
  }

  useLayoutEffect(() => {
    if (key && purchased.length === 0) replace('/')
  }, [key, purchased.length, replace])

  useLayoutEffect(() => {
    const purchased = p.length === 0 ? INIT_PURCHASED : p
    const list = purchased.map(({ hasOnlineAccess, ...data }) => {
      const draw = () => Math.floor(Math.random() * 2) ? OPENING_TYPE.key : OPENING_TYPE.pin
      const type = hasOnlineAccess ? OPENING_TYPE.app : draw()
      const id = data.id || v4()

      return { id, data: { ...data, type } }
    })
    setPurchased(list)
  }, [p])

  const getValue = id => purchased.find(e => e.id === id)
  const getNextId = id => {
    const predicate = purchased.findIndex(e => e.id === id)
    return predicate > -1 && purchased[predicate + 1] ? purchased[predicate + 1]?.id : ''
  }

  return (
    <main className='newWarehouse'>
      <section key={key} className='--animated'>
        <div className='slides'>
          {(() => {
            switch (key) {
              case 'pin': return <PinSlide next={() => next('biometric')} />
              case 'biometric': return <BiometricSlide next={() => next(`mag/${purchased[0]?.id}`)} />
              case 'mag': return <MagSlide
                {...getValue(id)}
                next={() => next(`gate/${id}`)} />
              case 'gate': return <GateSlide
                {...getValue(id)}
                next={() => next(purchased[purchased.length - 1]?.id === id ? '' : `mag/${getNextId(id)}`)} />
              default: return <FirstSlide next={() => next('pin')} />
            }
          })()}
        </div>
      </section>
    </main>
  )
}

export default NewWarehouse