import useTranslation from '../../hooks/useTranslation'

const Timer = ({ time }) => {
  const { t } = useTranslation('header')

  const formatTime = seconds => {
    const m = Math.floor((seconds % 3600) / 60)
    const s = Math.round(seconds % 60)
    return `${m ? `${m}:` : ''}${m && s < 10 ? '0' + s : s}`
  }

  return <div className='header__timer'>
    <span className='--secondary'>{t('reservation')}:</span> <span className='--sb'>{formatTime(time)}</span>
  </div>
}

export default Timer