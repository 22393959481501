import { LOCATION_STATES } from '../../../utils/constants'
import usePopupState from '../../../hooks/usePopupState'
import Switch from '../../../components/inputs/Switch'
import { useHistory } from 'react-router-dom'
import PinPopup from './PinPopup'
import { useState } from 'react'

const SettingsSection = () => {
  const [checkbox, setCheckbox] = useState(false)
  const [pin, setPin] = useState({
    mag: '1234',
    gate: ''
  })

  const { push } = useHistory()
  const { popup } = usePopupState()

  const openPopup = (p = '') => push(window.location.pathname, LOCATION_STATES.popup(p))

  return (
    <>
      {(popup === 'mag' || popup === 'gate') && <PinPopup
        name={popup}
        value={pin[popup]}
        setValue={v => setPin(p => { return { ...p, [popup]: v } })}
      />}

      <div>
        <div className='list__header element__title'>Ustawienia</div>
        <div className='list__element --big'>
          <div className='group'>
            <div className='element__desc'>PIN do MAGa: <span className='--marked'>1234</span></div>
            <div
              className='element__desc'
              onClick={() => openPopup('mag')}>Zmień</div>
          </div>

          <div className='group'>
            <div className='element__desc'>PIN do bramy:  <span className='--marked'>brak</span></div>
            <div
              className='element__desc'
              onClick={() => openPopup('gate')}>Zmień</div>
          </div>


          <div className='group'>
            <div
              className='element__desc'
              style={{ flexBasis: '75%' }}>Zezwól na autoryzację biometryczną</div>
            <Switch
              name='biometric'
              checked={checkbox}
              changeHandler={() => setCheckbox(c => !c)} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsSection