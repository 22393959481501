import { ACCOUNT_TYPE, selectUserData, updateValue } from '../accountSlice'
import { STATUS, UNAUTHORIZED_TYPES } from '../../../utils/constants'
import useAuthorizedFetch from '../../../hooks/useAuthorizedFetch'
import { finalExp, initialExp } from '../../../utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import Popup from '../../../components/popups/Popup'
import PinForm from '../../../components/PinForm'
import { useHistory } from 'react-router-dom'
import { PATCH } from '../../../rest/request'
import { user } from '../../../rest/urls'
import { useState } from 'react'

const type = ACCOUNT_TYPE.basic

const EditMailPopup = () => {

  const { data = {} } = useSelector(state => selectUserData(state, type))
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState(false)
  const [form, showForm] = useState(true)
  const [token, setToken] = useState('')
  const [mail, setMail] = useState('')

  const { run } = useAuthorizedFetch()
  const { goBack, push } = useHistory()
  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const sendCode = async () => {
    setStatus(STATUS.pennding)

    try {
      const body = { email: mail }
      await PATCH(`${user}/email`, { body })

    } catch (err) {
      try {
        const error = JSON.parse(err)
        const { type, message } = error

        if (type === UNAUTHORIZED_TYPES.mailOtp) {
          showForm(false)
          setStatus(STATUS.idle)
        } else if (message) {
          push(window.location.pathname, { ...error, popup: 'formError' })
        } else throw new Error('Wrong response')

      } catch (errr) {
        console.error(errr)
        setStatus(STATUS.failed)
      }
    }
  }

  const submit = () => {
    const body = { email: mail, email_token: token }
    setStatus(STATUS.pennding)

    run(
      PATCH(`${user}/email`, { body }),
      res => {
        if (res) {
          updateVal('data', { ...data, email: mail })
          goBack()
        }
      },
      () => {
        setToken('')
        setWarning(true)
        setStatus(STATUS.failed)
      }
    )
  }

  const validateMail = () => {
    if (mail.match(finalExp.mail)) sendCode()
    else setWarning(true)
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <div className='popup__title'>
          <div>Zmiana adresu email</div>
        </div>

        <div className='popup__form'>
          {form ? <>
            <Input
              required
              value={mail}
              name='mail'
              label='Adres e-mail'
              minLength='4'
              maxLength='100'
              warning={warning}
              placeholder='Wpisz e-mail'
              disabled={status === STATUS.pennding}
              blurHandler={() => setWarning(mail.match(finalExp.mail) ? false : 'niewłaściwy e-mail')}
              changeHandler={e => {
                const { value } = e.target
                if (!value || value.match(initialExp.mail)) setMail(value)
              }} />

            <Submit
              text='Zmień'
              block={status === STATUS.pennding}
              isLoading={status === STATUS.pennding}
              action={validateMail} />
          </> : <>
            <div className='verification__text'>
              <div>Przepisz kod weryfikacyjny</div>
              <div>wysłany na adres</div>
            </div>

            <h2>{mail}</h2>

            <PinForm
              isCode
              autoFocus
              value={token}
              warning={warning}
              block={status === STATUS.pennding}
              setWarning={setWarning}
              setValue={setToken}
              submit={submit}
            />

            <Submit
              text='Zapisz'
              block={status === STATUS.pennding}
              isLoading={status === STATUS.pennding}
              action={() => token?.length === 6 ? submit() : setWarning(true)} />
          </>}
        </div>
      </div>
    </Popup>
  )
}

export default EditMailPopup