export const STATUS = {
  idle: 'idle',
  pennding: 'pennding',
  succeed: 'succeed',
  failed: 'failed'
}

export const MAG_STATUS = {
  closed: 'mag_closed',
  closing: 'mag_closing',
  opening: 'mag_opening',
  opened: 'mag_opened',
}

export const GATE_STATUS = {
  closed: 'gate_closed',
  closing: 'gate_closing',
  opening: 'gate_opening',
  opened: 'gate_opened',
}

export const GATEWAY_STATUS = {
  closed: 'gateway_closed',
  opened: 'gateway_opened',
  unlocked: 'gateway_unlocked',
}

export const LOCATION_STATES = {
  popup: (p = 1) => { return { popup: p } },
  lastPage: 'end',
  menu: 'menu',
}

export const UNAUTHORIZED_TYPES = {
  password: 'password',
  webAuth: 'webauthn',
  device: 'device',
  phone: 'phone',
  mail: 'email',
  pin: 'pin',
  smsOtp: 'sms_otp',
  mailOtp: 'email_otp',
}