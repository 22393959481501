import './style.scss'

const Select = ({ name, disabled = false, value, label, changeHandler, options }) => {
  return (
    <div className='input__default'>
      <label className='input__label' htmlFor={name}>
        <span>{label} </span>
      </label>

      <div className='input__main'>
        <select
          id={name}
          name={name}
          value={value}
          className='select'
          disabled={disabled}
          onChange={changeHandler}>
          {options}
        </select>
      </div>
    </div>
  )
}

export default Select