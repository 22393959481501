import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import './style.scss'

const Popup = ({ close: oldClose, isLine = true, centered = false, children }) => {

  const { goBack } = useHistory()
  const { state } = useLocation()

  const popupRef = useRef(null)

  const close = oldClose || goBack

  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && close()
    const isHidden = document.body.style.overflow === 'hidden'
    if (!isHidden) document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      if (!isHidden) document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [close])

  const onTouch = e => {
    const { scrollTop = 0 } = popupRef.current
    if (scrollTop) return

    const initialY = e.touches[0].clientY
    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if (currentY - initialY > 50) {
        touchEnd()
        close()
      }
    }

    const touchEnd = () => {
      document.removeEventListener('touchmove', touchMove, false)
      document.removeEventListener('touchend', touchEnd, false)
    }

    document.addEventListener('touchmove', touchMove, false)
    document.addEventListener('touchend', touchEnd, false)
  }

  return (
    <div
      className={`popup__bg${centered ? ' --centered' : ''}${state?.popup ? '' : ' --close'}`}
      onClick={close}>

      <div
        ref={popupRef}
        className='popup'
        onClick={e => e.stopPropagation()}
        style={isLine ? { paddingTop: 0 } : {}}
        onTouchStart={e => isLine && state?.popup && onTouch(e)}>
        {isLine && <div className='popup__line' />}
        {children}
      </div>
    </div>
  )
}

export default Popup