import { ReactComponent as Arrow } from '../../../assets/arrowRight.svg'
import useTranslation from '../../../hooks/useTranslation'
import Checkbox from '../../../components/icons/Checkbox'
import Skeleton from 'react-loading-skeleton'

const CompaniesElement = ({ showNip = true, data, selected, selectData, edit }) => {
  const { nip = '', name = '', streetName = '', homeNr = '', flatNr = '', postalCode = '', cityName = '', voivodshipName = '' } = data || {}
  const { t } = useTranslation('form')

  return (
    <div
      className='companies__element list__element --big'
      onClick={() => data && selectData(data)}>

      {data ? <div className='list__title group'>
        <p>
          <span>{name}</span>
          <span>{showNip && nip ? ` NIP: ${nip}` : ''}</span>
        </p>
        {selected !== undefined && <Checkbox selected={selected} />}
      </div> : <Skeleton className='skeleton__p' />}

      <div className='group'>
        {data ? <div className='element__text'>
          <div>{streetName} {homeNr}{flatNr ? `/${flatNr}` : ''}</div>
          <div>{postalCode} {cityName}</div>
          <div>{voivodshipName}</div>
        </div> : <Skeleton
          containerClassName='skeleton__text'
          count={3} />}

        {data ? <>
          {selected === undefined && <div className='element__icon'>
            <Arrow />
          </div>}

          {edit && <p
            className='element__edit'
            onClick={e => {
              e.stopPropagation()
              edit()
            }}>{t('edit')}</p>}
        </> : <Skeleton
          className='skeleton__icon'
          circle />}
      </div>
    </div>
  )
}

export default CompaniesElement