import { createSlice } from '@reduxjs/toolkit'
import { PROVINCES } from './constants'

export const mapSearchSlice = createSlice({
  name: 'mapSearch',
  initialState: {
    search: [],
    provinces: PROVINCES
  },
  reducers: {
    addToSearchList: (state, action) => {
      const { payload } = action
      const { search } = state

      if (payload) {
        const isInList = search.find(e => e.id ? e.id === payload.id : e === payload)

        if (!isInList) {
          state.search.push(action.payload)
          if (state.search.length > 3) state.search.shift()
        }
      }
    },
    updateProvinces: (state, action) => {
      const { payload: places } = action

      if (places.length > 0) {
        const provinces = places.reduce((r, e) => {
          const provinceName = e.province.toLocaleLowerCase()
          const provinceIdx = r.findIndex(({ search }) => search === provinceName)

          if (provinceIdx > -1) {
            const element = r[provinceIdx]
            const { list = [] } = element
            const copy = [...r]

            copy[provinceIdx] = { ...element, list: [...list, e] }
            return copy
          }
          return r
        }, PROVINCES)
        state.provinces = provinces
      }
    },
    removeFromSearchList: (state, action) => {
      const { payload } = action

      if (state.search[payload]) state.search.splice(payload, 1)
    },
  }
})

export const { addToSearchList, removeFromSearchList, updateProvinces } = mapSearchSlice.actions

export const selectProvinces = state => state.mapSearch.provinces
export const selectSearch = state => state.mapSearch.search

export default mapSearchSlice.reducer
