import React, { useEffect, useState } from 'react'
import ComercialPopup from './ComercialPopup'
import Mobile from '../Mobile'
import './style.scss'

const app = <Mobile />
const checkIsSmallScreen = () => window.innerWidth < 768

const Desktop = () => {
  const [isDisabledPopup, disablePopup] = useState(false)
  const [isSmallScreen, setSmallScreen] = useState(checkIsSmallScreen())

  useEffect(() => {
    const onResize = () => {
      if (!isDisabledPopup && checkIsSmallScreen() !== isSmallScreen) setSmallScreen(s => !s)
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [isDisabledPopup, isSmallScreen])

  const closePopup = () => {
    disablePopup(true)
    setSmallScreen(true)
  }

  return (
    <>
      {app}
      {!isSmallScreen && <ComercialPopup close={closePopup} />}
    </>
  )
}

export default Desktop