import { ReactComponent as Fingerprint } from '../../../assets/fingerprint.svg'
import * as WebAuth from '../../../conteiners/Test/webauth'
import { useHistory, useLocation } from 'react-router-dom'
import Popup from '../../../components/popups/Popup'
import { authProtected } from '../../../rest/urls'
import { POST } from '../../../rest/request'
import { useEffect, useState } from 'react'

const AuthPopup = () => {
  const [succeedText, setSucceedText] = useState('')

  const { goBack } = useHistory()
  const { state } = useLocation()

  const sendAuthToServer = async data => {
    try {
      const body = { webauthn: data }
      const response = await POST(authProtected, { body })
      if (response) setSucceedText(Array.isArray(response) ? response[0] : 'Udało się!!!')
      console.log(response)

    } catch (err) {
      console.error(`Error, ${err}`)
    }
  }

  useEffect(() => {
    if (state?.publicKey) {
      try {
        WebAuth.sign(
          state?.publicKey,
          sendAuthToServer
        )

      } catch (err) {
        console.error(`Error: ${err}`)
      }
    } else console.log('Where is publicKey?')
  }, [state?.publicKey])

  useEffect(() => {
    if (succeedText) {
      const timeout = setTimeout(goBack, 1000)
      return () => clearTimeout(timeout)
    }
  }, [goBack, succeedText])

  return (
    <Popup>
      <div className='popup__title'>
        <div>Autoryzacja</div>
      </div>
      <div className='popup__desc' style={{ textAlign: 'center', color: 'var(--theme-text)' }}>
        <Fingerprint />
        <p>Przyłóż palec do czytnika biometrycznego</p>
        {succeedText && <p><b>{succeedText}</b></p>}
      </div>
    </Popup>
  )
}

export default AuthPopup