import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Input from '../../../components/inputs/Input'
import { isMobile } from '../../../utils/isMobile'
import { checkAddress } from '../../../rest/urls'
import { STATUS } from '../../../utils/constants'
import { POST } from '../../../rest/request'
import { updateValues } from '../formSlice'
import { useDispatch } from 'react-redux'

const mobile = isMobile()

const InputWithOptions = forwardRef(({ value = '', name, additionalValues = false, optionalValueSetter, blurHandler, ...rest }, ref) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [focus, setFocus] = useState(false)
  const [data, setData] = useState('')

  const fetchController = useRef(null)
  const lastBody = useRef(null)

  const dispatch = useDispatch()
  const saveValue = useCallback(
    v => optionalValueSetter ? optionalValueSetter(v) : dispatch(updateValues(v))
    , [dispatch, optionalValueSetter])

  const isAdditional = status === STATUS.pennding || (status === STATUS.succeed && data.length > 0)
  const find = name === 'houseNumber' ? 'number' : name === 'postalCode' ? 'zip' : name

  useEffect(() => {
    const find = name === 'postalCode' ? 'zip' : name
    const isNumber = find === 'houseNumber'
    const isZip = find === 'zip'

    if (fetchController.current) {
      fetchController.current.abort()
      fetchController.current = null
    }

    if (!focus && !isZip) return
    if ((!isZip && value.length > 1) || (isNumber && additionalValues) || (isZip && !value && additionalValues)) {

      const body = { find, ...additionalValues }
      body[find] = value

      const bodyString = JSON.stringify(body)
      if (lastBody.current && lastBody.current === bodyString) return

      const controller = new AbortController()
      const signal = controller.signal

      fetchController.current = controller
      setStatus(STATUS.pennding);

      (async () => {
        try {
          const response = await POST(checkAddress, { body, signal })
          if (response) {
            setStatus(STATUS.succeed)
            lastBody.current = bodyString
            if (isZip && response.length === 1) {
              saveValue({ [name]: response[0][find] })
              blurHandler({ target: { name, value: response[0][find] } })
            }
            else setData(response)
          }

        } catch (err) {
          setStatus(STATUS.failed)
          setData('')
        }
      })()
    } else setData('')
  }, [additionalValues, blurHandler, focus, name, saveValue, value])

  return (
    <div className={`input__conteiner--additionnal --${isAdditional && focus ? 'expanded' : 'basic'}`}>
      <Input
        ref={ref}
        name={name}
        value={value}
        autoComplete='off'
        focusHandler={() => setFocus(true)}
        blurHandler={e => {
          if (mobile) setTimeout(() => setFocus(false))
          blurHandler(e)
        }}
        {...rest}
      />

      {isAdditional && focus && <div className='input__additional'>
        {status === STATUS.pennding ? <p>ładowanie...</p>
          : data.map((e, idx) => <div
            key={idx}
            className='additional__item'
            onClick={() => {
              const values = { [name]: e[find] }
              if (name === 'street') values.streetPrefix = e.streetPrefix
              if (name === 'city') values.cityDistricted = e.cityDistricted
              blurHandler({ target: { name, value: e[find] } })
              saveValue(values)
              if (!mobile) setFocus(false)
            }}>
            {e[find]}{find === 'city' ? `, pow. ${e.district}` : ''}
          </div>
          )}
      </div>}
    </div>
  )
})

export default InputWithOptions