import { selectCategoriesStatus, fetchCategories } from './categoriesSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { ReactComponent as Help } from '../../assets/help.svg'
import { addOrUpdateFilter } from '../Maps/menu/filtersSlice'
import useTranslation from '../../hooks/useTranslation'
import { useSelector, useDispatch } from 'react-redux'
import { resetMapStatus } from '../Maps/Map/mapSlice'
import { selectCategories } from './categoriesSlice'
import CategoriesElement from './CategoriesElement'
import { useHistory } from 'react-router-dom'
import Error from '../../components/Error'
import Popups, { POPUPS } from './Popups'
import { useEffect } from 'react'
import svg from './header.svg'
import './style.scss'

const header = <img src={svg} alt='Header'/>

const Categories = () => {
  const status = useSelector(selectCategoriesStatus)
  const categories = useSelector(selectCategories)

  const { push } = useHistory()

  const { t } = useTranslation('categories')
  const dispatch = useDispatch()

  useEffect(() => {
    if (status !== STATUS.succeed) dispatch(fetchCategories())
  }, [dispatch, status])

  const showPopup = p => push(window.location.pathname, LOCATION_STATES.popup(p))

  const updateFilter = ({ id, areaMin, areaMax }) => {
    const value = {}
    if (areaMin) value.min = Number(areaMin)
    if (areaMax) value.max = Number(areaMax)

    dispatch(resetMapStatus())
    dispatch(addOrUpdateFilter({ name: 'categoryId', where: 'attributes', value: id }))
    dispatch(addOrUpdateFilter({ name: 'area', where: 'attributes', value }))
  }

  return (
    <div className='categories'>
      <Popups />

      <div className='categories__menu--top center'>
        {header}
      </div>

      <section className='--animated'>
        <div>
          <div className='title'>
            <div>{t('whatsNext')}?</div>
            <div className='group'>
              <span>{t('chooseService')}</span>
              <Help onClick={() => showPopup(POPUPS.types)} />
            </div>
          </div>

          {status === STATUS.failed ? <Error />
            : <div className='list'>
              {status === STATUS.succeed ? categories
                .filter(({ disabled }) => !disabled)
                .map((e, idx) => <CategoriesElement
                  key={idx}
                  data={e}
                  action={() => {
                    if (e.isHall) showPopup(POPUPS.hallInfo)
                    else {
                      updateFilter(e)
                      push('/map')
                    }
                  }} />)
                : [1, 2, 3, 4].map(e => <CategoriesElement key={e} />)}
            </div>}
        </div>
      </section>
    </div>
  )
}

export default Categories