import { MapContainer, TileLayer, Marker, Circle, AttributionControl, Polygon } from 'react-leaflet'
import { selectLightTheme } from '../../../appSlice'
import { useEffect, useRef, useMemo } from 'react'
import { selectMapOptions } from './mapSlice'
import { locationIcon } from './customIcon'
import { voivodships } from './voivodships'
import { useSelector } from 'react-redux'
import Markers from './Markers'

import 'react-leaflet-markercluster/dist/styles.min.css'
import 'leaflet/dist/leaflet.css'

const Map = () => {
  const { position, mapPosition, zoom, showMarker, distance, voivodship } = useSelector(selectMapOptions)
  const lightTheme = useSelector(selectLightTheme)
  const mapRef = useRef(null)

  useEffect(() => {
    if (mapRef.current) mapRef.current.flyTo(mapPosition, zoom)
  }, [mapPosition, zoom])

  return (
    <MapContainer
      className='map'
      center={mapPosition || [51.666, 19.389]}
      zoom={zoom}
      maxZoom={18}
      scrollWheelZoom
      zoomControl={false}
      attributionControl={false}
      whenCreated={map => mapRef.current = map}>

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url={`https://{s}.basemaps.cartocdn.com/${lightTheme ? 'light' : 'dark'}_all/{z}/{x}/{y}{r}.png`}
        subdomains='abcd'
      />

      <AttributionControl position='topright' prefix={false} />

      {voivodship !== undefined && <Polygon
        positions={voivodships[voivodship]}
        pathOptions={{ fillColor: 'var(--red)', color: 'var(--red)' }} />}

      {position && showMarker && <Marker
        icon={locationIcon}
        position={position} />}

      {position && distance && <Circle
        center={position}
        radius={distance * 1000}
        pathOptions={{ fillColor: 'none', color: 'var(--red)' }} />}

      {useMemo(() => <Markers
        mapRef={mapRef}
        lightTheme={lightTheme}
      />, [lightTheme])}

    </MapContainer>
  )
}

export default Map