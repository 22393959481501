import { DEVICE_TYPES, selectLightTheme, updateDevice, updateTheme } from './appSlice'
import { useSelector, useDispatch } from 'react-redux'
import { SkeletonTheme } from 'react-loading-skeleton'
import { LOCATION_STATES } from './utils/constants'
import { isIos, isMobile } from './utils/isMobile'
import AppLogin from './conteiners/AppLogin'
import { isDay } from './utils/sunPosition'
import { useEffect, useState } from 'react'
import './App.scss'

import 'react-loading-skeleton/dist/skeleton.css'

const ios = isIos()
const mobile = isMobile()

const App = ({ children }) => {
  const [isLogIn, logIn] = useState(!process.env.REACT_APP_PASSWORD)
  const lightTheme = useSelector(selectLightTheme)

  const dispatch = useDispatch()

  useEffect(() => dispatch(updateTheme(isDay())), [dispatch])

  useEffect(() => {
    const { href, origin } = window.location
    const homePath = `${origin}/`

    if (performance.getEntriesByType('navigation')[0].type !== 'reload') {
      window.history.replaceState(LOCATION_STATES.lastPage, null, homePath)
      window.history.pushState(null, null, homePath)
      window.history.pushState(null, null, href)
    }
  }, [])

  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01
      document.body.style.setProperty('--vh', `${vh}px`)
    }

    const popstate = () => {
      const { state } = window.history
      if (state === LOCATION_STATES.lastPage) window.history.go(1)
    }

    const orientationchange = () => {
      if (ios) window.scrollTo(0, 0)
    }

    window.addEventListener('popstate', popstate)
    window.addEventListener('orientationchange', orientationchange)
    if (mobile) {
      updateVH()
      window.addEventListener('resize', updateVH)
    }

    return () => {
      if (mobile) window.removeEventListener('resize', updateVH)
      window.removeEventListener('popstate', popstate)
      window.removeEventListener('orientationchange', orientationchange)
    }
  }, [])

  useEffect(() => {
    const { search } = window.location

    if (search) {
      const query = new URLSearchParams(search)
      const ios = query.get('ios')
      const android = query.get('android')

      if (ios) dispatch(updateDevice(DEVICE_TYPES.ios))
      else if (android) dispatch(updateDevice(DEVICE_TYPES.android))
    }
  }, [dispatch])

  return (
    <div className={`app --${lightTheme ? 'light' : 'dark'}Theme`}>
      <SkeletonTheme
        baseColor='var(--skeleton-base)'
        highlightColor='var(--skeleton-highlight)' >
        {isLogIn ? children : <AppLogin login={logIn} />}
      </SkeletonTheme>
    </div>
  )
}

export default App
