const Section = ({ header = '', title = [], desc = [] }) => {
  return (
    <div>
      <div className='list__header element__title'>{header}</div>
      <div className='list__element --big'>
        {title.length > 0 && <div className='element__title'>
          {title.map((e, i) => <span key={i}>{e}</span>)}
        </div>}

        {desc.length > 0 && <div className='element__desc'>
          {desc.map((e, i) => <div key={i}>{e}</div>)}
        </div>}
      </div>
    </div>
  )
}

export default Section