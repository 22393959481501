import useAuthorizedReduxFetch from '../../hooks/useAuthorizedReduxFetch'
import { fetchUserData, selectUserData } from './accountSlice'
import { STATUS } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const useUserFetch = (type) => {

  const { status = STATUS.idle } = useSelector(state => selectUserData(state, type))
  const { run } = useAuthorizedReduxFetch()

  useEffect(() => {
    if (status === STATUS.idle) run(fetchUserData(type))
  }, [run, status, type])

  return status
}

export default useUserFetch