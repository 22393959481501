import { selectFiltersTypes, removeFilter } from '../menu/filtersSlice'
import { selectCategories } from '../../Categories/categoriesSlice'
import { useSelector, useDispatch } from 'react-redux'
import FiltersElement from './FiltersElement'
import { Fragment } from 'react'

const FiltersList = ({ filters, openPopup }) => {
  const filterTypes = useSelector(selectFiltersTypes)
  const categories = useSelector(selectCategories)

  const dispatch = useDispatch()

  const removeAction = (field, where, value) => !!field && dispatch(removeFilter({ name: field, where, value }))

  return Object.entries(filterTypes).filter(([, { field, type, where, options }]) => {
    if (where && field) return !!filters[where][field]
    else if (type === 'section') {
      return options.reduce((r, { field, where }) => {
        if (field && where) r = r || !!filters[where][field]
        return r
      }, false)
    }
    return false
  }).map(([k, { name, field, type, where, options }]) => {
    if (field === 'categoryId') {
      const category = filters[where][field]
      const size = filters[where]['warehouseTypeId']
      const selectedCategory = options.find(({ value }) => value === category)
      const selectedSize = size ? categories.find(({ id }) => id === category)?.warehouseTypes?.find(({ id }) => id === size) : false

      return <Fragment key={k}>
        <FiltersElement
          name={name}
          openPopup={openPopup}
          desc={selectedCategory ? selectedCategory?.name : category} />
        {selectedSize && <FiltersElement
          name='Rozmiar'
          openPopup={openPopup}
          removeAction={() => removeAction('warehouseTypeId', where)}
          desc={selectedSize ? selectedSize?.typeNameForClient : size}  />}
      </Fragment>

    } else if (where && field) {
      const item = filters[where][field]
      const desc = (item.min || item.max) ? `${item.min ? ` od ${item.min}` : ''}${item.max ? ` do ${item.max}` : ''}`
        : field === 'rentalMinDays' ? `od ${item} ${item === '1' ? 'dnia' : 'dni'}`
          : item === true ? 'Tak' : item

      return <FiltersElement
        key={k}
        name={name}
        desc={desc}
        openPopup={openPopup}
        removeAction={() => removeAction(field, where)}  />

    } else if (type === 'section') {
      return <Fragment key={k}>
        {options.filter(({ where, field, value }) => {
          const item = filters[where][field]
          if (field === 'defaultAttributes' && item && Array.isArray(item)) return item.includes(value)
          else return !!item
        }).map(({ name, field, where, value }, idx) => <FiltersElement
          key={idx}
          desc={name}
          openPopup={openPopup}
          removeAction={() => removeAction(field, where, field === 'defaultAttributes' ? value : '')}  />)}
      </Fragment>
    }
    return false
  })
}

export default FiltersList