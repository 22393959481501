import { selectChatThreads } from '../Chats/chatSlice'
import { useSelector } from 'react-redux'
import ChatList from '../Chats/ChatList'

const Notifications = () => {
  const threads = useSelector(selectChatThreads)
  const { active, inactive } = threads.reduce((r, e) => {
    if (e.date?.end) r.inactive += 1
    else r.active += 1
    return r
  }, { active: 0, inactive: 0 })

  return (
    <div className='help__notification chat'>
      {active > 0 && <>
        <h4>Aktywne zgłoszenia</h4>
        <ChatList showOnlyActiveChats />
      </>}

      {inactive > 0 && <>
        <h4>Archiwalne zgłoszenia</h4>
        <ChatList showOnlyInactiveChats />
      </>}
    </div>
  )
}

export default Notifications