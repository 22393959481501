import { selectWarehouse, updateWarehouseState } from '../Warehouses/warehousesSlice'
import { useDispatch, useSelector } from 'react-redux'
import Option from '../../components/Option'

const MagMessages = ({ number }) => {
  const { alerts } = useSelector(state => selectWarehouse(state, number))

  const dispach = useDispatch()

  const action = index => {
    dispach(updateWarehouseState({
      number,
      key: 'alerts',
      value: [...alerts].filter((_, i) => i !== index),
    }))
  }

  return (
    alerts.length > 0 ?
      <div className='menu__section'>
        <p>Wymagane akcje</p>

        <div className='menu__message'>
          {alerts.map((e, i) => <Option
            key={i}
            {...e}
            selected={true}
            action={() => action(i)}
          />)}
        </div>
      </div> : false
  )
}

export default MagMessages