import { selectWarehouses } from '../../conteiners/Warehouses/warehousesSlice'
import { useHistory, useParams } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import { useSelector } from 'react-redux'
import Popup from '../popups/Popup'
import DateInput from './DateInput'
import { useState } from 'react'

const FilterPopup = ({ filters: startFilters = {}, fields = [], setFilters: saveFilters = () => { } }) => {

  const [filters, setFilters] = useState(startFilters)
  const warehouses = useSelector(selectWarehouses)

  const { t } = useTranslation('filters')

  const { goBack } = useHistory()
  const { number } = useParams()

  const updateFilter = (name, value = '', additionalName = '', f = filters) => {
    const newVal = additionalName ? { ...f[name], [additionalName]: value } : value

    if (value !== '') setFilters({ ...f, [name]: newVal })
    else {
      const copy = { ...f }

      if (additionalName) {
        delete copy[name][additionalName]
        if (Object.keys(copy[name]).length === 0) delete copy[name]

      } else delete copy[name]
      setFilters(copy)
    }
  }

  return (
    <Popup isLine={false}>

      <div className='popup--filter'>
        <h2>{t('chooseFilters')}</h2>

        <div className='popup__main center'>
          <div>
            <div className='popup__list'>
              {fields.map(({ name, label, type, options, attr }, i) => <div
                key={i}
                className='element'>
                <div className='element__title'>{label}</div>
                {name === 'date' ? (
                  <div className='element__date group'>
                    <DateInput
                      start
                      min={attr.min}
                      max={filters.date?.end || attr.max}
                      value={filters.date?.start || ''}
                      updateFilter={updateFilter} />

                    <DateInput
                      min={filters.date?.start || attr.min}
                      max={attr.max}
                      value={filters.date?.end || ''}
                      updateFilter={updateFilter} />
                  </div>
                ) : type === 'section' ? (
                  <select
                    value={filters[name] || ''}
                    onChange={e => updateFilter(name, e.target.value)}>
                    {options.map(({ name, value }, i) => <option key={i} value={value}>{name}</option>)}
                    {name === 'mag' && warehouses.map(({ number }, i) => <option key={i} value={number}>MAG {number}</option>)}
                  </select>
                ) : false}
              </div>)}
            </div>

            <div className='popup__buttons center'>
              <div
                className='button__cancel'
                onClick={() => {
                  saveFilters({ mag: number })
                  goBack()
                }}>{t('removeFilters')}</div>
              <div
                className='button__add'
                onClick={() => {
                  saveFilters(filters)
                  goBack()
                }}>{t('show')}</div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default FilterPopup