import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './data.json'

export const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    updateWarehouseState: (state, action) => {
      const { number, key, value } = action.payload

      if (number > -1) {
        const index = state.findIndex(e => e.number === number)
        if (index > -1) state[index][key] = value
      }
    },
  }
})

export const { updateWarehouseState } = warehousesSlice.actions

export const selectWarehouses = state => state.warehouses
export const selectWarehouse = (state, number) => {
  const { warehouses } = state
  const index = warehouses.findIndex(e => e.number === number)

  if (index > -1) return { index, ...warehouses[index] }
  else if (warehouses.length > 0) return { index: 0, ...warehouses[0] }
  else return {}
}

export default warehousesSlice.reducer
