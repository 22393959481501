import { ReactComponent as FullScreen } from '../../../assets/fullscreen.svg'
import { ReactComponent as NewPlace } from '../../../assets/newPlace.svg'
import { ReactComponent as Help } from '../../../assets/helpSmall.svg'
import { LOCATION_STATES } from '../../../utils/constants'
import Location from '../../../components/Location'
import { useHistory } from 'react-router-dom'

const Options = ({ fullScreen, setFullScreen }) => {
  const { push, location: { pathname } } = useHistory()
  const showPopup = (p = 'legend') => push(pathname, LOCATION_STATES.popup(p))

  return (
    <div className='map__options'>
      <div
        className='option__help'
        onClick={() => showPopup('legend')}>
        <Help />
      </div>

      <div
        className={`option__fullscreen ${fullScreen ? '--active' : ''}`}
        onClick={() => {
          if (!fullScreen) window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          setFullScreen(!fullScreen)
        }}>
        <FullScreen />
      </div>

      <div className='option__new'
        onClick={() => showPopup('newplace')}>
        <NewPlace />
      </div>

      <div className='option__location'>
        <Location secIcon />
      </div>
    </div>
  )
}

export default Options