import { selectWarehouse, updateWarehouseState } from '../Warehouses/warehousesSlice'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import { MAG_STATUS } from '../../utils/constants'
import { useEffect } from 'react'

const Begining = ({ number, next }) => {
  const { status } = useSelector(state => selectWarehouse(state, number))
  const { t } = useTranslation('slides')

  const dispatch = useDispatch()

  const desc = t('openedMagDescArray')

  useEffect(() => {
    const timeout = setTimeout(() => dispatch(updateWarehouseState({
      number,
      key: 'status',
      value: MAG_STATUS.opening
    })))
    return () => clearTimeout(timeout)
  }, [dispatch, number])

  return (
    <>
      <Logo />
      <div className='slide__title center'>
        <div className='--termina'>{t('congratulations')}</div>
        <div>{t('youOpenedYourMag')}</div>
      </div>

      <div className='fireworks'>
        <div />
        <div />
      </div>

      <Warehouse status={status} number={number} showShadow />

      <div className='slide__desc'>
        {Array.isArray(desc) ? desc.map((d, i) => <div key={i}>{d}</div>)
          : desc}
      </div>

      <Submit text={t('letsStart')} action={next} />
    </>
  )
}

export default Begining