import { ReactComponent as Arrow } from '../../assets/arrowRight.svg'
import { forwardRef } from 'react'

const access = [{
  type: 'once',
  date: '2022-05-25',
  time: '11:00',
  to: 'Filip Adamczyk'
}, {
  type: 'time',
  date: '2022-05-25',
  time: '11:00 - 13:00',
  to: 'Filip Adamczyk'
}, {
  type: 'infinity',
  date: '2022-05-25',
  time: '11:00',
  to: 'Filip Adamczyk'
}, {
  type: 'once',
  date: '2022-05-25',
  time: '11:00',
  to: 'Filip Adamczyk'
}, {
  type: 'infinity',
  date: '2022-05-25',
  time: '11:00',
  to: 'Filip Adamczyk'
}]

const MagAccess = forwardRef(({ full, more }, ref) => {
  return (
    <div className='menu__section'>
      <div className='group'>
        <p>Zarządzaj dostępem</p>
        <div className='menu__more --termina'>Nowy →</div>
      </div>

      <div
        ref={ref}
        className='menu__access'>

        <hr />
        {access
          .filter((_, i) => full ? true : i < 3)
          .map(({ type, date, time, to }, i) => <div
            key={i}
            className='access__item'>

            <div className='group'>
              <div className='center'>
                <div
                  className='access__symbol center'
                  style={type === 'time' ? { alignItems: 'flex-start' } : {}}>
                  <span>{type === 'once' ? '1' : type === 'time' ? 'L' : '∞'}</span>
                </div>

                <div className='access__desc'>
                  <div className='--termina'>{to}</div>
                  <div>Dostęp {type === 'once' ? 'jednorazowy' : type === 'time' ? 'czasowy' : 'stały'}</div>
                  <div>{date} {time}</div>
                </div>
              </div>

              <div className='access__symbol center --big'>
                <Arrow />
              </div>
            </div>

            <hr />
          </div>)}

        {!full && <>
          <div
            className='menu__more center --termina --high'
            onClick={more}>Więcej →</div>
          <hr />
        </>}
      </div>
    </div>
  )
})

export default MagAccess