import { useEffect, useState, useRef } from 'react'
import './style.scss'

const Range = ({ options = [], optionsPosition = [], unit = '', value, updateValue, darkBG = false }) => {
  const [optionsPos, setOptionsPos] = useState([])
  const [valIdx, setValIdx] = useState(0)

  const isArray = Array.isArray(value)

  const rangeRef = useRef(null)
  const currentIdx = useRef(0)

  useEffect(() => {
    if (options.length === optionsPosition.length) setOptionsPos(optionsPosition)
    else {
      const lastIdx = options.length - 1
      const element = 100 / lastIdx
      const posList = options.reduce((r, e, idx) => {
        r.push(idx === 0 ? 0 : idx === lastIdx ? 100 : idx * element)
        return r
      }, [])
      setOptionsPos(posList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Array.isArray(value)) {
      const firstIdx = value[0] !== undefined ? options.findIndex(e => e === value[0]) : 0
      const secondIdx = value[1] !== undefined ? options.findIndex(e => e === value[1]) : options.length - 1
      setValIdx([firstIdx, secondIdx])

    } else if (value) {
      const idx = options.findIndex(e => e === value)
      if (idx > -1) setValIdx(idx)
    }
  }, [options, value])

  const handleMove = e => {
    if (!rangeRef.current) return

    const x = (e.type === 'touchmove' ? e.touches[0].clientX : e.clientX) - rangeRef.current.offsetLeft
    const percent = (x * 100) / rangeRef.current.clientWidth
    const closest = optionsPos.reduce((prev, curr) => Math.abs(curr - percent) < Math.abs(prev - percent) ? curr : prev)
    const closestIdx = optionsPos.findIndex(e => e === closest)

    if (closestIdx > -1) {
      const selectedValue = options[closestIdx]
      const idx = currentIdx.current

      if (!isArray && selectedValue !== value) updateValue(selectedValue)
      else if (isArray && selectedValue !== value[idx]) {
        const getArray = (i = currentIdx.current) => i ? [value[0], selectedValue] : [selectedValue, value[1]]
        const array = getArray()
        if (array[0] > array[1]) {
          currentIdx.current = idx ? 0 : 1
          updateValue(getArray())

        } else updateValue(array)
      }
    }
  }

  return (
    <div
      ref={rangeRef}
      className='range__input'
      onTouchMove={e => !isArray && handleMove(e)}
      onClick={e => !isArray && handleMove(e)}>

      <div className={`range__line ${darkBG ? '--dark' : ''}`}>
        {options.map((e, idx) => {
          const isSelected = isArray ? valIdx[0] === idx || valIdx[1] === idx : valIdx === idx
          const isActive = isArray ? valIdx[0] < idx && valIdx[1] >= idx : valIdx >= idx

          return <div
            key={e}
            className={`range__part ${isActive ? '--active' : ''}`}
            style={{ width: `${idx !== 0 ? optionsPos[idx] - optionsPos[idx - 1] : optionsPos[idx]}%` }}>
            <div
              className={`range__thumb ${isSelected ? '--selected' : ''}`}
              onTouchStart={() => { if (isArray) currentIdx.current = valIdx[0] === idx ? 0 : 1 }}
              onTouchMove={e => isArray && handleMove(e)}
            />
            <span className={isSelected ? '--selected' : ''}>{e} {unit}</span>
          </div>
        })}
      </div>
    </div>
  )
}

export default Range