import { STATUS, UNAUTHORIZED_TYPES } from '../../../utils/constants'
import { newmailcode, newsmscode, verify } from '../../../rest/urls'
import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { useHistory, useLocation } from 'react-router-dom'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import Input from '../../../components/inputs/Input'
import { GET, POST } from '../../../rest/request'
import PinForm from '../../../components/PinForm'
import { useCallback, useState } from 'react'

const CODE_LENGTH = 6

const VerificationPopup = () => {

  const { state: { code_sent, popup, ...state } = {} } = useLocation()
  const { goBack } = useHistory()

  const [name, setName] = useState(popup === UNAUTHORIZED_TYPES.device ? '' : true)
  const [recipient, setRecipient] = useState(state?.recipient)
  const [token, setToken] = useState('')
  const [warning, setWarning] = useState({
    code: false,
    name: false
  })
  const [status, setStatus] = useState({
    getting: STATUS.idle,
    sending: STATUS.idle
  })

  const updateStatus = (status, name = 'sending') => setStatus(s => { return { ...s, [name]: status } })
  const updateWarning = (warning = true, name = 'name') => setWarning(w => { return { ...w, [name]: warning } })

  const submit = useCallback(async () => {
    updateStatus(STATUS.pennding)
    try {
      const body = { token }
      if (popup === UNAUTHORIZED_TYPES.device) body.name = name

      const response = await POST(verify(popup), { body })
      if (response) {
        updateStatus(STATUS.succeed)
        goBack()
        window.location.reload()
      }

    } catch (err) {
      updateStatus(STATUS.failed)
      updateWarning(true, 'code')
    }
  }, [goBack, name, popup, token])

  const getCode = async () => {
    try {
      const response = await GET(popup === UNAUTHORIZED_TYPES.mail ? newmailcode : newsmscode)
      if (response) {
        updateStatus(STATUS.succeed, 'getting')
        setRecipient(response?.recipient)
      }

    } catch (error) {
      updateStatus(STATUS.failed, 'getting')
      console.error('Code is not sent!')
    }
  }

  const getSubmitText = len => {
    return !name ? 'Brakuje nazwy'
      : len === CODE_LENGTH ? 'Autoryzuj'
        : `Brakuje ${CODE_LENGTH - len} znak${len === CODE_LENGTH - 1 ? 'u' : 'ów'}`
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <Logo />

        <div className='popup__title'>
          <div>Autoryzacja {popup === UNAUTHORIZED_TYPES.device ? 'urządzenia' : popup === UNAUTHORIZED_TYPES.mail ? 'mailowa' : 'SMS'}</div>
        </div>

        {code_sent || status.getting === STATUS.succeed ? <div className='verefication__phone'>
          <div>Kod został wysłany na:</div>
          <div>{recipient}</div>
        </div> : <div
          className='verefication__phone'
          onClick={getCode}>
          Przyślij kod
        </div>}

        {popup === UNAUTHORIZED_TYPES.device && <Input
          value={name}
          name='name'
          label='Nazwa'
          minLength='1'
          maxLength='200'
          warning={warning.name}
          placeholder='Wpisz nazwę urządzenia'
          blurHandler={() => {
            if (!name) updateWarning(true)
          }}
          changeHandler={e => {
            const { value } = e.target
            if (warning.name && value) updateWarning(false)
            if (value !== '\n') setName(value)
          }} />}

        <PinForm
          isCode
          autoFocus
          value={token}
          warning={warning.code}
          setValue={setToken}
          submit={submit}
          setWarning={w => updateWarning(w, 'code')}
          autoLoadCodeFromSMS={status.sending === STATUS.idle && popup !== UNAUTHORIZED_TYPES.device}
        />

        <Submit
          text={getSubmitText(token.length)}
          block={!name || token.length !== CODE_LENGTH || status.sending === STATUS.pennding}
          action={submit}
        />
      </div>
    </Popup>
  )
}

export default VerificationPopup