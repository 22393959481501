import Reservation from './Reservation'
import Messages from './Messages'
import React from 'react'
import './style.scss'

const Notifications = () => {
  return (
    <div className='app__notifications'>
      <Messages />
      <Reservation />
    </div>
  )
}

export default Notifications