const WarehouseInfo = ({ name, codeName, address }) => {
  const { street, estateNumber, localNumber, city } = address || {}
  const displayedAddress = `${street} ${estateNumber}${localNumber ? `/${localNumber}` : ''}, ${city}`

  return (
    <div className='warehouse__info'>
      <div className='info__text info__title'>
        <div className='--termina'>{name}</div>
        <div>{codeName}</div>
      </div>

      <div className='info__text info__desc'>
        <div>{displayedAddress}</div>
      </div>
    </div>
  )
}

export default WarehouseInfo