import { fetchMapDetails, selectPlace, selectPlaceId, selectStatus } from './mapDetailsSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { useLocation, useParams } from 'react-router-dom'
import { addItemToBasket } from '../Basket/basketSlice'
import { useSelector, useDispatch } from 'react-redux'
import usePopupState from '../../hooks/usePopupState'
import { useEffect, useMemo, useState } from 'react'
import DetailsLocation from './DetailsLocation'
import Loading from '../../components/Loading'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import DetailsElement from './DetailsElement'
import src from '../../assets/warehouse.svg'
import Error from '../../components/Error'
import SizePopup from './SizePopup'
import './style.scss'

const warehouse = <img src={src} alt='warehouse'/>

const MapDetails = () => {
  const [userSelectedDiffrentHash, selectDiffrentHashByUser] = useState(false)
  const [sizeArray, setSizeArray] = useState([])
  const [type, setType] = useState()
  const [size, setSize] = useState({
    hash: '',
    index: 0,
  })

  const { warehouses, ...place } = useSelector(selectPlace)
  const placeId = useSelector(selectPlaceId)
  const status = useSelector(selectStatus)

  const dispatch = useDispatch()

  const { search, pathname } = useLocation()
  const { placeId: placeIdParams } = useParams()
  const { popup } = usePopupState()
  const { push } = useHistory()

  const succeed = status === STATUS.succeed

  useEffect(() => {
    dispatch(fetchMapDetails(placeIdParams))
  }, [placeIdParams, dispatch])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const warehouseTypeId = query.get('warehouseTypeId')

    if (warehouseTypeId) setSize({
      hash: warehouseTypeId,
      index: -1
    })
  }, [search])

  useEffect(() => {
    if (warehouses && placeId === placeIdParams) {
      const keys = Object.entries(warehouses)
        .sort((a, b) => {
          const getSize = (v) => {
            const firstKey = Object.keys(v)[0]
            return v[firstKey]?.data?.capacity || 0
          }
          return getSize(a[1]) - getSize(b[1])
        })
        .map(([k]) => k)
      setSizeArray(keys)
    }
  }, [warehouses, placeId, placeIdParams])

  useEffect(() => {
    if (!size.hash && sizeArray[0]) setSize({
      hash: sizeArray[0],
      index: 0
    })
    else if (size.index === -1 && sizeArray.length > 0) {
      const newIdx = sizeArray.findIndex(e => e === size.hash)
      if (newIdx > -1) setSize({
        ...size,
        index: newIdx
      })
      else {
        selectDiffrentHashByUser(true)
        setSize({
          hash: sizeArray[0],
          index: 0
        })
      }
    }
  }, [size, sizeArray])

  useEffect(() => {
    if (size.hash && warehouses && warehouses[size.hash]) {
      const group = warehouses[size.hash]
      const firstElementInGroup = warehouses[size.hash][Object.keys(group)[0]]
      setType(firstElementInGroup)
    }
  }, [size.hash, warehouses])

  const changeHash = (toBigger = true) => {
    const newIdx = size.index + (toBigger ? 1 : -1)
    if (sizeArray[newIdx]) setSize({
      hash: sizeArray[newIdx],
      index: newIdx
    })
  }

  const getMagName = name => {
    if (!name) return ''
    const list = name.split(' ')
    const lastIdx = list.length - 1

    return list.reduce((r, e, i) => {
      const index = e.indexOf('m2')
      const el = <div key={i}>
        {index > -1 ? <>
          {e.slice(0, index)}
          <span> m</span>
          <sup>2</sup>
        </> : e}
      </div>

      i === lastIdx && i > 0 ? r.splice(1, 0, el) : r.push(el)
      return r
    }, [])
  }

  const location = useMemo(() => <DetailsLocation succeed={succeed} />, [succeed])

  const mag = <div style={userSelectedDiffrentHash ? { opacity: .5 } : {}}>{warehouse}</div>

  const magSection = <div className='mag__section'>
    {succeed ? <div className='mag__title'>
      {userSelectedDiffrentHash ? 'Zobacz inne rozmiary' : 'Dostępne opcje MAGa'}
    </div> : <Skeleton className='skeleton__title' />}

    {succeed ? <div className='mag__title--secondary'>
      {userSelectedDiffrentHash ? 'Wybierz jeden z dostępnych' : 'Wybierz jedną z listy'}
    </div> : <Skeleton className='skeleton__title--secondary' />}
  </div>

  return (
    <div className='map__details'>
      {popup && <SizePopup data={type?.data} />}

      {status === STATUS.failed ? <Error />
        : <div>
          {location}
          {((succeed && warehouses[size.hash]) || status === STATUS.pennding) && <>
            <div className='details__mag'>
              <div className='mag__graphics center'>
                <div
                  className='mag__marker'
                  onClick={() => succeed && size.index > 0 && changeHash(false)}
                  style={size.index === 0 ? { opacity: '0.5' } : {}}>
                  <div className='marker'>
                    <div className='arrow --left' />
                  </div>
                  <span>Mniejszy</span>
                </div>

                {succeed ? <div className='mag__group group'>
                  <div className='mag__svg'>
                    {mag}
                    {userSelectedDiffrentHash && <div className='svg__title'>Niedostępny</div>}
                  </div>

                  {type && <div>
                    {/* <div className='mag__text'>Twój wybór</div> */}
                    <div className='mag__title'>{getMagName(type?.warehouseTypeName)}</div>
                    <div className='mag__size'>
                      <div className='size__title'>Rozmiar w metrach:</div>
                      <div
                        className='size__list'
                        onClick={() => push(pathname, LOCATION_STATES.popup())}>
                        <div className='size__item'>
                          <div>{Number(type?.data?.internalLength)}</div>
                          <div>dł.</div>
                        </div>

                        <div className='size__item'>
                          <div>{Number(type?.data?.internalWidth)}</div>
                          <div>szer.</div>
                        </div>

                        <div className='size__item'>
                          <div>{Number(type?.data?.internalHeight)}</div>
                          <div>wys.</div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div> : <Loading />}

                <div
                  className='mag__marker'
                  onClick={() => succeed && size.index < sizeArray.length - 1 && changeHash()}
                  style={!succeed || size.index === sizeArray.length - 1 ? { opacity: '0.5' } : {}}>
                  <div className='marker'>
                    <div className='arrow --right' />
                  </div>
                  <span>Większy</span>
                </div>
              </div>

              {magSection}
            </div>

            <div className='map__result'>
              <div className='list'>
                {succeed ? Object.entries(warehouses[size.hash])
                  .sort(([, a], [, b]) => b.free_now_to_rent_count - a.free_now_to_rent_count)
                  .map(([k, v]) => <DetailsElement
                    key={k}
                    data={{ ...v, availabilityDate: place.closest_availability_date }}
                    action={date => {
                      const { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude } = place
                      const {
                        warehouseTypeName = '',
                        data: { hasOnlineAccess, internalHeight, internalLength, internalWidth, area } = {}
                      } = type || {}

                      dispatch(addItemToBasket({
                        address: { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude },
                        minDate: date ? date.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1') : undefined,
                        size: { internalHeight, internalLength, internalWidth, area },
                        name: warehouseTypeName,
                        hasOnlineAccess,
                        placeId,
                      }))
                      push(`/calendar/${placeId}/${k}`)
                    }} />)
                  : [1, 2, 3].map(e => <DetailsElement key={e} />)}
              </div>
            </div>
          </>}
        </div>}
    </div >
  )
}

export default MapDetails