import { ReactComponent as Forward } from '../../assets/forwardBtn.svg'
import { ReactComponent as Dots } from '../../assets/dots.svg'
import useTranslation from '../../hooks/useTranslation'
import Skeleton from 'react-loading-skeleton'

const CategoriesElement = ({ data, action }) => {
  const { name, description, isHall = false, areaMin, areaMax, recommended } = data || {}
  const { t } = useTranslation('filters')

  return <div
    className={`list__element ${isHall ? '--big' : recommended ? '--black --big' : ''}`}
    onClick={() => action && action()}>

    <div className='element__group group'>
      {data ? <>
        <div className='element__price'>
          {areaMin && <span>{t('fromB')} {Number(areaMin)}m<sup>2</sup> </span>}
          {areaMax && <span>{t(areaMin ? 'to' : 'toB')} {Number(areaMax)}m<sup>2</sup></span>}
        </div>
        <Dots />
      </>
        : <>
          <Skeleton className='skeleton__price' />
          <Skeleton className='skeleton__dots' />
        </>}
    </div>

    {data ? <div className='element__title'>
      <div className='--termina'>{name}</div>
      <div>{description}</div>
    </div> : (
      <Skeleton
        count={2}
        className='skeleton__title' />
    )}

    {data ? <Forward /> : <Skeleton circle className='skeleton__forward' />}
  </div>
}

export default CategoriesElement