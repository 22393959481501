import { selectUserData, ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { ReactComponent as Phone } from '../../../assets/phone.svg'
import { detectBrowser, isMobile } from '../../../utils/isMobile'
import { ReactComponent as Pc } from '../../../assets/pc.svg'
import useTranslation from '../../../hooks/useTranslation'
import { LOCATION_STATES } from '../../../utils/constants'
import Switch from '../../../components/inputs/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../Login/loginSlice'
import { useHistory } from 'react-router-dom'
import Item from '../Item'

const type = ACCOUNT_TYPE.settings
const mobile = isMobile()

const UserSettings = () => {
  const { biometrics = true, auth2step = false } = useSelector(state => selectUserData(state, type))
  const { pin } = useSelector(selectUser)

  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const { push } = useHistory()

  const showPopup = name => push(window.location.pathname, LOCATION_STATES.popup(name))
  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))
  
  return (
    <>
      <p>{t('appSecurity')}</p>
      <div className='account__list'>
        <Item
          title={t('biometrics')}
          desc={t(biometrics ? 'on' : 'off')}
          right={<Switch
            name='biometrics'
            checked={biometrics}
            changeHandler={() => updateVal('biometrics', !biometrics)} />
          } />

        <Item
          title={t('2stepVerification')}
          desc={t(auth2step ? 'on' : 'off')}
          right={<Switch
            name='auth2step'
            checked={auth2step}
            changeHandler={() => updateVal('auth2step', !auth2step)} />
          } />

        <Item
          showArrow
          title={t('pinCode')}
          desc={t(pin ? 'set' : 'none')}
          action={() => showPopup('changePin')} />
      </div>

      <p>{t('identityVerification')}</p>
      <div className='account__list'>
        <Item
          showArrow
          title={t('secondaryPhoneNumber')}
          desc={t('none')} />

        <Item
          showArrow
          title={t('secondaryEmailAddress')}
          desc={t('none')} />
      </div>

      <p>{t('activityHistory')}</p>
      <div className='account__list'>
        <Item
          showArrow
          desc={t('now')}
          icon={mobile ? <Phone /> : <Pc />}
          title={<span>{t(mobile ? 'mobileDevice' : 'desktop')} - {detectBrowser()}</span>}
          action={() => showPopup('deviceList')} />
      </div>
    </>
  )
}

export default UserSettings