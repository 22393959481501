import { DEVICE_TYPES, selectDevice, selectLightTheme } from '../../appSlice'
import { resetTime, selectBasket } from '../../conteiners/Basket/basketSlice'
import { ReactComponent as Back } from '../../assets/back.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as Menu } from '../../assets/menu.svg'
import { LOCATION_STATES } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Warning from './Warning'
import Timer from './Timer'
import './style.scss'


const INIT_TIME = 180

const Header = ({ dark = false, flying = false, backAction }) => {
  const { time: startTime } = useSelector(selectBasket)
  const lightTheme = useSelector(selectLightTheme)
  const device = useSelector(selectDevice)

  const [time, updateTime] = useState(0)

  const { push, replace, goBack, location: { pathname } } = useHistory()
  const dispatch = useDispatch()

  const isIos = device === DEVICE_TYPES.ios

  useEffect(() => updateTime(startTime ? INIT_TIME : 0), [startTime])

  useEffect(() => {
    if (time > 0) {
      const resetCounter = () => dispatch(resetTime())
      window.addEventListener('click', resetCounter)
      return () => window.removeEventListener('click', resetCounter)

    } else if (time < 0) push('/map')
  }, [dispatch, push, time])

  useEffect(() => {
    if (time > 0 && startTime > 0) {
      const interval = setInterval(() => updateTime(INIT_TIME - ((Date.now() - startTime) / 1000)), 1000)
      return () => clearInterval(interval)
    }
  }, [startTime, time])

  if (time > 0 && time < 9) return (
    <Warning time={time} />
  )

  return (
    <header className={`group --${dark ? 'dark' : 'light'}${flying ? ' --flying' : ''}${isIos ? ' --ios' : ''}`}>
      {pathname !== '/' && <div
        className={`header__back ${lightTheme ? '' : '--dark'}`}
        onClick={() => backAction ? backAction() : window.history ? goBack() : replace('/')} >
        <Back />
      </div>}

      {time > 0 && <Timer time={time} />}

      {!flying && time <= 0 && <div
        className={`header__logo${lightTheme ? '' : '--dark'}`}
        onClick={() => push('/')}>
        <Logo />
      </div>}

      <Menu onClick={() => push(pathname, LOCATION_STATES.menu)} />
    </header>
  )
}

export default Header