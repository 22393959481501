import HeadersManager from './HeadersManager'
import { getUuid } from '../utils/uuid'

const uuid = getUuid()

const request = async (resource = '', method = 'GET', { body = false, form = null, signal = false, credentials = false }) => {
  const connectionId = HeadersManager.connectionId.get()
  const dbHeader = process.env.REACT_APP_DB_HEADER
  const token = HeadersManager.jwt.get()
  const headers = new Headers({
    'Accept': 'application/json'
  })

  if (token) headers.set('Authorization', `Bearer ${token}`)
  if (!resource.includes('dostolika.pl')) {
    headers.set('X-Client-Hash', uuid)
    if (dbHeader) headers.set('X-Database', dbHeader)
    if (connectionId) headers.set('WSS-ID', connectionId)
  }

  const init = {
    method,
    headers,
  }

  if (signal) init.signal = signal
  if (credentials) init.credentials = 'include'

  if (form) {
    init.body = form
  } else if (body) {
    init.body = JSON.stringify(body)
    init.headers.append('content-type', 'application/json')
  }

  try {
    const response = await fetch(resource, init)
    const type = response.headers.get('content-type')
    const data = (method === 'DELETE' && !response.bodyUsed) ? true :
      type.includes('json') ? await response.json() : await response.text()
    if (response.ok) return data

    throw new Error(data ? JSON.stringify(data) : response.statusText)

  } catch (err) {
    return Promise.reject(err.message)
  }
}

export const GET = (resource, options = { signal: false }) => request(resource, 'GET', options)
export const DELETE = resource => request(resource, 'DELETE', { credentials: true })
export const POST = (resource = '', options = { body: '', form: null, signal: false, credentials: false }) => request(resource, 'POST', options)
export const PATCH = (resource = '', options = { body: '', credentials: false }) => request(resource, 'PATCH', options)

