import { selectUserData, ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { ReactComponent as Arrow } from '../../../assets/arrowDown.svg'
import { ReactComponent as User } from '../../../assets/account.svg'
import useTranslation from '../../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../../../utils/constants'
import Filters from '../../../components/Filters'
import Loading from '../../../components/Loading'
import Error from '../../../components/Error'
import { MONTHS } from '../../../utils/date'
import { useEffect, useState } from 'react'
import * as filtersFields from './filters'
import useUserFetch from '../useUserFetch'
import Item from '../Item'

const type = ACCOUNT_TYPE.invoices

const Invoices = () => {

  const { data } = useSelector(state => selectUserData(state, type))
  const { t, lang } = useTranslation()

  const [filteredList, filterList] = useState(data)
  const [filters, setFilters] = useState({})

  const dispatch = useDispatch()
  const status = useUserFetch(type)

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const downloadFv = (id, link, updateItem = false) => {
    if (updateItem) {
      const value = [...data].map(e => {
        if (e.id === id) return { ...e, lastAccessDate: new Date(Date.now()).toLocaleDateString() }
        else return e
      })
      updateVal('data', value)
    }

    if (link) window.open(link, '_blank')
  }

  useEffect(() => {
    if (status !== STATUS.succeed) return

    const filtersList = Object.entries(filters)
    const filterDate = filters.date ? {} : false
    if (filterDate) {
      if (filters.date.start) filterDate.start = new Date(`${filters.date.start}, 00:00`)
      if (filters.date.end) filterDate.end = new Date(`${filters.date.end}, 00:00`)
    }

    const filtered = data.filter(({ documentDate, lastAccessDate, mag }) => {
      return filtersList.reduce((r, [k, v]) => {
        if (k === 'date') {
          if (!documentDate) return false
          const date = new Date(documentDate)
          const { start, end } = filterDate

          if (start) r = r && date >= start
          if (end) r = r && date <= end

        } else if (k === 'download') {
          if (v === 'true') r = r && !!lastAccessDate
          else if (v === 'false') r = r && !lastAccessDate

        } else if (k === 'mag') {
          if (mag && Array.isArray(mag)) r = r && mag.includes(v)
        }
        return r
      }, true)
    })
    filterList(filtered)
  }, [filters, data, status])

  return (
    <div className='account__safety account__popup'>
      <div className='account__header'>
        <div className='account__icon'>
          <User />
        </div>

        <div className='title'>
          <div>{t('account.invoices')}</div>
          <div>{t('account.subTitle')}</div>
        </div>
      </div>

      <Filters
        showFiltersBtn
        filters={filters}
        setFilters={setFilters}
        fields={filtersFields[lang]}
      />

      <div className='account__list'>
        {status === STATUS.failed ? <Error />
          : status === STATUS.succeed ? (
            filteredList?.length > 0 ? filteredList
              .map(({ id, documentDate, fullNumber, link, lastAccessDate }, i) => <Item
                key={i}
                label={(() => {
                  const date = new Date(documentDate)
                  return `${date.getDate()} ${MONTHS.short[date.getMonth()]}`
                })()}
                icon={<div className='circle center'>
                  {lastAccessDate && <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4077 0L4.35194 6.84321L0.596373 3.20182L0 3.78024L4.35194 8L12 0.578372L11.4077 0Z" fill="var(--theme-text)" />
                  </svg>}
                </div>}
                title={`FV ${fullNumber}`}
                desc={lastAccessDate ? `${t('account.downloaded')} ${lastAccessDate}` : t('account.notDownloaded')}
                right={<Arrow onClick={() => downloadFv(id, link, !lastAccessDate)} />} />
              ) : <Error text={t('filters.noItems')} />
          ) : <Loading middleOfPage />}
      </div>
    </div>
  )
}

export default Invoices