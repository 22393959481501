import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { selectWarehouse } from '../Warehouses/warehousesSlice'
import { useHistory, useParams } from 'react-router-dom'
import MagNumber from '../../components/MagNumber'
import { isMobile } from '../../utils/isMobile'
import WarehouseSlides from './WarehouseSlides'
import { useSelector } from 'react-redux'
import MagPromotion from './MagPromotion'
import MagMessages from './MagMessages'
import MagActions from './MagActions'
import MagHistory from './MagHistory'
import MagAccess from './MagAccess'
import MagStatus from './MagStatus'
import MagManage from './MagManage'
import './style.scss'

const mobile = isMobile()

const statuses = <MagStatus />
const promotion = <MagPromotion />

export const SECTION_TYPES = {
  access: 'access',
  details: 'details',
  history: 'history',
  payments: 'payments',
  resignation: 'resignation'
}

const Warehouse = () => {

  const { push, replace, location: { pathname, search } } = useHistory()
  const { number } = useParams()

  const { mag: { hasOnlineAccess = true } = {} } = useSelector(state => selectWarehouse(state, number))

  const [expanded, expand] = useState(!!search)

  const menuRef = useRef(null)
  const accessRef = useRef(null)
  const manageRef = useRef(null)
  const historyRef = useRef(null)
  const warehouseRef = useRef(null)

  const scrolledUp = useRef(true)

  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => document.body.style.removeProperty('overflow')
    }
  }, [])

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search)
      const from = query.get('from')

      if (from) {
        scroll(from === SECTION_TYPES.history ? historyRef
          : from === SECTION_TYPES.access ? accessRef
            : manageRef, 'auto')
        replace(pathname)
      }
    }
  }, [pathname, replace, search])

  const onTouch = e => {
    const menu = menuRef.current
    if (!menu || !mobile) return

    const initialY = e.touches[0].clientY

    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 40 && !expanded) || (currentY - initialY > 40 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  const scrollPage = e => {
    const { scrollTop } = e.target
    if (!expanded && scrollTop > 160) expand(true)
    else if (expanded && scrollTop < 20) expand(false)
  }

  const scrollMenu = e => {
    const { scrollTop } = e.target
    scrolledUp.current = scrollTop < 1
  }

  const scroll = (ref, behavior = 'smooth') => ref.current?.scrollIntoView({ behavior })

  const goTo = useCallback(({ path = SECTION_TYPES.access, back = true }) => {
    if (back) replace(`${pathname}?from=${path}`)
    push(`${pathname}/${path}`)
  }, [pathname, push, replace])

  const toggleMenu = useCallback(() => {
    if (menuRef.current && expanded) menuRef.current.scrollTop = 0
    mobile ? expand(!expanded) : scroll(expanded ? warehouseRef : menuRef)
  }, [expanded])

  const access = useMemo(() => <div ref={accessRef}>
    <MagAccess more={goTo} />
  </div>, [goTo])

  const history = useMemo(() => <div ref={historyRef}>
    <MagHistory more={() => goTo({ path: SECTION_TYPES.history })} />
  </div>, [goTo])

  const manage = useMemo(() => <div ref={manageRef}>
    <MagManage goTo={goTo} />
  </div>, [goTo])

  const message = useMemo(() => <MagMessages number={number} />, [number])

  return (
    <div
      className='warehouse'
      onScroll={e => !mobile && scrollPage(e)}
      style={{ overflow: mobile ? 'hidden' : 'auto' }}>

      <div
        ref={warehouseRef}
        className='warehouse__main center'>

        {useMemo(() => <>
          <MagNumber number={number} />
          <WarehouseSlides number={number} />
        </>, [number])}
      </div>

      <div
        ref={menuRef}
        className='menu__main'
        style={(expanded && mobile) ? { top: 0 } : {}}
        onTouchStart={e => (mobile && scrolledUp.current) && onTouch(e)}>

        <div
          className='menu__full'
          onTouchStart={e => mobile && onTouch(e)}>
          {statuses}
        </div>

        <div
          onScroll={e => mobile && scrollMenu(e)}
          style={{ overflowY: expanded ? 'auto' : 'hidden' }}>

          <MagActions
            number={number}
            expanded={expanded}
            toggleMenu={toggleMenu} />

          {expanded && <>
            {message}
            {manage}
            {hasOnlineAccess && history}
            {hasOnlineAccess && access}
            {promotion}
          </>}
        </div>
      </div>
    </div>
  )
}

export default Warehouse