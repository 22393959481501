import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../utils/constants'
import { checkPrice } from '../../rest/urls'
import { POST } from '../../rest/request'

export const fetchCalendar = createAsyncThunk('calendar/fetchCalendar', async ({ userID = '', placeID, hash }) => {
  const body = { userID, warehouses: [{ placeID, hash }] }
  const response = await POST(checkPrice, { body })
  return response.warehouses ? { hash, ...response.warehouses[hash] } : { hash, ...response[hash] }
})

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    hash: '',
    priceList: [],
    promotions: [],
    termiateDays: 0,
    availableAttributes: [],
    defaultAttributes: [],
    status: STATUS.idle,
  },
  extraReducers: {
    [fetchCalendar.fulfilled]: (state, action) => {
      const { hash, pricelist = [], promotions = [], rental_min_days, add_attrs, default_attrs } = action.payload

      if (pricelist.length > 0) {
        const el = pricelist[pricelist.length - 1]
        pricelist[pricelist.length - 1] = { ...el, v: '∞', m: -1, r: 100 }
      }

      state.hash = hash
      state.priceList = pricelist
      state.promotions = promotions
      state.availableAttributes = add_attrs
      state.defaultAttributes = default_attrs
      state.termiateDays = rental_min_days
      state.status = STATUS.succeed
    },
    [fetchCalendar.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const selectCalendar = state => state.calendar

export default calendarSlice.reducer
