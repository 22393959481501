import useTranslation from '../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import Popup from './Popup'

const ConfirmationPopup = ({ title, desc, cancelAction, okAction }) => {
  const { t } = useTranslation('basic')
  const { goBack } = useHistory()

  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__basket--warning'>
        <h3>{title || t('areYouSure')}</h3>
        {desc && <p>{desc}</p>}

        <div className='popup__buttons group'>
          <div
            className='button --cancel'
            onClick={cancelAction || goBack}>{t('no')}</div>

          <div
            className='button --accept'
            onClick={okAction}>{t('yes')}</div>
        </div>
      </div>
    </Popup>
  )
}

export default ConfirmationPopup