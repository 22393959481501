import { createSlice } from '@reduxjs/toolkit'

export const DEVICE_TYPES = {
  android: 'android',
  ios: 'ios',
  any: '',
}

export const AVAILABLE_LANGUAGES = {
  pl: 'pl',
  en: 'en'
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    lightTheme: true,
    device: DEVICE_TYPES.any,
    language: AVAILABLE_LANGUAGES.pl,
  },
  reducers: {
    updateTheme: (state, action) => {
      state.lightTheme = action.payload
    },
    updateDevice: (state, action) => {
      state.device = action.payload
    },
    updateLanguage: (state, action) => {
      state.language = action.payload
    }
  },
})

export const { updateTheme, updateDevice, updateLanguage } = appSlice.actions

export const selectDevice = state => state.app.device
export const selectLanguage = state => state.app.language
export const selectLightTheme = state => state.app.lightTheme

export default appSlice.reducer
