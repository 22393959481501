import location from '../../../assets/userLocation.svg'
import selectedMarker from './selectedMarker.svg'
import lightMarker from './lightMarker.svg'
import darkMarker from './darkMarker.svg'
import { Icon } from 'leaflet'

export const selectedMarkerIcon = new Icon({
  iconUrl: selectedMarker,
  iconAnchor: [30, 120],
  iconSize: [60, 120],
})

export const lightMarkerIcon = new Icon({
  iconUrl: lightMarker,
  iconAnchor: [30, 120],
  iconSize: [60, 120],
})

export const darkMarkerIcon = new Icon({
  iconUrl: darkMarker,
  iconAnchor: [30, 120],
  iconSize: [60, 120],
})

export const locationIcon = new Icon({
  iconUrl: location,
  iconAnchor: [15, 25],
  iconSize: [30, 50],
})