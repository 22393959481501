import { selectWarehouse, updateWarehouseState } from '../Warehouses/warehousesSlice'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MAG_STATUS } from '../../utils/constants'
import SlideShow from '../Warehouses/SlideShow'

const WarehouseSlides = ({ number }) => {
  const { index, wasOpen = false } = useSelector(state => selectWarehouse(state, number))

  const { replace, push } = useHistory()
  const { path } = useRouteMatch()

  const dispach = useDispatch()

  const changeMagStatus = status => dispach(updateWarehouseState({
    number,
    key: 'status',
    value: status === MAG_STATUS.closed ? MAG_STATUS.opening : MAG_STATUS.closing
  }))

  return (
    <SlideShow
      actionInAllSlides
      startIndex={index}
      action={(num, status) => {
        if (num === number && status) {
          if (!wasOpen) push(`/first/${number}`)
          else changeMagStatus(status)

        } else if (num !== number) replace(path.replace(':number', num))
      }} />
  )
}

export default WarehouseSlides