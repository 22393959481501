import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login, logout, refresh } from '../../rest/urls'
import HeadersManager from '../../rest/HeadersManager'
import { DELETE, POST } from '../../rest/request'
import { parseJwt } from '../../utils/parseJwt'

export const fetchLogIn = createAsyncThunk('login/fetchLogIn', async body => {
  const response = await POST(login, { body, credentials: true })
  return response
})

export const fetchLogOut = createAsyncThunk('login/fetchLogOut', async () => {
  const response = await DELETE(logout)
  return response
})

export const fetchToken = createAsyncThunk('login/fetchToken', async () => {
  const response = await POST(refresh, { credentials: true })
  return response
})

const initialState = {
  loggedIn: false,
  contract: false,
  isLoading: false,
  expirationDate: 0,
  error: '',
  user: {
    name: '',
    pin: false,
  }
}

const logInUser = (state, data) => {
  if (data) {
    const { expires_in: expirationDate, cfg, access_token: token, hasDataForContract } = data
    const { name = '' } = parseJwt(token)
    const { pin = false } = cfg

    state.expirationDate = (expirationDate - 60) * 1000
    state.user = { name, pin }
    state.isLoading = false
    state.loggedIn = true
    state.error = ''
    if (hasDataForContract && hasDataForContract.fields) state.contract = hasDataForContract.fields
    HeadersManager.jwt.set(token)

  } else {
    HeadersManager.jwt.destroy()
    return initialState
  }
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addPin: (state, _) => { state.user.pin = true },
    removeError: (state, _) => { state.error = '' },
    resetContract: (state, _) => { state.contract = false }
  },
  extraReducers: {
    [fetchLogIn.fulfilled]: (state, action) => logInUser(state, action.payload),
    [fetchLogIn.rejected]: (state, action) => {
      const { message: error = '' } = action.error

      try {
        const paresedError = JSON.parse(error)
        const { message = '' } = paresedError

        if (message) state.error = message
        else throw new Error()

      } catch (err) {
        state.error = 'Nie udało się zalogować'
      }
    },

    [fetchLogOut.fulfilled]: (state, _) => logInUser(state, false),

    [fetchToken.fulfilled]: (state, action) => logInUser(state, action.payload),
    [fetchToken.pending]: (state, _) => { state.isLoading = true },
    [fetchToken.rejected]: (state, _) => logInUser(state, false),
  },
})

export const { addPin, removeError, resetContract } = loginSlice.actions

export const selectExpirationDate = state => state.login.expirationDate
export const selectIsLoggedIn = state => state.login.loggedIn
export const selectIsLoading = state => state.login.isLoading
export const selectContract = state => state.login.contract
export const selectError = state => state.login.error
export const selectUser = state => state.login.user

export default loginSlice.reducer
