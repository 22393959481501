import { passwordForgot, passwordReset } from '../../../rest/urls'
import { finalExp, initialExp } from '../../../utils/validation'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { POST } from '../../../rest/request'
import { useEffect, useState } from 'react'
import '../style.scss'

const Reset = () => {
  const [form, setForm] = useState({})
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState({
    email: false,
    password: false,
  })

  const { goBack, replace, location: { pathname, search } } = useHistory()

  const isToken = !!form.token
  const isLoading = status === STATUS.pennding

  useEffect(() => {
    if (!search) return
    const query = new URLSearchParams(search)
    const email = query.get('email')
    const token = query.get('token')

    if (token && email) setForm({
      email,
      token,
    })
    replace(pathname)
  }, [pathname, replace, search])


  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    if (name === 'email' && value && !value.match(initialExp.mail)) return

    setForm({
      ...form,
      [name]: value
    })
    
    if (value && warning[name]) setWarning({
      ...warning,
      [name]: false
    })
  }

  const submit = () => {
    const { email = '', token = '', password = '' } = form

    if (isToken) {
      if (password && password.length > 7) {
        const body = { email, token, password }
        fetch(body, passwordReset)

      } else setWarning({
        email: false,
        password: password.length < 8 ? 'za krótkie hasło' : false,
      })

    } else {
      if (email && email.match(finalExp.mail)) {
        const reseturl = `${window.location.origin}${pathname}`
        const body = { email, reseturl }
        fetch(body, passwordForgot)

      } else setWarning({
        email: !email.match(finalExp.mail) ? 'niewłaściwy e-mail' : false,
        password: false,
      })
    }
  }

  const fetch = async (body, url) => {
    setStatus(STATUS.pennding)

    try {
      const response = await POST(url, { body })

      if (response) {
        setStatus(STATUS.succeed)
        if (url === passwordReset) goBack()
      }

    } catch (err) {
      setStatus(STATUS.failed)
    }
  }

  return (
    <div className='reset__password'>
      <div className='reset__title title'>
        <div>Resetowanie hasła</div>
      </div>

      <div className='reset__main main center'>
        <div className='reset__form form'>
          <Input
            disabled={isLoading || isToken}
            value={form.email || ''}
            name='email'
            label='E-mail'
            minLength='1'
            maxLength='200'
            warning={warning.email}
            placeholder='Podaj adres e-mail'
            changeHandler={changeHandler} />

          {form.token && <Input
            disabled={isLoading}
            value={form.password || ''}
            name='password'
            label='Nowe hasło'
            minLength='1'
            maxLength='200'
            warning={warning.password}
            placeholder='Podaj nowe hasło'
            changeHandler={changeHandler} />}

          <Submit
            text='Resetuj hasło'
            action={() => status !== STATUS.pennding && submit()} />
        </div>
      </div>
    </div>
  )

}

export default Reset