import { selectIsLoading, selectIsLoggedIn } from '../Login/loginSlice'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import Loading from '../../components/Loading'
import { ACCOUNT_TYPE } from './accountSlice'
import { useSelector } from 'react-redux'
import HiddenMenu from './HiddenMenu'
import BasicInfo from './BasicInfo'
import Settings from './Settings'
import Invoices from './Invoices'
import Payments from './Payments'
import Company from './Company'
import Popups from './Popups'
import Main from './Main'
import './style.scss'

const hidenMenu = <HiddenMenu />

const Account = () => {
  const isLoading = useSelector(selectIsLoading)
  const isLogin = useSelector(selectIsLoggedIn)
  const [expanded, expand] = useState(true)

  const { replace } = useHistory()
  const { key } = useParams()

  const menuRef = useRef(null)


  useEffect(() => {
    if (!isLogin && !isLoading && key !== ACCOUNT_TYPE.settings) replace('/login')
  }, [isLoading, isLogin, key, replace])

  const onTouch = e => {
    const menu = menuRef.current
    if (!menu || key || menu.scrollTop !== 0) return

    const initialY = e.touches[0].clientY

    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 20 && !expanded) || (currentY - initialY > 20 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  return (
    <div className='account'>
      {hidenMenu}
      <Popups />

      <div
        ref={menuRef}
        onTouchStart={onTouch}
        className='account__main'
        style={{ top: expanded ? 0 : '15em' }}>

        {isLoading ? <Loading middleOfPage /> : <>
          {(() => {
            switch (key) {
              case ACCOUNT_TYPE.invoices: return <Invoices />
              case ACCOUNT_TYPE.payments: return <Payments />
              case ACCOUNT_TYPE.settings: return <Settings />
              case ACCOUNT_TYPE.company: return <Company menuRef={menuRef} />
              case ACCOUNT_TYPE.basic: return <BasicInfo />
              default: return <Main />
            }
          })()}
        </>}
      </div>
    </div>
  )
}

export default Account