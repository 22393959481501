import { ReactComponent as Account } from '../../../assets/accountBig.svg'
import { ReactComponent as Checked } from '../../../assets/checked.svg'
import { ReactComponent as Safety } from '../../../assets/safety.svg'
import { selectWarehouse } from '../../Warehouses/warehousesSlice'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import { LOCATION_STATES } from '../../../utils/constants'
import BackBtn from '../../../components/buttons/BackBtn'
import { useHistory, useParams } from 'react-router-dom'
import usePopupState from '../../../hooks/usePopupState'
import OutsourcePhotoPopup from './OutsourcePhotoPopup'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useRef } from 'react'
import Item from './Item'

const Camera = ({ setForm }) => {

  const [photo, setPhoto] = useState()

  const { number } = useParams()
  const { push, goBack } = useHistory()
  const { popup, pathname } = usePopupState()

  const { mag: { hasOnlineAccess } } = useSelector(state => selectWarehouse(state, number))

  const inputRef = useRef()

  const addPhoto = () => {
    setForm(photo)
    goBack()
  }

  return (
    <>
      <div>
        {popup === 'photo' && <OutsourcePhotoPopup />}
      </div>

      <div style={{ display: 'none' }}>
        <input
          ref={inputRef}
          type='file'
          accept='image/*'
          capture='camera'
          onChange={e => {
            const { files } = e.target
            if (files[0]) setPhoto(files[0])
          }}
        />
      </div>

      <div className='title'>
        <div>Zrób zdjęcie</div>
        <div>Pokaż, że magazyn jest już pusty, czysty<br /> i gotowy dla innych klientów</div>
      </div>

      <div className='resignation__main'>
        <div className='resignation__list'>
          <div className='list__label'>Po co zdjęcia?</div>

          <Item
            icon={<Safety />}
            title='Bezpieczeństwo'
            desc='Upewnimy się, że nic nie zostawiłeś'
          />

          <Item
            icon={<Checked />}
            title='Jakość'
            desc='Dbamy, aby MAGi były w 100% sprawne'
          />

          <Item
            icon={<Account />}
            title='Dobro klientów'
            desc='Każdy chce wynająć czysty magazyn!'
          />
        </div>

        <div className='resignation__buttons'>
          <ArrowBtn
            enlarged
            text={photo ? `Dodaj ${photo?.name}` : `${hasOnlineAccess ? 'Otwórz MAGa i z' : 'Z'}rób zdjęcie`}
            action={() => photo ? addPhoto() : inputRef.current.click()}
          />

          <BackBtn
            text='Zleć to Nam'
            action={() => push(pathname, LOCATION_STATES.popup('photo'))}
          />
        </div>
      </div>
    </>
  )
}

export default Camera