import { useHistory, useLocation } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import { getStartDate, YEAR } from '../../utils/date'
import { useState, useEffect } from 'react'
import Calendar from 'react-calendar'

const CalendarPopup = ({ start, end, minDate, minEndDate, rangeCom, selected, select, change }) => {

  const [activeStartDate, setActiveStartDate] = useState(true)

  const { t } = useTranslation('filters')

  const { goBack } = useHistory()
  const { state } = useLocation()

  useEffect(() => setActiveStartDate(true), [selected, end])

  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && goBack()
    const isHidden = document.body.style.overflow === 'hidden'
    if (!isHidden) document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      if (!isHidden) document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [goBack])

  return (
    <div
      className={`popup__bg${state?.popup ? '' : ' --close'}`}
      onClick={goBack}>

      <div
        className='popup__calendar'
        onClick={e => e.stopPropagation()}>

        <div className='popup__shadow'>
          <div />
          <div />
        </div>

        {typeof start !== 'string' && <div className='popup__main center'>
          <div>
            <div className='popup__inputs'>
              <div
                className={`popup__input ${selected ? '--selected' : ''}`}
                onClick={() => select(true)}>
                <span>{t('from')}<b>{start.toLocaleDateString()}</b></span>
              </div>
              <div
                className={`popup__input ${!selected ? '--selected' : ''}`}
                onClick={() => select(false)}>
                <span>{t('to')}<b>{end ? end.toLocaleDateString() : '∞'}</b></span>
              </div>
            </div>

            <div onTouchStart={e => {
              const initialX = e.touches[0].clientX
              const initialY = e.touches[0].clientY

              const touchend = () => {
                window.removeEventListener('touchmove', touchmove)
                window.removeEventListener('touchend', touchend)
              }

              const touchmove = e => {
                const currentX = e.touches[0].clientX
                const currentY = e.touches[0].clientY

                if (Math.abs(initialX - currentX) > 100) {
                  touchend()
                  handleClickArrow(currentX < initialX)

                } else if (Math.abs(initialY - currentY) > 20) touchend()
              }

              const handleClickArrow = next => {
                const element = document.querySelector(`.react-calendar__navigation__${next ? 'next' : 'prev'}-button`)
                if (element) element.click()
              }

              window.addEventListener('touchmove', touchmove)
              window.addEventListener('touchend', touchend)
            }}>
              <Calendar
                minDetail='year'
                minDate={selected ? getStartDate(minDate) : minEndDate}
                maxDate={new Date(Date.now() + (5 * YEAR))}
                activeStartDate={activeStartDate ? selected ? start : end : undefined}
                value={start && end ? [start, end] : start || [new Date()]}
                onActiveStartDateChange={() => setActiveStartDate(false)}
                returnValue={selected ? 'start' : 'end'}
                showNeighboringMonth={false}
                onChange={change}
              />
            </div>

            {rangeCom}

            <div
              className='popup__button'
              onClick={goBack}>{t('saveChanges')}</div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default CalendarPopup