import { ReactComponent as Triangle } from '../../assets/warningTriangle.svg'
import useTranslation from '../../hooks/useTranslation'

const Alert = ({ title, desc, action, buttonText }) => {
  const { t } = useTranslation('basic')

  return (
    <div className='warehouse__popup'>
      <div className='popup__header'>
        <Triangle />
        <div>{t('actionRequired')}</div>
      </div>

      <div className='popup__title'>
        <div className='--termina'>{title}</div>
        <div>{desc}</div>
      </div>

      <div
        className='popup__button'
        onClick={e => {
          action()
          e.stopPropagation()
        }}>
        {buttonText}
      </div>
    </div>
  )
}

export default Alert