import { ReactComponent as Logo } from '../../assets/logo.svg'
import useTranslation from '../../hooks/useTranslation'
import Popup from '../../components/popups/Popup'

const RentalPeriodPopup = () => {
  const { t } = useTranslation('calendar')
  const rules = t('rentalRulesArray')

  return (
    <Popup>
      <div className='popup__title'>
        <Logo />
        <div>{t('leasePeriod')}</div>
        <div>{t('durationOfAgreement')}</div>
      </div>

      <div className='popup__list'>
        <div className='list__title'>{t('learnRules')}</div>
        <ul>
          {Array.isArray(rules) ? rules.map((r, i) => <li key={i}>{r}</li>)
            : rules}
        </ul>
      </div>
    </Popup>
  )
}

export default RentalPeriodPopup