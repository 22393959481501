import { selectBasketItem, extendFormToMap } from '../../../conteiners/Basket/basketSlice'
import { ReactComponent as Arrow } from '../../../assets/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../../../components/popups/Popup'
import { useHistory } from 'react-router-dom'

const NewMagPopup = () => {
  const { placeId } = useSelector(selectBasketItem)
  const { replace } = useHistory()
  const dispatch = useDispatch()

  const openMap = withDetails => {
    dispatch(extendFormToMap(true))
    replace(withDetails && placeId ? `/mapdetails/${placeId}` : '/map')
  }

  return (
    <Popup>
      <div className='popup__newMag'>
        <h2>Nowy MAG</h2>

        <div className='popup__menu'>
          <div
            className='menu__item group'
            onClick={() => openMap(true)}>
            <div className='item__desc --termina'>
              <div>W tej samej</div>
              <div>lokalizaji</div>
            </div>

            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>

          <div
            className='menu__item group'
            onClick={() => openMap(false)}>
            <div className='item__desc --termina'>
              <div>Na innym</div>
              <div>placu</div>
            </div>

            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default NewMagPopup