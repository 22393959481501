import useTranslation from '../../hooks/useTranslation'
import BackBtn from '../../components/buttons/BackBtn'
import Submit from '../../components/inputs/Submit'
import { useHistory } from 'react-router-dom'
import { useState, useRef } from 'react'

const Photo = ({ header, next }) => {

  const { push } = useHistory()
  const inputRef = useRef()

  const [photos, setPhotos] = useState([])
  const { t } = useTranslation('slides')

  return <>
    {header}

    <div className='slide__title center'>
      <div className='--termina'>{t('timeForPhotos')}</div>
      <div>{t('takePictureOfWarehouse')}</div>
    </div>

    <div className='slide__photos'>
      {photos.length > 0 && <div className='photos__slides'>
        {photos.map((p, i) => {
          const src = URL.createObjectURL(p)

          return <div
            key={i}
            className='slides__image center'>

            <img
              src={src}
              alt='preview' />
            <div
              className='image__close'
              onClick={() => setPhotos(p => p.filter((_, idx) => i !== idx))} />
          </div>
        })}
      </div>}

      <label>
        <span>+ {t('addPicture')}</span>
        <input
          ref={inputRef}
          type='file'
          accept='image/*'
          capture='camera'
          style={{ display: 'none' }}
          onChange={e => {
            const { files } = e.target
            if (files[0]) setPhotos(p => [...p, files[0]])
          }} />
      </label>
    </div>

    <div className='slide__buttons'>
      <BackBtn
        text={t('reportProblem')}
        action={() => push('/chat')} />

      <Submit
        text={t(photos.length > 0 ? 'next' : 'takePhoto')}
        action={() => photos.length > 0 ? next() : inputRef.current?.click()} />
    </div>

  </>
}

export default Photo