import categoriesReducer from './conteiners/Categories/categoriesSlice'
import mapDetailsReducer from './conteiners/MapDetails/mapDetailsSlice'
import warehousesReducer from './conteiners/Warehouses/warehousesSlice'
import mapSearchReducer from './conteiners/MapSearch/mapSearchSlice'
import locationReducer from './conteiners/Maps/Result/locationSlice'
import calendarReducer from './conteiners/Calendar/calendarSlice'
import filtersReducer from './conteiners/Maps/menu/filtersSlice'
import accountReducer from './conteiners/Account/accountSlice'
import basketReducer from './conteiners/Basket/basketSlice'
import loginReducer from './conteiners/Login/loginSlice'
import mapReducer from './conteiners/Maps/Map/mapSlice'
import formReducer from './conteiners/forms/formSlice'
import chatReducer from './conteiners/Chats/chatSlice'
import { loadState, saveState } from './utils/storage'
import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appSlice'

const preloadedState = loadState()

const reducer = {
  app: appReducer,
  account: accountReducer,
  basket: basketReducer,
  calendar: calendarReducer,
  categories: categoriesReducer,
  chat: chatReducer,
  filters: filtersReducer,
  form: formReducer,
  location: locationReducer,
  login: loginReducer,
  map: mapReducer,
  mapDetails: mapDetailsReducer,
  mapSearch: mapSearchReducer,
  warehouses: warehousesReducer,
}

const store = configureStore({
  preloadedState,
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

const getState = () => {
  const { app, account, login, warehouses, ...rest } = store.getState()
  return { ...rest }
}

store.subscribe(() => saveState(getState()))

export default store