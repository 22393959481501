import { ReactComponent as Logo } from '../../assets/logo.svg'
import { selectChatThreads } from './chatSlice'
import { useHistory } from 'react-router-dom'
import { MONTHS } from '../../utils/date'
import { useSelector } from 'react-redux'

const ChatList = ({ mobile = true, showOnlyActiveChats = false, showOnlyInactiveChats = false }) => {
  const threads = useSelector(selectChatThreads)
  const { push, replace } = useHistory()

  const todayList = (() => {
    const today = new Date()
    return [today.getDate(), today.getMonth() + 1, today.getFullYear()]
  })()

  return (
    <div className='chat__list'>
      {threads
        .filter(({ date }) => {
          if (showOnlyActiveChats) return !date.end
          else if (showOnlyInactiveChats) return !!date.end
          return true
        })
        .map(({ id, title, date, messages }) => {
          const lastMessage = messages.length > 0 ? messages.at(-1) : {}
          const dateList = lastMessage.date?.split('.')
          const isToday = dateList.length > 0 ? dateList.reduce((r, e, i) => r && todayList[i] === Number(e), true) : false

          return <div
            key={id}
            className='list__contact group'
            style={date.end ? { opacity: 0.5 } : {}}
            onClick={() => mobile ? push(`/chat/${id}`) : replace(`/chat/${id}`)}>

            <div className='contact__avatar center'>
              <Logo />
            </div>

            <div className='contact__desc'>
              <div className='--termmina'>{title || 'Obsługa'}</div>
              <div className='chat__text'>{lastMessage?.desc}...</div>
            </div>

            <div className='contact__time chat__text'>
              {isToday ? lastMessage?.time : dateList.length > 1 ? `${dateList[0]} ${MONTHS.short[dateList[1] - 1]}` : ''}
            </div>
          </div>
        })}
    </div>
  )
}

export default ChatList