import { ReactComponent as Logo } from '../../assets/logo.svg'
import useTranslation from '../../hooks/useTranslation'
import usePopupState from '../../hooks/usePopupState'
import Popup from '../../components/popups/Popup'

export const POPUPS = {
  types: 1,
  hallInfo: 2
}

const Popups = ({ close }) => {
  const { popup } = usePopupState()
  const { t } = useTranslation('categories')
  const differences = t('knowDifferencesArray')

  switch (popup) {
    case 1: return (
      <Popup close={close}>
        <div className='popup__title'>
          <Logo />
          <div>{t('typeOfServices')}</div>
          <div>{t('whatToChooseAndWhen')}</div>
        </div>

        <div className='popup__list'>
          <div className='list__title'>{t('knowDifferences')}</div>
          <ul>
            {Array.isArray(differences) ? differences.map((d, i) => <li key={i}>{d}</li>)
              : differences}
          </ul>
        </div>
      </Popup>
    )

    case 2: return (
      <Popup close={close}>
        <div className='popup__title'>
          <Logo />
          <div>Już wkrótce!</div>
          <div>Portal ogłoszeniowy dla większych magazynów np. hal</div>
        </div>
      </Popup>
    )

    default: return null
  }
}

export default Popups