import { authProtected, webauthn } from '../../rest/urls'
import Submit from '../../components/inputs/Submit'
import { useHistory } from 'react-router-dom'
import { POST } from '../../rest/request'
import * as WebAuth from './webauth'
import { v4 } from 'uuid'

const Test = () => {
  const { push } = useHistory()

  const getAuthOptions = async (register = true) => {
    try {
      const response = await POST(`${webauthn}${register ? 'keys' : 'auth'}/options`)
      return response
    }
    catch (err) {
      return null
    }
  }

  const sendAuthToServer = async (body, register = true) => {
    try {
      const response = await POST(`${webauthn}${register ? 'keys' : 'check'}`, { body })
      console.log(response)

    } catch (err) {
      console.error(`Error, ${err}`)
    }
  }

  const register = async () => {
    const authOptions = await getAuthOptions()

    if (authOptions) {
      try {
        WebAuth.register(
          authOptions?.publicKey,
          data => sendAuthToServer({ ...data, name: v4() })
        )

      } catch (err) {
        console.error(`Error: ${err}`)
      }
    }
  }

  const login = async () => {
    const authOptions = await getAuthOptions(false)

    if (authOptions) {
      try {
        WebAuth.sign(
          authOptions?.publicKey,
          data => sendAuthToServer(data, false)
        )

      } catch (err) {
        console.error(`Error: ${err}`)
      }
    }
  }

  const test = async () => {
    try {
      const response = await POST(authProtected)
      console.log(response)

    } catch (err) {
      try {
        const paresedError = JSON.parse(err)
        const { protected_by_webauthn, publicKey } = paresedError

        if (protected_by_webauthn && publicKey) push(window.location.pathname, { popup: 'webauthn', publicKey })
        else throw new Error()

      } catch (err) {
        console.error(`Parser error: ${err}`)
      }
    }
  }

  return (
    <div
      className='test'
      style={{
        margin: '2em',
        boxSizing: 'border-box'
      }}>

      <h2>Damian's playground</h2>

      <div className='group'>
        <Submit
          text='Zarejestruj'
          action={register}
        />

        <Submit
          text='Zaloguj'
          action={login}
        />

        <Submit
          text='Test'
          action={test}
        />
      </div>
    </div>
  )
}

export default Test