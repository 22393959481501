export const fields = [{
  label: 'Okres czasu',
  name: 'date',
  type: 'range',
  attr: {
    min: '2022-05-25',
    max: '2022-06-01',
  }
}, {
  label: 'Rodzaj akcji',
  name: 'action',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' },
    { name: 'Otwarcie', value: 'opening' },
    { name: 'Zamknięcie', value: 'closing' },
    { name: 'Alarm', value: 'alarm' }
  ]
}, {
  label: 'Uzytkownik',
  name: 'user',
  type: 'section',
  options: [
    { name: 'Wszyscy', value: '' },
    { name: 'Bartek', value: 'Bartek' },
    { name: 'Michał', value: 'Michał' },
    { name: 'Paweł', value: 'Paweł' }
  ]
}, {
  label: 'Magazyn',
  name: 'mag',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' }
  ]
}]