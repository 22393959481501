export const PROVINCES = [
  { name: 'Dolnośląskie', search: 'dolnośląskie', capitol: 'Wrocław', value: 0, gpslatitude: 51.06, gpslongitude: 17.01 },
  { name: 'Kujawsko-pomorskie', search: 'kujawsko-pomorskie', capitol: 'Toruń', value: 1, gpslatitude: 53, gpslongitude: 18.53 },
  { name: 'Lubelskie', search: 'lubelskie', capitol: 'Lublin', value: 2, gpslatitude: 51.14, gpslongitude: 22.34 },
  { name: 'Lubuskie', search: 'lubuskie', capitol: 'Gorzów Wielkopolski', value: 3, gpslatitude: 52.43, gpslongitude: 15.14 },
  { name: 'Łódzkie', search: 'łódzkie', capitol: 'Łódź', value: 4, gpslatitude: 51.46, gpslongitude: 19.27 },
  { name: 'Małopolskie', search: 'małopolskie', capitol: 'Kraków', value: 5, gpslatitude: 50.03, gpslongitude: 19.56 },
  { name: 'Mazowieckie', search: 'mazowieckie', capitol: 'Warszawa', value: 6, gpslatitude: 52.13, gpslongitude: 21 },
  { name: 'Opolskie', search: 'opolskie', capitol: 'Opole', value: 7, gpslatitude: 50.39, gpslongitude: 17.55 },
  { name: 'Podkarpackie', search: 'podkarpackie', capitol: 'Rzeszów', value: 8, gpslatitude: 50.02, gpslongitude: 22 },
  { name: 'Podlaskie', search: 'podlaskie', capitol: 'Białystok', value: 9, gpslatitude: 53.08, gpslongitude: 23.08 },
  { name: 'Pomorskie', search: 'pomorskie', capitol: 'Gdańsk', value: 10, gpslatitude: 54.20, gpslongitude: 18.38 },
  { name: 'Śląskie', search: 'śląskie', capitol: 'Katowice', value: 11, gpslatitude: 50.15, gpslongitude: 19.01 },
  { name: 'Świętokrzyskie', search: 'świętokrzyskie', capitol: 'Kielce', value: 12, gpslatitude: 50.53, gpslongitude: 20.37 },
  { name: 'Warmińsko-mazurskie', search: 'warmińsko-mazurskie', capitol: 'Olsztyn', value: 13, gpslatitude: 53.47, gpslongitude: 20.30 },
  { name: 'Wielkopolskie', search: 'wielkopolskie', capitol: 'Poznań', value: 14, gpslatitude: 52.24, gpslongitude: 16.56 },
  { name: 'Zachodniopomorskie', search: 'zachodniopomorskie', capitol: 'Szczecin', value: 15, gpslatitude: 53.26, gpslongitude: 14.32 },
]

export const CITIES = [
  [
    { city: 'Bielawa', district: 'dzierżoniowski', gpslatitude: 50.68988, gpslongitude: 16.62663, radius: 4.5 },
    { city: 'Bogatynia', district: 'zgorzelecki', gpslatitude: 50.91416, gpslongitude: 14.95655, radius: 3.75 },
    { city: 'Boguszów-Gorce', district: 'wałbrzyski', gpslatitude: 50.75229, gpslongitude: 16.20327, radius: 3.75 },
    { city: 'Bolesławiec', district: 'bolesławiecki', gpslatitude: 51.26247, gpslongitude: 15.56956, radius: 4.5 },
    { city: 'Brzeg Dolny', district: 'wołowski', gpslatitude: 51.26865, gpslongitude: 16.72659, radius: 3 },
    { city: 'Bystrzyca Kłodzka', district: 'kłodzki', gpslatitude: 50.29572, gpslongitude: 16.65185, radius: 3 },
    { city: 'Dzierżoniów', district: 'dzierżoniowski', gpslatitude: 50.72623, gpslongitude: 16.65407, radius: 4.5 },
    { city: 'Głogów', district: 'głogowski', gpslatitude: 51.66277, gpslongitude: 16.08749, radius: 5.25 },
    { city: 'Jawor', district: 'jaworski', gpslatitude: 51.05265, gpslongitude: 16.19509, radius: 4.5 },
    { city: 'Jelcz-Laskowice', district: 'oławski', gpslatitude: 51.02129, gpslongitude: 17.31648, radius: 3.75 },
    { city: 'Jelenia Góra', district: 'Jelenia Góra', gpslatitude: 50.8931, gpslongitude: 15.7052, radius: 5.25 },
    { city: 'Kamienna Góra', district: 'kamiennogórski', gpslatitude: 50.78363, gpslongitude: 16.0275, radius: 4.5 },
    { city: 'Kłodzko', district: 'kłodzki', gpslatitude: 50.43437, gpslongitude: 16.65733, radius: 4.5 },
    { city: 'Legnica', district: 'Legnica', gpslatitude: 51.20701, gpslongitude: 16.15532, radius: 6 },
    { city: 'Lubań', district: 'lubański', gpslatitude: 51.12389, gpslongitude: 15.2888, radius: 4.5 },
    { city: 'Lubin', district: 'lubiński', gpslatitude: 51.40026, gpslongitude: 16.20152, radius: 5.25 },
    { city: 'Milicz', district: 'milicki', gpslatitude: 51.52808, gpslongitude: 17.27191, radius: 3 },
    { city: 'Nowa Ruda', district: 'kłodzki', gpslatitude: 50.57929, gpslongitude: 16.5047, radius: 4.5 },
    { city: 'Oleśnica', district: 'oleśnicki', gpslatitude: 51.21263, gpslongitude: 17.39596, radius: 4.5 },
    { city: 'Oława', district: 'oławski', gpslatitude: 50.93848, gpslongitude: 17.30294, radius: 4.5 },
    { city: 'Polkowice', district: 'polkowicki', gpslatitude: 51.50341, gpslongitude: 16.0703, radius: 4.5 },
    { city: 'Strzegom', district: 'świdnicki', gpslatitude: 50.95928, gpslongitude: 16.34863, radius: 3.75 },
    { city: 'Strzelin', district: 'strzeliński', gpslatitude: 50.78333, gpslongitude: 17.06667, radius: 3 },
    { city: 'Świdnica', district: 'świdnicki', gpslatitude: 50.84051, gpslongitude: 16.4886, radius: 5.25 },
    { city: 'Świebodzice', district: 'świdnicki', gpslatitude: 50.85803, gpslongitude: 16.32151, radius: 4.5 },
    { city: 'Wałbrzych', district: 'Wałbrzych', gpslatitude: 50.784, gpslongitude: 16.2843, radius: 6 },
    { city: 'Wrocław', district: 'Wrocław', gpslatitude: 51.10195, gpslongitude: 17.03667, radius: 7.5 },
    { city: 'Ząbkowice Śląskie', district: 'ząbkowicki', gpslatitude: 50.59629, gpslongitude: 16.81108, radius: 3.75 },
    { city: 'Zgorzelec', district: 'zgorzelecki', gpslatitude: 51.14865, gpslongitude: 15.01585, radius: 4.5 },
    { city: 'Złotoryja', district: 'złotoryjski', gpslatitude: 51.12343, gpslongitude: 15.91757, radius: 3.75 }
  ], [
    { city: 'Aleksandrów Kujawski', district: 'aleksandrowski', gpslatitude: 52.87523, gpslongitude: 18.69046, radius: 3 },
    { city: 'Barcin', district: 'żniński', gpslatitude: 52.86387, gpslongitude: 17.94406, radius: 2.25 },
    { city: 'Białe Błota', district: 'bydgoski', gpslatitude: 53.09754, gpslongitude: 17.91819, radius: 2.25 },
    { city: 'Brodnica', district: 'brodnicki', gpslatitude: 53.25021, gpslongitude: 19.40285, radius: 4.5 },
    { city: 'Bydgoszcz', district: 'Bydgoszcz', gpslatitude: 53.1342, gpslongitude: 17.99035, radius: 7.5 },
    { city: 'Chełmno', district: 'chełmiński', gpslatitude: 53.35, gpslongitude: 18.43333, radius: 4.5 },
    { city: 'Chełmża', district: 'toruński', gpslatitude: 53.18478, gpslongitude: 18.606, radius: 3 },
    { city: 'Ciechocinek', district: 'aleksandrowski', gpslatitude: 52.86943, gpslongitude: 18.79115, radius: 3 },
    { city: 'Gniewkowo', district: 'inowrocławski', gpslatitude: 52.89387, gpslongitude: 18.41116, radius: 2.25 },
    { city: 'Golub-Dobrzyń', district: 'golubsko-dobrzyński', gpslatitude: 53.11187, gpslongitude: 19.05372, radius: 3 },
    { city: 'Grudziądz', district: 'Grudziądz', gpslatitude: 53.46843, gpslongitude: 18.74876, radius: 5.25 },
    { city: 'Inowrocław', district: 'inowrocławski', gpslatitude: 52.7935, gpslongitude: 18.26298, radius: 5.25 },
    { city: 'Janikowo', district: 'inowrocławski', gpslatitude: 52.74921, gpslongitude: 18.11518, radius: 2.25 },
    { city: 'Koronowo', district: 'bydgoski', gpslatitude: 53.31296, gpslongitude: 17.94148, radius: 3 },
    { city: 'Kruszwica', district: 'inowrocławski', gpslatitude: 52.66974, gpslongitude: 18.33116, radius: 2.25 },
    { city: 'Lipno', district: 'lipnowski', gpslatitude: 52.83782, gpslongitude: 19.17463, radius: 3 },
    { city: 'Mogilno', district: 'mogileński', gpslatitude: 52.65346, gpslongitude: 17.96175, radius: 3 },
    { city: 'Nakło nad Notecią', district: 'nakielski', gpslatitude: 53.14118, gpslongitude: 17.604, radius: 3.75 },
    { city: 'Nowe', district: 'świecki', gpslatitude: 53.64175, gpslongitude: 18.71849, radius: 2.25 },
    { city: 'Radziejów', district: 'radziejowski', gpslatitude: 52.62296, gpslongitude: 18.52907, radius: 2.25 },
    { city: 'Rypin', district: 'rypiński', gpslatitude: 53.06519, gpslongitude: 19.4157, radius: 3.75 },
    { city: 'Sępólno Krajeńskie', district: 'sępoleński', gpslatitude: 53.45469, gpslongitude: 17.53593, radius: 2.25 },
    { city: 'Solec Kujawski', district: 'bydgoski', gpslatitude: 53.07911, gpslongitude: 18.22321, radius: 3.75 },
    { city: 'Strzelno', district: 'mogileński', gpslatitude: 52.62999, gpslongitude: 18.17605, radius: 2.25 },
    { city: 'Świecie', district: 'świecki', gpslatitude: 53.40709, gpslongitude: 18.44439, radius: 4.5 },
    { city: 'Toruń', district: 'Toruń', gpslatitude: 53.00717, gpslongitude: 18.61052, radius: 6 },
    { city: 'Tuchola', district: 'tucholski', gpslatitude: 53.58351, gpslongitude: 17.86441, radius: 3 },
    { city: 'Wąbrzeźno', district: 'wąbrzeski', gpslatitude: 53.28104, gpslongitude: 18.94751, radius: 3 },
    { city: 'Włocławek', district: 'Włocławek', gpslatitude: 52.65975, gpslongitude: 19.07799, radius: 6 },
    { city: 'Żnin', district: 'żniński', gpslatitude: 52.848, gpslongitude: 17.73255, radius: 3 }
  ], [
    { city: 'Bełżyce', district: 'lubelski', gpslatitude: 51.17378, gpslongitude: 22.286, radius: 2.25 },
    { city: 'Biała Podlaska', district: 'Biała Podlaska', gpslatitude: 52.04282, gpslongitude: 23.11088, radius: 5.25 },
    { city: 'Biłgoraj', district: 'biłgorajski', gpslatitude: 50.53669, gpslongitude: 22.72358, radius: 4.5 },
    { city: 'Brzozowica Duża', district: 'radzyński', gpslatitude: 51.95068, gpslongitude: 22.62281, radius: 1.5 },
    { city: 'Bychawa', district: 'lubelski', gpslatitude: 51.01295, gpslongitude: 22.53739, radius: 2.25 },
    { city: 'Chełm', district: 'Chełm', gpslatitude: 51.13296, gpslongitude: 23.47468, radius: 5.25 },
    { city: 'Dęblin', district: 'rycki', gpslatitude: 51.56609, gpslongitude: 21.85935, radius: 3.75 },
    { city: 'Dys', district: 'lubelski', gpslatitude: 51.31432, gpslongitude: 22.57795, radius: 1.5 },
    { city: 'Gołąb', district: 'puławski', gpslatitude: 51.48709, gpslongitude: 21.87813, radius: 1.5 },
    { city: 'Hrubieszów', district: 'hrubieszowski', gpslatitude: 50.80882, gpslongitude: 23.8979, radius: 3.75 },
    { city: 'Janów Lubelski', district: 'janowski', gpslatitude: 50.70599, gpslongitude: 22.4106, radius: 3 },
    { city: 'Krasnystaw', district: 'krasnostawski', gpslatitude: 50.98628, gpslongitude: 23.17521, radius: 3.75 },
    { city: 'Kraśnik', district: 'kraśnicki', gpslatitude: 50.92237, gpslongitude: 22.22704, radius: 4.5 },
    { city: 'Lubartów', district: 'lubartowski', gpslatitude: 51.45928, gpslongitude: 22.60895, radius: 4.5 },
    { city: 'Lublin', district: 'Lublin', gpslatitude: 51.23955, gpslongitude: 22.55257, radius: 6.75 },
    { city: 'Łęczna', district: 'łęczyński', gpslatitude: 51.30222, gpslongitude: 22.87957, radius: 4.5 },
    { city: 'Łuków', district: 'łukowski', gpslatitude: 51.93176, gpslongitude: 22.37845, radius: 4.5 },
    { city: 'Międzyrzec Podlaski', district: 'bialski', gpslatitude: 51.98589, gpslongitude: 22.78325, radius: 3.75 },
    { city: 'Opole Lubelskie', district: 'opolski', gpslatitude: 51.14663, gpslongitude: 21.96701, radius: 2.25 },
    { city: 'Parczew', district: 'parczewski', gpslatitude: 51.63806, gpslongitude: 22.91263, radius: 3 },
    { city: 'Poniatowa', district: 'opolski', gpslatitude: 51.19099, gpslongitude: 22.06949, radius: 3 },
    { city: 'Puławy', district: 'puławski', gpslatitude: 51.42209, gpslongitude: 21.95676, radius: 5.25 },
    { city: 'Radzyń Podlaski', district: 'radzyński', gpslatitude: 51.78528, gpslongitude: 22.6237, radius: 3.75 },
    { city: 'Ryki', district: 'rycki', gpslatitude: 51.62642, gpslongitude: 21.93399, radius: 3 },
    { city: 'Szczebrzeszyn', district: 'zamojski', gpslatitude: 50.6944, gpslongitude: 22.98319, radius: 2.25 },
    { city: 'Świdnik', district: 'świdnicki', gpslatitude: 51.21368, gpslongitude: 22.6982, radius: 4.5 },
    { city: 'Terespol', district: 'bialski', gpslatitude: 52.0729, gpslongitude: 23.60896, radius: 2.25 },
    { city: 'Tomaszów Lubelski', district: 'tomaszowski', gpslatitude: 50.44568, gpslongitude: 23.41846, radius: 4.5 },
    { city: 'Włodawa', district: 'włodawski', gpslatitude: 51.55029, gpslongitude: 23.5528, radius: 3 },
    { city: 'Zamość', district: 'Zamość', gpslatitude: 50.72105, gpslongitude: 23.26006, radius: 5.25 }
  ], [
    { city: 'Brójce', district: 'międzyrzecki', gpslatitude: 52.3182, gpslongitude: 15.67718, radius: 1.5 },
    { city: 'Bytom Odrzański', district: 'nowosolski', gpslatitude: 51.73095, gpslongitude: 15.82706, radius: 1.5 },
    { city: 'Drezdenko', district: 'strzelecko-drezdenecki', gpslatitude: 52.83655, gpslongitude: 15.83316, radius: 3 },
    { city: 'Gorzów Wielkopolski', district: 'Gorzów Wielkopolski', gpslatitude: 52.72918, gpslongitude: 15.24028, radius: 6 },
    { city: 'Gozdnica', district: 'żagański', gpslatitude: 51.43689, gpslongitude: 15.09736, radius: 1.5 },
    { city: 'Gubin', district: 'krośnieński', gpslatitude: 51.94796, gpslongitude: 14.73707, radius: 3.75 },
    { city: 'Janczewo', district: 'gorzowski', gpslatitude: 52.75608, gpslongitude: 15.34848, radius: 1.5 },
    { city: 'Klenica', district: 'zielonogórski', gpslatitude: 51.99154, gpslongitude: 15.78431, radius: 1.5 },
    { city: 'Kostrzyn nad Odrą', district: 'gorzowski', gpslatitude: 52.58761, gpslongitude: 14.65188, radius: 3.75 },
    { city: 'Kożuchów', district: 'nowosolski', gpslatitude: 51.74403, gpslongitude: 15.59733, radius: 2.25 },
    { city: 'Krosno Odrzańskie', district: 'krośnieński', gpslatitude: 52.05004, gpslongitude: 15.09726, radius: 3 },
    { city: 'Lipki Wielkie', district: 'gorzowski', gpslatitude: 52.72501, gpslongitude: 15.53256, radius: 1.5 },
    { city: 'Lubsko', district: 'żarski', gpslatitude: 51.78453, gpslongitude: 14.97317, radius: 3 },
    { city: 'Międzyrzecz', district: 'międzyrzecki', gpslatitude: 52.44649, gpslongitude: 15.57687, radius: 3.75 },
    { city: 'Nowa Sól', district: 'nowosolski', gpslatitude: 51.80328, gpslongitude: 15.7215, radius: 4.5 },
    { city: 'Nowogród Bobrzański', district: 'zielonogórski', gpslatitude: 51.79805, gpslongitude: 15.2388, radius: 2.25 },
    { city: 'Rzepin', district: 'słubicki', gpslatitude: 52.34816, gpslongitude: 14.83096, radius: 2.25 },
    { city: 'Skwierzyna', district: 'międzyrzecki', gpslatitude: 52.59321, gpslongitude: 15.49977, radius: 3.12908 },
    { city: 'Słubice', district: 'słubicki', gpslatitude: 52.35254, gpslongitude: 14.57418, radius: 3.75 },
    { city: 'Strzelce Krajeńskie', district: 'strzelecko-drezdenecki', gpslatitude: 52.87728, gpslongitude: 15.53251, radius: 3 },
    { city: 'Sulechów', district: 'zielonogórski', gpslatitude: 52.08394, gpslongitude: 15.62267, radius: 3.75 },
    { city: 'Sulęcin', district: 'sulęciński', gpslatitude: 52.44214, gpslongitude: 15.11792, radius: 3 },
    { city: 'Szprotawa', district: 'żagański', gpslatitude: 51.564, gpslongitude: 15.5362, radius: 3 },
    { city: 'Świebodzin', district: 'świebodziński', gpslatitude: 52.2479, gpslongitude: 15.53585, radius: 4.5 },
    { city: 'Witnica', district: 'gorzowski', gpslatitude: 52.67311, gpslongitude: 14.89903, radius: 2.25 },
    { city: 'Wschowa', district: 'wschowski', gpslatitude: 51.8023, gpslongitude: 16.31961, radius: 3 },
    { city: 'Zbąszynek', district: 'świebodziński', gpslatitude: 52.24454, gpslongitude: 15.81807, radius: 2.25 },
    { city: 'Zielona Góra', district: 'Zielona Góra', gpslatitude: 51.94633, gpslongitude: 15.49585, radius: 6 },
    { city: 'Żagań', district: 'żagański', gpslatitude: 51.61666, gpslongitude: 15.31667, radius: 4.5 },
    { city: 'Żary', district: 'żarski', gpslatitude: 51.64034, gpslongitude: 15.12432, radius: 4.5 }
  ], [
    { city: 'Aleksandrów Łódzki', district: 'zgierski', gpslatitude: 51.81836, gpslongitude: 19.30549, radius: 4.5 },
    { city: 'Bełchatów', district: 'bełchatowski', gpslatitude: 51.36614, gpslongitude: 19.37201, radius: 5.25 },
    { city: 'Brzeziny', district: 'brzeziński', gpslatitude: 51.79951, gpslongitude: 19.75364, radius: 3 },
    { city: 'Działoszyn', district: 'pajęczański', gpslatitude: 51.11666, gpslongitude: 18.86667, radius: 2.25 },
    { city: 'Głowno', district: 'zgierski', gpslatitude: 51.96207, gpslongitude: 19.71038, radius: 3.75 },
    { city: 'Koluszki', district: 'łódzki wschodni', gpslatitude: 51.74378, gpslongitude: 19.82106, radius: 3 },
    { city: 'Konstantynów Łódzki', district: 'pabianicki', gpslatitude: 51.74644, gpslongitude: 19.32674, radius: 3.75 },
    { city: 'Kutno', district: 'kutnowski', gpslatitude: 52.23188, gpslongitude: 19.35362, radius: 4.5 },
    { city: 'Łask', district: 'łaski', gpslatitude: 51.59124, gpslongitude: 19.13231, radius: 3.75 },
    { city: 'Łęczyca', district: 'łęczycki', gpslatitude: 52.06173, gpslongitude: 19.18911, radius: 3.75 },
    { city: 'Łowicz', district: 'łowicki', gpslatitude: 52.1109, gpslongitude: 19.95112, radius: 4.5 },
    { city: 'Łódź', district: 'Łódź', gpslatitude: 51.75949, gpslongitude: 19.4318, radius: 7.5 },
    { city: 'Opoczno', district: 'opoczyński', gpslatitude: 51.37765, gpslongitude: 20.28672, radius: 4.5 },
    { city: 'Ozorków', district: 'zgierski', gpslatitude: 51.96938, gpslongitude: 19.29287, radius: 4.5 },
    { city: 'Pabianice', district: 'pabianicki', gpslatitude: 51.6637, gpslongitude: 19.35685, radius: 5.25 },
    { city: 'Pajęczno', district: 'pajęczański', gpslatitude: 51.14574, gpslongitude: 18.99867, radius: 2.25 },
    { city: 'Piotrków Trybunalski', district: 'Piotrków Trybunalski', gpslatitude: 51.40776, gpslongitude: 19.69326, radius: 5.25 },
    { city: 'Poddębice', district: 'poddębicki', gpslatitude: 51.89421, gpslongitude: 18.95667, radius: 2.25 },
    { city: 'Radomsko', district: 'radomszczański', gpslatitude: 51.06754, gpslongitude: 19.44224, radius: 4.5 },
    { city: 'Rawa Mazowiecka', district: 'rawski', gpslatitude: 51.76655, gpslongitude: 20.25396, radius: 3.75 },
    { city: 'Sieradz', district: 'sieradzki', gpslatitude: 51.59442, gpslongitude: 18.73288, radius: 4.5 },
    { city: 'Skierniewice', district: 'Skierniewice', gpslatitude: 51.95949, gpslongitude: 20.14328, radius: 4.5 },
    { city: 'Sulejów', district: 'piotrkowski', gpslatitude: 51.35318, gpslongitude: 19.88661, radius: 2.25 },
    { city: 'Tomaszów Mazowiecki', district: 'tomaszowski', gpslatitude: 51.53174, gpslongitude: 20.01097, radius: 5.25 },
    { city: 'Tuszyn', district: 'łódzki wschodni', gpslatitude: 51.60254, gpslongitude: 19.54667, radius: 2.25 },
    { city: 'Wieluń', district: 'wieluński', gpslatitude: 51.22041, gpslongitude: 18.57114, radius: 4.5 },
    { city: 'Zduńska Wola', district: 'zduńskowolski', gpslatitude: 51.60113, gpslongitude: 18.93772, radius: 4.5 },
    { city: 'Zelów', district: 'bełchatowski', gpslatitude: 51.46667, gpslongitude: 19.21667, radius: 2.25 },
    { city: 'Zgierz', district: 'zgierski', gpslatitude: 51.85836, gpslongitude: 19.40687, radius: 5.25 },
    { city: 'Żychlin', district: 'kutnowski', gpslatitude: 52.24249, gpslongitude: 19.63822, radius: 2.25 }
  ], [
    { city: 'Andrychów', district: 'wadowicki', gpslatitude: 49.85425, gpslongitude: 19.34213, radius: 4.5 },
    { city: 'Bochnia', district: 'bocheński', gpslatitude: 49.96765, gpslongitude: 20.43205, radius: 4.5 },
    { city: 'Brzesko', district: 'brzeski', gpslatitude: 49.96744, gpslongitude: 20.60817, radius: 3.75 },
    { city: 'Brzeszcze', district: 'oświęcimski', gpslatitude: 49.98318, gpslongitude: 19.14645, radius: 3 },
    { city: 'Bukowno', district: 'olkuski', gpslatitude: 50.26523, gpslongitude: 19.44956, radius: 3 },
    { city: 'Chocznia', district: 'wadowicki', gpslatitude: 49.87276, gpslongitude: 19.45808, radius: 2.25 },
    { city: 'Chrzanów', district: 'chrzanowski', gpslatitude: 50.13958, gpslongitude: 19.40482, radius: 4.5 },
    { city: 'Dąbrowa Tarnowska', district: 'dąbrowski', gpslatitude: 50.17459, gpslongitude: 20.98753, radius: 3 },
    { city: 'Gorlice', district: 'gorlicki', gpslatitude: 49.65661, gpslongitude: 21.16355, radius: 4.5 },
    { city: 'Kęty', district: 'oświęcimski', gpslatitude: 49.88103, gpslongitude: 19.22331, radius: 3.75 },
    { city: 'Kraków', district: 'Kraków', gpslatitude: 50.07567, gpslongitude: 19.93084, radius: 7.5 },
    { city: 'Krynica-Zdrój', district: 'nowosądecki', gpslatitude: 49.42088, gpslongitude: 20.96066, radius: 3 },
    { city: 'Krzeszowice', district: 'krakowski', gpslatitude: 50.13497, gpslongitude: 19.63131, radius: 3 },
    { city: 'Libiąż', district: 'chrzanowski', gpslatitude: 50.09915, gpslongitude: 19.31389, radius: 3.75 },
    { city: 'Limanowa', district: 'limanowski', gpslatitude: 49.70899, gpslongitude: 20.42225, radius: 3.75 },
    { city: 'Miechów', district: 'miechowski', gpslatitude: 50.35, gpslongitude: 20.03333, radius: 3 },
    { city: 'Myślenice', district: 'myślenicki', gpslatitude: 49.83378, gpslongitude: 19.94242, radius: 3.75 },
    { city: 'Nowy Sącz', district: 'Nowy Sącz', gpslatitude: 49.6294, gpslongitude: 20.69075, radius: 5.25 },
    { city: 'Nowy Targ', district: 'nowotarski', gpslatitude: 49.48062, gpslongitude: 20.03047, radius: 4.5 },
    { city: 'Olkusz', district: 'olkuski', gpslatitude: 50.27441, gpslongitude: 19.57633, radius: 4.5 },
    { city: 'Oświęcim', district: 'oświęcimski', gpslatitude: 50.03574, gpslongitude: 19.22832, radius: 4.5 },
    { city: 'Rabka-Zdrój', district: 'nowotarski', gpslatitude: 49.60798, gpslongitude: 19.97072, radius: 3 },
    { city: 'Skawina', district: 'krakowski', gpslatitude: 49.97582, gpslongitude: 19.82095, radius: 4.5 },
    { city: 'Stary Sącz', district: 'nowosądecki', gpslatitude: 49.56054, gpslongitude: 20.63727, radius: 2.25 },
    { city: 'Tarnów', district: 'Tarnów', gpslatitude: 50.01788, gpslongitude: 20.98269, radius: 4.33528 },
    { city: 'Trzebinia', district: 'chrzanowski', gpslatitude: 50.15554, gpslongitude: 19.46795, radius: 3.75 },
    { city: 'Wadowice', district: 'wadowicki', gpslatitude: 49.88272, gpslongitude: 19.48451, radius: 3.75 },
    { city: 'Wieliczka', district: 'wielicki', gpslatitude: 49.98608, gpslongitude: 20.06512, radius: 3.75 },
    { city: 'Wolbrom', district: 'olkuski', gpslatitude: 50.38422, gpslongitude: 19.7561, radius: 2.25 },
    { city: 'Zakopane', district: 'tatrzański', gpslatitude: 49.29631, gpslongitude: 19.94747, radius: 4.5 }
  ], [
    { city: 'Ciechanów', district: 'ciechanowski', gpslatitude: 52.88043, gpslongitude: 20.61429, radius: 4.5 },
    { city: 'Gostynin', district: 'gostyniński', gpslatitude: 52.42707, gpslongitude: 19.46384, radius: 3.75 },
    { city: 'Grodzisk Mazowiecki', district: 'grodziski', gpslatitude: 52.10206, gpslongitude: 20.62373, radius: 4.5 },
    { city: 'Józefów', district: 'otwocki', gpslatitude: 52.14122, gpslongitude: 21.22836, radius: 3.75 },
    { city: 'Kozienice', district: 'kozienicki', gpslatitude: 51.59082, gpslongitude: 21.54125, radius: 3.75 },
    { city: 'Legionowo', district: 'legionowski', gpslatitude: 52.41034, gpslongitude: 20.91834, radius: 5.25 },
    { city: 'Marki', district: 'wołomiński', gpslatitude: 52.32129, gpslongitude: 21.10589, radius: 4.5 },
    { city: 'Mińsk Mazowiecki', district: 'miński', gpslatitude: 52.1807, gpslongitude: 21.55615, radius: 4.5 },
    { city: 'Mława', district: 'mławski', gpslatitude: 53.11032, gpslongitude: 20.35166, radius: 4.5 },
    { city: 'Nowy Dwór Mazowiecki', district: 'nowodworski', gpslatitude: 52.4523, gpslongitude: 20.71922, radius: 4.5 },
    { city: 'Ostrołęka', district: 'Ostrołęka', gpslatitude: 53.07736, gpslongitude: 21.55409, radius: 5.25 },
    { city: 'Ostrów Mazowiecka', district: 'ostrowski', gpslatitude: 52.80201, gpslongitude: 21.89205, radius: 4.5 },
    { city: 'Otwock', district: 'otwocki', gpslatitude: 52.10301, gpslongitude: 21.2582, radius: 4.5 },
    { city: 'Piaseczno', district: 'piaseczyński', gpslatitude: 52.07434, gpslongitude: 21.02062, radius: 4.5 },
    { city: 'Piastów', district: 'pruszkowski', gpslatitude: 52.18444, gpslongitude: 20.83997, radius: 4.5 },
    { city: 'Pionki', district: 'radomski', gpslatitude: 51.46795, gpslongitude: 21.44215, radius: 4.5 },
    { city: 'Płock', district: 'Płock', gpslatitude: 52.54635, gpslongitude: 19.70654, radius: 6 },
    { city: 'Płońsk', district: 'płoński', gpslatitude: 52.62115, gpslongitude: 20.37632, radius: 4.5 },
    { city: 'Pruszków', district: 'pruszkowski', gpslatitude: 52.17004, gpslongitude: 20.80789, radius: 2.24833 },
    { city: 'Pułtusk', district: 'pułtuski', gpslatitude: 52.70946, gpslongitude: 21.08212, radius: 3.75 },
    { city: 'Radom', district: 'Radom', gpslatitude: 51.40071, gpslongitude: 21.14834, radius: 26.4102 },
    { city: 'Siedlce', district: 'Siedlce', gpslatitude: 52.16823, gpslongitude: 22.29119, radius: 5.25 },
    { city: 'Sierpc', district: 'sierpecki', gpslatitude: 52.85417, gpslongitude: 19.67141, radius: 3.75 },
    { city: 'Sochaczew', district: 'sochaczewski', gpslatitude: 52.22891, gpslongitude: 20.24062, radius: 4.5 },
    { city: 'Sokołów Podlaski', district: 'sokołowski', gpslatitude: 52.40809, gpslongitude: 22.24062, radius: 3.75 },
    { city: 'Warszawa', district: 'Warszawa', gpslatitude: 52.23614, gpslongitude: 21.00817, radius: 27.9909 },
    { city: 'Wołomin', district: 'wołomiński', gpslatitude: 52.34547, gpslongitude: 21.23894, radius: 4.5 },
    { city: 'Wyszków', district: 'wyszkowski', gpslatitude: 52.59905, gpslongitude: 21.4592, radius: 4.5 },
    { city: 'Ząbki', district: 'wołomiński', gpslatitude: 52.28983, gpslongitude: 21.11357, radius: 4.5 },
    { city: 'Żyrardów', district: 'żyrardowski', gpslatitude: 52.04904, gpslongitude: 20.44463, radius: 4.5 }
  ], [
    { city: 'Branice', district: 'głubczycki', gpslatitude: 50.05043, gpslongitude: 17.79613, radius: 1.5 },
    { city: 'Brzeg', district: 'brzeski', gpslatitude: 50.86138, gpslongitude: 17.47117, radius: 4.5 },
    { city: 'Głogówek', district: 'prudnicki', gpslatitude: 50.35235, gpslongitude: 17.86701, radius: 2.25 },
    { city: 'Głubczyce', district: 'głubczycki', gpslatitude: 50.20064, gpslongitude: 17.83074, radius: 3 },
    { city: 'Głuchołazy', district: 'nyski', gpslatitude: 50.31945, gpslongitude: 17.38355, radius: 3 },
    { city: 'Gogolin', district: 'krapkowicki', gpslatitude: 50.49264, gpslongitude: 18.02078, radius: 2.25 },
    { city: 'Gracze', district: 'opolski', gpslatitude: 50.69543, gpslongitude: 17.55841, radius: 1.5 },
    { city: 'Grodków', district: 'brzeski', gpslatitude: 50.69855, gpslongitude: 17.38485, radius: 2.25 },
    { city: 'Januszkowice', district: 'krapkowicki', gpslatitude: 50.39198, gpslongitude: 18.13806, radius: 1.5 },
    { city: 'Kamienica', district: 'nyski', gpslatitude: 50.45015, gpslongitude: 16.95389, radius: 1.5 },
    { city: 'Karłowice', district: 'opolski', gpslatitude: 50.87467, gpslongitude: 17.71211, radius: 1.5 },
    { city: 'Kędzierzyn-Koźle', district: 'kędzierzyńsko-kozielski', gpslatitude: 50.34533, gpslongitude: 18.17601, radius: 5.25 },
    { city: 'Kietrz', district: 'głubczycki', gpslatitude: 50.08383, gpslongitude: 18.00464, radius: 2.25 },
    { city: 'Kluczbork', district: 'kluczborski', gpslatitude: 50.97266, gpslongitude: 18.21523, radius: 4.5 },
    { city: 'Krapkowice', district: 'krapkowicki', gpslatitude: 50.47594, gpslongitude: 17.95164, radius: 3.75 },
    { city: 'Lewin Brzeski', district: 'brzeski', gpslatitude: 50.74775, gpslongitude: 17.62224, radius: 2.25 },
    { city: 'Namysłów', district: 'namysłowski', gpslatitude: 51.07313, gpslongitude: 17.72473, radius: 3.75 },
    { city: 'Niemodlin', district: 'opolski', gpslatitude: 50.64122, gpslongitude: 17.61752, radius: 2.25 },
    { city: 'Nysa', district: 'nyski', gpslatitude: 50.47625, gpslongitude: 17.33254, radius: 4.5 },
    { city: 'Olesno', district: 'oleski', gpslatitude: 50.87536, gpslongitude: 18.4172, radius: 2.25 },
    { city: 'Opole', district: 'Opole', gpslatitude: 50.66987, gpslongitude: 17.91934, radius: 6 },
    { city: 'Otmuchów', district: 'nyski', gpslatitude: 50.46371, gpslongitude: 17.17536, radius: 2.25 },
    { city: 'Ozimek', district: 'opolski', gpslatitude: 50.67997, gpslongitude: 18.22295, radius: 2.25 },
    { city: 'Paczków', district: 'nyski', gpslatitude: 50.46245, gpslongitude: 17.00951, radius: 2.25 },
    { city: 'Praszka', district: 'oleski', gpslatitude: 51.05747, gpslongitude: 18.46793, radius: 2.25 },
    { city: 'Prudnik', district: 'prudnicki', gpslatitude: 50.32856, gpslongitude: 17.57751, radius: 4.5 },
    { city: 'Strzelce Opolskie', district: 'strzelecki', gpslatitude: 50.51342, gpslongitude: 18.30473, radius: 3.75 },
    { city: 'Wołczyn', district: 'kluczborski', gpslatitude: 51.01667, gpslongitude: 18.05, radius: 2.25 },
    { city: 'Zawadzkie', district: 'strzelecki', gpslatitude: 50.60833, gpslongitude: 18.4795, radius: 2.25 },
    { city: 'Zdzieszowice', district: 'krapkowicki', gpslatitude: 50.42373, gpslongitude: 18.12338, radius: 3 }
  ], [
    { city: 'Boguchwała', district: 'rzeszowski', gpslatitude: 49.98346, gpslongitude: 21.93941, radius: 2.25 },
    { city: 'Brzozów', district: 'brzozowski', gpslatitude: 49.69465, gpslongitude: 22.01978, radius: 2.25 },
    { city: 'Dębica', district: 'dębicki', gpslatitude: 50.04866, gpslongitude: 21.42131, radius: 4.5 },
    { city: 'Dynów', district: 'rzeszowski', gpslatitude: 49.81667, gpslongitude: 22.23333, radius: 2.25 },
    { city: 'Głogów Małopolski', district: 'rzeszowski', gpslatitude: 50.15, gpslongitude: 21.96667, radius: 2.25 },
    { city: 'Gorzyce', district: 'tarnobrzeski', gpslatitude: 50.65915, gpslongitude: 21.8457, radius: 2.25 },
    { city: 'Jarosław', district: 'jarosławski', gpslatitude: 50.0158, gpslongitude: 22.68024, radius: 4.6 },
    { city: 'Jasło', district: 'jasielski', gpslatitude: 49.74074, gpslongitude: 21.46831, radius: 4.5 },
    { city: 'Jedlicze', district: 'krośnieński', gpslatitude: 49.71657, gpslongitude: 21.65213, radius: 2.25 },
    { city: 'Kolbuszowa', district: 'kolbuszowski', gpslatitude: 50.24322, gpslongitude: 21.77589, radius: 2.25 },
    { city: 'Korczyna', district: 'krośnieński', gpslatitude: 49.71645, gpslongitude: 21.81235, radius: 2.25 },
    { city: 'Krosno', district: 'Krosno', gpslatitude: 49.69371, gpslongitude: 21.76972, radius: 4.5 },
    { city: 'Lesko', district: 'leski', gpslatitude: 49.47084, gpslongitude: 22.3314, radius: 2.25 },
    { city: 'Leżajsk', district: 'leżajski', gpslatitude: 50.25494, gpslongitude: 22.42323, radius: 3 },
    { city: 'Lubaczów', district: 'lubaczowski', gpslatitude: 50.15566, gpslongitude: 23.13508, radius: 3 },
    { city: 'Łańcut', district: 'łańcucki', gpslatitude: 50.06822, gpslongitude: 22.23178, radius: 3.75 },
    { city: 'Mielec', district: 'mielecki', gpslatitude: 50.28828, gpslongitude: 21.42617, radius: 5.25 },
    { city: 'Nisko', district: 'niżański', gpslatitude: 50.51755, gpslongitude: 22.14474, radius: 3.75 },
    { city: 'Nowa Dęba', district: 'tarnobrzeski', gpslatitude: 50.42853, gpslongitude: 21.75233, radius: 3 },
    { city: 'Nowa Sarzyna', district: 'leżajski', gpslatitude: 50.32502, gpslongitude: 22.33555, radius: 2.25 },
    { city: 'Przemyśl', district: 'Przemyśl', gpslatitude: 49.78376, gpslongitude: 22.76782, radius: 5.25 },
    { city: 'Przeworsk', district: 'przeworski', gpslatitude: 50.05518, gpslongitude: 22.48941, radius: 3.75 },
    { city: 'Radymno', district: 'jarosławski', gpslatitude: 49.94648, gpslongitude: 22.82475, radius: 2.25 },
    { city: 'Rakszawa', district: 'łańcucki', gpslatitude: 50.16092, gpslongitude: 22.23819, radius: 2.25 },
    { city: 'Ropczyce', district: 'ropczycko-sędziszowski', gpslatitude: 50.05283, gpslongitude: 21.60841, radius: 3.75 },
    { city: 'Rzeszów', district: 'Rzeszów', gpslatitude: 50.04152, gpslongitude: 22.00953, radius: 6 },
    { city: 'Sanok', district: 'sanocki', gpslatitude: 49.55815, gpslongitude: 22.20117, radius: 4.5 },
    { city: 'Sędziszów Małopolski', district: 'ropczycko-sędziszowski', gpslatitude: 50.07126, gpslongitude: 21.7, radius: 2.25 },
    { city: 'Stalowa Wola', district: 'stalowowolski', gpslatitude: 50.58448, gpslongitude: 22.05793, radius: 5.25 },
    { city: 'Tarnobrzeg', district: 'Tarnobrzeg', gpslatitude: 50.56884, gpslongitude: 21.6735, radius: 4.5 }
  ], [
    { city: 'Augustów', district: 'augustowski', gpslatitude: 53.84344, gpslongitude: 22.9796, radius: 4.5 },
    { city: 'Białystok', district: 'Białystok', gpslatitude: 53.12988, gpslongitude: 23.16996, radius: 6 },
    { city: 'Bielsk Podlaski', district: 'bielski', gpslatitude: 52.7685, gpslongitude: 23.18625, radius: 10.1481 },
    { city: 'Brańsk', district: 'bielski', gpslatitude: 52.74302, gpslongitude: 22.84123, radius: 1.5 },
    { city: 'Choroszcz', district: 'białostocki', gpslatitude: 53.14442, gpslongitude: 22.99087, radius: 2.25 },
    { city: 'Czarna Białostocka', district: 'białostocki', gpslatitude: 53.30522, gpslongitude: 23.27235, radius: 2.25 },
    { city: 'Czeremcha', district: 'hajnowski', gpslatitude: 52.51667, gpslongitude: 23.35, radius: 1.5 },
    { city: 'Dąbrowa Białostocka', district: 'sokólski', gpslatitude: 53.65377, gpslongitude: 23.35091, radius: 2.25 },
    { city: 'Drohiczyn', district: 'siemiatycki', gpslatitude: 52.39866, gpslongitude: 22.6601, radius: 1.5 },
    { city: 'Grajewo', district: 'grajewski', gpslatitude: 53.6299, gpslongitude: 22.45613, radius: 4.5 },
    { city: 'Hajnówka', district: 'hajnowski', gpslatitude: 52.74674, gpslongitude: 23.57178, radius: 4.5 },
    { city: 'Kleosin', district: 'białostocki', gpslatitude: 53.09688, gpslongitude: 23.11793, radius: 1.5 },
    { city: 'Kolno', district: 'kolneński', gpslatitude: 53.40787, gpslongitude: 21.93442, radius: 3 },
    { city: 'Łapy', district: 'białostocki', gpslatitude: 52.99095, gpslongitude: 22.88709, radius: 3.75 },
    { city: 'Łomża', district: 'Łomża', gpslatitude: 53.17341, gpslongitude: 22.04729, radius: 5.25 },
    { city: 'Mońki', district: 'moniecki', gpslatitude: 53.40525, gpslongitude: 22.79995, radius: 3 },
    { city: 'Narew', district: 'hajnowski', gpslatitude: 52.9119, gpslongitude: 23.52112, radius: 1.5 },
    { city: 'Radziłów', district: 'grajewski', gpslatitude: 53.40992, gpslongitude: 22.41018, radius: 1.5 },
    { city: 'Rudka', district: 'bielski', gpslatitude: 52.72191, gpslongitude: 22.73271, radius: 1.5 },
    { city: 'Rutki-Kossaki', district: 'zambrowski', gpslatitude: 53.08808, gpslongitude: 22.442, radius: 1.5 },
    { city: 'Sejny', district: 'sejneński', gpslatitude: 54.10682, gpslongitude: 23.35052, radius: 2.25 },
    { city: 'Siemiatycze', district: 'siemiatycki', gpslatitude: 52.42703, gpslongitude: 22.86909, radius: 3.75 },
    { city: 'Sokółka', district: 'sokólski', gpslatitude: 53.40539, gpslongitude: 23.50562, radius: 3.75 },
    { city: 'Stare Kupiski', district: 'łomżyński', gpslatitude: 53.19173, gpslongitude: 21.99647, radius: 1.5 },
    { city: 'Suwałki', district: 'Suwałki', gpslatitude: 54.10253, gpslongitude: 22.92554, radius: 5.25 },
    { city: 'Szumowo', district: 'zambrowski', gpslatitude: 52.9202, gpslongitude: 22.08645, radius: 1.5 },
    { city: 'Wasilków', district: 'białostocki', gpslatitude: 53.19724, gpslongitude: 23.20935, radius: 2.25 },
    { city: 'Wąsosz', district: 'grajewski', gpslatitude: 53.52188, gpslongitude: 22.3196, radius: 1.5 },
    { city: 'Wysokie Mazowieckie', district: 'wysokomazowiecki', gpslatitude: 52.9168, gpslongitude: 22.51139, radius: 2.25 },
    { city: 'Zambrów', district: 'zambrowski', gpslatitude: 52.98537, gpslongitude: 22.24458, radius: 4.5 }
  ], [
    { city: 'Bydgoszcz', district: 'bytowski', gpslatitude: 53.973, gpslongitude: 17.26517, radius: 7.5 },
    { city: 'Bytów', district: 'bytowski', gpslatitude: 54.16892, gpslongitude: 17.4916, radius: 3.75 },
    { city: 'Chojnice', district: 'chojnicki', gpslatitude: 53.68751, gpslongitude: 17.5744, radius: 4.5 },
    { city: 'Czarne', district: 'człuchowski', gpslatitude: 53.68293, gpslongitude: 16.94003, radius: 2.25 },
    { city: 'Człuchów', district: 'człuchowski', gpslatitude: 53.66626, gpslongitude: 17.36262, radius: 3 },
    { city: 'Dzierzgoń', district: 'sztumski', gpslatitude: 53.92068, gpslongitude: 19.35025, radius: 2.25 },
    { city: 'Gdańsk', district: 'Gdańsk', gpslatitude: 54.37156, gpslongitude: 18.62303, radius: 8.09758 },
    { city: 'Gdynia', district: 'Gdynia', gpslatitude: 54.54361, gpslongitude: 18.46015, radius: 6 },
    { city: 'Kartuzy', district: 'kartuski', gpslatitude: 54.33758, gpslongitude: 18.18855, radius: 3.75 },
    { city: 'Kościerzyna', district: 'kościerski', gpslatitude: 54.1264, gpslongitude: 17.98301, radius: 4.5 },
    { city: 'Kwidzyn', district: 'kwidzyński', gpslatitude: 53.72788, gpslongitude: 18.9334, radius: 4.5 },
    { city: 'Lębork', district: 'lęborski', gpslatitude: 54.54275, gpslongitude: 17.76813, radius: 8 },
    { city: 'Malbork', district: 'malborski', gpslatitude: 54.03834, gpslongitude: 19.02913, radius: 4.5 },
    { city: 'Miastko', district: 'bytowski', gpslatitude: 54.003, gpslongitude: 16.9801, radius: 3 },
    { city: 'Nowy Dwór Gdański', district: 'nowodworski', gpslatitude: 54.21218, gpslongitude: 19.12366, radius: 3 },
    { city: 'Pelplin', district: 'tczewski', gpslatitude: 53.92722, gpslongitude: 18.69722, radius: 2.25 },
    { city: 'Pruszcz Gdański', district: 'gdański', gpslatitude: 54.26023, gpslongitude: 18.64818, radius: 4.5 },
    { city: 'Puck', district: 'pucki', gpslatitude: 54.71879, gpslongitude: 18.41176, radius: 3 },
    { city: 'Reda', district: 'wejherowski', gpslatitude: 54.60189, gpslongitude: 18.34948, radius: 4.5 },
    { city: 'Rumia', district: 'wejherowski', gpslatitude: 54.57078, gpslongitude: 18.38782, radius: 4.5 },
    { city: 'Skarszewy', district: 'starogardzki', gpslatitude: 54.07072, gpslongitude: 18.44173, radius: 2.25 },
    { city: 'Słupsk', district: 'Słupsk', gpslatitude: 54.4667, gpslongitude: 17.03442, radius: 5.25 },
    { city: 'Sopot', district: 'Sopot', gpslatitude: 54.4415, gpslongitude: 18.573, radius: 4.5 },
    { city: 'Starogard Gdański', district: 'starogardzki', gpslatitude: 53.96424, gpslongitude: 18.53013, radius: 4.5 },
    { city: 'Sztum', district: 'sztumski', gpslatitude: 53.92157, gpslongitude: 19.03409, radius: 3 },
    { city: 'Tczew', district: 'tczewski', gpslatitude: 54.08734, gpslongitude: 18.80074, radius: 5.25 },
    { city: 'Ustka', district: 'słupski', gpslatitude: 54.58644, gpslongitude: 16.85838, radius: 3.75 },
    { city: 'Wejherowo', district: 'wejherowski', gpslatitude: 54.6019, gpslongitude: 18.2368, radius: 4.5 },
    { city: 'Władysławowo', district: 'pucki', gpslatitude: 54.79351, gpslongitude: 18.40728, radius: 3 },
    { city: 'Żukowo', district: 'kartuski', gpslatitude: 54.34281, gpslongitude: 18.3643, radius: 2.25 }
  ], [
    { city: 'Będzin', district: 'będziński', gpslatitude: 50.33495, gpslongitude: 19.13768, radius: 5.25 },
    { city: 'Bielsko-Biała', district: 'Bielsko-Biała', gpslatitude: 49.82198, gpslongitude: 19.05043, radius: 6 },
    { city: 'Bytom', district: 'Bytom', gpslatitude: 50.34938, gpslongitude: 18.91962, radius: 6 },
    { city: 'Chorzów', district: 'Chorzów', gpslatitude: 50.29512, gpslongitude: 18.95103, radius: 6 },
    { city: 'Cieszyn', district: 'cieszyński', gpslatitude: 49.73482, gpslongitude: 18.65702, radius: 4.5 },
    { city: 'Czeladź', district: 'będziński', gpslatitude: 50.31377, gpslongitude: 19.07778, radius: 4.5 },
    { city: 'Czerwionka-Leszczyny', district: 'rybnicki', gpslatitude: 50.1493, gpslongitude: 18.66899, radius: 4.5 },
    { city: 'Częstochowa', district: 'Częstochowa', gpslatitude: 50.8093, gpslongitude: 19.05273, radius: 10 },
    { city: 'Dąbrowa Górnicza', district: 'Dąbrowa Górnicza', gpslatitude: 50.3308, gpslongitude: 19.22989, radius: 6 },
    { city: 'Gliwice', district: 'Gliwice', gpslatitude: 50.28741, gpslongitude: 18.6576, radius: 6 },
    { city: 'Jastrzębie-Zdrój', district: 'Jastrzębie-Zdrój', gpslatitude: 49.95369, gpslongitude: 18.59392, radius: 5.25 },
    { city: 'Jaworzno', district: 'Jaworzno', gpslatitude: 50.21052, gpslongitude: 19.26565, radius: 5.25 },
    { city: 'Katowice', district: 'Katowice', gpslatitude: 50.26288, gpslongitude: 19.02276, radius: 6 },
    { city: 'Łaziska Górne', district: 'mikołowski', gpslatitude: 50.14913, gpslongitude: 18.84118, radius: 4.5 },
    { city: 'Mikołów', district: 'mikołowski', gpslatitude: 50.17769, gpslongitude: 18.89011, radius: 4.5 },
    { city: 'Mysłowice', district: 'Mysłowice', gpslatitude: 50.21045, gpslongitude: 19.16683, radius: 5.25 },
    { city: 'Piekary Śląskie', district: 'Piekary Śląskie', gpslatitude: 50.36347, gpslongitude: 18.98547, radius: 5.25 },
    { city: 'Racibórz', district: 'raciborski', gpslatitude: 50.09012, gpslongitude: 18.21874, radius: 5.25 },
    { city: 'Ruda Śląska', district: 'Ruda Śląska', gpslatitude: 50.25151, gpslongitude: 18.90779, radius: 6 },
    { city: 'Rybnik', district: 'Rybnik', gpslatitude: 50.1021, gpslongitude: 18.5462, radius: 6 },
    { city: 'Siemianowice Śląskie', district: 'Siemianowice Śląskie', gpslatitude: 50.33012, gpslongitude: 19.03136, radius: 5.25 },
    { city: 'Sosnowiec', district: 'Sosnowiec', gpslatitude: 50.28158, gpslongitude: 19.11219, radius: 6 },
    { city: 'Świętochłowice', district: 'Świętochłowice', gpslatitude: 50.2977, gpslongitude: 18.91066, radius: 5.25 },
    { city: 'Tarnowskie Góry', district: 'tarnogórski', gpslatitude: 50.44613, gpslongitude: 18.85827, radius: 5.25 },
    { city: 'Tychy', district: 'Tychy', gpslatitude: 50.1218, gpslongitude: 19.02, radius: 6 },
    { city: 'Wodzisław Śląski', district: 'wodzisławski', gpslatitude: 50.00063, gpslongitude: 18.46057, radius: 4.5 },
    { city: 'Zabrze', district: 'Zabrze', gpslatitude: 50.31558, gpslongitude: 18.78669, radius: 6 },
    { city: 'Zawiercie', district: 'zawierciański', gpslatitude: 50.4899, gpslongitude: 19.42219, radius: 3.73388 },
    { city: 'Żory', district: 'Żory', gpslatitude: 50.03937, gpslongitude: 18.68291, radius: 5.25 },
    { city: 'Żywiec', district: 'żywiecki', gpslatitude: 49.68829, gpslongitude: 19.20381, radius: 4.5 }
  ], [
    { city: 'Bieliny', district: 'kielecki', gpslatitude: 50.84791, gpslongitude: 20.95551, radius: 1.5 },
    { city: 'Bukowa', district: 'włoszczowski', gpslatitude: 50.88733, gpslongitude: 20.13855, radius: 1.5 },
    { city: 'Busko-Zdrój', district: 'buski', gpslatitude: 50.47086, gpslongitude: 20.72001, radius: 3.75 },
    { city: 'Daleszyce', district: 'kielecki', gpslatitude: 50.80164, gpslongitude: 20.81017, radius: 1.5 },
    { city: 'Dąbrowa', district: 'kielecki', gpslatitude: 50.90778, gpslongitude: 20.68921, radius: 1.5 },
    { city: 'Huta Nowa', district: 'kielecki', gpslatitude: 50.84288, gpslongitude: 20.98367, radius: 1.5 },
    { city: 'Janik', district: 'ostrowiecki', gpslatitude: 50.97377, gpslongitude: 21.31439, radius: 1.5 },
    { city: 'Jędrzejów', district: 'jędrzejowski', gpslatitude: 50.64003, gpslongitude: 20.30568, radius: 3.75 },
    { city: 'Kazimierza Wielka', district: 'kazimierski', gpslatitude: 50.2621, gpslongitude: 20.49378, radius: 2.25 },
    { city: 'Kielce', district: 'Kielce', gpslatitude: 50.87056, gpslongitude: 20.62798, radius: 6 },
    { city: 'Końskie', district: 'konecki', gpslatitude: 51.19163, gpslongitude: 20.40866, radius: 4.5 },
    { city: 'Koprzywnica', district: 'sandomierski', gpslatitude: 50.59235, gpslongitude: 21.58588, radius: 1.5 },
    { city: 'Leszczyny', district: 'kielecki', gpslatitude: 50.87417, gpslongitude: 20.76064, radius: 1.5 },
    { city: 'Michałów', district: 'pińczowski', gpslatitude: 50.48873, gpslongitude: 20.40424, radius: 1.5 },
    { city: 'Opatów', district: 'opatowski', gpslatitude: 50.79645, gpslongitude: 21.42023, radius: 2.25 },
    { city: 'Ostrowiec Świętokrzyski', district: 'ostrowiecki', gpslatitude: 50.93816, gpslongitude: 21.38997, radius: 5.25 },
    { city: 'Pińczów', district: 'pińczowski', gpslatitude: 50.51935, gpslongitude: 20.52523, radius: 3 },
    { city: 'Połaniec', district: 'staszowski', gpslatitude: 50.43179, gpslongitude: 21.28088, radius: 2.25 },
    { city: 'Radlin', district: 'kielecki', gpslatitude: 50.86084, gpslongitude: 20.75416, radius: 1.5 },
    { city: 'Sandomierz', district: 'sandomierski', gpslatitude: 50.67605, gpslongitude: 21.75749, radius: 4.5 },
    { city: 'Sędziszów', district: 'jędrzejowski', gpslatitude: 50.57373, gpslongitude: 20.07203, radius: 2.25 },
    { city: 'Skarżysko-Kamienna', district: 'skarżyski', gpslatitude: 51.11584, gpslongitude: 20.86287, radius: 4.5 },
    { city: 'Starachowice', district: 'starachowicki', gpslatitude: 51.04796, gpslongitude: 21.06108, radius: 5.25 },
    { city: 'Staszów', district: 'staszowski', gpslatitude: 50.56428, gpslongitude: 21.17081, radius: 3.75 },
    { city: 'Stąporków', district: 'konecki', gpslatitude: 51.13665, gpslongitude: 20.5592, radius: 2.25 },
    { city: 'Suchedniów', district: 'skarżyski', gpslatitude: 51.04375, gpslongitude: 20.83468, radius: 2.25 },
    { city: 'Szewna', district: 'ostrowiecki', gpslatitude: 50.9149, gpslongitude: 21.36605, radius: 1.5 },
    { city: 'Włoszczowa', district: 'włoszczowski', gpslatitude: 50.85259, gpslongitude: 19.96787, radius: 3 },
    { city: 'Wodzisław', district: 'jędrzejowski', gpslatitude: 50.51937, gpslongitude: 20.19233, radius: 1.5 },
    { city: 'Zagnańsk', district: 'kielecki', gpslatitude: 50.97291, gpslongitude: 20.65639, radius: 1.5 }
  ], [
    { city: 'Barczewo', district: 'olsztyński', gpslatitude: 53.82907, gpslongitude: 20.69321, radius: 2.25 },
    { city: 'Bartoszyce', district: 'bartoszycki', gpslatitude: 54.25325, gpslongitude: 20.81024, radius: 4.5 },
    { city: 'Biskupiec', district: 'olsztyński', gpslatitude: 53.86218, gpslongitude: 20.95601, radius: 3 },
    { city: 'Braniewo', district: 'braniewski', gpslatitude: 54.38135, gpslongitude: 19.82623, radius: 3.75 },
    { city: 'Dobre Miasto', district: 'olsztyński', gpslatitude: 53.98678, gpslongitude: 20.39516, radius: 3 },
    { city: 'Działdowo', district: 'działdowski', gpslatitude: 53.23408, gpslongitude: 20.1805, radius: 4.5 },
    { city: 'Elbląg', district: 'Elbląg', gpslatitude: 54.17079, gpslongitude: 19.40479, radius: 6 },
    { city: 'Ełk', district: 'ełcki', gpslatitude: 53.80957, gpslongitude: 22.33548, radius: 5.25 },
    { city: 'Giżycko', district: 'giżycki', gpslatitude: 54.03567, gpslongitude: 21.77016, radius: 4.5 },
    { city: 'Gołdap', district: 'gołdapski', gpslatitude: 54.30451, gpslongitude: 22.29834, radius: 3.977 },
    { city: 'Iława', district: 'iławski', gpslatitude: 53.5937, gpslongitude: 19.57128, radius: 4.5 },
    { city: 'Kętrzyn', district: 'kętrzyński', gpslatitude: 54.0788, gpslongitude: 21.37029, radius: 4.5 },
    { city: 'Lidzbark', district: 'działdowski', gpslatitude: 53.26188, gpslongitude: 19.8233, radius: 2.25 },
    { city: 'Lidzbark Warmiński', district: 'lidzbarski', gpslatitude: 54.12375, gpslongitude: 20.58684, radius: 3.75 },
    { city: 'Lubawa', district: 'iławski', gpslatitude: 53.49683, gpslongitude: 19.76208, radius: 2.25 },
    { city: 'Morąg', district: 'ostródzki', gpslatitude: 53.91736, gpslongitude: 19.92865, radius: 3 },
    { city: 'Mrągowo', district: 'mrągowski', gpslatitude: 53.86359, gpslongitude: 21.3077, radius: 4.5 },
    { city: 'Nidzica', district: 'nidzicki', gpslatitude: 53.36006, gpslongitude: 20.42701, radius: 3 },
    { city: 'Nowe Miasto Lubawskie', district: 'nowomiejski', gpslatitude: 53.42103, gpslongitude: 19.59436, radius: 3 },
    { city: 'Olecko', district: 'olecki', gpslatitude: 54.03504, gpslongitude: 22.50675, radius: 3.75 },
    { city: 'Olsztyn', district: 'Olsztyn', gpslatitude: 53.77602, gpslongitude: 20.47703, radius: 6 },
    { city: 'Orneta', district: 'lidzbarski', gpslatitude: 54.11552, gpslongitude: 20.13056, radius: 2.25 },
    { city: 'Orzysz', district: 'piski', gpslatitude: 53.80871, gpslongitude: 21.94405, radius: 2.25 },
    { city: 'Ostróda', district: 'ostródzki', gpslatitude: 53.7, gpslongitude: 19.96776, radius: 4.5 },
    { city: 'Pasłęk', district: 'elbląski', gpslatitude: 54.06152, gpslongitude: 19.66067, radius: 3 },
    { city: 'Pisz', district: 'piski', gpslatitude: 53.62644, gpslongitude: 21.80469, radius: 3.75 },
    { city: 'Reszel', district: 'kętrzyński', gpslatitude: 54.05031, gpslongitude: 21.14602, radius: 2.25 },
    { city: 'Susz', district: 'iławski', gpslatitude: 53.71887, gpslongitude: 19.33831, radius: 2.25 },
    { city: 'Szczytno', district: 'szczycieński', gpslatitude: 53.55827, gpslongitude: 21.00724, radius: 4.5 },
    { city: 'Węgorzewo', district: 'węgorzewski', gpslatitude: 54.21493, gpslongitude: 21.72263, radius: 3 }
  ], [
    { city: 'Chodzież', district: 'chodzieski', gpslatitude: 52.99183, gpslongitude: 16.91639, radius: 3.75 },
    { city: 'Czarnków', district: 'czarnkowsko-trzcianecki', gpslatitude: 52.90161, gpslongitude: 16.56829, radius: 3 },
    { city: 'Gniezno', district: 'gnieźnieński', gpslatitude: 52.52918, gpslongitude: 17.59895, radius: 5.25 },
    { city: 'Gostyń', district: 'gostyński', gpslatitude: 51.88363, gpslongitude: 17.01231, radius: 4.5 },
    { city: 'Grodzisk Wielkopolski', district: 'grodziski', gpslatitude: 52.22705, gpslongitude: 16.36207, radius: 3 },
    { city: 'Jarocin', district: 'jarociński', gpslatitude: 51.97589, gpslongitude: 17.50121, radius: 4.5 },
    { city: 'Kalisz', district: 'Kalisz', gpslatitude: 51.76272, gpslongitude: 18.09094, radius: 6 },
    { city: 'Koło', district: 'kolski', gpslatitude: 52.19634, gpslongitude: 18.63834, radius: 4.5 },
    { city: 'Konin', district: 'Konin', gpslatitude: 52.21424, gpslongitude: 18.25861, radius: 5.25 },
    { city: 'Kościan', district: 'kościański', gpslatitude: 52.08491, gpslongitude: 16.6478, radius: 4.5 },
    { city: 'Koziegłowy', district: 'poznański', gpslatitude: 52.44863, gpslongitude: 16.99509, radius: 1 },
    { city: 'Krotoszyn', district: 'krotoszyński', gpslatitude: 51.69865, gpslongitude: 17.44274, radius: 4.5 },
    { city: 'Leszno', district: 'Leszno', gpslatitude: 51.84266, gpslongitude: 16.57781, radius: 5.25 },
    { city: 'Luboń', district: 'poznański', gpslatitude: 52.34163, gpslongitude: 16.87561, radius: 4 },
    { city: 'Nowy Tomyśl', district: 'nowotomyski', gpslatitude: 52.31667, gpslongitude: 16.13333, radius: 3.75 },
    { city: 'Oborniki', district: 'obornicki', gpslatitude: 52.64397, gpslongitude: 16.81828, radius: 3.75 },
    { city: 'Ostrów Wielkopolski', district: 'ostrowski', gpslatitude: 51.65217, gpslongitude: 17.81083, radius: 5.25 },
    { city: 'Piła', district: 'pilski', gpslatitude: 53.15324, gpslongitude: 16.73333, radius: 5.25 },
    { city: 'Pleszew', district: 'pleszewski', gpslatitude: 51.88859, gpslongitude: 17.78953, radius: 3.75 },
    { city: 'Poznań', district: 'Poznań', gpslatitude: 52.40916, gpslongitude: 16.89856, radius: 6.64852 },
    { city: 'Rawicz', district: 'rawicki', gpslatitude: 51.60875, gpslongitude: 16.87278, radius: 4.5 },
    { city: 'Swarzędz', district: 'poznański', gpslatitude: 52.40768, gpslongitude: 17.06927, radius: 2 },
    { city: 'Szamotuły', district: 'szamotulski', gpslatitude: 52.61197, gpslongitude: 16.58278, radius: 3.75 },
    { city: 'Śrem', district: 'śremski', gpslatitude: 52.09116, gpslongitude: 17.01848, radius: 4.5 },
    { city: 'Środa Wielkopolska', district: 'średzki', gpslatitude: 52.22811, gpslongitude: 17.28452, radius: 4.5 },
    { city: 'Trzcianka', district: 'czarnkowsko-trzcianecki', gpslatitude: 53.04059, gpslongitude: 16.46245, radius: 3.75 },
    { city: 'Turek', district: 'turecki', gpslatitude: 52.01508, gpslongitude: 18.49974, radius: 4.5 },
    { city: 'Wągrowiec', district: 'wągrowiecki', gpslatitude: 52.80789, gpslongitude: 17.1971, radius: 4.5 },
    { city: 'Września', district: 'wrzesiński', gpslatitude: 52.32233, gpslongitude: 17.56544, radius: 4.5 },
    { city: 'Złotów', district: 'złotowski', gpslatitude: 53.36246, gpslongitude: 17.04217, radius: 3.75 }
  ], [
    { city: 'Barlinek', district: 'myśliborski', gpslatitude: 52.99402, gpslongitude: 15.22102, radius: 3 },
    { city: 'Białogard', district: 'białogardzki', gpslatitude: 54.00229, gpslongitude: 15.98862, radius: 4.5 },
    { city: 'Chojna', district: 'gryfiński', gpslatitude: 52.9627, gpslongitude: 14.43392, radius: 2.25 },
    { city: 'Choszczno', district: 'choszczeński', gpslatitude: 53.16622, gpslongitude: 15.41687, radius: 3.75 },
    { city: 'Darłowo', district: 'sławieński', gpslatitude: 54.41903, gpslongitude: 16.40876, radius: 3 },
    { city: 'Dębno', district: 'myśliborski', gpslatitude: 52.73584, gpslongitude: 14.69133, radius: 3 },
    { city: 'Drawsko Pomorskie', district: 'drawski', gpslatitude: 53.52981, gpslongitude: 15.81134, radius: 3 },
    { city: 'Goleniów', district: 'goleniowski', gpslatitude: 53.56253, gpslongitude: 14.82844, radius: 4.5 },
    { city: 'Gryfice', district: 'gryficki', gpslatitude: 53.91333, gpslongitude: 15.20306, radius: 3.75 },
    { city: 'Gryfino', district: 'gryfiński', gpslatitude: 53.25009, gpslongitude: 14.51084, radius: 4.5 },
    { city: 'Kamień Pomorski', district: 'kamieński', gpslatitude: 53.97046, gpslongitude: 14.77527, radius: 2.25 },
    { city: 'Kołobrzeg', district: 'kołobrzeski', gpslatitude: 54.17944, gpslongitude: 15.55643, radius: 4.5 },
    { city: 'Koszalin', district: 'Koszalin', gpslatitude: 54.19044, gpslongitude: 16.18948, radius: 6 },
    { city: 'Łobez', district: 'łobeski', gpslatitude: 53.63915, gpslongitude: 15.62303, radius: 3 },
    { city: 'Mierzyn', district: 'policki', gpslatitude: 53.42912, gpslongitude: 14.4659, radius: 2.25 },
    { city: 'Międzyzdroje', district: 'kamieński', gpslatitude: 53.92912, gpslongitude: 14.44954, radius: 2.25 },
    { city: 'Myślibórz', district: 'myśliborski', gpslatitude: 52.92164, gpslongitude: 14.86529, radius: 3 },
    { city: 'Nowogard', district: 'goleniowski', gpslatitude: 53.67158, gpslongitude: 15.11876, radius: 3.75 },
    { city: 'Police', district: 'policki', gpslatitude: 53.5468, gpslongitude: 14.56514, radius: 4.5 },
    { city: 'Połczyn-Zdrój', district: 'świdwiński', gpslatitude: 53.76552, gpslongitude: 16.09435, radius: 2.25 },
    { city: 'Pyrzyce', district: 'pyrzycki', gpslatitude: 53.14945, gpslongitude: 14.8885, radius: 3 },
    { city: 'Sławno', district: 'sławieński', gpslatitude: 54.35649, gpslongitude: 16.69036, radius: 3 },
    { city: 'Stargard', district: 'stargardzki', gpslatitude: 53.33643, gpslongitude: 15.04314, radius: 5.25 },
    { city: 'Szczecin', district: 'Szczecin', gpslatitude: 53.39368, gpslongitude: 14.51525, radius: 13 },
    { city: 'Szczecinek', district: 'szczecinecki', gpslatitude: 53.70573, gpslongitude: 16.69825, radius: 4.5 },
    { city: 'Świdwin', district: 'świdwiński', gpslatitude: 53.77443, gpslongitude: 15.77633, radius: 3.75 },
    { city: 'Świnoujście', district: 'Świnoujście', gpslatitude: 53.87864, gpslongitude: 14.29185, radius: 6.92959 },
    { city: 'Trzebiatów', district: 'gryficki', gpslatitude: 54.06168, gpslongitude: 15.27184, radius: 3 },
    { city: 'Wałcz', district: 'wałecki', gpslatitude: 53.28029, gpslongitude: 16.43889, radius: 4.5 },
    { city: 'Złocieniec', district: 'drawski', gpslatitude: 53.53362, gpslongitude: 16.00928, radius: 3 }
  ]
]