import { ReactComponent as Logo } from '../../../assets/logo.svg'
import BackBtn from '../../../components/buttons/BackBtn'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import PinForm from '../../../components/PinForm'
import { useState } from 'react'

const CODE_LENGTH = 4

const PinPopup = ({ name, value, setValue }) => {

  const [code, setCode] = useState(value)
  const [warning, setWarning] = useState(false)

  const validateCode = () => {
    if (code?.length === CODE_LENGTH) setValue(code)
    else setWarning(true)
  }

  return (
    <Popup>
      <div className='popup__pin'>
        <div className='popup__title'>
          <Logo />
          <div>{value ? 'Zmień' : 'Dodaj'} PIN</div>
          <div>do {name === 'mag' ? 'MAGa' : 'bramy'}</div>
        </div>

        <PinForm
          autoFocus
          value={code}
          warning={warning}
          setValue={setCode}
          setWarning={setWarning}
        />

        <div className='popup__buttons'>
          <Submit
            text={value ? 'Zmień' : 'Zapisz'}
            block={code?.length !== CODE_LENGTH}
            action={validateCode}
          />

          <BackBtn text='Anuluj' />
        </div>
      </div>
    </Popup>
  )
}

export default PinPopup