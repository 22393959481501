import usePopupState from '../../../hooks/usePopupState'
import FindPlacePopup from './FindPlacePopup'
import NewPlacePopup from './NewPlacePopup'
import LegendPopup from './LegendPopup'
import FilterPopup from './FilterPopup'

const Popups = () => {
  const { popup } = usePopupState()

  return (
    popup ? <>
      {popup === 'legend' ? <LegendPopup />
        : popup === 'filters' ? <FilterPopup />
          : popup === 'newplace' ? <NewPlacePopup />
            : popup === 'findplace' ? <FindPlacePopup />
              : false}
    </> : false
  )
}

export default Popups