import phillars from '../../assets/gateway/doorPhillars.png'
import { GATEWAY_STATUS } from '../../utils/constants'
import door from '../../assets/gateway/door.png'
import { useState } from 'react'

const Gateway = ({ status = GATEWAY_STATUS.closed }) => {
  const [loading, setLoading] = useState({
    door: true,
    phillars: true
  })

  const updateLoading = (n = 'door') => setLoading(l => { return { ...l, [n]: false } })

  return (
    <svg id='gateway' xmlns='http://www.w3.org/2000/svg' width='187' height='252' viewBox='0 0 187 252'>
      <defs>
        <pattern id='pattern-column' preserveAspectRatio='none' width='100%' height='100%' viewBox='0 0 374 496'>
          <image
            width='374'
            height='496'
            href={phillars}
            onLoad={() => updateLoading('door')}
            onError={() => updateLoading('door')} />
        </pattern>
        <pattern id='pattern-gateway' preserveAspectRatio='none' width='100%' height='100%' viewBox='0 0 274 434'>
          <image
            width='274'
            height='434'
            href={door}
            onLoad={() => updateLoading('phillars')}
            onError={() => updateLoading('phillars')} />
        </pattern>

        <filter id='shadow-under-phillars' filterUnits='userSpaceOnUse'>
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodColor='#000000' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>

      <g id='gateway-main'>
        <g id='door' className={status === 'gateway_opened' ? '--opened' : ''}>
          <rect id='gateway-image' width='137' height='217' transform='translate(25 21)' fill='url(#pattern-gateway)' />
          {!loading.door && <ellipse id='shadow-under-gateway' cx='69' cy='1.5' rx='69' ry='1.5' transform='translate(24 242)' fill='var(--gate-shadow)' filter='url(#shadow-under-phillars)' />}
        </g>
        {!loading.phillars && <ellipse id='column-shadow' cx='8' cy='101.5' rx='8' ry='101.5' transform='translate(155 34)' opacity='0.42' />}
        <rect id='column-image' width='187' height='248' fill='url(#pattern-column)' />
      </g>
    </svg>
  )
}

export default Gateway