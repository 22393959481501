import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../utils/constants'
import { locations } from '../../rest/urls'
import { POST } from '../../rest/request'

export const fetchMapDetails = createAsyncThunk('mapDetails/fetchMapDetails', async placeId => {
  const response = await POST(`${locations}/${placeId}`)
  return { placeId, ...response }
})

export const mapDetailsSlice = createSlice({
  name: 'mapDetails',
  initialState: {
    placeId: '',
    place: {},
    status: STATUS.idle,
  },
  reducers: {},
  extraReducers: {
    [fetchMapDetails.fulfilled]: (state, action) => {
      const { placeId, places } = action.payload

      state.placeId = placeId
      state.place = places[0] ? places[0] : {}
      state.status = STATUS.succeed
    },
    [fetchMapDetails.rejected]: (state, action) => {
      state.placeId = ''
      state.place = {}
      state.status = STATUS.failed
    },
  }
})

export const selectPlace = state => state.mapDetails.place
export const selectStatus = state => state.mapDetails.status
export const selectPlaceId = state => state.mapDetails.placeId

export default mapDetailsSlice.reducer
