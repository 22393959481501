import { ReactComponent as Lightning } from '../../../assets/lightning.svg'
import { ReactComponent as Calendar } from '../../../assets/calendar.svg'
import { ReactComponent as Marked } from '../../../assets/marked.svg'
import { ReactComponent as Key } from '../../../assets/key.svg'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import WarehouseAttr from './WarehouseAttr'
import { Pagination } from 'swiper'
import { useEffect } from 'react'

const ResultElement = ({ idx, data, warehouseTypeId, scroll }) => {
  const { id, city, zip, street, estateNumber, localNumber, sizes, closest_availability_date: availabilityDate, distance, free_with_online_access, min_renting_days, min_day_price = 0, photos, restartCarousel = false } = data || {}
  const { push, replace } = useHistory()

  useEffect(() => scroll && scroll(), [scroll])

  return <div
    id={`mag_${idx}`}
    className={`list__element --big${availabilityDate ? ' --planned' : ''}`}>

    {availabilityDate && <div className='element__header'>Planowany</div>}

    <div className='element__group group'>
      {data ? <div className='element__title'>
        <div>
          <span className='--termina'>{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}</span>
        </div>
        <div>{zip}, <span className='--termina'>{city}</span></div>
      </div> : <Skeleton
        containerClassName='skeleton__title'
        count={2}
      />}

      {data ? distance !== null && <div className='element__detail --distance center'>
        <div className='--big'>{typeof distance === 'number' ? distance.toFixed(1) : distance} km</div>
        <div>Odległość</div>
      </div> : <Skeleton className='skeleton__detail' />}
    </div>

    {data ? <>
      <Swiper
        loop
        pagination
        grabCursor
        autoHeight
        preventClicks
        centeredSlides
        slideToClickedSlide
        slidesPerView='auto'
        modules={[Pagination]}
        className='place__slideShow'
        key={restartCarousel ? id : undefined}
        onSlideChangeTransitionEnd={() => scroll && scroll()}>

        <SwiperSlide>
          <div className='element__main'>
            <div className='element__icons group'>
              <div className='element__icon --first'>
                {free_with_online_access ? <Lightning /> : <Key />}
                <div className='icon__desc'>
                  <div>{free_with_online_access ? 'Elekt. zapięcie' : 'Zamek klasyczny'}</div>
                </div>
              </div>

              <div className='element__icon'>
                <Calendar />
                <div className='icon__desc'>
                  <div>Wynajem od {min_renting_days} {min_renting_days < 2 ? 'dnia' : 'dni'}</div>
                </div>
              </div>
            </div>

            <div className='element__size'>
              <div className='size__title'>Dostępne rozmiary</div>
              <div className='size__list'>
                {sizes && Object.values(sizes).map(({ match, size }, idx, arr) => {
                  if (idx && arr[idx - 1].size === size) return null
                  return <div
                    key={idx}
                    className={`size__item${match ? ' --red' : ''}`}>
                    {size}{typeof size === 'number' ? <>m<sup>2</sup></> : ''}
                  </div>
                })}
              </div>
            </div>

            <WarehouseAttr resize={() => scroll && scroll()} />
          </div>
        </SwiperSlide>

        {photos?.map(({ is_image, document_title, public_path }, i) => is_image && <SwiperSlide
          key={i}
          className='place__slide center'>
          <img src={public_path} alt={document_title} loading='lazy' />
          <div className='element__placeAttr center'>
            <Marked />
            <div className='placeAttr__list'>
              {['Monitoring CCTV', 'Wjazd Tirem', 'Uzbrojenie w alarm', 'Brama na pilot'].map((e, i, arr) => {
                const isSelected = Math.floor(Math.random() * 2)

                return <span
                  key={i}
                  className='item__desc'
                  style={isSelected ? {} : { opacity: 0.25 }}>{e}{i === arr.length - 1 ? '' : ', '}</span>
              })}
            </div>
          </div>
        </SwiperSlide>)}
      </Swiper>
    </> : <>
      <Skeleton className='skeleton__icons' count={2} />
      <Skeleton className='skeleton__icons' height='5em' />
    </>}

    <div className='element__buttons group'>
      {data ? <>
        <div className='element__detail --price center'>
          <div className='--big'>od {min_day_price} zł</div>
          <div>dzień</div>
        </div>

        <ArrowBtn
          enlarged
          text={availabilityDate ? `Zarezerwuj od ${availabilityDate}` : 'Zamów teraz'}
          action={() => {
            if (idx !== undefined) replace(`${window.location.pathname}?from=mag_${idx}`)
            push(`/mapdetails/${id}${warehouseTypeId ? `?warehouseTypeId=${warehouseTypeId}` : ''}`)
          }} />
      </> : <>
        <Skeleton className='skeleton__detail' height='2.5em' />
        <Skeleton containerClassName='skeleton__button' height='2.5em' />
      </>}
    </div>
  </div>
}

export default ResultElement