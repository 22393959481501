import EditPhonePopup from './BasicInfo/EditPhonePopup'
import EditMailPopup from './BasicInfo/EditMailPopup'
import EditUserPopup from './BasicInfo/EditUserPopup'
import usePopupState from '../../hooks/usePopupState'
import Popup from '../../components/popups/Popup'
import DevicePopup from './Settings/DevicePopup'
import { ACCOUNT_TYPE } from './accountSlice'
import PinPopup from './Settings/PinPopup'
import Payments from './Payments'

const Popups = () => {
  const { popup } = usePopupState()

  switch (popup) {
    case ACCOUNT_TYPE.payments: return <Popup><Payments popup /></Popup>
    case 'editPhone': return <EditPhonePopup />
    case 'editUser': return <EditUserPopup />
    case 'editMail': return <EditMailPopup />
    case 'deviceList': return <DevicePopup />
    case 'changePin': return <PinPopup />
    default: return false
  }
}

export default Popups