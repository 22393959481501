import { ReactComponent as PadlockClosedS } from '../../../assets/padlockClosed.svg'
import { ReactComponent as PadlockOpenS } from '../../../assets/padlockOpen.svg'
import { forwardRef, Fragment, useEffect, useMemo, useState } from 'react'
import FilterButton from '../../../components/Filters/FilterButton'
import { ReactComponent as Ring } from '../../../assets/ring.svg'
import Filters from '../../../components/Filters'
import { useParams } from 'react-router-dom'
import { MONTHS } from '../../../utils/date'
import { fields } from './fields'
import { data } from './data'


const LENGTH = data.length
const history = data.reverse()
const height = window.innerHeight
const ITEMS = height > 900 ? 12 : 8

const MagHistory = forwardRef(({ full, more }, ref) => {

  const { number } = useParams()

  const [filteredHistory, filterHistory] = useState(history)
  const [filters, setFilters] = useState({ mag: number })
  const [items, showItems] = useState(full ? ITEMS : 3)

  const addItems = () => showItems(i => i + ITEMS <= LENGTH ? i + ITEMS : LENGTH)

  const onScroll = e => {
    const { clientHeight, scrollTop, scrollHeight } = e.target
    if (scrollHeight - clientHeight - scrollTop < 5) addItems()
  }

  useEffect(() => {
    const filtersList = Object.entries(filters)
    const filterDate = filters.date ? {} : false
    if (filterDate) {
      if (filters.date.start) filterDate.start = new Date(`${filters.date.start}, 00:00`)
      if (filters.date.end) filterDate.end = new Date(`${filters.date.end}, 00:00`)
    }
    const filtered = history.filter(({ date, ...d }) => {
      return filtersList.reduce((r, [k, v]) => {
        if (k === 'date') {
          if (!date) return false
          const dateObj = new Date(date.split('.')?.reverse())
          const { start, end } = filterDate

          if (start) r = r && dateObj >= start
          if (end) r = r && dateObj <= end
        }
        else if (d[k] !== undefined) r = r && d[k] === v
        return r
      }, true)
    })
    filterHistory(filtered)
  }, [filters])

  return (
    <div className='menu__section'>
      <div className='group'>
        <p>Lista zdarzeń</p>
        {full && <FilterButton />}
      </div>

      {useMemo(() => full && <Filters
        fields={fields}
        filters={filters}
        setFilters={setFilters}
      />, [filters, full])}

      <div
        ref={ref}
        className='menu__history'
        onScroll={onScroll}>

        {useMemo(() => filteredHistory
          .filter((_, i) => i < items)
          .map(({ time, date, action, title, desc, user }, i, arr) => {
            const showDate = i === 0 || arr[i - 1].date !== date
            const dateList = showDate ? date.split('.') : []

            return <Fragment key={i}>
              {showDate && <>
                <div className='history__date'>{Number(dateList[0])} {MONTHS.long[dateList[1] - 1]}</div>
                <hr />
              </>}

              <div className='history__item'>
                <div className='group'>
                  <div className='item__left'>
                    <div className='history__symbol center'>
                      {action === 'opening' ? <PadlockOpenS />
                        : action === 'closing' ? <PadlockClosedS />
                          : <Ring />}
                    </div>

                    <div className='history__desc'>
                      <div
                        className='--termina'
                        style={action === 'alarm' ? { color: 'var(--red)' } : {}}>{title}</div>
                      <div>{desc || `Akcje uruchomił uzytkownik: ${user}`}</div>
                    </div>
                  </div>

                  <div className='history__time'>{time}</div>
                </div>
              </div>
            </Fragment>
          }), [filteredHistory, items])}

        {!full && <>
          <div
            className='menu__more center --termina --high'
            onClick={more}>Więcej →</div>
          <hr />
        </>}
      </div>
    </div>
  )
})

export default MagHistory