import { ReactComponent as Logo } from '../../assets/logo.svg'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import Form from '../../components/PinForm'
import { useState } from 'react'
import phone from './phone.png'

const PinSlide = ({ next }) => {
  const [pin, setPin] = useState('')
  const [warning, setWarning] = useState(false)

  const validation = () => pin.length === 4 ? next() : setWarning(true)

  return <>
    <Logo />

    <div className='slide__warehouses'>
      <div className='warehouse --left'>
        <Warehouse />
      </div>
      <div className='warehouse --right'>
        <Warehouse />
      </div>
      <img className='img--phone' alt='phones' src={phone} />
    </div>

    <div className='slide__title center'>
      <div className='--termina'>Ustaw PIN do swojej aplikacji</div>
    </div>

    <Form
      clear={!pin}
      setValue={setPin}
      warning={warning}
      setWarning={setWarning} />

    <div className='slide__desc'>
      <div>Będziesz mógł za jego pomocą</div>
      <div>zalogować się do aplikacji</div>
    </div>

    <div className='slide__buttons'>
      <button className='button --back' onClick={() => setPin('')}>
        <span>Wyczyść</span>
      </button>

      <Submit text='Dalej' action={validation} />
    </div>
  </>
}

export default PinSlide