import useTranslation from '../../hooks/useTranslation'

const FiltersElement = ({ label, desc, openPopup, removeAction }) => {
  const { t } = useTranslation('basic')

  return (
    <div
      className='filters__item'>
      {label && <span className='item__label'>{label}</span>}
      <div className='item__content group'>
        <span onClick={openPopup}>{desc === 'true' ? t('yes') : desc === 'false' ? t('no') : desc}</span>
        <span
          className='cross--small'
          onClick={removeAction}>x</span>
      </div>
    </div>
  )
}

export default FiltersElement