import useTranslation from '../../../hooks/useTranslation'
import BackBtn from '../../../components/buttons/BackBtn'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import PinForm from '../../../components/PinForm'
import { useHistory } from 'react-router-dom'
import { POST } from '../../../rest/request'
import { verify } from '../../../rest/urls'
import { useState } from 'react'

const CODE_LENGTH = 4

const PinPopup = () => {

  const [code, setCode] = useState('')
  const [warning, setWarning] = useState(false)

  const { t } = useTranslation('form')
  const { goBack } = useHistory()


  const submit = async () => {
    try {
      const body = { pin: code }

      const response = await POST(verify('pin'), { body })
      if (response?.result === 'ok') goBack()
      else {
        setCode('')
        setWarning(true)
      }

    } catch (error) {
      console.error(error)
    }
  }

  const getSubmitText = len => {
    return len === CODE_LENGTH ? 'Autoryzuj'
      : `Brakuje ${CODE_LENGTH - len} znak${len === CODE_LENGTH - 1 ? 'u' : 'ów'}`
  }

  return (
    <Popup>
      <div className='popup__pin'>
        <div className='popup__title'>
          <div>{t('enterYourPin')}</div>
        </div>

        <PinForm
          value={code}
          warning={warning}
          setValue={setCode}
          setWarning={setWarning}
        />

        <div className='popup__buttons'>
          <Submit
            action={submit}
            block={code.length !== 4}
            text={getSubmitText(code.length)}
          />

          <BackBtn text={t('cancel')} />
        </div>
      </div>
    </Popup>
  )
}

export default PinPopup