import Submit from '../../components/inputs/Submit'
import MagNumber from '../../components/MagNumber'
import { ReactComponent as Key } from './key.svg'
import Form from '../../components/PinForm'
import Gate from '../Entrance/Gate'
import { OPENING_TYPE } from '.'
import { useState } from 'react'

const GateAppSection = ({ next }) => {
  const [pin, setPin] = useState(null)
  const [warning, setWarning] = useState(false)

  const validation = () => pin?.length === 4 || pin === null ? next() : setWarning(true)

  return <>
    {pin !== null && <Form
      value={pin}
      clear={!pin}
      warning={warning}
      setValue={setPin}
      setWarning={setWarning} />}

    <div className='slide__desc'>
      <div>Twój plac jest otwierany zdalnie</div>
      <div>z aplikacji lub z panelu przy wjeździe</div>
      <div>przy pomocy Twojego kodu PIN</div>
    </div>

    <div className='slide__buttons'>
      <button
        className='button --back'
        onClick={() => setPin(p => p === null ? '' : null)}>
        <span>{pin === null ? 'Ustaw inny PIN' : 'Anuluj'}</span>
      </button>

      <Submit text='Dalej' action={validation} />
    </div>
  </>
}

const GateSlide = ({ data, next }) => {
  const { address, number, type } = data || {}
  const { street, estateNumber, localNumber, city } = address || {}

  const submit = <Submit text='Dalej' action={next} />

  return <>
    <div>
      <MagNumber number={number} />
      <div className='slide__address'>ul. {street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, {city}</div>
    </div>

    <div className='slide__gate center'>
      <Gate />
    </div>

    <div className='slide__title center'>
      <div className='--termina'>Jak otworzyć bramę?</div>
      {type === OPENING_TYPE.app && <div>Skorzystaj z aplikacji</div>}
    </div>

    {type === OPENING_TYPE.pin ? <>
      <Form
        disabled
        value='1234' />

      <div className='slide__desc'>
        <div>Twój plac jest otwierany</div>
        <div>przy pomocy Twojego kodu PIN</div>
      </div>

      {submit}
    </> : type === OPENING_TYPE.key ? <>
      <Key />

      <div className='slide__desc'>
        <div>Twój plac jest otwierany</div>
        <div>przy pomocy kłódki na klucz</div>
      </div>

      <div className='slide__buttons'>
        <button className='button --back'>
          <span>Skąd wziąść klucz</span>
        </button>

        {submit}
      </div>
    </> : <GateAppSection next={next} />}
  </>
}

export default GateSlide