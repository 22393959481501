import { ReactComponent as Outlet } from '../../../assets/outlet.svg'
import { ReactComponent as Alarm } from '../../../assets/alarm.svg'
import { ReactComponent as Light } from '../../../assets/light.svg'
import { ReactComponent as Rack } from '../../../assets/rack.svg'
import { ReactComponent as Hook } from '../../../assets/hook.svg'
import { ReactComponent as Fan } from '../../../assets/fan.svg'
import { useEffect, useState } from 'react'

const ATTR_LIST = [
  { Icon: Light, desc: 'Światło' },
  { Icon: Hook, desc: 'Wieszaki' },
  { Icon: Alarm, desc: 'Alarm' },
  { Icon: Rack, desc: 'Regały' },
  { Icon: Outlet, desc: 'Gniazdka' },
  { Icon: Fan, desc: 'Wentylacja' }
]

const WarehouseAttr = ({ selected: s = [], left, more = true }) => {
  const [selected, select] = useState(s)

  useEffect(() => {
    if (ATTR_LIST.length !== selected.length) {
      const list = ATTR_LIST.reduce(r => r.push(Math.floor(Math.random() * 2)) && r, [])
      select(list)
    }
  }, [selected])

  const Item = ({ Icon, desc, selected }) => <div
    className='attr__item'
    style={selected ? {} : { opacity: 0.25 }}>
    <Icon />
    {more && <span>{desc}</span>}
  </div>

  return (
    <div className='element__magAttr'>
      <div className='attr__title --left'>Dostępne opcje</div>
      {left > 0 && <div className='attr__title --right'>Zostało: {left}</div>}
      <div className={`attr__list group${more ? ' --wilde' : ''}`}>
        {ATTR_LIST.map((e, i) => <Item
          {...e}
          key={i}
          selected={selected[i]} />)}
      </div>
    </div>
  )
}

export default WarehouseAttr