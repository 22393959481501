import { ReactComponent as Logo } from '../../assets/logo.svg'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MAG_STATUS } from '../../utils/constants'
import SplashMessage from './SplashMessage'
import SplashButton from './SplashButton'
import SplashSlides from './SplashSlides'
import { useState } from 'react'
import './style.scss'

const INIT_MAGS = [{
  status: MAG_STATUS.closed,
  desc: 'Self-storage teraz online!',
}, {
  status: MAG_STATUS.closed,
  desc: 'Wymierz swojego MAGa',
}, {
  status: MAG_STATUS.closed,
  desc: 'Uzupełnij dane i zapłać online',
}, {
  status: MAG_STATUS.closed,
  desc: 'Zarządzaj zdalnie!',
}]

const message = <SplashMessage />

const SplashScreen = ({ close }) => {
  const [mags, setMags] = useState(INIT_MAGS)

  return (
    <div className='splash'>
      <div className='splash__logo'>
        <Logo />
      </div>

      <Switch>
        <Route exact path={['/splash/:number', '/splash']}>
          <SplashSlides mags={mags} />

          <div className='splash__footer center'>
            {message}
            <SplashButton
              mags={mags}
              close={close}
              setMags={setMags} />
          </div>
        </Route>
        <Redirect to='/splash' />
      </Switch>
    </div>
  )
}

export default SplashScreen