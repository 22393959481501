import ConfirmationPopup from './components/popups/ConfirmationPopup'
import { BrowserRouter } from 'react-router-dom'
import { useState, useRef } from 'react'

const Router = ({ children }) => {
  const [isPrompt, setPrompt] = useState(false)
  const prompt = useRef({
    message: '',
    callback: () => { }
  })

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        prompt.current = { message, callback }
        setPrompt(true)
      }}>
      {children}
      {isPrompt && <Prompt {...prompt.current} setPrompt={setPrompt} />}
    </BrowserRouter>
  )
}

const Prompt = ({ message, callback, setPrompt }) => {
  const close = (result = true) => {
    callback(result)
    setPrompt(false)
  }

  return (
    <ConfirmationPopup
      desc={message}
      cancelAction={() => close(false)}
      okAction={() => close(true)}
    />
  )
}

export default Router