import Header from '../../components/Header'
import { Route } from 'react-router-dom'

const MRoute = ({ path, darkHeader = false, flyingHeader = false, animation = true, children }) => {
  return (
    <Route path={path}>
      <Header
        dark={darkHeader}
        flying={flyingHeader} />

      <main className={`${flyingHeader ? '' : '--header'}${animation ? ' --animated' : ''}`}>
        {children}
      </main>
    </Route>
  )
}

export default MRoute