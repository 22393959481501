import { useHistory, useParams } from 'react-router-dom'
import { selectProvinces } from './mapSearchSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CITIES } from './constants'
import PlaceItem from './PlaceItem'

const Provinces = ({ fullScreen, setFullScreen, anotherSection, selectPosition }) => {
  const [province, setProvince] = useState([])

  const provinces = useSelector(selectProvinces)

  const { provinceIdx = -1, cityIdx = -1 } = useParams()
  const { goBack, push } = useHistory()


  if (provinceIdx > -2 && provinceIdx < 16);
  else goBack()

  const city = cityIdx > -1 && cityIdx < 30 ? province[cityIdx] : null

  useEffect(() => {
    if (provinceIdx > -1 && provinceIdx < 16) setFullScreen(true)
    else setFullScreen(false)
  }, [provinceIdx, setFullScreen])

  useEffect(() => {
    if (provinceIdx < 0) return
    const cities = [...CITIES[provinceIdx]]
    const list = provinces[provinceIdx].list
    const area = list ? list.reduce((r, e) => {
      const idx = r.findIndex(({ city }) => e.city === city)
      if (idx > -1) {
        const element = r[idx]
        const { list = [] } = element
        const copy = [...r]

        copy[idx] = { ...element, list: [...list, e] }
        return copy
      }
      return r
    }, cities) : cities
    setProvince(area)
  }, [provinceIdx, provinces])

  return (
    provinceIdx > -1 ? <section>
      <div className='search__list'>
        <PlaceItem
          arrow
          logo={false}
          selectPosition={p => selectPosition({ ...p, go: city ? -3 : -2 })}
          data={city ? { ...city, list: city.list || [], province: provinces[provinceIdx].name } : provinces[provinceIdx]} />

        {(city ? city.list : province).map((e, i) => <PlaceItem
          arrow
          key={i}
          logo={!!city}
          data={city ? { ...e, province: provinces[provinceIdx].name } : { ...e, list: e.list || [], province: provinces[provinceIdx].name }}
          selectPosition={p => e.list ? push(`/mapsearch/${provinceIdx}/${i}`) : selectPosition({ ...p, go: -2 })} />)}
      </div>

      <div
        className='group'
        onClick={goBack}>
        <h1>{city?.city || provinces[provinceIdx].name}</h1>
        <div className='cross' />
      </div>
    </section> : (
      <section className={anotherSection && !fullScreen ? '--medium' : ''}>
        <h1>Województwa</h1>
        <div
          className='search__list --secondary'
          onScroll={e => {
            if (!anotherSection) return

            const { scrollTop: top } = e.target
            if (top > 25 && !fullScreen) setFullScreen(true)
            else if (top < 4 && fullScreen) setFullScreen(false)
          }}>

          {(fullScreen || !anotherSection) && [-4, -3, -2, -1].map(e => <div
            key={e}
            className='list__item'>
            <span style={{ visibility: 'hidden' }}>-</span>
          </div>)}

          {provinces.map(({ list, ...e }, idx) => <div
            key={idx}
            className='list__item'
            onClick={() => push(`/mapsearch/${idx}`)}>
            <span>{e.name}  <span style={{ opacity: list?.length > 0 ? 1 : 0.5 }}>({list?.length || 0})</span> &#62;</span>
          </div>)}
        </div>
      </section>
    )
  )
}

export default Provinces