const FiltersElement = ({ name, desc, openPopup, removeAction }) => {
  return (
    <div
      className='filters__item'>
      {name && <span className='item__label'>{name}</span>}
      <div className='item__content group'>
        <span onClick={openPopup}>{desc}</span>
        {removeAction && <span
          className='cross'
          onClick={removeAction}>x</span>}
      </div>
    </div>
  )
}

export default FiltersElement