import SocketContext from '../../../context/socket/SocketContext'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const Messages = () => {
  const [isNotification, showNotification] = useState(false)
  const [unreadMessages, setUnreadMessages] = useState(0)
  const { messageData } = useContext(SocketContext)
  const { pathname } = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    if (pathname.includes('chat')) {
      setUnreadMessages(0)
      showNotification(false)
    }
  }, [pathname])

  useEffect(() => {
    if (messageData?.event === 'newMessage' && !window.location.pathname.includes('chat')) {
      setUnreadMessages(m => m + 1)
      showNotification(true)
    }
  }, [messageData])

  useEffect(() => {
    if (isNotification > 0) {
      const timeout = setTimeout(() => showNotification(false), 6000)
      return () => clearTimeout(timeout)
    }
  }, [isNotification])

  return (
    isNotification
      ? <div className='notification'>
        <div className='notification__desc'>
          {unreadMessages === 1 ? 'Masz nieprzeczytaną wiadomość' : `Masz nieprzeczytane ${unreadMessages} wiadomości`}
        </div>
        <div
          className='notification__button --termina'
          onClick={() => { push('/chat/new') }}>Zobacz</div>
      </div>
      : null
  )
}

export default Messages