class Header {
  header = null
  constructor(_header) {
    this.header = _header || null
  }
  
  get = () => this.header
  set = (token) => { this.header = token }
  destroy = () => { this.header = null }
}

const HeadersManager = () => {
  const connectionId = new Header()
  const jwt = new Header()

  return {
    connectionId,
    jwt
  }
}

export default HeadersManager()