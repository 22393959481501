import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { completePurchase } from '../Basket/basketSlice'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import useTranslation from '../../hooks/useTranslation'
import BackBtn from '../../components/buttons/BackBtn'
import usePopupState from '../../hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import Option from '../../components/Option'
import { useState, useEffect } from 'react'
import { banklist } from '../../rest/urls'
import { GET } from '../../rest/request'
import OptionsPopup from './OptionsPopup'
import { useDispatch } from 'react-redux'
import * as methods from './methods'
import Summary from './Summary'
import './style.scss'

const Payment = ({ price, action, primaryTheme = true }) => {

  const [data, setData] = useState([])
  const [status, setStatus] = useState(STATUS.idle)
  const [{ optionId, bankIdx }, select] = useState({
    optionId: '',
    bankIdx: ''
  })

  const { replace, push } = useHistory()
  const { popup, pathname } = usePopupState()

  const { t, lang } = useTranslation('payment')
  const dispatch = useDispatch()

  const showBankList = () => push(pathname, LOCATION_STATES.popup('banks'))
  const updateSelected = (value, name = 'optionId') => select(s => { return { ...s, [name]: value } })

  const purchase = () => {
    dispatch(completePurchase())
    replace('/newwarehouse')
  }

  const getBanksList = async () => {
    try {
      const response = await GET(banklist)
      setStatus(STATUS.succeed)
      setData(response)

    } catch (error) {
      setStatus(STATUS.failed)
    }
  }

  useEffect(() => {
    if (status === STATUS.idle) getBanksList()
  }, [status])


  return (
    <>
      {popup === 'banks' && <div>
        <OptionsPopup
          status={status}
          data={data}
          select={idx => updateSelected(idx, 'bankIdx')}
        />
      </div>}

      <div className={`payment --${primaryTheme ? 'primary' : 'secondary'}`}>
        <div className='title'>
          <div>{t('payment')}</div>
          <div>{t('chooseOneOfOptions')}</div>
        </div>

        <div className='payment__main center'>
          <div>
            <Summary price={price} />
            <p>{t('methodsAvailable')}</p>

            <div className='payment__methods'>
              {methods[lang] && methods[lang].map(({ id, showList, ...e }, i) => {
                const bank = showList && bankIdx ? data[bankIdx] : null

                if (bank) {
                  e.desc = bank?.name
                  e.icon = <img src={bank?.icon} alt={bank?.name} />
                }

                return <Option
                  key={i}
                  {...e}
                  changeIconColor={false}
                  selected={showList ? bankIdx && id === optionId : id === optionId}
                  action={() => {
                    if (showList) showBankList()
                    updateSelected(id)
                  }} />
              })}
            </div>

            <ArrowBtn
              enlarged
              text={t('payNow')}
              action={action || purchase} />

            <BackBtn />
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment