import ResultElement from '../Result/ResultElement'
import { selectFilters } from './filtersSlice'
import { selectPlaces } from '../Map/mapSlice'
import { useSelector } from 'react-redux'

const Place = ({ placeId, scroll }) => {
  const { attributes } = useSelector(selectFilters)
  const places = useSelector(selectPlaces)

  return (
    <div className='map__result'>
      <div className='result__header'>
        <div
          className='header__main'
          style={{ padding: 0, backgroundColor: 'var(--theme-sec-bg)' }}>

          <div className='list --simple'>
            <ResultElement
              shorter
              scroll={scroll}
              data={places.find(({ id }) => id === placeId)}
              warehouseTypeId={attributes['warehouseTypeId'] || ''} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Place