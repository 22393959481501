import { GATEWAY_STATUS, GATE_STATUS, STATUS } from '../../utils/constants'
import { ReactComponent as MagIcon } from '../../assets/magDetails.svg'
import { ReactComponent as HelpIcon } from '../../assets/helpBig.svg'
import { ReactComponent as GateIcon } from '../../assets/gate.svg'
import { ReactComponent as DoorIcon } from '../../assets/door.svg'
import BigCircleBtn from '../../components/buttons/BigCircleBtn'
import CircleBtn from '../../components/buttons/CircleBtn'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { selectLightTheme } from '../../appSlice'
import { login, register } from './credentials'
import { useSelector } from 'react-redux'
import Gateway from './Gateway'
import Gate from './Gate'
import './style.scss'

export const TIMES = {
  gateway: 10,
  gateClosing: 20,
  gateOpening: 15
}

const Entrance = () => {
  const lightTheme = useSelector(selectLightTheme)

  const { push, replace, goBack } = useHistory()
  const { state } = useLocation()

  const { t } = useTranslation('entrance')

  const [gate, setGate] = useState(!state?.gateway)
  const [rawId, setRawId] = useState('')
  const [status, setStatus] = useState({
    gate: GATE_STATUS.closed,
    gateway: GATEWAY_STATUS.closed
  })

  const statusKey = gate ? 'gate' : 'gateway'

  const updateStatus = useCallback(s => setStatus({
    ...status,
    [statusKey]: s
  }), [status, statusKey])

  useEffect(() => {
    if (!rawId && !window.history.state?.state) registration()
  }, [rawId])

  useEffect(() => {
    if (!state) return
    const { status } = state
    if (status) {
      replace(window.location.pathname)
      updateStatus(status)
    }
  }, [replace, state, updateStatus])

  useEffect(() => {
    if (status.gateway === 'gateway_unlocked') {
      const timeout = setTimeout(() => setStatus(s => {
        return {
          ...s,
          gateway: 'gateway_closed'
        }
      }), TIMES.gateway * 1000)
      return () => clearTimeout(timeout)
    }
  }, [status.gateway])

  useEffect(() => {
    const isOpenning = status.gate === 'gate_opening'
    const { gateClosing, gateOpening } = TIMES

    if ((isOpenning && gateOpening > 0) || (status.gate === 'gate_closing' && gateClosing > 0)) {
      const timeout = setTimeout(() => setStatus(s => {
        return {
          ...s,
          gate: isOpenning ? 'gate_opened' : 'gate_closed'
        }
      }), (isOpenning ? gateOpening : gateOpening) * 1000)
      return () => clearTimeout(timeout)
    }
  }, [status.gate])

  useEffect(() => {
    if (status[statusKey] === GATE_STATUS.opened || status[statusKey] === GATEWAY_STATUS.opened) {
      const timeout = setTimeout(goBack, 1000)
      return () => clearTimeout(timeout)
    }
  }, [goBack, status, statusKey])

  const registration = async () => {
    try {
      const id = await register()
      if (id) setRawId(id)
      else throw new Error('registration failed')
    } catch (err) {
      console.error(err)
    }
  }

  const openGate = async s => {
    const logIn = async () => {
      try {
        const res = await login(rawId)
        if (res) updateStatus(s)
        else throw new Error('auth failed')

      } catch (err) {
        console.error(err)
        const { pathname } = window.location
        push(`${pathname}/login`, { status: s, gateway: !gate })
      }
    }

    if (rawId) logIn()
    else registration().then(logIn)
  }

  return (
    <div className='entrance warehouse'>
      <div className={`entrance__main center${lightTheme ? '' : ' --shadow'}`}>
        {gate ? <Gate status={status.gate} /> : <Gateway status={status.gateway} />}
      </div>

      <div className='entrance__menu menu__main'>
        <div className='menu__buttons --top'>
          <CircleBtn
            text={t(gate ? 'openGateway' : 'openGate')}
            Icon={gate ? DoorIcon : GateIcon}
            action={() => setGate(!gate)} />

          <div className='buttons__col center'>
            {gate ? <BigCircleBtn
              text={t(status.gate === GATE_STATUS.opening ? 'opening'
                : status.gate === GATE_STATUS.closing ? 'closing'
                  : status.gate === GATE_STATUS.opened ? 'close' : 'open')}
              status={status.gate.includes('ing') ? STATUS.pennding : STATUS.idle}
              action={() => status.gate === GATE_STATUS.closed && openGate(GATE_STATUS.opening)}
            /> : <BigCircleBtn
              text={t(status.gateway === GATEWAY_STATUS.closed ? 'open' : 'close')}
              status={status.gateway === GATEWAY_STATUS.opened ? STATUS.succeed : STATUS.idle}
              action={() => status.gateway !== GATEWAY_STATUS.unlocked && openGate(GATEWAY_STATUS.unlocked)} />}

            <CircleBtn
              text={t('help')}
              Icon={HelpIcon}
              action={() => push('/help')} />
          </div>

          <CircleBtn
            text={t('manageMag')}
            Icon={MagIcon}
            action={goBack} />
        </div>
      </div>
    </div>
  )
}

export default Entrance