import Controller from './Controller'
import Popups from './Popups'
import View from './View'
import './style.scss'

const Maps = () => {
  return (
    <>
      <Controller />
      <Popups />
      <View />
    </>
  )
}

export default Maps