import { ReactComponent as GPay } from '../../assets/GPay.svg'
import blikSrc from '../../assets/blik.png'

const gPay = <GPay />
const blik = <img src={blikSrc} alt='blik' />

const en = [{
  id: '1',
  title: 'Google Pay',
  desc: 'Pay with your Google Account',
  showList: false,
  icon: gPay,
}, {
  id: '2',
  title: 'Blik',
  desc: 'Internet payments',
  showList: false,
  icon: blik,
}, {
  id: '3',
  title: 'Credit card',
  desc: 'Add a MasterCard / Visa card',
  showList: false,
}, {
  id: '4',
  title: 'Fast transfer',
  desc: 'Choose your bank',
  showList: true,
}]

const pl = [{
  id: '1',
  title: 'Google Pay',
  desc: 'Płać z kontem Google',
  showList: false,
  icon: gPay,
}, {
  id: '2',
  title: 'Blik',
  desc: 'Płatności internetowe',
  showList: false,
  icon: blik,
}, {
  id: '3',
  title: 'Karta płatnicza',
  desc: 'Dodaj kartę Visa / MasterCard',
  showList: false,
}, {
  id: '4',
  title: 'Szybki przelew',
  desc: 'Wybierz swój bank',
  showList: true,
}]

export { pl, en }