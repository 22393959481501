const en = [{
  label: 'Period of time',
  name: 'date',
  type: 'range',
  attr: {
    min: '2022-01-01',
    max: '2022-12-01',
  }
}, {
  label: 'Show downloaded invoices',
  name: 'download',
  type: 'section',
  options: [
    { name: 'All', value: '' },
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ]
}, {
  label: 'Warehouse',
  name: 'mag',
  type: 'section',
  options: [
    { name: 'All', value: '' }
  ]
}]

const pl = [{
  label: 'Okres czasu',
  name: 'date',
  type: 'range',
  attr: {
    min: '2022-01-01',
    max: '2022-12-01',
  }
}, {
  label: 'Pokaż pobrane faktury',
  name: 'download',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' },
    { name: 'Tak', value: 'true' },
    { name: 'Nie', value: 'false' },
  ]
}, {
  label: 'Magazyn',
  name: 'mag',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' }
  ]
}]

export { en, pl }