import { ReactComponent as Logo } from '../../../assets/logo.svg'
import Popup from '../../../components/popups/Popup'
import { useHistory } from 'react-router-dom'


const WarningPopup = ({ mail = false, close }) => {
  const { replace } = useHistory()

  return (
    <Popup close={close}>
      <div className='popup__verification'>
        <Logo />

        <p>{`${mail ? 'Mail' : 'Numer telefonu'} został już wykorzystany, czy zamiast tego wolisz się zalogować? `}</p>

        <div className='verification__buttons center'>
          <div
            className='verification__button --primary --termina'
            onClick={() => replace('/login')}>Zaloguj się</div>
          <div
            className='verification__button'
            onClick={close}>kontynuuj</div>
        </div>
      </div>
    </Popup>
  )
}

export default WarningPopup