import { LOCATION_STATES } from '../../utils/constants'
import useTranslation from '../../hooks/useTranslation'
import usePopupState from '../../hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import FiltersElement from './FiltersElement'
import FilterButton from './FilterButton'
import FilterPopup from './FilterPopup'
import { Fragment } from 'react'
import './style.scss'

const Filters = ({ showFiltersBtn = false, ...restProps }) => {
  const { filters, setFilters, fields } = restProps

  const { popup, pathname } = usePopupState()
  const { t } = useTranslation('filters')

  const { push } = useHistory()

  const openFilters = () => push(pathname, LOCATION_STATES.popup('filters'))

  const removeAction = (key, secKey) => {
    const f = { ...filters }
    if (secKey) {
      delete f[key][secKey]
      if (Object.keys(f[key]).length === 0) delete f[key]

    } else delete f[key]
    setFilters(f)
  }

  return (
    <div className='filters'>
      {popup === 'filters' && <FilterPopup {...restProps} />}

      {(Object.keys(filters).length > 0 || showFiltersBtn) && <div className='group'>
        <div className='filters__list'>
          {Object.entries(filters).map(([k, v], i) => {
            if (k === 'date') {
              const { start, end } = v
              return <Fragment key={i}>
                {start && <FiltersElement
                  desc={start}
                  label={`${t('date')} ${t('from')}`}
                  openPopup={openFilters}
                  removeAction={() => removeAction(k, 'start')} />}
                {end && <FiltersElement
                  desc={end}
                  label={`${t('date')} ${t('to')}`}
                  openPopup={openFilters}
                  removeAction={() => removeAction(k, 'end')} />}
              </Fragment>
            }

            const field = fields.find(({ name }) => name === k)
            return <FiltersElement
              key={i}
              desc={v}
              openPopup={openFilters}
              label={field && field.label}
              removeAction={() => removeAction(k)} />
          })}
        </div>

        {showFiltersBtn && <FilterButton />}
      </div>}
    </div>
  )
}

export default Filters