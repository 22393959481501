import { fetchMapLocations, fetchMapStatus, selectMapOptions, selectMapStatus } from './Map/mapSlice'
import { fetchFilters, selectFilters, selectFiltersStatus } from './menu/filtersSlice'
import { fetchLocations, selectLocationStatus } from './Result/locationSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { PROVINCES } from '../MapSearch/constants'
import { useHistory } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const Controller = () => {
  const { distance, position, voivodship } = useSelector(selectMapOptions)
  const locationStatus = useSelector(selectLocationStatus)
  const filtersStatus = useSelector(selectFiltersStatus)
  const mapStatus = useSelector(selectMapStatus)
  const filters = useSelector(selectFilters)

  const dispatch = useDispatch()

  const filtersRef = useRef(null)
  const firstLaunch = useRef(true)
  const blockFetches = useRef(false)
  const mapStatusController = useRef(null)
  const locationFetchesController = useRef(null)

  const { push } = useHistory()

  useEffect(() => {
    if (mapStatus === STATUS.succeed) blockFetches.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filtersStatus !== STATUS.succeed) dispatch(fetchFilters())
  }, [filtersStatus, dispatch])

  useEffect(() => {
    if (locationStatus === STATUS.succeed && mapStatus === STATUS.succeed) locationFetchesController.current = null
  }, [locationStatus, mapStatus])

  useEffect(() => {
    if (blockFetches.current) {
      blockFetches.current = false
      return
    }

    if (locationFetchesController.current) {
      locationFetchesController.current.abort()
      locationFetchesController.current = null
    }

    if (mapStatusController.current) {
      mapStatusController.current.abort()
      mapStatusController.current = null
    }

    const controller = new AbortController()
    const signal = controller.signal
    const isVoivodship = voivodship !== undefined
    const body = {
      filters: position ? { ...filters, position, distance }
        : isVoivodship ? { ...filters, location: PROVINCES[voivodship].search, distance: '0' }
          : filters
    }

    if (filtersRef.current !== filters) dispatch(fetchMapLocations({ body, signal }))
    if (position || isVoivodship) dispatch(fetchLocations({ body, signal })).then(({ payload }) => {
      if (firstLaunch.current && payload && payload?.places?.filteredAndLocalized?.length === 0 && payload?.places?.localized?.length === 0) {
        push(window.location.pathname, LOCATION_STATES.popup('findplace'))
        firstLaunch.current = false
      }
    })

    locationFetchesController.current = controller
    filtersRef.current = filters
  }, [dispatch, distance, filters, position, push, voivodship])

  useEffect(() => {
    if (mapStatus === STATUS.succeed || mapStatus === STATUS.failed) {
      let interval = setInterval(() => {
        const controller = new AbortController()
        const signal = controller.signal
        const body = {
          filters: voivodship !== undefined ? { ...filters, location: PROVINCES[voivodship].search, distance: '0' }
            : position ? { ...filters, position, distance }
              : filters
        }

        mapStatusController.current = controller
        dispatch(fetchMapStatus({ body, signal }))
          .then(() => mapStatusController.current = null)
      }, 30000)

      return () => clearInterval(interval)
    }
  }, [dispatch, distance, filters, mapStatus, position, voivodship])

  return false
}

export default Controller