import useTranslation from '../../../hooks/useTranslation'
import Skeleton from 'react-loading-skeleton'

const Receipt = ({ data, independent = false }) => {
  const { id, name, pesel, street, building_nr, apartment_nr, zip_code, city, phone = '', email } = data || {}
  const { t } = useTranslation('form')

  const element = <div>
    <div className='list__header element__title'>{t('contractData')}</div>
    <div className='list__element --big'>
      {id ? <div className='element__title'>
        <span>{name}</span>
        <span>PESEL: {pesel}</span>
      </div> : <Skeleton />}

      {id ? <div className='element__desc'>
        <div>{street} {building_nr}{apartment_nr ? `/${apartment_nr}` : ''}</div>
        <div>{zip_code} {city}</div>
        <div>tel. {phone?.replace(/(\d{3})/g, '$1 ')}</div>
        <div>email: {email}</div>
      </div> : <Skeleton
        count={4}
        containerClassName='skeleton__desc' />}
    </div>
  </div>

  return (
    independent ? element :
      <div className='billing__receipt summary'>
        <div className='list'>
          {element}
        </div>
      </div>
  )
}

export default Receipt