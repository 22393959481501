import { LOCATION_STATES } from '../../../utils/constants'
import useTranslation from '../../../hooks/useTranslation'
import { useHistory } from 'react-router-dom'

const NewBtn = ({ newCompany = false, action }) => {
  const { push, location: { pathname } } = useHistory()
  const { t } = useTranslation('form')

  return <div
    className='button__newMag center'
    onClick={() => newCompany ? action() : push(pathname, LOCATION_STATES.popup('newMag'))}>
    <svg xmlns="http://www.w3.org/2000/svg" width="9.5" height="9.5" viewBox="0 0 9.5 9.5">
      <g id="plus" transform="translate(0.75 0.75)">
        <line id="Line_130" data-name="Line 130" y2="8" transform="translate(4)" fill="none" stroke="#eb003d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Line_131" data-name="Line 131" x2="8" transform="translate(0 4)" fill="none" stroke="#eb003d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>
    <div className='button__desc'>{t(newCompany ? 'addAnotherCompany' : 'addAnotherOrder')}</div>
  </div>
}

export default NewBtn
