import { selectWarehouse, updateWarehouseState } from '../Warehouses/warehousesSlice'
import { ReactComponent as Arrow } from '../../assets/circleArrow.svg'
import { ReactComponent as Compass } from '../../assets/compass.svg'
import { ReactComponent as Alert } from '../../assets/alert.svg'
import { ReactComponent as Gate } from '../../assets/gate.svg'
import BigCircleBtn from '../../components/buttons/BigCircleBtn'
import CircleBtn from '../../components/buttons/CircleBtn'
import { MAG_STATUS, STATUS } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const MagActions = ({ expanded, toggleMenu, number }) => {

  const { status = MAG_STATUS.closed, address, mag, wasOpen = false } = useSelector(state => selectWarehouse(state, number))
  const { gpslatitude, gpslongitude } = address || {}
  const { hasOnlineAccess = true } = mag || {}

  const dispatch = useDispatch()
  const { push } = useHistory()

  const updateStatus = value => {
    if (!wasOpen) push(`/first/${number}`)
    else dispatch(updateWarehouseState({
      number,
      key: 'status',
      value
    }))
  }

  return (
    <div className='menu__buttons --top'>
      {hasOnlineAccess ? <CircleBtn
        Icon={Compass}
        text='Nawiguj'
        action={() => window.open(`https://www.google.com/maps/search/?api=1&query=${gpslatitude},${gpslongitude}`)}
      /> : <CircleBtn
        text='Zgłoś problem'
        Icon={Alert}
        action={() => push('/help')} />}

      <div className='buttons__col center'>
        <div style={hasOnlineAccess ? {} : { opacity: 0.5 }}>
          <BigCircleBtn
            text={status === MAG_STATUS.opening ? 'Otwieram...'
              : status === MAG_STATUS.closing ? 'Zamykam...'
                : status === MAG_STATUS.opened ? 'Zamknij' : 'Otwórz'}
            status={status?.includes('ing') ? STATUS.pennding : STATUS.idle}
            action={() => hasOnlineAccess && updateStatus(status === MAG_STATUS.opened ? MAG_STATUS.closing : MAG_STATUS.opening)} />
        </div>

        {!expanded && <CircleBtn
          text='Zarządzaj'
          Icon={Arrow}
          action={toggleMenu} />}
      </div>

      <CircleBtn
        text='Otwórz bramę'
        Icon={Gate}
        action={() => push('/entrance')} />
    </div>
  )
}

export default MagActions