const Paperclip = ({ disabled, length, setFiles }) => {
  const onChange = e => {
    const { files } = e.target
    setFiles((files && files.length > 0) ? files : null)
  }

  return (
    <label>
      <input
        multiple
        type='file'
        disabled={disabled}
        onChange={onChange}
        style={{ display: 'none' }} />

      <div className='paperclip__main'>
        {length > 0 && <div className='paperclip__counter center'>
          <span>{length}</span>
        </div>}

        <svg xmlns="http://www.w3.org/2000/svg" width="16.42" height="24.039" viewBox="0 0 23.068 24.039">
          <path id="paperclip" d="M23.259,11.954,13.209,22a6.565,6.565,0,1,1-9.284-9.284L13.974,2.67a4.377,4.377,0,1,1,6.19,6.19L10.1,18.909a2.188,2.188,0,0,1-3.095-3.095l9.284-9.273" transform="translate(-1.252 -0.638)" fill="rgba(160,165,181,0)" stroke="var(--theme-text)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
      </div>
    </label>
  )
}

export default Paperclip