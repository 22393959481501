import { ReactComponent as Payments } from '../../assets/payments.svg'
import useTranslation from '../../hooks/useTranslation'
import './style.scss'

const Option = ({ title, desc, label, icon = <Payments />, selected: isSeleceted = false, secondary = false, changeIconColor = true, action, buttonText = '' }) => {
  const { t } = useTranslation('basic')

  return (
    <div className={`option${isSeleceted ? ' --selected' : ''}${secondary ? ' --secondary' : ''}`}>
      {label && <div className='option__label'>
        <span>{label}</span>
      </div>}

      <div className='center'>
        <div className={`option__icon center${changeIconColor ? '' : ' --color'}`}>
          {icon}
        </div>

        <div className='option__title'>
          <div className='--termina'>{title}</div>
          <div>{desc}</div>
        </div>
      </div>

      {action && <div
        className='option__button'
        onClick={action}>{buttonText ? buttonText : t(isSeleceted ? 'selected' : 'choose')}</div>}
    </div>
  )
}

export default Option