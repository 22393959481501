import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg'
import { selectWarehouse } from '../../Warehouses/warehousesSlice'
import { ReactComponent as Cost } from '../../../assets/cost.svg'
import { DAY, getRangeFromDates } from '../../../utils/date'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import BackBtn from '../../../components/buttons/BackBtn'
import { useHistory, useParams } from 'react-router-dom'
import ReactCalendar from 'react-calendar'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Item from './Item'

const PRICES = {
  monthly: 300,
  daily: 20
}

const Calendar = ({ amount: previousAmount = 0, date: previousDate, setForm }) => {

  const { goBack } = useHistory()
  const { number } = useParams()

  const { termiateDays, end } = useSelector(state => selectWarehouse(state, number))

  const dateStrToObj = s => s ? new Date(s.split('.').reverse()) : undefined
  const rangeToAmount = r => r.m * PRICES.monthly + r.d * PRICES.daily
  const dateToStr = (d = new Date()) => d.toLocaleDateString()

  const [date, setDate] = useState(dateStrToObj(previousDate))
  const [amount, setAmount] = useState(previousAmount)
  const [summary, showSummary] = useState(false)


  const save = () => {
    setForm(dateToStr(date), amount)
    goBack()
  }

  return <>
    <div className='title'>
      {summary ? <>
        <div>Podsumowanie</div>
        <div>Kiedy chcesz skończyć wynajem?</div>
      </> : <>
        <div>Wybierz datę</div>
        <div>Kiedy chcesz skończyć wynajem?</div>
      </>}
    </div>

    <div className='resignation__main'>
      {summary ? <>
        {(() => {
          const { m, d } = getRangeFromDates(date)

          return <div className='resignation__list'>
            <Item
              icon={<CalendarIcon />}
              title='Okres rozliczeniowy'
              desc={`Od ${dateToStr()} do ${dateToStr(date)}`}
              extra={`${Math.floor((date - new Date()) / DAY)} dni`}
            />

            {m > 0 && <Item
              icon={<Cost />}
              title='Koszt pozostałych miesięcy'
              desc={`${m} miesi${m === 1 ? 'ąc' : m < 5 ? 'ące' : 'ęcy'} x ${PRICES.monthly} zł`}
              extra={`${PRICES.monthly * m} zł`}
            />}

            {d > 0 && <Item
              icon={<Cost />}
              title='Koszt pozostałaych dni'
              desc={`${d} ${m === 1 ? 'dzień' : 'dni'} x ${PRICES.daily} zł`}
              extra={`${PRICES.daily * d} zł`}
            />}

            <div className='list__summary'>W sumie: <span>{amount} zł</span></div>
          </div>
        })()}

      </> : <div className='resignation__calendar calendar'>
        <div className='calendar__labels group'>
          <div>Koniec: <span>{date ? dateToStr(date) : 'Wybierz'}</span></div>
          <div>Do zapłaty: <span>{amount}zł</span></div>
        </div>

        <ReactCalendar
          className='--light'
          value={date}
          maxDate={dateStrToObj(end)}
          minDate={new Date(Date.now() + (termiateDays * DAY))}
          defaultActiveStartDate={new Date(Date.now() + (termiateDays * DAY))}
          showNeighboringMonth={false}
          onChange={d => {
            setDate(d)
            setAmount(rangeToAmount(getRangeFromDates(d)))
          }}
        />

        <div className='calendar__info'>Okres wypowiedzenia: <span>{termiateDays} dni</span></div>
      </div>}

      <div className='resignation__buttons'>
        <ArrowBtn
          enlarged
          text='Dalej'
          disabled={!date}
          action={() => summary ? save() : showSummary(true)}
        />

        <BackBtn
          text='Wróć'
          action={summary ? () => showSummary(false) : undefined}
        />
      </div>
    </div>
  </>
}

export default Calendar