import { lightMarkerIcon, darkMarkerIcon, selectedMarkerIcon } from './customIcon'
import { selectPlaces, selectMapStatus, updateMapOption } from './mapSlice'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { Marker, Tooltip } from 'react-leaflet'
import Sygnet from './Sygnet'

const Markers = ({ mapRef, lightTheme }) => {
  const status = useSelector(selectMapStatus)
  const places = useSelector(selectPlaces)

  const { push, replace } = useHistory()
  const { placeId } = useParams()

  const dispatch = useDispatch()

  const action = path => window.location.pathname === '/map' ? push(path) : replace(path)

  return (
    <>
      {status === STATUS.pennding && <Loading middleOfPage />}
      {status === STATUS.succeed && <MarkerClusterGroup>
        {places.map(({ id, gpslatitude, gpslongitude, distance, marker_text, marker_color }) => {
          const mapPosition = [gpslatitude || 51.666, gpslongitude || 19.389]
          const isSelected = placeId === id

          return <Marker
            key={id}
            position={mapPosition}
            icon={isSelected ? selectedMarkerIcon : lightTheme ? lightMarkerIcon : darkMarkerIcon}
            eventHandlers={{
              click() {
                const zoom = mapRef.current._zoom || 8

                dispatch(updateMapOption({ mapPosition, zoom }))
                action(`/map/${id}`)
              }
            }}>

            <Tooltip direction='top' permanent>
              <div className={`marker__inner${isSelected ? ' --dark' : ''}`}>
                <div className='top'>{marker_text}</div>
                <div className='center'>
                  <Sygnet color={marker_color} />
                </div>
                {distance && <div className='bottom'>{distance} km</div>}
              </div>
            </Tooltip>
          </Marker>
        })}
      </MarkerClusterGroup>}
    </>
  )
}

export default Markers