import { useHistory, useParams } from 'react-router-dom'
import { STATUS } from '../../../utils/constants'
import Payment from '../../Payment'
import Calendar from './Calendar'
import { useState } from 'react'
import Summary from './Summary'
import Default from './Default'
import Camera from './Camera'

export const RESIGNATION_TYPE = {
  date: 'date',
  photo: 'photo',
  payment: 'payment'
}

const MagResignation = () => {
  const [status, setStatus] = useState(STATUS.idle)
  const [form, setForm] = useState({
    date: '',
    amount: 0,
    photo: '',
    paid: false
  })

  const { action } = useParams()
  const { goBack } = useHistory()

  return (
    <div className='menu__section menu__resignation'>
      {(() => {
        switch (action) {
          case RESIGNATION_TYPE.date: return <Calendar
            amout={form.amount}
            photo={form.date}
            setForm={(date, amount) => setForm(f => { return { ...f, date, amount } })}
          />

          case RESIGNATION_TYPE.photo: return <Camera
            photo={form.photo}
            setForm={photo => setForm(f => { return { ...f, photo } })}
          />

          case RESIGNATION_TYPE.payment: return <Payment
            price={form.amount}
            primaryTheme={false}
            action={() => {
              setForm(f => { return { ...f, paid: true } })
              goBack()
            }}
          />

          default: return status === STATUS.succeed ? <Summary />
            : <Default {...form} setStatus={setStatus} />
        }
      })()}
    </div>
  )
}

export default MagResignation