import { selectIsLoggedIn } from '../Login/loginSlice'
import Receipt from '../forms/Billing/Receipt'
import { useEffect, useState } from 'react'
import { userInfo } from '../../rest/urls'
import { useSelector } from 'react-redux'
import { GET } from '../../rest/request'

const UserSection = () => {
  const isLogin = useSelector(selectIsLoggedIn)

  const [data, setData] = useState({})

  useEffect(() => {
    if (isLogin) {
      const controller = new AbortController()
      getUserData(controller.signal)

      return () => controller.abort()
    }
  }, [isLogin])

  const getUserData = async (signal) => {
    try {
      const response = await GET(userInfo, { signal })
      if (response.data) setData(response.data)

    } catch (err) {
      console.warn('Fetch: failed')
    }
  }

  return (
    <Receipt independent data={data} />
  )
}

export default UserSection