import { ReactComponent as Filter } from '../../assets/filter.svg'
import { LOCATION_STATES } from '../../utils/constants'
import { useHistory } from 'react-router-dom'

const FilterButton = () => {
  const { push } = useHistory()

  return (
    <div
      className='filter__button center'
      onClick={() => push(window.location.pathname, LOCATION_STATES.popup('filters'))}>
      <Filter />
      <span>Filtruj</span>
    </div>
  )
}

export default FilterButton