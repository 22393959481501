import { updateWarehouseState } from '../../conteiners/Warehouses/warehousesSlice'
import rightDoor from '../../assets/warehouse/rightDoor.png'
import leftDoor from '../../assets/warehouse/leftDoor.png'
import shadow from '../../assets/warehouse/shadow.png'
import { MAG_STATUS } from '../../utils/constants'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Alert from './Alert'
import './style.scss'

const Warehouse = ({ status = MAG_STATUS.closed, alerts = [], number, showShadow = false }) => {

  const dispach = useDispatch()

  const mod = (() => {
    switch (status) {
      case MAG_STATUS.closing: return '--closed --animate'
      case MAG_STATUS.opening: return '--opened --animate'
      case MAG_STATUS.opened: return '--opened'
      default: return '--closed'
    }
  })()
  const isAlert = alerts.length > 0

  const updateState = useCallback((key, value) => dispach(updateWarehouseState({ number, key, value })), [dispach, number])
  const updateStatus = useCallback(status => updateState('status', status === MAG_STATUS.closing ? MAG_STATUS.closed : MAG_STATUS.opened), [updateState])
  const updateAlert = index => updateState('alerts', [...alerts].filter((_, i) => i !== index))

  useEffect(() => {
    if (number === undefined) return
    if (status === MAG_STATUS.closing || status === MAG_STATUS.opening) {
      const timeout = setTimeout(() => updateStatus(status), 3000)
      return () => clearTimeout(timeout)
    }
  }, [number, status, updateStatus])

  return (
    <div className={`warehouse__animation ${mod} ${isAlert ? ' --warning' : showShadow ? ' --shadow' : ''}`}>
      <div className='animation__interior'>
        <img className='animation__door --left' src={leftDoor} alt='door' />
        <img className='animation__door --right' src={rightDoor} alt='door' />
        <img className='animation__shadow' src={shadow} alt='shadow' />
        {isAlert && <Alert {...alerts[0]} action={() => updateAlert(0)} />}
      </div>
    </div>
  )
}

export default Warehouse