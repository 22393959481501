import { initialExp, finalExp } from '../../../utils/validation'
import { selectPlaces, updateMapOption } from '../Map/mapSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from '../menu/filtersSlice'
import Popup from '../../../components/popups/Popup'
import { locationRequest } from '../../../rest/urls'
import { useHistory } from 'react-router-dom'
import { POST } from '../../../rest/request'
import { useEffect, useState } from 'react'

const NewPlacePopup = () => {

  const filterLocation = useSelector(selectLocation)
  const places = useSelector(selectPlaces)

  const [place, setPlace] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [form, setForm] = useState({
    location: filterLocation,
    phone: '',
    mail: ''
  })

  const { goBack, push } = useHistory()
  const dispatch = useDispatch()

  const { location, phone, mail } = form
  const isDisabled = isLoading || !location || !(phone.match(finalExp.phone) || mail.match(finalExp.mail))


  useEffect(() => {
    if (places) {
      const nearest = places.reduce((r, e) => !e.distance || (r?.distance < e.distance) ? r : e, null)
      setPlace(nearest)
    }
  }, [places])

  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target
    if (name === 'location' || (!value || value.match(initialExp[name]))) setForm({
      ...form,
      [name]: value
    })
  }

  const submit = async () => {
    if (isDisabled) return
    try {
      const body = { location, phone, email: mail }
      const response = await POST(locationRequest, { body })
      if (response) goBack()
    } catch (error) {
      setLoading(false)
    }
  }

  const getPlaceData = () => {
    const { id, gpslatitude, gpslongitude, distance } = place

    const showPlace = () => {
      if (!id) return
      goBack()
      if (gpslatitude && gpslongitude) dispatch(updateMapOption({
        mapPosition: [gpslatitude, gpslongitude]
      }))
      setTimeout(() => push(`/map/${id}`))
    }

    return <p>
      <span>Najbliższy <u onClick={showPlace}>plac</u>: <span style={{ color: 'var(--red)' }}>{distance} km</span></span>
    </p>
  }

  return (
    <Popup
      centered
      isLine={false}>

      <div className='popup--newPlace'>
        {place && getPlaceData()}

        <div className='popup__input'>
          {location && <div className='input__label'>Gdzie mamy postawić MAGa?</div>}
          <input
            value={location}
            name='location'
            placeholder='Gdzie mamy postawić MAGa?'
            onChange={changeHandler} />
        </div>

        <p>
          <span>Powiadomienia</span>
          <span>Uzupełnij aby otrzymywać inf. o budowie</span>
        </p>

        <div className='popup__input'>
          {phone && <div className='input__label'>Numer telefonu</div>}
          <input
            value={phone}
            name='phone'
            type='tel'
            placeholder='Numer telefonu'
            onChange={changeHandler} />
        </div>

        <div className='popup__input'>
          {mail && <div className='input__label'>Adres e-mail</div>}
          <input
            value={mail}
            name='mail'
            placeholder='Adres e-mail'
            onChange={changeHandler} />
        </div>

        <div className='popup__buttons center'>
          <div
            className='button__cancel --termina'
            onClick={goBack}>Zamknij</div>
          <div
            className='button__submit --termina'
            style={isDisabled ? { opacity: 0.5 } : undefined}
            onClick={submit}>Zgłoś</div>
        </div>
      </div>
    </Popup>
  )
}

export default NewPlacePopup