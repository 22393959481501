import { selectContract, selectIsLoggedIn } from './loginSlice'
import { selectBasket } from '../Basket/basketSlice'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Form from './Form'
import './style.scss'

const Login = () => {
  const { list = [] } = useSelector(selectBasket)
  const isLogin = useSelector(selectIsLoggedIn)
  const contract = useSelector(selectContract)

  const { replace, push } = useHistory()

  useEffect(() => {
    if (isLogin) replace(list?.length > 0 ? contract ? '/contract' : '/billing' : '/account')
  }, [contract, isLogin, list?.length, replace])

  return (
    <div className='login'>
      <div className='login__title title'>
        <div>Zaloguj się</div>
      </div>

      <div className='center'>
        <div className='login__main'>
          <Form />

          <div className='login__register'>
            <hr />
            <p>Nie masz konta?</p>
            <p
              className='--termina'
              onClick={() => push('/registration')}>Zarejestruj się</p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login