import { forwardRef, useState } from 'react'
import './style.scss'

const Input = forwardRef(({ required, type = 'text', disabled = false, name, value, icon, iconPosRight = false, warning = false, label, enterClicked, changeHandler, blurHandler, focusHandler, ...restAttr }, ref) => {
  const password = type === 'password'

  const [secured, setSecure] = useState(password)
  const [focused, setFocus] = useState(false)

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.target.blur()
      ref && enterClicked(e.target)
    }
  }

  const updateFocus = (e, focus, action) => {
    setFocus(focus)
    action && action(e)
  }

  return (
    <div className={`input__default${focused ? ' --focused' : ''}${disabled ? ' --disabled' : warning ? ' --warning' : ''}`}>

      <label className='input__label' htmlFor={name}>
        <span className='label--warning'>{required ? '* ' : ''}</span>
        <span>{label} </span>
        {warning && value && <span className='label--warning'>
          - {warning}
        </span>}
      </label>

      <div className='input__main'>
        <input
          className={`input${icon ? iconPosRight ? ' --right' : ' --left' : ''}${password ? ' --right' : ''}`}
          ref={ref}
          id={name}
          name={name}
          value={value}
          disabled={disabled}
          type={password ? secured ? 'password' : 'text' : type}
          {...restAttr}
          onKeyDown={onKeyDown}
          onChange={changeHandler}
          onFocus={e => updateFocus(e, true, focusHandler)}
          onBlur={e => updateFocus(e, false, blurHandler)}
        />

        {icon && <div className={`input__icon --${iconPosRight && !password ? 'right' : 'left'}`}>
          {icon}
        </div>}

        {password && <div
          className='input__icon --right'
          onClick={() => setSecure(!secured)}>

          <svg xmlns="http://www.w3.org/2000/svg" width="18.121" height="18.121" viewBox="0 0 18.121 18.121">
            <g id="eye-off" transform="translate(-0.08 -0.08)">
              <path id="Path_1926" data-name="Path 1926" d="M13.537,14.317a7.453,7.453,0,0,1-4.4,1.525C3.96,15.842,1,9.921,1,9.921a13.655,13.655,0,0,1,3.745-4.4M7.587,4.178A6.75,6.75,0,0,1,9.141,4c5.181,0,8.141,5.921,8.141,5.921a13.692,13.692,0,0,1-1.6,2.361M10.71,11.49A2.22,2.22,0,1,1,7.572,8.352" transform="translate(0 -0.78)" fill="none" stroke="#d8d8d8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              {secured && <line id="Line_146" data-name="Line 146" x2="16" y2="16" transform="translate(1.141 1.141)" fill="none" stroke="#d8d8d8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />}
            </g>
          </svg>
        </div>}
      </div>
    </div>
  )
})

export default Input