import { selectLocation, updateLocation } from '../../conteiners/Maps/menu/filtersSlice'
import { updateMapOption } from '../../conteiners/Maps/Map/mapSlice'
import InaccessiblePopup from '../popups/InaccessiblePopup'
import useTranslation from '../../hooks/useTranslation'
import { LOCATION_STATES } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import usePopupState from '../../hooks/usePopupState'
import { useHistory } from 'react-router-dom'

const USER_LOCATION = 'Twoja lokalizacja'

const Location = ({ secIcon = false, white = false, back }) => {

  const { t } = useTranslation('map')
  const location = useSelector(selectLocation)

  const { popup, pathname } = usePopupState()
  const { push } = useHistory()

  const dispatch = useDispatch()

  const getLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }

    const success = pos => {
      console.log(pos)
      const { latitude, longitude } = pos.coords

      dispatch(updateLocation(USER_LOCATION))
      dispatch(updateMapOption({
        position: [latitude, longitude],
        showMarker: true,
      }))
      back && back()
    }

    const error = err => {
      push(pathname, LOCATION_STATES.popup('location'))
      console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    navigator.geolocation.getCurrentPosition(success, error, options)
  }

  return <>
    {popup === 'location' && <InaccessiblePopup
      desc={t('locationError')} />}

    <div className='location__button'
      onClick={getLocation}>

      {secIcon ?
        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h48v48h-48z" fill="none" />
          <path d="M24 16c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm17.88 6c-.92-8.34-7.54-14.96-15.88-15.88v-4.12h-4v4.12c-8.34.92-14.96 7.54-15.88 15.88h-4.12v4h4.12c.92 8.34 7.54 14.96 15.88 15.88v4.12h4v-4.12c8.34-.92 14.96-7.54 15.88-15.88h4.12v-4h-4.12zm-17.88 16c-7.73 0-14-6.27-14-14s6.27-14 14-14 14 6.27 14 14-6.27 14-14 14z" fill={`var(--${location === USER_LOCATION ? 'red' : 'theme-text'})`} />
        </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
          <g id="Group_14178" data-name="Group 14178" transform="translate(-18 -493)">
            <rect id="Bg" width="44" height="44" rx="10" transform="translate(18 493)" fill="#212121" />
            <path id="location-pin" d="M21.62,0A10.168,10.168,0,0,0,11.5,10.193c0,7.987,9.169,16.234,9.559,16.58a.845.845,0,0,0,1.122,0c.39-.347,9.559-8.594,9.559-16.581A10.168,10.168,0,0,0,21.62,0Zm0,15.742a5.622,5.622,0,1,1,5.622-5.622A5.629,5.629,0,0,1,21.62,15.742Z" transform="translate(18.38 501.507)" fill={white ? '#fff' : '#eb003d'} />
          </g>
        </svg>}
    </div>
  </>
}

export default Location