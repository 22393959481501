import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const usePopupState = () => {
  const [popup, setPopup] = useState(undefined)
  const { state, pathname } = useLocation()

  useEffect(() => {
    const isPopup = state?.popup
    if (isPopup) setPopup(isPopup)
    else {
      const timeout = setTimeout(() => setPopup(isPopup), 200)
      return () => clearTimeout(timeout)
    }
  }, [state?.popup])

  return { popup, pathname }
}

export default usePopupState