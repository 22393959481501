import { ReactComponent as SearchIcon } from '../../assets/search.svg'
import Location from '../../components/Location'
import { useState } from 'react'

const Search = ({ back, search, setSearch, resetMap }) => {
  const [keyboardActive, setKeyboardActive] = useState(false)

  return (
    <div className='search__panel'>
      {!keyboardActive && <div className='search__button'>
        <Location
          white
          back={() => {
            resetMap()
            back()
          }} />
      </div>}

      <div className='search__input'>
        <input
          value={search}
          placeholder='szukaj ulicy...'
          onFocus={() => setKeyboardActive(true)}
          onBlur={() => setKeyboardActive(false)}
          onKeyDown={e => e.keyCode === 13 && e.target.blur()}
          onChange={e => {
            const { value } = e.target
            if (!value || value.match(/^([\p{L}\d .,-]*)$/u)) setSearch(value)
          }} />

        <div
          className='input__icon'
          onClick={() => search && setSearch('')}>
          {!search ? <SearchIcon />
            : <div className='cross' />}
        </div>
      </div>

      <div className='search__button'>
        <div
          className='button__cancel'
          onClick={() => back()}>Anuluj</div>
      </div>
    </div>
  )
}

export default Search