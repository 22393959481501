import { UNAUTHORIZED_TYPES } from '../utils/constants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const useAuthorizedReduxFetch = () => {

  const dispatch = useDispatch()
  const { push } = useHistory()

  const run = (asyncThunkAction, success) => {
    dispatch(asyncThunkAction).then(res => {
      if (res.error) {
        try {
          const error = JSON.parse(res.error?.message)
          const { state } = window.history
          const { type } = error

          if (type === UNAUTHORIZED_TYPES.pin
            || type === UNAUTHORIZED_TYPES.mail
            || type === UNAUTHORIZED_TYPES.phone
            || type === UNAUTHORIZED_TYPES.device
            || type === UNAUTHORIZED_TYPES.webAuth) {
            if (state?.state?.type !== type) push(window.location.pathname, { ...error, popup: type })
          }

        } catch (error) { }
      } else if (success) success()
    })
  }

  return { run }
}

export default useAuthorizedReduxFetch