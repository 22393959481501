import { useEffect, useMemo, useState, useRef } from 'react'
import usePopupState from '../../hooks/usePopupState'
import { isMobile } from '../../utils/isMobile'
import BasketDetails from './BasketDetails'
import './style.scss'

const mobile = isMobile()

const BasketConteiner = ({ extendedAppearance = true, children }) => {
  const [height, setHeight] = useState(0)

  const mainRef = useRef(null)
  const basketRef = useRef(null)

  const { popup } = usePopupState()

  const isBasketExpanded = popup === 'basket'

  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => document.body.style.removeProperty('overflow')
    }
  }, [])

  useEffect(() => {
    if (!isBasketExpanded && mainRef.current && basketRef.current) {
      const resize = () => setHeight(mainRef.current.clientHeight - basketRef.current.clientHeight)

      resize()
      window.addEventListener('resize', resize, false)
      return () => window.removeEventListener('resize', resize, false)
    }
  }, [isBasketExpanded])

  return <div className='basket' ref={mainRef}>
    <section
      className='--animated'
      style={height ? { height: `${height}px`, overflowY: 'auto' } : {}}>
      {children}
    </section>

    {useMemo(() => <BasketDetails
      ref={basketRef}
      extendedAppearance={extendedAppearance} />, [extendedAppearance])}
  </div>
}

export default BasketConteiner