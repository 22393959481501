import useTranslation from '../../hooks/useTranslation'
import BackBtn from '../../components/buttons/BackBtn'
import Popup from '../../components/popups/Popup'
import { STATUS } from '../../utils/constants'
import Loading from '../../components/Loading'
import { useHistory } from 'react-router-dom'
import Error from '../../components/Error'
import { useRef } from 'react'

const OptionsPopup = ({ status, data, select }) => {
  const { t } = useTranslation('payment')
  const { goBack } = useHistory()

  const listRef = useRef()

  return (
    <Popup>
      <div className='popup__payment'>
        <div className='popup__title'>
          <div>{t('chooseBank')}</div>
        </div>

        <div
          ref={listRef}
          className='payment__list'
          onTouchStart={e => {
            if (listRef.current?.scrollTop !== 0) e.stopPropagation()
          }}>

          {status === STATUS.failed ? <Error />
            : status === STATUS.succeed ? typeof data === 'object' && Object.entries(data)
              .map(([idx, { id = 0, name = '', icon = '' }]) => <div
                key={id}
                className='list__item'
                onClick={() => {
                  select(idx)
                  goBack()
                }}>
                <img src={icon} alt={name} />
              </div>
              ) : <Loading />}
        </div>

        <BackBtn />
      </div>
    </Popup>
  )
}

export default OptionsPopup