import { ReactComponent as Terminate } from '../../assets/terminateRent.svg'
import { ReactComponent as Calendar } from '../../assets/calendarSmall.svg'
import { changeSelectedItem, selectBasket } from './basketSlice'
import { ReactComponent as Cost } from '../../assets/cost.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalDateString } from '../../utils/date'
import { useHistory } from 'react-router-dom'
import SummaryHeader from './SummaryHeader'

const BasketSummary = () => {
  const { selected, list, toPay = {} } = useSelector(selectBasket)
  const { t } = useTranslation('basket')

  const { goBack, replace } = useHistory()
  const dispatch = useDispatch()

  return (
    <div
      className='basket__summary'>
      {list.map(({ identifier, hash, start, end, termiateDays, rangeDate: { m, d } }, idx) => {
        const isSelected = selected === idx

        return <div
          key={idx}
          className={`summary__item${isSelected ? ' --selected' : ''}`}
          style={isSelected ? {} : { opacity: .57 }}
          onClick={() => {
            if (!isSelected) {
              goBack()
              dispatch(changeSelectedItem(idx))
              const pathname = window.location.pathname
              if (pathname.includes('calendar')) {
                replace(pathname.replace(/(\/calendar\/[A-z0-9-_]*\/)([A-z0-9-_]*)/, `$1${hash}`))
              }
            }
          }}>

          <SummaryHeader
            index={idx}
            block={!isSelected} />

          <div className='basket__decs group'>
            <div className='center'>
              <Calendar />
              <div>{t('rentalPeriod')}</div>
            </div>
            <div>{getLocalDateString(start)} - {end ? getLocalDateString(end) : 'niokreślono'}</div>
          </div>

          {/* {m !== 0 && <div className='basket__decs group'>
            <div className='center'>
              <Cost />
              <div>{t(m === 1 && d === 0 ? 'rentalCost' : 'monthlyCost')}</div>
            </div>
            <div>{amount * Number(m === 0 ? dailyPrice * d : monthlyPrice)} zł</div>
          </div>}

          {d > 0 && <div className='basket__decs group'>
            <div className='center'>
              <Cost />
              <div>{t(m === 0 ? 'rentalCost' : 'costOfRemainingDays')}</div>
            </div>
            <div>{d} {t(d === 1 ? 'day' : 'days')} x {dailyPrice} zł = {dailyPrice * d} zł</div>
          </div>} */}

          {toPay[identifier]?.list?.map(({ text, price }, i) => <div
            key={i}
            className='basket__decs group'>
            <div className='center'>
              <Cost />
              <div>{text}</div>
            </div>
            <div>{price} zł</div>
          </div>)}

          <div className='basket__decs group'>
            <div className='center'>
              <Terminate className='terminate' />
              <div>{t('periodOfNotice')}</div>
            </div>
            <div>{termiateDays} {t('days')}</div>
          </div>

          <hr />
        </div>
      })}
    </div>
  )
}

export default BasketSummary