import { ReactComponent as Bulldozer } from '../../assets/bulldozer.svg'
import { selectMapOptions } from '../Maps/Map/mapSlice'
import { getDistance } from '../Maps/Map/mapUtils'
import { selectPlace } from './mapDetailsSlice'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

const DetailsLocation = ({ succeed }) => {
  const { shortcutName, street, estateNumber, localNumber, city, province, closest_availability_date: availabilityDate, marker_color, gpslatitude, gpslongitude } = useSelector(selectPlace)
  const { position } = useSelector(selectMapOptions)

  const { goBack, replace } = useHistory()

  const isPLanned = marker_color === 'yellow'

  const inner = <div className={`location__main${isPLanned ? ' --planned' : ''}`}>
    {position && succeed && <div className='location__distance'>
      {isPLanned ? 'W budowie, ' : ''}{getDistance(position, [gpslatitude || 51.666, gpslongitude || 19.389]).toFixed(1)}km od Ciebie
    </div>}

    <div className='location__group group'>
      {succeed ? <div className='center'>
        {isPLanned && <Bulldozer />}
        <div className='location__desc'>
          <div className='location__title --termina'>{shortcutName} - {city}</div>
          <div className='location__address'>ul.{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, {province}</div>
        </div>
      </div> : <Skeleton
        count={2}
        containerClassName='skeleton__desc' />}

      {succeed ? <div
        className='location__button'
        onClick={() => window.history ? goBack() : replace('/map')}>Zmień</div>
        : <Skeleton className='skeleton__button' />}
    </div>
  </div>

  return (
    <div className='details__location'>
      {availabilityDate ? <div className={`location__main${isPLanned ? ' --planned' : ''}`}>
        {inner}
        <div className='location__additional group'>
          <div>Planowane oddanie placu:</div>
          <div>{availabilityDate}</div>
        </div>
      </div> : inner}
    </div>
  )
}

export default DetailsLocation