const toRadians = value => value * Math.PI / 180

export const DISTANCE_LIST = ['10', '25', '40', '55', '70']

export const getDistance = (point1, point2, meters = false) => {
  const R = meters ? 6371071 : 6371.071
  const rlat1 = toRadians(point1[0])
  const rlat2 = toRadians(point2[0])
  const diffLat = rlat2 - rlat1
  const diffLon = toRadians(point2[1] - point1[1])
  const sinHalfDiffLat = Math.sin(diffLat / 2)
  const sinHalfDiffLon = Math.sin(diffLon / 2)

  return 2 * R * Math.asin(Math.sqrt(sinHalfDiffLat * sinHalfDiffLat + Math.cos(rlat1) * Math.cos(rlat2) * sinHalfDiffLon * sinHalfDiffLon))
}

export const getZoom = r => {
  switch (r) {
    case DISTANCE_LIST[0]: return 10.5
    case DISTANCE_LIST[1]: return 9.2
    case DISTANCE_LIST[2]: return 8.5
    case DISTANCE_LIST[3]: return 8.0
    case DISTANCE_LIST[4]: return 7.7
    default: return 6.4
  }
}