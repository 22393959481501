import ArrowBtn from '../../components/buttons/ArrowBtn'
import BackBtn from '../../components/buttons/BackBtn'
import { selectBasket } from '../Basket/basketSlice'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PriceSection from './PriceSection'
import UserSection from './UserSection'
import { useLayoutEffect } from 'react'
import MagSection from './MagSection'
import Section from './Section'
import './style.scss'

const Summary = () => {
  const { invoice, list } = useSelector(selectBasket)
  const { push, replace } = useHistory()

  useLayoutEffect(() => {
    if (list?.length === 0) replace('/map')
  }, [list?.length, replace])

  return (
    <div className='summary'>
      <div className='title'>
        <div>Podsumowanie</div>
        <div>Sprawdź dane</div>
      </div>

      <div className='list'>
        <UserSection />

        {invoice && <Section
          header='Dane do faktury'
          title={[invoice?.name, `NIP: ${invoice?.nip}`]}
          desc={[invoice?.streetName, `${invoice?.postalCode} ${invoice?.voivodshipName}`]} />}

        {list?.length > 0 && <>
          <div className='list'>
            {list.map((e, idx) => <MagSection key={idx} {...e} />)}
          </div>

          <PriceSection />
        </>}

        <Section
          header='Oświadczenia'
          title={['Oświadczam że']}
          desc={['Nie będę magazynować przedmiotów nielegalnych oraz że będę wykonywał wszystkie polecenia i wskazania aby umowa przebiegała miło i sympatycznie']} />
      </div>

      <div className='summary__buttons'>
        <ArrowBtn
          enlarged
          text='Zapłać'
          action={() => {
            window.scrollTo({ top: 0 })
            push('/payment')
          }} />

        <BackBtn />
      </div>
    </div>
  )
}

export default Summary