import { ReactComponent as Logo } from '../../assets/logo.svg'
import usePopupState from '../../hooks/usePopupState'
import Popup from '../../components/popups/Popup'
import React from 'react'

export const POPUPS = {
  mobileWarehouse: 1,
  otherServices: 2,
  invest: 3,
}

const Popups = () => {
  const { popup } = usePopupState()

  switch (popup) {
    case 3: return (
      <Popup>
        <div className='popup__title'>
          <Logo />
          <div>Już wkrótce!</div>
          <div>Będziesz mógł zacząć zarabiać z nami</div>
        </div>
      </Popup>
    )

    case 2: return (
      <Popup>
        <div className='popup__title'>
          <Logo />
          <div>Usługi</div>
          <div>Poznaj nasze inne usługi</div>
        </div>

        <div className='popup__list'>
          <ul>
            <li>Przechowalnia (w przygotowaniu)</li>
            <li>Wirtualny sejf (w fazie projektowania)</li>
          </ul>
        </div>
      </Popup>
    )

    case 1: return (
      <Popup>
        <div className='popup__title'>
          <Logo />
          <div>Już wkrótce!</div>
          <div>Będziesz mógł wynająć magazyn</div>
        </div>

        <div className='popup__list'>
          <div className='list__title'>Wystarczy że:</div>
          <ul>
            <li>Wybierzesz okres czasu w przeciągu którego ma u Ciebie stać magazyn.</li>
            <li>Wskażesz nam adres gdzie ma zostać dostarczony.</li>
          </ul>
        </div>
      </Popup>
    )

    default: return null
  }
}

export default Popups